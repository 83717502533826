var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.modifiedChatData
    ? _c(
        "div",
        {
          staticClass: "pa-chat-log",
          class:
            ((_obj = {}),
            (_obj[_vm.chatLogClass] = _vm.isMobile !== true),
            (_obj["pa-chat-log--mobile"] =
              _vm.isMobile && _vm.isAudioVideoStartedBySomeone),
            _obj),
        },
        [
          _c("MeetingSidebar", {
            attrs: { isSidebarActive: _vm.isOpenedSidebar },
            on: { closeAppointmentsSidebar: _vm.toggleSidebar },
          }),
          _c("chats-view-sidebar", {
            attrs: { isSidebarActive: _vm.showChatsSideBar, data: _vm.chats },
            on: { closeSidebar: _vm.toggleChatsDataSidebar },
          }),
          _c(
            "scroll-view",
            {
              ref: "svChatLog",
              class: !_vm.maxHeight ? "scroll-area" : "",
              style: _vm.chatHeightStyle,
            },
            _vm._l(_vm.modifiedChatData, function (msg, index) {
              return _c(
                "div",
                { key: index },
                [
                  _vm.modifiedChatData[index - 1]
                    ? [
                        !_vm.isSameDay(
                          msg.time,
                          _vm.modifiedChatData[index - 1].time
                        )
                          ? _c("vs-divider", { staticClass: "msg-time" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.toDate(msg.time))),
                              ]),
                            ])
                          : _vm._e(),
                        !_vm.hasSentPreviousMsg(
                          _vm.modifiedChatData[index - 1].sender !== "visitor",
                          msg.sender !== "visitor"
                        )
                          ? _c("div", { staticClass: "spacer mt-2" })
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      ref: "chatMessage" + msg.time,
                      refInFor: true,
                      staticClass: "flex items-start",
                      class: [{ "flex-row-reverse": msg.sender !== "visitor" }],
                    },
                    [
                      _vm.modifiedChatData[index - 1]
                        ? [
                            !(
                              !_vm.hasSentPreviousMsg(
                                _vm.modifiedChatData[index - 1].sender ===
                                  "agent",
                                msg.sender === "agent"
                              ) ||
                              !_vm.isSameDay(
                                msg.time,
                                _vm.modifiedChatData[index - 1].time
                              )
                            )
                              ? _c("div")
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "chat-message",
                          class: _vm.getChatClass(),
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "break-words relative shadow-md rounded py-3 px-4 rounded-lg max-w-message",
                              class: {
                                "agent-message":
                                  msg.sender === "agent" ||
                                  msg.sender === "bot",
                                "visitor-message": msg.sender === "visitor",
                                "private-message":
                                  msg.type === "agent-private-message",
                                "private-message_location":
                                  msg.type === "agent-private-message" &&
                                  msg.pageData,
                              },
                            },
                            [
                              _vm.loadingIds.find(function (x) {
                                return x.loaderId === msg.chatTime
                              })
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "chat-message-overlay-inner",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "chat-message-overlay-content",
                                        },
                                        [
                                          _c("span", {
                                            staticClass:
                                              "chat-message-overlay-spinner",
                                          }),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c("div", { staticClass: "top-right" }, [
                                _vm._v(_vm._s(_vm.chatTime(msg.time))),
                              ]),
                              _c(
                                "div",
                                { staticClass: "flex flex-col w-full" },
                                [
                                  msg.sender === "agent"
                                    ? _c("div", { staticClass: "agent-name" }, [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.getDisplayName(msg.agentId)
                                            ) +
                                            "\n              "
                                        ),
                                      ])
                                    : _vm._e(),
                                  msg.sender === "visitor" &&
                                  _vm.visitor.visitorDisplayName
                                    ? _c(
                                        "div",
                                        { staticClass: "visitor-name" },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.visitor.visitorDisplayName
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  msg.type === "call-recording"
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: { "text-align": "left" },
                                        },
                                        [
                                          _c("video", {
                                            staticStyle: {
                                              "object-fit": "cover",
                                              "border-radius": "6px",
                                            },
                                            attrs: {
                                              preload: "metadata",
                                              controls: "",
                                              width: "160",
                                              height: "90",
                                              src: msg.link,
                                            },
                                          }),
                                        ]
                                      )
                                    : msg.type === "agent-private-message"
                                    ? _c("div", [
                                        msg.reworkData
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "text-align": "left",
                                                },
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _c("strong", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "vue.reworkInfo"
                                                          )
                                                        ) + ":"
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "message-content",
                                                  },
                                                  [
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("vue.status")
                                                        ) + ":"
                                                      ),
                                                    ]),
                                                    _c(
                                                      "div",
                                                      { staticClass: "flex" },
                                                      [
                                                        _c("div", [
                                                          _vm._v(
                                                            _vm._s(
                                                              msg.reworkData
                                                                .status.name
                                                            )
                                                          ),
                                                        ]),
                                                        _c("div", {
                                                          staticClass:
                                                            "message-content__color",
                                                          style: {
                                                            background:
                                                              _vm.getStatusColor(
                                                                msg.reworkData
                                                                  .status.type
                                                              ),
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "message-content",
                                                  },
                                                  [
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("vue.comment")
                                                        ) + ":"
                                                      ),
                                                    ]),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          msg.reworkData.comment
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "pre",
                                                  {
                                                    staticClass:
                                                      "message-content",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("vue.reworkTime")
                                                      ) +
                                                        ": " +
                                                        _vm._s(
                                                          _vm.modifyReworkTime(
                                                            msg.reworkData
                                                              .reworkTimeStartAt,
                                                            msg.reworkData
                                                              .reworkTimeFinishAt
                                                          )
                                                        )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        msg.pageData
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "text-align": "left",
                                                },
                                              },
                                              [
                                                _c("MapPinIcon", {
                                                  staticClass:
                                                    "message-content-icon",
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "message-content",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "message-content-title",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Current Location:"
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "message-content-text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            msg.pageData
                                                              .pageTitle
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("div", {
                                                      staticClass:
                                                        "message-content-text",
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.sanitizeHtml(
                                                            msg.pageData
                                                              .metaDescription
                                                          )
                                                        ),
                                                      },
                                                    }),
                                                    _c("div", {
                                                      staticClass:
                                                        "message-content-text message-content-path",
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.modifyToLink(
                                                            msg.pageData
                                                              .pagePath
                                                          )
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _c(
                                        "div",
                                        {
                                          staticStyle: { "text-align": "left" },
                                          style: msg.feedbackQuizAnswers
                                            ? "display: flex; flex-direction: column-reverse"
                                            : "",
                                        },
                                        [
                                          _c("pre", {
                                            staticClass: "message-content",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.sanitizeHtml(msg.message)
                                              ),
                                            },
                                          }),
                                          msg.files && msg.files.length
                                            ? _c("div", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "flex flex-row flex-wrap -mb-2 -mr-4 justify-end",
                                                  },
                                                  [
                                                    _vm._l(
                                                      msg.files,
                                                      function (file, index) {
                                                        return [
                                                          _c("message-file", {
                                                            key: index,
                                                            staticClass: "my-2",
                                                            attrs: {
                                                              isWhite: true,
                                                              hasDeleteIcon: false,
                                                              hasDownload: true,
                                                              file: file,
                                                              id: msg.chatTime,
                                                            },
                                                          }),
                                                        ]
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "chat-message-transfer-date flex flex-row justify-end text-xs w-full",
                                                    class: {
                                                      _expired:
                                                        _vm.isFilesExpired(
                                                          msg.files
                                                        ),
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.getChatFilesTransferMessage(
                                                            msg.files
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            : _vm._e(),
                                          msg.feedbackQuizQuestions &&
                                          msg.feedbackQuizAnswers
                                            ? _c("div", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "flex flex-row flex-wrap -mb-2 -mr-4 justify-end",
                                                    staticStyle: {
                                                      "padding-right": "15px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "pathadvice__finish-communication__form",
                                                      },
                                                      [
                                                        msg.feedbackQuizAnswers
                                                          .grade1 !== ""
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "pathadvice__finish-communication__field mb-3",
                                                              },
                                                              [
                                                                msg
                                                                  .feedbackQuizQuestions
                                                                  .question1
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "pathadvice__finish-communication__field-title mb-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              msg
                                                                                .feedbackQuizQuestions
                                                                                .question1
                                                                            ) +
                                                                            "\n                        "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _c(
                                                                  "rating-stars",
                                                                  {
                                                                    attrs: {
                                                                      grade:
                                                                        msg
                                                                          .feedbackQuizAnswers
                                                                          .grade1,
                                                                      maxStars: 5,
                                                                      disabled: true,
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        msg.feedbackQuizAnswers
                                                          .grade2 !== ""
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "pathadvice__finish-communication__field mb-3",
                                                              },
                                                              [
                                                                msg
                                                                  .feedbackQuizQuestions
                                                                  .question2
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "pathadvice__finish-communication__field-title mb-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              msg
                                                                                .feedbackQuizQuestions
                                                                                .question2
                                                                            ) +
                                                                            "\n                        "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _c(
                                                                  "rating-stars",
                                                                  {
                                                                    attrs: {
                                                                      grade:
                                                                        msg
                                                                          .feedbackQuizAnswers
                                                                          .grade2,
                                                                      maxStars: 5,
                                                                      disabled: true,
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        msg.feedbackQuizAnswers
                                                          .grade3 !== ""
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "pathadvice__finish-communication__field mb-3",
                                                              },
                                                              [
                                                                msg
                                                                  .feedbackQuizQuestions
                                                                  .question3
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "pathadvice__finish-communication__field-title mb-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              msg
                                                                                .feedbackQuizQuestions
                                                                                .question3
                                                                            ) +
                                                                            "\n                        "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _c(
                                                                  "rating-stars",
                                                                  {
                                                                    attrs: {
                                                                      grade:
                                                                        msg
                                                                          .feedbackQuizAnswers
                                                                          .grade3,
                                                                      maxStars: 5,
                                                                      disabled: true,
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            : _vm._e(),
                                          msg.contactFormData
                                            ? _c("div", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "flex flex-row flex-wrap -mb-2 -mr-4 justify-end w-full",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "chat-message__contact-form w-full",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "chat-message__contact-form-item",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Name: " +
                                                                _vm._s(
                                                                  msg
                                                                    .contactFormData
                                                                    .data.name
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                        msg.contactFormData.data
                                                          .email
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "chat-message__contact-form-item",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Email: " +
                                                                    _vm._s(
                                                                      msg
                                                                        .contactFormData
                                                                        .data
                                                                        .email
                                                                    )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        msg.contactFormData.data
                                                          .phone
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "chat-message__contact-form-item",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                        Phone number: " +
                                                                    _vm._s(
                                                                      msg
                                                                        .contactFormData
                                                                        .data
                                                                        .phone
                                                                    ) +
                                                                    "\n                      "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        msg.contactFormData.data
                                                          .date
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "chat-message__contact-form-item",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                        Date: " +
                                                                    _vm._s(
                                                                      _vm.getDate(
                                                                        msg
                                                                          .contactFormData
                                                                          .data
                                                                          .date
                                                                      )
                                                                    ) +
                                                                    "\n                      "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        msg.contactFormData
                                                          .type ===
                                                        "contact-form-info-saved"
                                                          ? [
                                                              _c(
                                                                "vs-button",
                                                                {
                                                                  staticClass:
                                                                    "my-2",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.preventDefault()
                                                                        return function () {
                                                                          return _vm.viewChats(
                                                                            msg
                                                                              .contactFormData
                                                                              .data
                                                                              .leadId
                                                                          )
                                                                        }.apply(
                                                                          null,
                                                                          arguments
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      "Open Message"
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          : [
                                                              _c(
                                                                "vs-button",
                                                                {
                                                                  staticClass:
                                                                    "my-2",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.preventDefault()
                                                                        return _vm.eventClick(
                                                                          msg
                                                                            .contactFormData
                                                                            .data
                                                                            .meetingId
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      "Show booked meeting"
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                      ],
                                                      2
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                ]
                              ),
                              msg.sender === "bot"
                                ? _c("img", {
                                    staticClass: "chat-message-img-bot",
                                    attrs: { src: _vm.showBotPhoto },
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                ],
                2
              )
            }),
            0
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.visitorIsTyping,
                  expression: "visitorIsTyping",
                },
              ],
              staticClass: "chat-message-typing-title chat-message-flex-row",
            },
            [
              _c("div", [_vm._v(_vm._s(_vm.$t("vue.visitorIsTyping")))]),
              _vm._m(0),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "chat-message-typing-indicator" }, [
      _c("span", { staticStyle: { "background-color": "#262629" } }),
      _c("span", { staticStyle: { "background-color": "#262629" } }),
      _c("span", { staticStyle: { "background-color": "#262629" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }