<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    :stroke="color"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-check-circle"
  >
    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
    <polyline points="22 4 12 14.01 9 11.01"></polyline>
  </svg>
</template>

<script>
export default {
  name: 'CheckCircleIcon',
  props: {
    color: {
      type: String,
      default: '#fff'
    },
    width: {
      type: Number,
      default: 35
    },
    height: {
      type: Number,
      default: 35
    },
    showCircle: {
      type: Boolean,
      required: false,
      default: true
    }
  }
}
</script>
