<template>
  <svg :width="width" :height="height" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 11L1 6L6 1" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'BackIcon',
  props: {
    color: {
      type: String,
      default: '#12598D'
    },
    width: {
      type: Number,
      default: 7
    },
    height: {
      type: Number,
      default: 12
    }
  }
}
</script>
