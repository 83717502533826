<template>
  <div v-if="modifiedChatData" class="pa-chat-log" :class="{
      [chatLogClass]: isMobile !== true,
      'pa-chat-log--mobile': isMobile && isAudioVideoStartedBySomeone
    }">
    <MeetingSidebar :isSidebarActive="isOpenedSidebar" @closeAppointmentsSidebar="toggleSidebar" />
    <chats-view-sidebar :isSidebarActive="showChatsSideBar" @closeSidebar="toggleChatsDataSidebar" :data="chats" />
    <scroll-view ref="svChatLog" :class="!maxHeight ? 'scroll-area' : ''" :style="chatHeightStyle">
      <div class="" v-for="(msg, index) in modifiedChatData" :key="index">
        <!-- If previous msg is older than current time -->
        <template v-if="modifiedChatData[index - 1]">
          <vs-divider v-if="!isSameDay(msg.time, modifiedChatData[index - 1].time)" class="msg-time">
            <span>{{ toDate(msg.time) }}</span>
          </vs-divider>
          <div class="spacer mt-2" v-if="!hasSentPreviousMsg(modifiedChatData[index - 1].sender !== 'visitor', msg.sender !== 'visitor')"></div>
        </template>

        <div class="flex items-start" :class="[{ 'flex-row-reverse': msg.sender !== 'visitor' }]" :ref="`chatMessage${msg.time}`">
          <template v-if="modifiedChatData[index - 1]">
            <div v-if="
                !(
                  !hasSentPreviousMsg(modifiedChatData[index - 1].sender === 'agent', msg.sender === 'agent') ||
                  !isSameDay(msg.time, modifiedChatData[index - 1].time)
                )
              "></div>
          </template>

          <div class="chat-message" :class="getChatClass()">
            <div class="break-words relative shadow-md rounded py-3 px-4 rounded-lg max-w-message" :class="{
                'agent-message': msg.sender === 'agent' || msg.sender === 'bot',
                'visitor-message': msg.sender === 'visitor',
                'private-message': msg.type === 'agent-private-message',
                'private-message_location': msg.type === 'agent-private-message' && msg.pageData
              }">
              <div v-if="loadingIds.find((x) => x.loaderId === msg.chatTime)" class="chat-message-overlay-inner">
                <div class="chat-message-overlay-content">
                  <span class="chat-message-overlay-spinner"></span>
                </div>
              </div>
              <div class="top-right">{{ chatTime(msg.time) }}</div>

              <div class="flex flex-col w-full">
                <div class="agent-name" v-if="msg.sender === 'agent'">
                  {{ getDisplayName(msg.agentId) }}
                </div>

                <div class="visitor-name" v-if="msg.sender === 'visitor' && visitor.visitorDisplayName">
                  {{ visitor.visitorDisplayName }}
                </div>

                <div style="text-align: left" v-if="msg.type === 'call-recording'">
                  <video preload="metadata" controls width="160" height="90" style="object-fit: cover; border-radius: 6px" :src="msg.link"></video>
                </div>

                <div v-else-if="msg.type === 'agent-private-message'">
                  <div v-if="msg.reworkData" style="text-align: left">
                    <p class="mt-2">
                      <strong>{{ $t('vue.reworkInfo') }}:</strong>
                    </p>

                    <div class="message-content">
                      <div>{{ $t('vue.status') }}:</div>
                      <div class="flex">
                        <div>{{ msg.reworkData.status.name }}</div>
                        <div class="message-content__color" :style="{ background: getStatusColor(msg.reworkData.status.type) }" />
                      </div>
                    </div>

                    <div class="message-content">
                      <div>{{ $t('vue.comment') }}:</div>
                      <div>{{ msg.reworkData.comment }}</div>
                    </div>

                    <pre class="message-content">{{ $t('vue.reworkTime') }}: {{ modifyReworkTime(msg.reworkData.reworkTimeStartAt, msg.reworkData.reworkTimeFinishAt) }}</pre>
                  </div>

                  <div v-if="msg.pageData" style="text-align: left">
                    <MapPinIcon class="message-content-icon" />

                    <div class="message-content">
                      <div class="message-content-title">Current Location:</div>
                        <div class="message-content-text">{{ msg.pageData.pageTitle }}</div>
                        <div class="message-content-text" v-html="sanitizeHtml(msg.pageData.metaDescription)" />
                        <div class="message-content-text message-content-path" v-html="modifyToLink(msg.pageData.pagePath)" />
                    </div>
                  </div>
                </div>

                <div style="text-align: left" :style="msg.feedbackQuizAnswers ? 'display: flex; flex-direction: column-reverse' : ''" v-else>
                  <pre class="message-content" v-html="sanitizeHtml(msg.message)" />

                  <div v-if="msg.files && msg.files.length">
                    <div class="flex flex-row flex-wrap -mb-2 -mr-4 justify-end">
                      <template v-for="(file, index) in msg.files">
                        <message-file class="my-2" :isWhite="true" :hasDeleteIcon="false" :hasDownload="true" :key="index" :file="file" :id="msg.chatTime" />
                      </template>
                    </div>
                    <div class="chat-message-transfer-date flex flex-row justify-end text-xs w-full" :class="{ _expired: isFilesExpired(msg.files) }">
                      {{ getChatFilesTransferMessage(msg.files) }}
                    </div>
                  </div>
                  <div v-if="msg.feedbackQuizQuestions && msg.feedbackQuizAnswers">
                    <div class="flex flex-row flex-wrap -mb-2 -mr-4 justify-end" style="padding-right: 15px">
                      <div class="pathadvice__finish-communication__form">
                        <div class="pathadvice__finish-communication__field mb-3" v-if="msg.feedbackQuizAnswers.grade1 !== ''">
                          <div class="pathadvice__finish-communication__field-title mb-2" v-if="msg.feedbackQuizQuestions.question1">
                            {{ msg.feedbackQuizQuestions.question1 }}
                          </div>
                          <rating-stars :grade="msg.feedbackQuizAnswers.grade1" :maxStars="5" :disabled="true" />
                        </div>
                        <div class="pathadvice__finish-communication__field mb-3" v-if="msg.feedbackQuizAnswers.grade2 !== ''">
                          <div class="pathadvice__finish-communication__field-title mb-2" v-if="msg.feedbackQuizQuestions.question2">
                            {{ msg.feedbackQuizQuestions.question2 }}
                          </div>
                          <rating-stars :grade="msg.feedbackQuizAnswers.grade2" :maxStars="5" :disabled="true" />
                        </div>
                        <div class="pathadvice__finish-communication__field mb-3" v-if="msg.feedbackQuizAnswers.grade3 !== ''">
                          <div class="pathadvice__finish-communication__field-title mb-2" v-if="msg.feedbackQuizQuestions.question3">
                            {{ msg.feedbackQuizQuestions.question3 }}
                          </div>
                          <rating-stars :grade="msg.feedbackQuizAnswers.grade3" :maxStars="5" :disabled="true" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="msg.contactFormData">
                    <div class="flex flex-row flex-wrap -mb-2 -mr-4 justify-end w-full">
                      <div class="chat-message__contact-form w-full">
                        <div class="chat-message__contact-form-item">Name: {{ msg.contactFormData.data.name }}</div>
                        <div v-if="msg.contactFormData.data.email" class="chat-message__contact-form-item">Email: {{ msg.contactFormData.data.email }}</div>
                        <div v-if="msg.contactFormData.data.phone" class="chat-message__contact-form-item">
                          Phone number: {{ msg.contactFormData.data.phone }}
                        </div>
                        <div v-if="msg.contactFormData.data.date" class="chat-message__contact-form-item">
                          Date: {{ getDate(msg.contactFormData.data.date) }}
                        </div>

                        <template v-if="msg.contactFormData.type === 'contact-form-info-saved'">
                          <vs-button class="my-2" @click.prevent="() => viewChats(msg.contactFormData.data.leadId)">{{ 'Open Message' }}</vs-button>
                        </template>
                        <template v-else>
                          <vs-button class="my-2" @click.prevent="eventClick(msg.contactFormData.data.meetingId)">{{ 'Show booked meeting' }}</vs-button>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <img v-if="msg.sender === 'bot'" class="chat-message-img-bot" :src="showBotPhoto" />
            </div>
          </div>
        </div>
      </div>
    </scroll-view>
    <div class="chat-message-typing-title chat-message-flex-row" v-show="visitorIsTyping">
      <div>{{ $t('vue.visitorIsTyping') }}</div>
      <div class="chat-message-typing-indicator">
        <span style="background-color: #262629"></span>
        <span style="background-color: #262629"></span>
        <span style="background-color: #262629"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ScrollView from '@blackbp/vue-smooth-scrollbar'
import Autolinker from 'autolinker'
import Marked from 'marked'
import MessageFile from '@/views/incoming/messages/MessageFile.vue'
import RatingStars from '@/components/rating-stars/RatingStars.vue'
import MeetingSidebar from '@/views/meeting/MeetingSidebar'
import ChatsViewSidebar from '@/views/lead-overview/ChatsViewSidebar.vue'
import MapPinIcon from '@/components/icons/MapPinIcon.vue'
import sanitizeHtml from 'sanitize-html'

const SCREEN = {
  MINIMIZED: 'minimzed',
  MAXIMIZED: 'maximized'
}

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
const duration = require('dayjs/plugin/duration')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(duration)

const renderer = new Marked.Renderer()

renderer.link = function() {
  const link = Marked.Renderer.prototype.link.apply(this, arguments)
  return link.replace('<a', '<a target="_blank"')
}

Marked.setOptions({ renderer })

export default {
  components: {
    ScrollView,
    MessageFile,
    RatingStars,
    MeetingSidebar,
    ChatsViewSidebar,
    MapPinIcon
  },
  props: {
    maxHeight: {
      type: Number,
      required: false,
      default: null
    },
    isInsideModal: {
      type: Boolean,
      required: false,
      default: false
    },
    screen: {
      type: String,
      required: false
    },
    isMobile: {
      type: Boolean,
      required: false
    },
    isAudioVideoStartedBySomeone: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      isOpenedSidebar: false,
      showChatsSideBar: false,
      chats: [],

      SCREEN,
      statusColors: [
        {
          type: 'positive',
          color: 'rgb(40, 199, 111)'
        },
        {
          type: 'neutral',
          color: 'rgb(245, 220, 0)'
        },
        {
          type: 'negative',
          color: 'rgb(234, 84, 85)'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      files: (state) => state.files
    }),

    ...mapGetters({
      users: 'users',
      dialogs: 'dialogs',
      visitorIsTyping: 'visitorIsTyping',
      allmeetings: 'meetings',
      leads: 'leads',
      visitor: 'webrtc/visitor',
      screenSharingStatus: 'webrtc/screenSharingStatus',
      SCREEN_SHARING_STATUS: 'webrtc/SCREEN_SHARING_STATUS',
      loadingIds: 'chatFiles/loadingIds',
      gptChats: 'gptChats',
      isSearchGptChatsOn: 'isSearchGptChatsOn'
    }),

    IS_VISITOR_SCREEN_SHARING() {
      return (
        this.screenSharingStatus &&
        this.screenSharingStatus.status === this.SCREEN_SHARING_STATUS.SCREEN_SHARING &&
        this.screenSharingStatus.initiatedBy === 'visitor'
      )
    },

    sortedLeads() {
      const sortedLeads = [...this.leads].sort((a, b) => {
        const dateA = a.created && a.created.seconds ? dayjs.unix(a.created.seconds) : dayjs(a.created)
        const dateB = b.created && b.created.seconds ? dayjs.unix(b.created.seconds) : dayjs(b.created)

        if (dateA.isBefore(dateB)) {
          return -1
        } else if (dateA.isAfter(dateB)) {
          return 1
        } else {
          return 0
        }
      })

      return sortedLeads.reverse()
    },

    meetings() {
      const filteredMeetings = this.allmeetings.filter((x) => typeof x.type === 'undefined' || x.type === 'online-meeting')
      const sortedMeetings = [...filteredMeetings].sort((a, b) => {
        const dateA = a.created && a.created.seconds ? dayjs.unix(a.created.seconds) : dayjs(a.created)
        const dateB = b.created && b.created.seconds ? dayjs.unix(b.created.seconds) : dayjs(b.created)

        if (dateA.isBefore(dateB)) {
          return -1
        } else if (dateA.isAfter(dateB)) {
          return 1
        } else {
          return 0
        }
      })

      return sortedMeetings.reverse()
    },
    modifiedMeetings() {
      const modifiedMeetings = this.meetings.map((item) => {
        const start = item.date || (item.created && item.created.seconds) || item.created

        if (!start) {
          return false
        }

        item.title = item.name
        item.start = dayjs(start).toISOString()
        item.end = dayjs(dayjs(start).add(item.duration || 30, 'minute')).toISOString()
        item.allDay = false

        item.extendedProps = {
          calendar: item.status === 'canceled' ? 'Canceled' : 'Primary'
        }

        return item
      })

      const filteredMeetings = modifiedMeetings.filter((item) => !!item)

      return filteredMeetings
    },
    chatLogClass() {
      let classChatLog = ''
      if (this.isMobile) {
        classChatLog = 'mg-t36'
        return classChatLog
      }
      if (this.IS_VISITOR_SCREEN_SHARING) {
        classChatLog = 'ht-100pcless'
      } else if (this.screen === this.SCREEN.MAXIMIZED) {
        classChatLog = 'ht-100pc'
      }
      classChatLog = !this.isInsideModal ? `${classChatLog} mg-t15` : `${classChatLog} mg-t36`
      return classChatLog
    },
    chatData() {
      if (!this.visitor) {
        return []
      }

      const messages = this.isSearchGptChatsOn ? [] : (this.visitor.chats || [])
      let gptMessages = []

      if (this.visitor.visitorId && this.gptChats && this.gptChats.length > 0) {
        const visitorGpt = this.gptChats.find((item) => item.id === this.visitor.visitorId)

        if (visitorGpt && visitorGpt.chats) {
          gptMessages = visitorGpt.chats
        }
      }

      return [...messages, ...gptMessages].sort((a, b) => a.time - b.time)
    },
    modifiedChatData() {
      return this.chatData.map((item) => {
        if (item.sender === 'bot') {
          item.message = this.replaceFirstWordThatContainsSpecialCharacter(item.message)
        }

        return {
          ...item,
          message: item.sender === 'bot' ? Marked(item.message) : Autolinker.link(item.message)
        }
      })
    },
    activeUserImg() {
      return this.$store.state.AppActiveUser.photoURL
    },
    hasSentPreviousMsg() {
      return (last_sender, current_sender) => last_sender === current_sender
    },
    chatHeightStyle() {
      if (this.IS_VISITOR_SCREEN_SHARING) {
        return {
          maxHeight: 'calc(100vh - 200px)',
          minHeight: this.isMobile ? (this.files.length > 0 ? '120px' : '170px') : '200px',
          height: `${this.maxHeight}px`
        }
      }

      if (this.screen === this.SCREEN.MINIMIZED) {
        return {
          maxHeight: `${this.maxHeight}px`,
          height: `${this.maxHeight}px`
        }
      }
      if (this.screen === this.SCREEN.MAXIMIZED) {
        return {
          maxHeight: 'calc(100vh - 300px)',
          minHeight: '200px'
        }
      }
      return ''
    },

    showBotPhoto() {
      if (!this.dialog || !this.dialog.gptBotface) {
        return '/img/lcbot.png'
      }

      return this.dialog.gptBotface
    },
  },
  mounted() {
    /* To Scroll to bottom for Chat inside poppup (Audio/Video) */
    if (this.isInsideModal) {
      setTimeout(() => {
        const scrollElement = this.$refs.svChatLog
        if (scrollElement) {
          const scrollBar = scrollElement.scrollBar
          if (scrollElement && scrollBar && scrollBar.size && scrollBar.size.content && scrollBar.size.content.height) {
            scrollElement.scrollTo(0, scrollBar.size.content.height, 600)
          }
        }
      }, 100)
    }
  },
  updated() {
    const scrollElement = this.$refs.svChatLog
    const scrollBar = scrollElement.scrollBar
    if (scrollElement && scrollBar && scrollBar.size && scrollBar.size.content && scrollBar.size.content.height) {
      scrollElement.scrollTo(0, scrollBar.size.content.height, 600)
    }
  },
  methods: {
    sanitizeHtml,
    toggleSidebar(isOpened = false) {
      this.isOpenedSidebar = isOpened
    },
    async viewChats(id) {
      const data = this.sortedLeads.find((el) => el.id === id)
      const chats = []
      if (data && data.leadMessages && data.leadMessages.length) {
        data.leadMessages.forEach((message) => {
          const modifiedMessage = {
            name: data.name,
            message: message.message,
            sender: 'visitor',
            time: message.time && message.time.seconds ? dayjs.unix(message.time.seconds) : message.time
          }
          chats.push(modifiedMessage)
        })
      } else {
        const message = {
          name: data.name,
          chatTime: dayjs.utc(data.created).diff('2021-01-01'),
          message: data.message,
          sender: 'visitor',
          time: data.created
        }
        chats.push(message)
      }
      this.chats = chats
      this.toggleChatsDataSidebar(true)
    },
    toggleChatsDataSidebar(val = false) {
      this.showChatsSideBar = val
    },
    eventClick(id) {
      const selectedMeeting = this.modifiedMeetings.find((item) => item.id === id)

      this.$serverBus.$emit('set-meeting-data', {
        meeting: selectedMeeting
      })

      this.isOpenedSidebar = true
    },
    getDate(date) {
      const newDate = dayjs(date).utc().local().format('LLL')
      return newDate
    },
    getDisplayName(userId) {
      if (this.users && this.users.length) {
        const agent = this.users.find((el) => el.id === userId)
        if (agent) {
          return agent.displayName ? agent.displayName : `${agent.firstaname} ${agent.lastname}`
        }
      }
    },
    modifyReworkTime(startAt, finishAt) {
      const t = dayjs.duration(dayjs.unix(finishAt.seconds).diff(dayjs.unix(startAt.seconds)))

      return dayjs.utc(t.asMilliseconds()).format('HH:mm:ss')
    },

    getStatusColor(type) {
      const status = this.statusColors.find((status) => status.type === type)

      if (!status || !status.color) {
        return ''
      }

      return status.color
    },

    getChatClass() {
      if (this.isInsideModal) {
        return 'chat-message-modal'
      }
    },

    isSameDay(time_to, time_from) {
      let to = time_to,
        from = time_from

      if (typeof time_to === 'object' && time_to.seconds) {
        to = dayjs.unix(time_to.seconds).toISOString()
      }

      if (typeof time_from === 'object' && time_from.seconds) {
        from = dayjs.unix(time_from.seconds).toISOString()
      }

      const date_time_to = new Date(Date.parse(to))
      const date_time_from = new Date(Date.parse(from))
      return (
        date_time_to.getFullYear() === date_time_from.getFullYear() &&
        date_time_to.getMonth() === date_time_from.getMonth() &&
        date_time_to.getDate() === date_time_from.getDate()
      )
    },
    toDate(time) {
      if (typeof time === 'string' && time.seconds) {
        return dayjs(time).locale(this.$i18n.locale).tz(this.$defaultTimezone).format('DD MMM')
      }
      if (typeof time === 'object' && time.seconds) {
        return dayjs.unix(time.seconds).locale(this.$i18n.locale).tz(this.$defaultTimezone).format('DD MMM')
      }
    },
    chatTimeFromNow(time) {
      if (typeof time === 'string') {
        return dayjs(time).tz(this.$defaultTimezone).locale(this.$i18n.locale).fromNow()
      }
      if (typeof time === 'object' && time.seconds) {
        return dayjs.unix(time.seconds).tz(this.$defaultTimezone).locale(this.$i18n.locale).fromNow()
      }
      return ''
    },
    chatTime(time) {
      if (typeof time === 'string') {
        return dayjs(time).locale(this.$i18n.locale).format('HH:mm')
      }
      if (typeof time === 'object' && time.seconds) {
        return dayjs.unix(time.seconds).locale(this.$i18n.locale).format('HH:mm')
      }
      return ''
    },

    getFilesExpiration(files) {
      if (files) {
        const expiresAt = dayjs.unix(files[0].expiresAt.seconds)
        const expiresIn = dayjs(expiresAt).tz(this.$defaultTimezone).diff(dayjs(), 'day')

        return expiresIn
      }
      return ''
    },

    isFilesExpired(files) {
      if (files) {
        const expiresAt = dayjs.unix(files[0].expiresAt.seconds)
        return dayjs().isSameOrAfter(expiresAt, 'day')
      }
      return false
    },

    getChatFilesTransferMessage(files) {
      if (files) {
        const isFilesExpired = this.isFilesExpired(files)
        const filesExpiresIn = this.getFilesExpiration(files)

        if (isFilesExpired) {
          return this.$t('uploads.transferIsExpired')
        }
        return `${this.$t('uploads.transferExpires')} ${filesExpiresIn} ${this.$t('uploads.transferDays')}.`
      }
    },

    modifyToLink(string) {
      if (!string) {
        return ''
      }

      const listOfIgnoredQueryParameters = ['gclid', 'utm_content', 'utm_term', 'utm_campaign', 'utm_medium', 'utm_source']
      let modfiedLocation = string

      listOfIgnoredQueryParameters.forEach((item) => {
        modfiedLocation = this.removeURLParameter(modfiedLocation, item)
      })

      return Autolinker.link(modfiedLocation)
    },

    removeURLParameter(url, parameter) {
      const urlparts = url.split('?')

      if (urlparts.length >= 2) {
        const prefix = `${encodeURIComponent(parameter)}=`
        const pars = urlparts[1].split(/[&;]/g)

        for (let i = pars.length; i-- > 0;) {
          if (pars[i].lastIndexOf(prefix, 0) !== -1) {
            pars.splice(i, 1)
          }
        }

        return urlparts[0] + (pars.length > 0 ? `?${pars.join('&')}` : '')
      }

      return url
    },

    replaceFirstWordThatContainsSpecialCharacter(str) {
      if (!str) {
        return ''
      }

      if (this.dialog && this.dialog.gpt && this.dialog.gpt.botName && str.includes(`${this.dialog.gpt.botName}: `)) {
        return str.replace(`${this.dialog.gpt.botName}: `, '')
      }

      const words = str.split(' ')

      if (words.length > 0 && words[0].includes(':') && ['http', 'https', 'file'].every((item) => !words[0].includes(`${item}:`))) {
        words.shift()
      }

      return words.join(' ')
    }
  }
}
</script>
<style lang="scss">
.ht-100pcless {
  height: calc(100vh - 200px);
}
.ht-100pc {
  height: calc(100vh - 300px);
}
.mg-t15 {
  margin-top: 15px;
}
.mg-t36 {
  margin-top: 36px;
}
.pa-chat-log {
  min-height: 200px;

  &--mobile {
    min-height: 120px;
  }
  .scroll-area {
    width: 100%;
    height: calc(100%);
  }
  .chat-message {
    .chat-message-overlay-inner {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 6px;
    }
    .chat-message-overlay-content {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .chat-message-overlay-spinner {
      width: 25px;
      height: 25px;
      display: inline-block;
      border-width: 2px;
      border-color: rgba(0, 0, 0, 0.4);
      border-top-color: #fff;
      animation: spin 1s infinite linear;
      border-radius: 100%;
      border-style: solid;
    }

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
    position: relative;
    min-width: 300px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    margin-bottom: 15px;
    @media (max-width: 767px) {
      min-width: 200px;
    }

    &__contact-form {
      display: flex;
      flex-direction: column;
      &-item {
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        color: #12598d;
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-flex-row {
      display: flex;
    }
    @keyframes typing {
      50% {
        opacity: 1;
        transform: scale(1.25);
      }
    }
    @keyframes visible {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    &-typing-title {
      position: absolute;
      margin-left: 20px;
      margin-top: 10px;
      color: #262629;
    }

    &-typing-indicator {
      margin-top: 6px;
      animation: 1s visible;
      span {
        height: 10px;
        width: 10px;
        float: left;
        margin: 0 5px;
        display: block;
        border-radius: 50%;
        opacity: 0.4;
        @for $i from 1 through 3 {
          &:nth-of-type(#{$i}) {
            animation: 1s typing infinite ($i * 0.3333s);
          }
        }
      }
    }

    &-modal {
      min-width: 250px !important;

      .private-message {
        min-width: 250px !important;
      }

      .visitor-message, .agent-message {
        margin-right: 15px !important;
      }

      .agent-message {
        margin-left: 15px !important;
      }
    }

    &-transfer-date {
      color: #c8c9ca;
      margin-top: 5px;
      &._expired {
        color: #f05541;
      }
    }

    .agent-name,
    .visitor-name {
      font-family: 'Lato';
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 19px;
      color: #275D73;
      text-transform: capitalize;
      margin-top: 10px;
      text-align: left;
    }
    .agent-message {
      background: #e6ecf1;
      border-radius: 6px;
      color: rgba(var(--vs-secondary), 1) !important;
      display: flex;
      flex-wrap: wrap;
      .top-right {
        color: #b2b2b2;
      }
      margin-right: 25px;
    }
    .visitor-message {
      background: #c4c4c4;
      border-radius: 6px;
      color: rgba(var(--vs-secondary), 1) !important;
      display: flex;
      flex-wrap: wrap;
      .top-right {
        color: white;
      }
      margin-left: 15px;
      .chat-message-transfer-date {
        color: #fff;
        &._expired {
          color: #f05541;
        }
      }
    }

    .private-message {
      min-width: 300px;
      background: rgba(245, 220, 0, 0.2);
      border: 2px solid rgba(245, 220, 0, 0.99);

      &_location {
        margin-top: 10px;
        background: rgba(239, 241, 245, 1);
        border: none;

        .top-right {
          color: rgba(196, 196, 196, 1);
        }

        .message-content {
          color: rgba(38, 38, 41, 1);

          &-icon {
            position: absolute;
            top: -10px;
            left: 12px;
          }


          &-text {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          &-title {
            margin: 8px 0 4px 0;
            font-weight: 700;
          }

          &-path {
            a {
              color: rgba(153, 152, 161, 1);

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    .message-content {
      font-family: 'Lato';
      margin-top: 7px;
      white-space: pre-wrap;
      word-break: break-word;

      &__color {
        margin-top: 1px;
        margin-left: 8px;
        width: 14px;
        min-width: 14px;
        height: 14px;
        min-height: 14px;
        border-radius: 50%;
      }
    }

    .top-right {
      position: absolute;
      top: 2px;
      right: 10px;
      color: #b2b2b2;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
    }
    .max-w-message {
      max-width: 34rem !important;
    }
  }
}

.chat-message-img-bot {
  position: absolute;
  top: -10px;
  left: -6px;
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 8px;
  background: #EBF5FE;
}
</style>
