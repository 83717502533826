import { mapGetters } from 'vuex'
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export const webrtc = {
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser'
    }),
    agentName() {
      let name = ''
      if (this.activeUserInfo.firstname) {
        name = this.activeUserInfo.firstname
        if (this.activeUserInfo.lastname) {
          name = `${name} ${this.activeUserInfo.lastname}`
        }
      } else if (this.activeUserInfo.displayName) {
        name = this.activeUserInfo.displayName
      }
      return name
    }
  },
  methods: {
    startCallRecording() {
      this.recordedBlobs = []
      const mimeType = this.recordingMIMEType
      const options = { mimeType }

      try {
        this.mediaRecorder = new MediaRecorder(this.localStream, options)

        this.mediaRecorder.onstop = (event) => {
          /* eslint-disable no-console */
          console.log('Recorder stopped: ', event)
        }
        const visitorId = this.visitorId
        this.mediaRecorder.ondataavailable = (event) => {
          this.recordingDataAvailable(event, visitorId)
        }
        this.mediaRecorder.start()

        this.$serverBus.$emit('on-call-recording')
      } catch (e) {
        console.error('Exception while creating MediaRecorder:', e)
      }
    },
    async recordingDataAvailable(event, visitorId) {
      const vm = this
      if (event.data && event.data.size > 0) {
        const storageService = this.$firebase.storage()
        const storageRef = storageService.ref()
        const file = event.data

        const metadata = {
          contentType: this.recordingMIMEType,
          cacheControl: 'max-age=604800, public'
        }

        let uploadTask = null

        const fileKey = Math.random().toString(36).substring(2, 15)
        uploadTask = storageRef.child(`call-recordings/${visitorId}/${fileKey}`).put(file, metadata)
        vm.uploadTask = true

        uploadTask.on(
          vm.$firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            vm.progressUpload = progress
          },
          function (error) {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                vm.text = 'fields.storage/unauthorized'
                break
              case 'storage/canceled':
                // User canceled the upload
                vm.text = 'fields.storage/canceled'
                break
              case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                vm.text = 'fields.storage/unknown'
                break
            }

            vm.$vs.notify({
              time: 8800,
              title: vm.$i18n.t('vue.error'),
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })

            vm.uploadTask = false
          },
          async () => {
            // Upload completed successfully, now we can get the download URL
            vm.uploadTask = false
            const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()
            const visitorDoc = await vm.$db.collection('visitors').doc(visitorId).get()
            if (visitorDoc && visitorDoc.data()) {
              const message = {
                link: downloadURL,
                type: 'call-recording',
                time: new Date(),
                chatTime: dayjs().tz(vm.$defaultTimezone).diff('2021-01-01'),
                agentName: vm.agentName,
                agentId: vm.activeUserInfo.uid,
                sender: 'agent'
              }
              await vm.$db
                .collection('visitors')
                .doc(visitorId)
                .update({ chats: vm.$firebase.firestore.FieldValue.arrayUnion(message), callTypeState: 'call-recording-completed' })
            }

            vm.$vs.notify({
              time: 8800,
              title: vm.$i18n.t('vue.success'),
              text: vm.$i18n.t('vue.recordingSavedSuccessfully'),
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'success'
            })
          }
        )
      }
    },
    stopRecording() {
      if (this.mediaRecorder) {
        this.mediaRecorder.stop()
      }
      this.$serverBus.$emit('on-call-recording-stopped')
    }
  }
}
