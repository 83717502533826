// https://web-push-codelab.glitch.me/
// https://developers.google.com/web/fundamentals/codelabs/push-notifications
// https://developers.google.com/web/fundamentals/push-notifications/subscribing-a-user?hl=en
export default {
  checkSupported() {
    if (!('serviceWorker' in navigator)) {
      // Service Worker isn't supported on this browser, disable or hide UI.
      return
    }

    if (!('PushManager' in window)) {
      // Push isn't supported on this browser, disable or hide UI.
      return
    }
  },

  askPermission() {
    return new Promise(function (resolve, reject) {
      const permissionResult = Notification.requestPermission(function (result) {
        resolve(result)
      })

      if (permissionResult) {
        permissionResult.then(resolve, reject)
      }
    }).then(function (permissionResult) {
      if (permissionResult !== 'granted') {
        throw new Error("We weren't granted permission.")
      }
    })
  },
  displayNotification() {
    if (Notification.permission == 'granted') {
      navigator.serviceWorker.getRegistration().then(function (reg) {
        reg.showNotification('Hello world!')
      })
    }
  },
  subscribeUserToPush() {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (!userInfo || !userInfo.impersonated) {
      return navigator.serviceWorker
        .register('/service-worker.js')
        .then(function (registration) {
          const subscribeOptions = {
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array('BM7CVNaF6HENXa9bI5DBbaZgOlJEqS-CUuwc8VJEV_zGSe0S-mhDnIpIRLLgA0iMeKFDfqeAySlRlYdG_1mZ3tc')
          }

          if (registration && registration.pushManager && registration.pushManager.subscribe) {
            return registration.pushManager.subscribe(subscribeOptions)
          } else {
            return false
          }
        })
        .then(function (pushSubscription) {
          console.log('Received PushSubscription: ')
          console.log(JSON.stringify(pushSubscription))
          return pushSubscription
        })
    }
  },
  unsubscribeUser() {
    return navigator.serviceWorker.register('/service-worker.js').then(function (registration) {
      registration.pushManager
        .getSubscription()
        .then(function (subscription) {
          if (subscription) {
            return subscription.unsubscribe()
          }
        })
        .catch(function (error) {
          console.log('Error unsubscribing', error)
        })
    })
  }
}

function urlBase64ToUint8Array(base64String) {
  var padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  var base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')

  var rawData = window.atob(base64)
  var outputArray = new Uint8Array(rawData.length)

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}
