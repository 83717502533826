var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "toolbar-tools flex" }, [
    _c(
      "div",
      {
        staticClass: "flex flex-row items-center",
        staticStyle: { "justify-content": "flex-start", "flex-grow": "1" },
      },
      [
        _c("vx-tooltip", { attrs: { text: _vm.$t("tooltip.uploadFile") } }, [
          _c("label", { attrs: { for: "files-sharing " } }, [
            _c(
              "div",
              {
                staticClass: "toolbar-tools-button",
                on: { click: _vm.onFileShareClick },
              },
              [
                _c("input", {
                  ref: "fileSharing",
                  staticStyle: { display: "none" },
                  attrs: {
                    type: "file",
                    id: "files-sharing",
                    disabled: _vm.isFileSharingDisabled,
                    multiple: "",
                  },
                  on: { change: _vm.detectFiles, click: _vm.onFileInputClick },
                }),
                _c("clip-icon", {
                  staticClass: "toolbar-container-icon",
                  attrs: {
                    width: 20,
                    height: 20,
                    color: _vm.fileSharingIconColor,
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm.isConnectedVisitor
          ? [
              !_vm.isMobile ||
              (_vm.isMobile && _vm.isAudioVideoStartedBySomeone)
                ? _c(
                    "vx-tooltip",
                    {
                      attrs: {
                        text: _vm.HAS_SCREENSHARING_ACCESS
                          ? _vm.$t("tooltip.screenSharing")
                          : _vm.$t("tooltip.screenSharing") +
                            " - " +
                            _vm.$t("upgrade-messages.locked-feature"),
                        showLockIcon: !_vm.HAS_SCREENSHARING_ACCESS,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "toolbar-tools-button",
                          on: { click: _vm.onScreenSharingClicked },
                        },
                        [
                          _vm.HAS_SCREEN_SHARING_STOPPED
                            ? _c("share-icon", {
                                staticClass: "toolbar-container-icon",
                                class: _vm.IS_SCREEN_SHARING
                                  ? "toolbar-active"
                                  : "",
                                attrs: {
                                  width: 25.41,
                                  height: 18,
                                  color: _vm.SCREENSHARING_ICON_COLOR,
                                },
                              })
                            : _vm._e(),
                          _vm.HAS_SCREEN_SHARING_STARTED
                            ? _c("loader-icon", {
                                staticClass: "toolbar-container-icon",
                                attrs: {
                                  width: 20,
                                  height: 20,
                                  color: "#12598d",
                                },
                              })
                            : _vm._e(),
                          _vm.IS_SCREEN_SHARING
                            ? _c("share-icon", {
                                staticClass:
                                  "toolbar-container-icon toolbar-tools-pulse",
                                class: _vm.IS_SCREEN_SHARING
                                  ? "toolbar-active"
                                  : "",
                                attrs: {
                                  width: 25.41,
                                  height: 18,
                                  color: _vm.SCREENSHARING_ICON_COLOR,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _c("vx-tooltip", { attrs: { text: "Lead Form" } }, [
                _c(
                  "div",
                  {
                    staticClass: "toolbar-tools-button",
                    on: { click: _vm.onLeadFormClicked },
                  },
                  [
                    _c("lead-form-icon", {
                      staticClass: "toolbar-container-icon",
                      attrs: { width: 25.41, height: 18, color: "#ffffff" },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }