<template>
  <div class="chat-header">
    <vs-sidebar position-right parent="body" default-index="1" color="primary" class="visitors-sidebar items-no-padding" spacer v-model="showVisitors">
      <SearchVisitors v-if="isSearchVisitorsOn" :key="searchVisitorsKey" />
    </vs-sidebar>
    <vs-sidebar position-right parent="body" default-index="1" color="primary" class="visitors-sidebar items-no-padding" spacer v-model="showGptChats">
      <GptChats v-if="isSearchGptChatsOn" :key="searchGptChatsKey" />
    </vs-sidebar>
    <LinkedVisitorDraggableModal
      :show="visitor && showLinkedCCVisitor && linkedCCFormFields"
      :width="400"
      :height="600"
      bkcolor="#F7F7F7"
      v-if="visitor && showLinkedCCVisitor && linkedCCFormFields"
    >
      <LinkedVisitorInfo :linkedCCFormFields="linkedCCFormFields" />
    </LinkedVisitorDraggableModal>
    <vs-prompt
      :title="$t('vue.endConversation')"
      @cancel="deletePrompt = false"
      @accept="onEndSessionWithVisitor"
      @close="deletePrompt = false"
      color="danger"
      :cancel-text="$t('vue.cancel')"
      :accept-text="$t('inputs.exit')"
      :active.sync="deletePrompt"
    >
      <div class="con-exemple-prompt">
        <p>
          <strong>{{ $t('vue.shouldEndConversationText') }}</strong>
        </p>
      </div>
    </vs-prompt>
    <vs-popup :title="`${$t('vue.deleteVisitor')}`" :active.sync="showConfirmDelete">
      <p class="mb-2">
        {{ $t('vue.confirmQuestion') }}
      </p>
      <vs-button @click.prevent="onDeleteVisitorConfirmed" color="#e8546f" type="filled" class="mr-2">{{ $t('vue.confirm') }}</vs-button>
      <vs-button @click.prevent="showConfirmDelete = false" type="border" color="#e8546f">{{ $t('vue.cancel') }}</vs-button>
    </vs-popup>
    <connect-modal v-if="visitorId" :show="showVisitor" :width="'100%'" :height="'100%'" class="visitor-modal" bkcolor="#F7F7F7">
      <visitor />
    </connect-modal>

    <audio-video-settings
      v-if="showSettings"
      @close="onCloseSettings()"
      :show="showSettings"
      :selected-audio="selectedAudio"
      :selected-video="selectedVideo"
      :selected-speaker="selectedSpeaker"
      :volume-level="volumeLevel"
    ></audio-video-settings>
    <div class="chat-header-navbar">
      <div class="chat-header-navbar-left-container">
        <vs-button
          class="chat-header-navbar-back-button"
          :text-color="'#00A9F5'"
          :color="'#E8F6FF'"
          type="filled"
          size="large"
          icon-pack="feather"
          icon="icon-arrow-left"
          @click="$serverBus.$emit('back-to-visitors-list')"
        ></vs-button>

        <div class="chat-header-navbar-button ml-4" @click="showSettings = true">
          <vx-tooltip :text="$t('vue.settings')" class="inline-flex" position="top">
            <vs-button :text-color="'#00A9F5'" :color="'#E8F6FF'" type="filled" size="large" icon-pack="feather" icon="icon-settings"></vs-button>
          </vx-tooltip>
        </div>
        <div class="chat-header-navbar-button ml-4" @click="onShowSearchVisitors">
          <vx-tooltip :text="$t('vue.searchVisitors')" class="inline-flex" position="top">
            <vs-button :text-color="'#00A9F5'" :color="'#E8F6FF'" type="filled" size="large" icon-pack="feather" icon="icon-book-open"></vs-button>
          </vx-tooltip>
        </div>
        <div class="chat-header-navbar-button chat-header-navbar-button--gpt ml-4" @click="onShowSearchGptChats">
          <vx-tooltip :text="'GPT Chats'" class="inline-flex" position="top">
            <gpt-connect-icon :width="19" :height="18" color="rgb(0, 169, 245)" style="margin-bottom: 2px" />
          </vx-tooltip>
        </div>
        <div v-if="!audioVideoStarted && visitorId" class="chat-header-navbar-communications-control">
          <div
            class="chat-header-navbar-communications-control-button ml-4"
            :class="{ 'chat-header-active': messageMode === 'chat' }"
            @click="onClickCommunicationControl('chat')"
          >
            <chat-icon :class="{ 'pa-icon-default': messageMode !== 'chat' }" :width="16" :height="16"></chat-icon>
          </div>
          <div class="chat-header-navbar-communications-control-button" :class="audioButtonClass" @click="onClickCommunicationControl('audio')">
            <microphone-icon v-if="messageModes.includes('audio') && !isAudioDisabled" :class="audioButtonIconClass" :width="16" :height="16"></microphone-icon>
            <microphone-off-icon v-else :class="audioButtonIconClass" :width="16" :height="16"></microphone-off-icon>
          </div>

          <div class="chat-header-navbar-communications-control-button" :class="videoButtonClass" @click="onClickCommunicationControl('video')">
            <video-call-icon v-if="messageModes.includes('video') && !isVideoDisabled" :class="videoButtonIconClass" :width="16" :height="16"></video-call-icon>
            <video-call-off-icon v-else :class="videoButtonIconClass" :width="16" :height="16"></video-call-off-icon>
          </div>
        </div>
        <div v-if="visitor" class="chat-header-navbar-visitor-status ml-4">
          <VisitorStatusIconWithText
            :score="visitor.scoreHighest || visitor.score"
            :online="visitor.online"
            :isVegaWidgetEnabled="visitor.isVegaWidgetEnabled"
          />
        </div>
        <div v-if="IS_CALL_ACTIVE && visitorId" class="chat-header-navbar-button pa-bk-danger ml-2" @click="deletePrompt = true">
          <phone-icon class="chat-header-navbar-icon" :width="18" :height="18"></phone-icon>
        </div>
      </div>
      <div v-if="visitorId" class="chat-header-navbar-right-container">
        <div v-if="activeUserInfo.role === 'admin'" class="chat-header-navbar-button pa-bk-danger mr-2" @click="onDeleteVisitor()">
          <trash-icon class="chat-header-navbar-icon" :width="16" :height="16"></trash-icon>
        </div>
        <template v-if="!IS_VISITOR_FROM_CALL_CENTER_CUSTOMER">
          <div
            class="chat-header-navbar-button mr-4"
            :class="visitor.b2b && !HAS_B2B_ACCESS ? 'chat-header-navbar-button-disable' : ''"
            @click="onShowVisitor()"
          >
            <info-icon class="chat-header-navbar-icon pa-icon-default" :width="16" :height="16"></info-icon>
          </div>
        </template>
        <template v-else>
          <div
            class="chat-header-navbar-button mr-4"
            :class="visitor.b2b && !HAS_B2B_ACCESS ? 'chat-header-navbar-button-disable' : ''"
            @click="onShowLinkedCCVisitor()"
          >
            <info-icon class="chat-header-navbar-icon pa-icon-default" :width="16" :height="16"></info-icon>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

import ChatIcon from '@/components/icons/ChatIcon.vue'
import MicrophoneIcon from '@/components/icons/MicrophoneIcon.vue'
import MicrophoneOffIcon from '@/components/icons/MicrophoneOffIcon.vue'
import VideoCallIcon from '@/components/icons/VideoCallIcon.vue'
import VideoCallOffIcon from '@/components/icons/VideoCallOffIcon.vue'
import InfoIcon from '@/components/icons/InfoIcon.vue'
import TrashIcon from '@/components/icons/TrashIcon.vue'
import PhoneIcon from '@/components/icons/PhoneIcon.vue'
import ConnectModal from '@/components/ConnectModal.vue'
import GptConnectIcon from '@/components/icons/gpt/GptConnectIcon.vue'

import LinkedVisitorDraggableModal from '@/components/LinkedVisitorDraggableModal.vue'
import LinkedVisitorInfo from '@/components/LinkedVisitorInfo.vue'

import Visitor from '@/views/Visitor.vue'
import AudioVideoSettings from './AudioVideoSettings.vue'
import SearchVisitors from '../search/SearchVisitors.vue'
import GptChats from '../search/GptChats.vue'

import VisitorStatusIconWithText from '@/components/vega/VisitorStatusIconWithText'

export default {
  components: {
    ChatIcon,
    MicrophoneIcon,
    MicrophoneOffIcon,
    VideoCallIcon,
    VideoCallOffIcon,
    InfoIcon,
    TrashIcon,
    PhoneIcon,
    ConnectModal,
    AudioVideoSettings,
    LinkedVisitorDraggableModal,
    LinkedVisitorInfo,
    SearchVisitors,
    GptChats,
    VisitorStatusIconWithText,
    GptConnectIcon,
    Visitor
  },
  props: {
    selectedAudio: {
      type: Object,
      required: false,
      default: null
    },
    selectedSpeaker: {
      type: Object,
      required: false,
      default: null
    },
    selectedVideo: {
      type: Object,
      required: false,
      default: null
    },
    volumeLevel: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      showVisitor: false,
      showSettings: false,
      showConfirmDelete: false,
      deletePrompt: false,
      showLinkedCCVisitor: false,
      linkedCCFormFields: null,
      showVisitors: false,
      showGptChats: false,
      searchVisitorsKey: Math.random().toString(36).substring(2, 15),
      searchGptChatsKey: Math.random().toString(36).substring(2, 15)
    }
  },
  computed: {
    ...mapGetters({
      company: 'company',
      activeUserInfo: 'activeUser',
      messageModes: 'webrtc/messageModes',
      messageMode: 'webrtc/messageMode',
      audioVideoStarted: 'webrtc/audioVideoStarted',
      visitorId: 'webrtc/visitorId',
      visitor: 'webrtc/visitor',
      isSearchVisitorsOn: 'isSearchVisitorsOn',
      isSearchGptChatsOn: 'isSearchGptChatsOn'
    }),
    isAudioDisabled() {
      return this.company && this.company.disableAudio
    },
    audioButtonClass() {
      if (this.isAudioDisabled) return 'chat-header-disabled'
      if (this.messageMode === 'audio') return 'chat-header-active'
      return ''
    },
    audioButtonIconClass() {
      if (this.isAudioDisabled) return 'chat-header-icon-disabled'
      if (this.messageMode !== 'audio') return 'pa-icon-default'
      return ''
    },
    isVideoDisabled() {
      return this.company && this.company.disableVideo
    },
    videoButtonClass() {
      if (this.isVideoDisabled) return 'chat-header-disabled'
      if (this.messageMode === 'video') return 'chat-header-active'
      return ''
    },
    videoButtonIconClass() {
      if (this.isVideoDisabled) return 'chat-header-icon-disabled'
      if (this.messageMode !== 'video') return 'pa-icon-default'
      return ''
    },

    HAS_B2B_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('b2b-identification') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },
    IS_CALL_ACTIVE() {
      return Boolean(this.visitor && this.visitor.connectedAgentId)
    },
    IS_VISITOR_FROM_CALL_CENTER_CUSTOMER() {
      if (this.visitor && this.visitor.callCenterId && this.visitor.callCenterId !== this.visitor.company) {
        return true
      }
      return false
    }
  },
  created() {
    this.$serverBus.$on('close-company-info', () => {
      this.showVisitor = false
    })
  },
  mounted() {
    this.$serverBus.$on('close-linked-visitor-info', () => {
      this.showLinkedCCVisitor = false
      this.linkedCCFormFields = null
    })
  },
  beforeDestroy() {
    this.$serverBus.$off('close-company-info')
    this.$serverBus.$off('close-linked-visitor-info')
  },
  methods: {
    ...mapMutations({
      setIsSearchVisitors: 'SET_IS_SEARCH_VISITORS',
      setIsSearchGptChats: 'SET_IS_SEARCH_GPT_CHATS'
    }),
    onShowSearchVisitors() {
      this.setIsSearchVisitors(true)
      this.showVisitors = true
    },
    onShowSearchGptChats() {
      this.setIsSearchGptChats(true)
      this.showGptChats = true
    },
    onEndSessionWithVisitor() {
      this.$serverBus.$emit('agent-end-call')
    },
    onClickCommunicationControl(type) {
      if (type === 'audio' && this.isAudioDisabled) {
        return
      }
      if (type === 'video' && this.isVideoDisabled) {
        return
      }

      this.$parent.$emit('change-communication-control', type)
    },
    async onShowLinkedCCVisitor() {
      this.showLinkedCCVisitor = true
      const visitorCompanyRef = await this.$db.collection('company').doc(this.visitor.company).get()
      const visitorCompanyData = await visitorCompanyRef.data()
      if (visitorCompanyData && visitorCompanyData.linkCCFormFields) {
        this.linkedCCFormFields = visitorCompanyData.linkCCFormFields
      }
    },
    onShowVisitor() {
      if (this.visitor && this.visitor.b2b && !this.HAS_B2B_ACCESS) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('vue.accessDenied'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        return
      }

      this.showVisitor = true
      this.$serverBus.$emit('get-company-info', this.visitor)
    },
    onCloseSettings() {
      this.showSettings = false
    },
    onDeleteVisitor() {
      this.showConfirmDelete = true
    },
    async onDeleteVisitorConfirmed() {
      this.showConfirmDelete = true
      this.$vs.loading()
      if (this.visitorId) {
        await this.$db.collection('visitors').doc(this.visitorId).delete()
      }
      this.$parent.$emit('visitor-deleted', true)
      setTimeout(() => {
        this.showConfirmDelete = false
        this.$vs.loading.close()
      }, 500)
    }
  },
  watch: {
    isSearchVisitorsOn() {
      this.searchVisitorsKey = Math.random().toString(36).substring(2, 15)
      this.showVisitors = this.isSearchVisitorsOn
    },
    isSearchGptChatsOn() {
      this.searchGptChatsKey = Math.random().toString(36).substring(2, 15)
      this.showGptChats = this.isSearchGptChatsOn
    }
  }
}
</script>
<style lang="scss">
.visitor-modal {
  .modal-container {
    padding: 0 !important;
  }
}
.visitors-sidebar {
  .vs-sidebar {
    z-index: 52010 !important;
    max-width: 100% !important;
  }
}
.pa-bk-secondary {
  background: rgba(var(--vs-secondary), 1) !important;
  border-radius: 10px;
  color: #fff !important;
}

.pa-bk-danger {
  background: rgba(240, 85, 65, 0.8) !important;
}
.pa-bk-danger:hover {
  background: #f05541 !important;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.5);
}
.pa-icon-default {
  color: rgba(var(--vs-primary), 1) !important;
  path {
    stroke: rgba(var(--vs-primary), 1) !important;
  }
}

.chat-header {
  &-icon-disabled {
    color: #fff !important;

    path {
      stroke: #fff !important;
      fill: #d3d3d3 !important;
    }
  }

  &-disabled {
    background: #d3d3d3 !important;
    cursor: default !important;
  }
  &-disabled:hover {
    background: #d3d3d3 !important;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.5);
  }

  &-active {
    background: #275D73 !important;
  }
  &-active:hover {
    background: #275D73 !important;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.5);
  }

  .delete-button {
    background: #e6ecf1;
  }
  .delete-button:hover {
    background: rgba(240, 85, 65, 1);
    color: #fff !important;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.3);
  }

  .cancel-button {
    background: #eaeaea;
  }
  .cancel-button:hover {
    background: #fff;
    color: #000 !important;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.3);
  }

  &-delete {
    border-color: rgba(240, 85, 65, 0.8) !important;
    border-top: none !important;
    border-left: none;
    border-right: none;
    border-style: solid;
    border-width: 1.5px;
    &-button {
      cursor: pointer;
      padding: 10px;
      width: 100px;
      text-align: center;
      color: rgba(var(--vs-secondary), 1) !important;
      font-weight: 600;
      border-radius: 10px;
    }
  }

  &-navbar {
    display: flex;
    background-color: #e8f6ff !important;
    box-sizing: border-box;
    border: 0.5px solid #e6ecf1;
    height: 65px;
    border-top-right-radius: 6px;

    &-back-button {
      display: none;
      margin-right: 150px;

      @media only screen and (max-width: 1024px) {
        display: block;
      }
    }

    &-icon {
      margin-left: -1px;
      cursor: pointer;
    }

    &-button {
      width: 35px;
      height: 35px;
      background: #fff;
      border-radius: 6px;
      display: flex;
      cursor: pointer;

      justify-content: center;
      align-items: center;
      transition: 0.5s;

      @media only screen and (max-width: 1024px) {
        display: none;
      }

      &-disable {
        background: #ccc !important;
        cursor: default !important;
      }

      &--gpt {
        background: rgb(232, 246, 255);

        &:hover {
          box-shadow: rgb(232, 246, 255) 0, 8px 25px -8px;
        }
      }
    }
    &-button:hover {
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.8);
    }
    &-left-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    &-communications-control {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      @media only screen and (max-width: 600px) {
        &-button {
          max-width: 35px;
        }
      }

      &-button {
        width: 106px;
        height: 35px;
        background: #f7f7f7;
        border-radius: 6px;
        display: flex;
        box-shadow: 0px 2px 4px rgba(196, 196, 196, 0.25);
        cursor: pointer;
        justify-content: center;
        align-items: center;
        transition: 0.5s;
      }
      &-button:hover {
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.5);
      }
    }

    &-right-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      width: 100%;

      @media only screen and (max-width: 1024px) {
        display: none;
      }
    }

    &-chat-buttons {
      &-btn {
        width: 106.6px;
        height: 35px;
        &-highlighted {
          background-color: rgba(var(--vs-primary), 1) !important;
        }
      }
    }
  }

  &-header {
    background-color: rgba(var(--vs-secondary), 0.1) !important;
  }

  .connect-visitor.includeIconOnly {
    width: 188px !important;
    height: 38px !important;
  }
}
</style>
