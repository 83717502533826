<template>
  <div class="search-visitors">
    <div id="visitor-search-connected-agent-info" style="position: fixed; z-index: 54000; top: 50%; left: 100%"></div>
    <div class="search-visitors--action">
      <input type="text" name="search" :placeholder="$t('vue.searchVisitors')" class="search-visitors--input w-full mr-2" v-model="searchInput" />
      <vs-button class="search-visitors--close-button ml-2" color="#eaeeea" @click.prevent="onCloseSearchVisitors">
        <CloseIcon :width="18" :height="18" color="#53535B" />
      </vs-button>
    </div>
    <vs-progress v-if="isLoading" indeterminate color="primary">primary</vs-progress>
    <div class="search-visitors--container">
      <!-- LEFT COLUMN : VISITORS -->
      <visitors
        :visitors="filteredVisitors"
        @update-active-chat-visitor="updateActiveChatUser"
        @load-more="loadMore"
        :isLoadMoreDisabled="isLoadMoreDisabled"
      />
      <!-- RIGHT COLUMN : MESSAGES -->
      <messages />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import CloseIcon from '@/components/icons/CloseIcon'
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

import Visitors from './Visitors.vue'
import Messages from './Messages.vue'
export default {
  name: 'SearchVisitors',
  components: {
    CloseIcon,
    Visitors,
    Messages
  },
  data() {
    return {
      query: null,
      isLoading: true,
      searchInput: ''
    }
  },
  computed: {
    ...mapGetters({
      isSearchVisitorsOn: 'isSearchVisitorsOn',
      visitors: 'searchVisitors',
      dialogs: 'dialogs',
      campaigns: 'campaigns',
      company: 'company',
      widgetsAssigned: 'widgetsAssigned',
      activeUserInfo: 'activeUser',
      widgetsFilterForVisitor: 'widgetsFilterForVisitor'
    }),
    isLoadMoreDisabled() {
      return this.isLoading || this.visitors.length === 0
    },
    filteredVisitors() {
      let visitors = this.visitors
      const search = this.searchInput.toLowerCase().trim()
      if (search.length > 0) {
        visitors = visitors.filter((x) => {
          const foundCity = search.length > 0 && x.city && x.city.toLowerCase().indexOf(search) > -1

          const foundCountry = search.length > 0 && x.country && x.country.toLowerCase().indexOf(search) > -1

          const foundISP = search.length > 0 && x.companyName && x.companyName.toLowerCase().indexOf(search) > -1

          const foundName = search.length > 0 && x.name && x.name.toLowerCase().indexOf(search) > -1

          const foundNetName = search.length > 0 && x.netname && x.netname.toLowerCase().indexOf(search) > -1

          const foundRegion = search.length > 0 && x.region && x.region.toLowerCase().indexOf(search) > -1

          const filteredDialogs =
            search.length > 0 &&
            this.dialogs
              .map((f) => {
                return { name: f.name && f.name.toLowerCase(), id: f.id }
              })
              .filter((x) => x.name.indexOf(search) > -1)
          const foundDialog = search.length > 0 && filteredDialogs.filter((d) => d.id === x.dialogId).length > 0

          const filteredCampaigns =
            search.length > 0 &&
            this.campaigns
              .map((f) => {
                return { name: f.name && f.name.toLowerCase(), id: f.id }
              })
              .filter((x) => x.name.indexOf(search) > -1)
          const foundCampaign = search.length > 0 && filteredCampaigns.filter((d) => d.id === x.campaignId).length > 0

          const foundChats =
            !x.chats || search.length === 0 ? false : x.chats.filter((x) => x.message && x.message.toLowerCase().indexOf(search) > -1).length > 0

          return foundCity || foundCountry || foundISP || foundName || foundNetName || foundRegion || foundDialog || foundCampaign || foundChats
        })
      }

      return visitors
    }
  },
  async mounted() {
    this.setSearchQuery()
    if (this.visitors.length === 0 || !window.__pa_last_visitor_search_doc) {
      this.setSearchVisitors([])

      const _visitorsRef = await this.query.get()
      const documents = _visitorsRef.docs
      window.__pa_last_visitor_search_doc = documents[documents.length - 1]

      _visitorsRef.docs.map(async (visitorRef) => {
        const data = visitorRef.data()
        data.id = visitorRef.id
        this.processData(data)

        this.pushSearchVisitor(data)
      })
    }
    this.isLoading = false
  },
  methods: {
    ...mapMutations({
      setIsSearchVisitors: 'SET_IS_SEARCH_VISITORS',
      setSearchVisitors: 'SET_SEARCH_VISITORS',
      pushSearchVisitor: 'PUSH_SEARCH_VISITOR',
      setVisitorId: 'webrtc/setVisitorId',
      setVisitor: 'webrtc/setVisitor'
    }),
    setSearchQuery() {
      this.query = this.$db.collection('visitors').where('company', '==', this.activeUserInfo.company)
      this.query = this.query.where('type', '==', 'embed')
      this.query = this.query.where('chats', '>', [])
      if (this.activeUserInfo.role === 'agent' && this.widgetsAssigned && this.widgetsAssigned.length > 0) {
        this.query = this.query.where('dialogId', 'in', this.widgetsAssigned)
      }
      if (this.activeUserInfo.role === 'admin' && this.widgetsFilterForVisitor && this.widgetsFilterForVisitor.length > 0) {
        this.query = this.query.where('dialogId', 'in', this.widgetsFilterForVisitor)
      }
      this.query = this.query.orderBy('chats', 'desc')
      this.query = this.query.orderBy('lastRequestDate', 'desc').limit(30)
    },
    onCloseSearchVisitors() {
      this.setIsSearchVisitors(false)
    },
    async loadMore() {
      this.isLoading = true
      this.query = this.query.startAfter(window.__pa_last_visitor_search_doc)
      const _visitorsRef = await this.query.get()
      const documents = _visitorsRef.docs
      window.__pa_last_visitor_search_doc = documents[documents.length - 1]
      _visitorsRef.docs.map(async (visitorRef) => {
        const data = visitorRef.data()
        data.id = visitorRef.id
        this.processData(data)
        this.pushSearchVisitor(data)
      })
      this.isLoading = false
    },

    processData(data) {
      data.name = data.owner || data.isp || data.netname
      data.city = data.city ? data.city.charAt(0).toUpperCase() + data.city.slice(1) : null
      data.scoring = data.score ? data.score * 10 : 40
      data.lastSeen = dayjs.unix(data.lastRequestDate.seconds).locale(this.$i18n.locale).fromNow()
      data.lastSeenTimestamp = dayjs.unix(data.lastRequestDate.seconds).locale(this.$i18n.locale).format('LLL')
    },
    async updateActiveChatUser(visitorId) {
      const visitor = this.visitors.find((x) => x.id === visitorId)
      if (visitor) {
        this.setVisitorId(visitorId)
        this.setVisitor(visitor)
      }
    }
  },
  beforeDestroy() {
    this.setVisitor(null)
    this.setVisitorId(null)
    this.$serverBus.$off('show-search-connected-agent-info')
  }
}
</script>
<style lang="scss" scoped>
.search-visitors {
  position: relative;
  display: flex;
  flex-direction: column;

  &--action {
    display: flex;
    flex-direction: row;
    padding: 10px;
    border-bottom-width: 1px;
    border-bottom-color: #ececec;
    border-bottom-style: solid;
  }

  &--input {
    border-radius: 6px;
    padding: 5px 10px;
    border-width: 1px;
    border-color: #ececec;
  }

  &--close-button {
    padding: 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    //position: absolute;
    // top: 10px;
    // right: 10px;
    width: 35px;
    height: 35px;

    ::v-deep .vs-button-text {
      width: 18px !important;
      height: 18px !important;
    }
  }

  &--container {
    background: #ffffff;
    border-radius: 6px;
    font-family: 'Lato';
    display: flex;
    flex-direction: row;
  }
}
</style>
