<template>
  <div
    class="chat-messages border border-solid d-theme-border-grey-light border-t-0 border-r-0 border-b-0"
    :class="{
      'sidebar-spacer--wide': clickNotClose,
      'flex items-center justify-center': visitorId === null,
      relative: conversationCompleted,
      'chat-messages--active-mobile': isActiveChatMobile
    }"
  >
    <template>
      <div class="flex flex-col chat-messages-midcontainer">
        <conversation-completed v-if="conversationCompleted && visitorId"></conversation-completed>
        <chat-navbar
          :selected-audio="selectedAudio"
          :selected-video="selectedVideo"
          :selected-speaker="selectedSpeaker"
          :volume-level="volumeLevel"
        ></chat-navbar>
        <div class="chat-messages-chat-av-container" :style="avContainerStyle">
          <chat-log v-if="visitorId" :class="messageMode === 'chat' || audioVideoStarted ? 'w-full' : 'flex-50'"></chat-log>
          <div :class="!audioVideoStarted ? 'flex-50' : ''" v-if="messageMode === 'audio' || messageMode === 'video'">
            <audio-call v-if="messageMode === 'audio' && !audioVideoStarted"></audio-call>
            <video-call v-if="messageMode === 'video' && !audioVideoStarted"></video-call>
          </div>
        </div>
        <div v-if="visitorId" :class="visitorIsTyping ? 'chat-messages-toolbar-mt-10' : 'chat-messages-toolbar-mt-auto'">
          <div v-if="canChatWithVisitor">
            <toolbar :cobrowse-status="cobrowseStatus" :call-recording-status="callRecordingStatus">
            </toolbar>

            <send-chat-message></send-chat-message>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ChatLog from './ChatLog.vue'
import ChatNavbar from './ChatNavbar.vue'
import AudioCall from './AudioCall.vue'
import VideoCall from './VideoCall.vue'
import Toolbar from './Toolbar.vue'
import ConversationCompleted from './ConversationCompleted.vue'
import SendChatMessage from './SendChatMessage.vue'

const COBROWSE_STATUS = {
  STOPPED: 'stopped',
  STARTED: 'started',
  COBROWSING: 'cobrowsing'
}

export default {
  components: {
    ChatLog,
    ChatNavbar,
    AudioCall,
    VideoCall,
    Toolbar,
    ConversationCompleted,
    SendChatMessage
  },
  props: {
    clickNotClose: {
      type: Boolean,
      required: true
    },
    conversationCompleted: {
      type: Boolean,
      required: false,
      default: false
    },
    localStream: {
      type: MediaStream,
      required: false,
      default: null
    },
    remoteStream: {
      type: MediaStream,
      required: false,
      default: null
    },
    selectedAudio: {
      type: Object,
      required: false,
      default: null
    },
    selectedSpeaker: {
      type: Object,
      required: false,
      default: null
    },
    selectedVideo: {
      type: Object,
      required: false,
      default: null
    },
    volumeLevel: {
      type: Number,
      required: true
    },
    cobrowseStatus: {
      type: String,
      required: false,
      default: COBROWSE_STATUS.STOPPED
    },
    callRecordingStatus: {
      type: String,
      required: true
    },
    isActiveChatMobile: {
      type: Boolean
    }
  },
  data() {
    return {
      typedMessage: ''
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      visitorIsTyping: 'visitorIsTyping',
      messageMode: 'webrtc/messageMode',
      audioVideoStarted: 'webrtc/audioVideoStarted',
      visitorId: 'webrtc/visitorId',
      visitor: 'webrtc/visitor'
    }),
    canChatWithVisitor() {
      return (
        this.visitor &&
        (this.isVisitorConnectedToTheCurrentAgent || !this.visitor.connectedAgentId) &&
        (this.visitor.type === 'embed' || this.visitor.type === 'campaign')
      )
    },
    isVisitorConnectedToTheCurrentAgent() {
      return this.visitor && this.visitor.connectedAgentId && this.activeUserInfo.uid === this.visitor.connectedAgentId
    },
    avContainerStyle() {
      return {
        //height: this.visitor.connectedAgentId === this.activeUserInfo.uid ? 'calc(51vh)' : 'calc(100% - 65px)'
        height: 'calc(100% - 65px)',
        maxHeight: 'calc(63vh)'
      }
    }
  }
}
</script>
<style lang="scss">
.chat-messages {
  border-radius: 6px;
  border-top-left-radius: 0;
  background-color: white;

  @media only screen and (max-width: 1024px) {
    display: none !important;
  }

  &--active-mobile {
    @media only screen and (max-width: 1024px) {
      display: block !important;
      position: absolute;
      left: -2.2rem;
      width: calc(100% + 1.8rem + 2.2rem) !important;
      opacity: 1;
      visibility: visible;
      z-index: 999;
    }
  }

  &-midcontainer {
    height: 100%;
    width: 100%;
  }
  .pa-icon-default {
    color: rgba(var(--vs-secondary), 1) !important;
    path {
      stroke: rgba(var(--vs-secondary), 1) !important;
    }
  }
  .flex-50 {
    flex: 0 0 50%;
  }

  &-chat-av-container {
    display: flex;
    flex-direction: row;
    flex-flow: row nowrap;
  }

  &-toolbar {
    &-mt-auto {
      margin-top: auto;
    }
    &-mt-10 {
      margin-top: 2.5rem !important;
    }
  }
}
</style>
