<template>
  <div class="video-call">
    <div class="video-call-start">
      <div class="video-call-row">
        <div
          class="video-call-button flex flex-row justify-center items-center"
          :class="isVideoActive ? ' video-call-pulse active' : ' video-call-pulse inactive'"
          @click="onStartVideo"
        >
          <video-call-icon class="video-call-button-icon" :width="25" :height="16"></video-call-icon>
          <span>{{ $t('vue.startVideo') }} </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import VideoCallIcon from '../../../components/icons/VideoCallIcon.vue'
export default {
  components: {
    VideoCallIcon
  },
  emits: ['on-audio-video-started'],
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      messageModes: 'webrtc/messageModes',
      audioVideoStarted: 'webrtc/audioVideoStarted',
      visitor: 'webrtc/visitor'
    }),
    isVideoActive() {
      return (
        (this.visitor && this.visitor.agentRequest && !this.audioVideoStarted) ||
        (!this.audioVideoStarted && !this.visitor.agentRequest && this.visitor.connectedAgentId === this.activeUserInfo.uid)
      )
    }
  },
  methods: {
    onStartVideo() {
      if (this.isVideoActive) {
        if (this.audioVideoStarted) {
          const session = {
            messageMode: 'video',
            started: true,
            messageModes: ['video']
          }
          this.$parent.$emit('on-audio-video-started', session)
        } else {
          this.$serverBus.$emit('on-start-av-call', 'video')
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.video-call {
  width: 100%;
  height: 100%;
  background: rgba(230, 236, 241, 0.8);
  &-start {
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-row {
    width: auto;
  }
  &-button {
    border-radius: 6px;
    text-align: center;
    padding: 14px 24px 14px 24px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    span {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      margin-left: 17px;
      color: #ffffff;
      text-transform: uppercase;
    }
  }
  &-button.active {
    background: #1c8439;
  }
  &-button.inactive {
    background: #95a098;
  }
  &-pulse.active {
    box-shadow: 0 0 0 rgba(28, 132, 57, 1);
    background: #1c8439;
    animation: pulse 2s infinite;
  }
  &-pulse.inactive {
    box-shadow: 0 0 0 rgba(181, 194, 184, 1);
    background: #95a098;
  }
  &-button:hover.inactive {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
  &-item {
    background-color: tomato;
    padding: 5px;
    margin: 10px;
    line-height: 20px;
    color: white;
    font-weight: bold;
    font-size: 2em;
    text-align: center;
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(28, 132, 57, 1);
    }
    70% {
      -webkit-box-shadow: 0 0 0 15px rgba(28, 132, 57, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(28, 132, 57, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(28, 132, 57, 1);
      box-shadow: 0 0 0 0 rgba(28, 132, 57, 0.4);
    }
    70% {
      -moz-box-shadow: 0 0 0 15px rgba(28, 132, 57, 0);
      box-shadow: 0 0 0 15px rgba(28, 132, 57, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(28, 132, 57, 0);
      box-shadow: 0 0 0 0 rgba(28, 132, 57, 0);
    }
  }
}
</style>
