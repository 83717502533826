<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    :stroke="color"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-volume-2"
  >
    <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
    <path d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07"></path>
  </svg>
</template>
<script>
export default {
  name: 'SpeakerIcon',
  props: {
    color: {
      type: String,
      default: '#B2B2B2'
    },
    stroke: {
      type: String,
      default: '#fff'
    },
    width: {
      type: Number,
      default: 41
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>
