<template>
  <div v-show="IS_CHAT_ON" class="ss-chat-container">
    <div class="ss-chat-container-close-btn" @click="onCloseChatClicked">
      <close-icon color="#3034F7" :width="24" :height="24" />
    </div>
    <div class="ss-chat-container-chats">
      <chat-log :is-inside-modal="true" :screen="SCREEN.MAXIMIZED" />
      <div v-if="canChatWithVisitor">
        <send-chat-message></send-chat-message>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import CloseIcon from '@/components/icons/CloseIcon.vue'
import ChatLog from './ChatLog.vue'
import SendChatMessage from './SendChatMessage.vue'

const SCREEN = {
  MINIMIZED: 'minimzed',
  MAXIMIZED: 'maximized'
}

export default {
  name: 'ScreenSharingChat',
  components: {
    ChatLog,
    SendChatMessage,
    CloseIcon
  },
  data() {
    return {
      SCREEN
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      messageModes: 'webrtc/messageModes',
      visitorId: 'webrtc/visitorId',
      visitor: 'webrtc/visitor'
    }),
    IS_CHAT_ON() {
      return this.messageModes && this.messageModes.includes('chat')
    },
    isVisitorConnectedToTheCurrentAgent() {
      return this.visitor && this.visitor.connectedAgentId && this.activeUserInfo.uid === this.visitor.connectedAgentId
    },
    canChatWithVisitor() {
      return (
        this.visitor &&
        (this.isVisitorConnectedToTheCurrentAgent || !this.visitor.connectedAgentId) &&
        (this.visitor.type === 'embed' || this.visitor.type === 'campaign')
      )
    }
  },
  methods: {
    async onCloseChatClicked() {
      this.$db.collection('visitors').doc(this.visitorId).set({ visitorMessages: this.$firebase.firestore.FieldValue.delete() }, { merge: true })
      this.$emit('on-click-communications-controls', 'chat')
    }
  }
}
</script>
<style lang="scss" scoped>
.ss-chat-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 280px;
  height: calc(100vh);
  position: fixed;
  background-color: white;
  overflow-wrap: break-word;
  word-wrap: break-word;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.5);

  z-index: 54000;

  &-close-btn {
    cursor: pointer;
    margin-right: 10px;
    margin-top: 10px;
  }

  &-chats {
    width: 100%;
    height: calc(100vh);
  }
}
</style>
