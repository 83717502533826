<template>
  <div class="agent-inr">
    <rating-screen v-show="showRatingScreen" :visitor="activeChatVisitor" @conversation-rating-saved="onConversationRatingSaved"></rating-screen>
    <div class="requests-container">
      <div
        class="incoming-requests"
        :class="{
          '_with-cobrowsing': IS_COBROWSING
        }"
      >
        <!-- Header Component -->
        <requests-header
          v-if="IS_SCREEN_MINIMIZED"
          @update-filter-by-sessions="updateFilterBySessions"
          @search-visitor="onSearchVisitor"
          :conversation-status="conversationStatus"
          :isMobile="isMobile"
        ></requests-header>

        <div class="incoming-requests-chat-interface">
          <!-- LEFT COLUMN : VISITORS -->
          <visitors
            v-if="IS_SCREEN_MINIMIZED"
            :visitors="visitorList"
            :selected-audio="selectedAudio"
            :selected-video="selectedVideo"
            :selected-speaker="selectedSpeaker"
            :volume-level="volumeLevel"
            :isMobile="isMobile"
            @start-communication-with-visitor="onStartCommunicationWithVisitor"
            @update-active-chat-visitor="updateActiveChatUser"
            @select-visitors="onSelectVisitors"
          />

          <!-- RIGHT COLUMN : MESSAGES -->
          <messages
            v-if="IS_SCREEN_MINIMIZED && !IS_COBROWSING"
            :click-not-close="clickNotClose"
            :conversation-completed="conversationCompleted"
            :local-stream="localStream"
            :remote-stream="remoteStream"
            :selected-audio="selectedAudio"
            :selected-video="selectedVideo"
            :selected-speaker="selectedSpeaker"
            :volumeLevel="volumeLevel"
            :cobrowse-status="cobrowseStatus"
            :call-recording-status="callRecordingStatus"
            :isActiveChatMobile="isActiveChatMobile"
            @visitor-deleted="onVisitorDeleted"
            @change-communication-control="onChangeCommunicationControl"
            @close-communication-control="onCloseCommunicationControl"
            @on-conversation-completed="onConversationCompleted"
            @on-audio-video-started="onAudioVideoStarted"
          />
        </div>
      </div>
      <incoming-requests-message v-if="!HAS_VIDEO_AUDIO_CHAT_ACCESS && SHOW_CLAIMS_MESSAGE && !isMobileApp" />
    </div>

    <webrtc-communication
      :selected-audio="selectedAudio"
      :selected-video="selectedVideo"
      :selected-speaker="selectedSpeaker"
      :call-recording-status="callRecordingStatus"
      :clearWebRtc="clearWebRtc"
      :has-visitor-made-offer="hasVisitorMadeOffer"
      :isMobile="isMobile"
      @update-webrtc-stream="onWebrtcStreamUpdated"
      @on-mute-audio-video="onMuteAudioVideo"
      @on-change-message-mode-audio="onChangeMessageModeAudio"
      @on-change-message-mode-video="onChangeMessageModeVideo"
    >
    </webrtc-communication>
    <draggable-modal v-if="audioVideoStarted && !isMobile" :show="displayAudioVideoModal" :width="280" :height="280" bkcolor="#F7F7F7">
      <audio-video-call-modal
        :local-stream="localStream"
        :remote-stream="remoteStream"
        :selected-audio="selectedAudio"
        :selected-video="selectedVideo"
        :selected-speaker="selectedSpeaker"
        :volumeLevel="volumeLevel"
        :cobrowse-status="cobrowseStatus"
        :call-recording-status="callRecordingStatus"
        :screen="screen"
        @change-communication-control="onChangeCommunicationControl"
        @close-communication-control="onCloseCommunicationControl"
      ></audio-video-call-modal>
    </draggable-modal>

    <div v-if="isMobile && isActiveChatMobile" class="chat-mobile-wrapper">
      <audio-video-call-modal
        :local-stream="localStream"
        :remote-stream="remoteStream"
        :selected-audio="selectedAudio"
        :selected-video="selectedVideo"
        :selected-speaker="selectedSpeaker"
        :volumeLevel="volumeLevel"
        :cobrowse-status="cobrowseStatus"
        :call-recording-status="callRecordingStatus"
        :screen="screen"
        :isMobile="isMobile"
        :isAudioVideoStartedBySomeone="audioVideoStarted || HAS_VISITOR_STARTED_AUDIOVIDEO"
        :IS_VISITOR_SCREEN_SHARING="IS_VISITOR_SCREEN_SHARING"
        @change-communication-control="onChangeCommunicationControl"
        @close-communication-control="onCloseCommunicationControl"
      ></audio-video-call-modal>
    </div>

    <cobrowsing v-if="IS_COBROWSING" :cobrowse-status="cobrowseStatus" :visitor="activeChatVisitor" :call-recording-status="callRecordingStatus"></cobrowsing>
    <screen-sharing
      v-if="IS_VISITOR_SCREEN_SHARING && !isMobile"
      :remote-stream="remoteStream"
      @change-communication-control="onChangeCommunicationControl"
    ></screen-sharing>

    <vs-popup
      v-if="company && company.isCallCenter && callCenterSettings[0] && callCenterSettings[0].statusList"
      button-close-hidden
      class="custom-popup-rework-data"
      :title="$t('vue.describeInfoAboutTheRework')"
      :active.sync="isReworkDataPopupOpened"
    >
      <p>{{ $t('vue.reworkTime') }}: {{ reworkData.reworkTimeFormated }}</p>

      <multiselect
        v-model="reworkData.status"
        name="status"
        label="name"
        track-by="name"
        class="mt-6"
        :maxHeight="200"
        :options="callCenterSettings[0].statusList"
        :multiple="false"
        :close-on-select="true"
        :clear-on-select="false"
        :preserve-search="true"
        :placeholder="$t('vue.pickOne')"
        :selectLabel="$t('vue.pressEnterToSelect')"
        :selectedLabel="$t('vue.selectedLabel')"
        :deselectLabel="$t('vue.pressEnterToRemove')"
        :preselect-first="false"
      >
        <template slot="singleLabel" slot-scope="{ option }">
          {{ `${option.name} (${option.type})` }}
        </template>

        <template slot="option" slot-scope="{ option }">
          <div class="flex option__desc">
            <span class="option__title">{{ option.name }}</span>

            <div
              class="option__color"
              :style="{
                background: getStatusColor(option.type)
              }"
            />
          </div>
        </template>
      </multiselect>

      <vs-textarea
        name="comment"
        class="mt-6 mb-2 w-full custom-vs-textarea"
        :label="$t('vue.comment')"
        :data-vv-as="$t('vue.comment')"
        v-model="reworkData.comment"
        height="80px"
      />

      <br />

      <vs-button class="mt-6" type="border" @click="sendReworkData">
        {{ $t('vue.finish') }}
      </vs-button>
    </vs-popup>
  </div>
</template>

<script>
/* eslint-disable */
import 'firebase/firestore'
import 'firebase/database'
import _ from 'underscore'
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

import 'vue-multiselect/dist/vue-multiselect.min.css'
import Multiselect from 'vue-multiselect'

import { Device } from '@capacitor/device'
import { Camera as CapacitorCamera } from '@capacitor/camera'
import { Microphone as CapacitorMicrophone } from '@mozartec/capacitor-microphone'

import RequestsHeader from './header/RequestsHeader.vue'
import Visitors from './visitors/Visitors.vue'
import Messages from './messages/Messages.vue'
import WebrtcCommunication from './WebrtcCommunication.vue'

import DraggableModal from '../../components/DraggableModal.vue'
import AudioVideoCallModal from './messages/AudioVideoCallModal.vue'

import Cobrowsing from './Cobrowsing.vue'
import ScreenSharing from './ScreenSharing.vue'

import IncomingRequestsMessage from '@/components/upgrade-messages/IncomingRequestsMessage.vue'
import RatingScreen from './messages/RatingScreen.vue'

import { mobile_check } from '@/mixins/mobile_check'
import { gen2function } from '@/mixins/gen2function'

const COBROWSE_STATUS = {
  STOPPED: 'stopped',
  STARTED: 'started',
  COBROWSING: 'cobrowsing'
}

const CALL_RECORDING_STATUS = {
  STOPPED: 'stopped',
  STARTED: 'started',
  CALL_RECORDING: 'call-recording',
  STOPPING: 'stopping'
}

const CONVERSATION_STATUS = {
  STOPPED: 'stopped',
  STARTED: 'started'
}

const SCREEN = {
  MINIMIZED: 'minimzed',
  MAXIMIZED: 'maximized'
}

export default {
  components: {
    RequestsHeader,
    Visitors,
    Messages,
    WebrtcCommunication,
    DraggableModal,
    AudioVideoCallModal,
    Cobrowsing,
    ScreenSharing,
    Multiselect,
    IncomingRequestsMessage,
    RatingScreen
  },
  mixins: [mobile_check, gen2function],
  provide() {
    return {
      filterRequests: this.filterRequests,
      search: this.search
    }
  },
  created() {
    this.isMobile = this.mobileCheck()
    this.checkIsMobileApp()
    this.checkAudioVideoPermissionsOnMobile()

    setTimeout(() => {
      this.SHOW_CLAIMS_MESSAGE = true
    }, 10000)

    this.$serverBus.$on('back-to-visitors-list', () => {
      if (this.isMobile) {
        this.setScreenOverflow(false)

        this.isActiveChatMobile = false
      }
    })

    this.$serverBus.$on('set-screen-overflow', (value) => {
      this.setScreenOverflow(value)
    })

    this.$serverBus.$on('maximize-screen', () => {
      this.screen = this.SCREEN.MAXIMIZED
    })

    this.$serverBus.$on('agent-end-call', () => {
      this.onCloseCommunicationControl()
    })

    this.$serverBus.$on('minimize-screen', () => {
      this.screen = this.SCREEN.MINIMIZED
    })

    this.$serverBus.$on('audio-input-selected', (option) => {
      if (option && option.value && option.value === 'microphone-off' && this.audioVideoStarted) {
        let messageModes = [...this.messageModes]
        const _mode = 'audio'
        if (!messageModes.includes(_mode)) {
          messageModes.push(_mode)
        } else {
          messageModes = messageModes.filter((x) => x !== _mode)
        }
        this.setMessageModes([...new Set([...messageModes])])
        this.onChangeCommunicationControl(this.messageMode, messageModes, true)
      }

      if (option && option.value && option.value !== 'microphone-off' && this.audioVideoStarted) {
        const messageModes = [...this.messageModes]
        const _mode = 'audio'
        if (!messageModes.includes(_mode)) {
          messageModes.push(_mode)
          this.setMessageModes([...new Set([...messageModes])])
          this.onChangeCommunicationControl(this.messageMode, messageModes, true)
        }
      }

      this.selectedAudio = option
    })
    this.$serverBus.$on('audio-output-selected', (option) => {
      this.selectedSpeaker = option
    })
    this.$serverBus.$on('video-input-selected', (option) => {
      if (option && option.value && option.value === 'camera-off' && this.audioVideoStarted) {
        let messageModes = [...this.messageModes]
        const _mode = 'video'
        if (!messageModes.includes(_mode)) {
          messageModes.push(_mode)
        } else {
          messageModes = messageModes.filter((x) => x !== _mode)
        }
        this.onChangeCommunicationControl(this.messageMode, messageModes, true)
      }

      if (option && option.value && option.value !== 'camera-off' && this.audioVideoStarted) {
        const messageModes = [...this.messageModes]
        const _mode = 'video'
        if (!messageModes.includes(_mode)) {
          messageModes.push(_mode)
          this.setMessageModes([...new Set([...messageModes])])
          this.onChangeCommunicationControl(this.messageMode, messageModes, true)
        }
      }
      this.selectedVideo = option
    })
    this.$serverBus.$on('on-volume-changed', (volume) => {
      this.volumeLevel = volume.level
    })
    this.$serverBus.$on('on-cobrowse-clicked', () => {
      this.onChangeCobrowseStatus()
    })
    this.$serverBus.$on('on-screen-sharing-clicked', () => {
      this.onChangeScreeenSharingStatus()
    })
    this.$serverBus.$on('on-lead-form-clicked', () => {
      this.onClickLeadForm()
    })
    this.$serverBus.$on('on-call-recording-clicked', () => {
      this.onChangeCallRecordingStatus()
    })

    this.$serverBus.$on('on-call-recording', () => {
      this.onChangeCallRecordingStatus(this.CALL_RECORDING_STATUS.CALL_RECORDING)
    })

    this.$serverBus.$on('on-call-recording-stopped', () => {
      this.onChangeCallRecordingStatus(this.CALL_RECORDING_STATUS.STOPPED)
    })

    this.$serverBus.$on('on-conversation-started', () => {
      this.onConversationStarted()
    })

    this.$serverBus.$on('on-start-av-call', (mode) => {
      this.onStartCommunicationWithVisitor(this.activeChatVisitorId)
      //const lastReadChatsAgentInfo = this.onGetWhenLastReadChatAgent()
      // if (this.activeChatVisitor.agentRequest) {
      //   this.onAgentJoining(lastReadChatsAgentInfo)
      // }
      this.onStartAudioVideoByAgent(mode)
    })

    this.$serverBus.$on('on-agent-accept-call', (visitorId) => {
      this.onStartCommunicationWithVisitor(visitorId)
    })
  },
  async mounted() {
    const deviceInfos = await navigator.mediaDevices.enumerateDevices()
    const audioOutputDevices = []
    deviceInfos.forEach((device) => {
      if (device.kind === 'audiooutput') {
        audioOutputDevices.push({ text: device.label || 'Default Audio output', value: device.deviceId })
      }
    })

    this.setAudioOutputDevices(audioOutputDevices)

    if (!this.isAudioDisabled && localStorage.getItem('pa-speaker')) {
      const speaker = JSON.parse(localStorage.getItem('pa-speaker'))
      if (speaker) {
        const foundSpeaker = deviceInfos.find((x) => x.deviceId === speaker.value && x.kind === 'audiooutput')
        const defaultSpeaker =
          deviceInfos.find((x) => x.deviceId === 'default' && x.kind === 'audiooutput') || deviceInfos.find((x) => x.kind === 'audiooutput')
        if (!foundSpeaker && defaultSpeaker) {
          const option = {
            text: defaultSpeaker.label || 'Default Speaker',
            value: defaultSpeaker.deviceId
          }
          this.selectedSpeaker = option
          localStorage.setItem('pa-speaker', JSON.stringify(option))
        } else {
          this.selectedSpeaker = speaker
        }
      }
    }
    if (localStorage.getItem('pa-volume')) {
      const volumeLevel = parseFloat(localStorage.getItem('pa-volume'))
      this.volumeLevel = volumeLevel > 1 || volumeLevel < 0 ? 0.3 : volumeLevel
    }
    if (!this.isAudioDisabled && localStorage.getItem('pa-audio')) {
      const audio = JSON.parse(localStorage.getItem('pa-audio'))
      if (audio) {
        const foundAudio = deviceInfos.find((x) => x.deviceId === audio.value && x.kind === 'audioinput')
        const defaultAudio = deviceInfos.find((x) => x.deviceId === 'default' && x.kind === 'audioinput') || deviceInfos.find((x) => x.kind === 'audioinput')
        if (!foundAudio && defaultAudio) {
          const option = {
            text: defaultAudio.label || 'Default Microphone',
            value: defaultAudio.deviceId
          }
          this.selectedAudio = option
          localStorage.setItem('pa-audio', JSON.stringify(option))
        } else {
          this.selectedAudio = audio
        }
      }
    }
    if (!this.isVideoDisabled && localStorage.getItem('pa-video')) {
      const video = JSON.parse(localStorage.getItem('pa-video'))
      if (video) {
        const foundVideo = deviceInfos.find((x) => x.deviceId === video.value && x.kind === 'videoinput')
        const defaultVideo = deviceInfos.find((x) => x.deviceId === 'default' && x.kind === 'videoinput') || deviceInfos.find((x) => x.kind === 'videoinput')
        if (!foundVideo && defaultVideo) {
          const option = {
            text: defaultVideo.label || 'Default Camera',
            value: defaultVideo.deviceId
          }
          this.selectedVideo = option
          localStorage.setItem('pa-video', JSON.stringify(option))
        } else {
          this.selectedVideo = video
        }
      }
    }

    this.$serverBus.$on('set-user-status-online', () => {
      if (
        this.activeUserInfo &&
        this.activeChatVisitor &&
        this.activeChatVisitor.connectedAgentId &&
        this.activeChatVisitor.connectedAgentId === this.activeUserInfo.uid
      ) {
        this.setUserState('offline')
      }
    })

    this.$serverBus.$on('visitor-is-typing', (t) => {
      this.updateVisitorIsTyping(t)
    })

    this.$serverBus.$on('download-file', (refUrl, id) => {
      this.downloadFile(refUrl, id)
    })
    this.$serverBus.$on('delete-file', (closeUrl, index) => {
      this.onDeleteFile(closeUrl, index)
    })
    this.$serverBus.$on('clean-sent-files', () => {
      this.clearPickedFiles()
      this.clearChatFilesUpload()
    })
    this.$serverBus.$on('upload-files', (files) => {
      this.onUploadFiles(files)
    })
    this.$serverBus.$on('dropzone-upload-files', (files, ref) => {
      this.onDropzoneUploadFiles(files, ref)
    })

    if (this.$route && this.$route.params && this.$route.params.id && this.$route.params.action && this.$route.params.action === 'accept-call') {
      this.onStartCommunicationWithVisitor(this.$route.params.id)
    }
  },
  beforeDestroy() {
    this.onCloseAudioVideoSettings()
    this.onSetDefaultValues()
    this.$serverBus.$off('set-user-status-online')
    this.$serverBus.$off('visitor-is-typing')
    this.$serverBus.$off('agent-end-call')
    this.$serverBus.$off('maximize-screen')
    this.$serverBus.$off('minimize-screen')
    this.$serverBus.$off('audio-input-selected')
    this.$serverBus.$off('audio-output-selected')
    this.$serverBus.$off('video-input-selected')
    this.$serverBus.$off('on-volume-changed')
    this.$serverBus.$off('on-cobrowse-clicked')
    this.$serverBus.$off('on-screen-sharing-clicked')
    this.$serverBus.$off('on-call-recording-clicked')
    this.$serverBus.$off('on-call-recording')
    this.$serverBus.$off('on-call-recording-stopped')
    this.$serverBus.$off('on-conversation-started')

    this.$serverBus.$off('on-start-av-call')
    this.$serverBus.$off('on-agent-accept-call')

    this.$serverBus.$off('download-file')
    this.$serverBus.$off('delete-file')
    this.$serverBus.$off('clean-sent-files')
    this.$serverBus.$off('upload-files')
    this.$serverBus.$off('dropzone-upload-files')

    this.$serverBus.$off('show-connected-agent-info')
    if (this.unsubscribeVisitor) {
      this.unsubscribeVisitor()
    }
  },
  data() {
    return {
      chatInitiatedByAgent: {
        visitorId: null,
        initiatedBy: null,
        initiatedTime: null
      },
      isMobile: false,
      isMobileApp: false,
      isActiveChatMobile: false,
      SHOW_CLAIMS_MESSAGE: false,
      isStartedResettingCall: false,
      filterRequests: 'filter-all',
      search: '',
      defaultRequest: 'chat',
      selectedVisitors: false,
      clickNotClose: true,
      isChatSidebarActive: true,
      previousVisitorId: null,
      conversationCompleted: false,
      localStream: null,
      remoteStream: null,
      selectedAudio: null,
      selectedVideo: null,
      selectedSpeaker: null,
      volumeLevel: 0.3,
      cobrowseStatus: COBROWSE_STATUS.STOPPED,
      COBROWSE_STATUS,
      displayMediaStream: null,
      conversationStatus: CONVERSATION_STATUS.STOPPED,
      CONVERSATION_STATUS,
      callRecordingStatus: CALL_RECORDING_STATUS.STOPPED,
      CALL_RECORDING_STATUS,
      startCommunication: false,
      hasVisitorMadeOffer: false,
      screen: SCREEN.MINIMIZED,
      SCREEN,
      clearWebRtc: false,
      progressUpload: 0,
      uploadTask: null,
      chatFilesUploadTasks: [],
      unsubscribeVisitor: null,
      isReworkDataPopupOpened: false,
      reworkData: {
        reworkTimeCounter: null,
        comment: '',
        status: '',
        sessionId: '',
        dialogTrackingId: '',
        abTestId: '',
        dialogId: '',
        reworkTimeFormated: '',
        reworkTimeStartAt: '',
        activeChatVisitorId: ''
      },
      statusColors: [
        {
          type: 'positive',
          color: 'rgb(40, 199, 111)'
        },
        {
          type: 'neutral',
          color: 'rgb(245, 220, 0)'
        },
        {
          type: 'negative',
          color: 'rgb(234, 84, 85)'
        }
      ],
      ratingSessionId: null
    }
  },
  watch: {
    '$i18n.locale'() {
      setTimeout(() => {
        if (document.getElementById('upgradeAddonCC')) {
          document.getElementById('upgradeAddonCC').addEventListener('click', this.upgradeAddonCC)
        }
      }, 100)
    },
    /* Update Room Reference based on the active visitor */
    async activeChatVisitorId() {
      if (this.activeChatVisitorId) {
        if (this.unsubscribeVisitor) {
          this.unsubscribeVisitor()
        }
        this.onVisitorEntityChange()
        this.cobrowseStatus = this.COBROWSE_STATUS.STOPPED
      }
      await this.setUserState('online')
    },
    async activeChatVisitor() {
      if (this.activeChatVisitor && this.activeChatVisitor.connectedAgentId && this.activeChatVisitor.connectedAgentId === this.activeUserInfo.uid) {
        await this.setUserState('offline')
      }
    },
    cobrowseStatus() {
      if (this.cobrowseStatus === this.COBROWSE_STATUS.STARTED) {
        this.onCobrowseStarted()
      }
    },
    'activeChatVisitor.cobrowse'() {
      if (
        this.activeChatVisitor &&
        this.activeChatVisitor.cobrowse &&
        (this.activeChatVisitor.type === 'embed' || this.activeChatVisitor.type === 'campaign')
      ) {
        this.cobrowseStatus = this.COBROWSE_STATUS.COBROWSING
      } else {
        this.cobrowseStatus = this.COBROWSE_STATUS.STOPPED
      }
      this.clearToolTips()
    },
    'activeChatVisitor.agentRequest'() {
      if (
        this.activeChatVisitor &&
        !this.activeChatVisitor.agentRequest &&
        (this.activeChatVisitor.type === 'embed' || this.activeChatVisitor.type === 'campaign')
      ) {
        if (this.activeUserInfo.uid === this.activeChatVisitor.connectedAgentId) {
          this.onConversationStarted()
        }
      }
    },
    'activeChatVisitor.connectedAgentId'() {
      const chatCompletedStates = ['agent-chat-complete', 'visitor-chat-complete']
      if (!chatCompletedStates.includes(this.activeChatVisitor.callTypeState)) {
        return
      }
      if (this.activeChatVisitor && !this.activeChatVisitor.connectedAgentId && this.conversationStatus !== this.CONVERSATION_STATUS.STOPPED) {
        this.onCloseCommunicationControl(this.messageMode, this.activeChatVisitor.callTypeState)
      }
    },
    'activeChatVisitor.agentRequestType'() {
      if (
        this.activeChatVisitor &&
        this.activeChatVisitor.agentRequest &&
        (this.activeChatVisitor.type === 'embed' || this.activeChatVisitor.type === 'campaign')
      ) {
        this.setMessageMode(this.activeChatVisitor.agentRequestType)
        switch (this.activeChatVisitor.agentRequestType) {
          case 'chat':
            this.setMessageModes(['chat'])
            break
          case 'audio':
            this.setMessageModes(['audio', 'chat'])
            break
          case 'video':
            this.setMessageModes(['video', 'audio', 'chat'])
            break
        }
      }
    },
    messageModes() {
      if (this.audioVideoStarted) {
        if (this.messageModes && this.messageModes.length > 0) {
          const audio = !!this.messageModes.find((x) => x === 'audio')
          if (!audio) {
            this.selectedAudio = { text: this.$i18n.t('vue.microphoneOff'), value: 'microphone-off' }
          } else if (localStorage.getItem('pa-audio')) {
            const audio = JSON.parse(localStorage.getItem('pa-audio'))
            this.selectedAudio = audio
          }

          const video = !!this.messageModes.find((x) => x === 'video')
          if (!video) {
            this.selectedVideo = { text: this.$i18n.t('vue.cameraOff'), value: 'camera-off' }
          } else if (localStorage.getItem('pa-video')) {
            const video = JSON.parse(localStorage.getItem('pa-video'))
            this.selectedVideo = video
          }
        }
        if (this.messageModes.includes('video')) {
          this.$database.ref(`/chats/${this.activeChatVisitorId}`).update({ agentVideoOn: true })
          const visitor = this.visitorList.find((x) => x.id === this.activeChatVisitorId)
          if (
            visitor &&
            (typeof visitor.agentRequestTypeUpdated === 'undefined' ||
              visitor.agentRequestTypeUpdated === 'chat' ||
              visitor.agentRequestTypeUpdated === 'audio')
          ) {
            this.$db
              .collection('visitors')
              .doc(this.activeChatVisitorId)
              .set({ callTypeState: 'call-upgraded', agentRequestTypeUpdated: 'video' }, { merge: true })
          }
        } else {
          this.$database.ref(`/chats/${this.activeChatVisitorId}`).update({ agentVideoOn: false })
        }

        if (this.messageModes.includes('audio')) {
          this.$database.ref(`/chats/${this.activeChatVisitorId}`).update({ agentAudioOn: true })
          const visitor = this.visitorList.find((x) => x.id === this.activeChatVisitorId)
          if (
            visitor &&
            (typeof visitor.agentRequestTypeUpdated === 'undefined' || visitor.agentRequestTypeUpdated === 'chat') &&
            !this.messageModes.includes('video')
          ) {
            this.$db
              .collection('visitors')
              .doc(this.activeChatVisitorId)
              .set({ callTypeState: 'call-upgraded', agentRequestTypeUpdated: 'audio' }, { merge: true })
          }
        } else {
          this.$database.ref(`/chats/${this.activeChatVisitorId}`).update({ agentAudioOn: false })
        }
      }
    },

    visitors() {
      if (this.activeChatVisitorId) {
        const currentVisitor = this.visitors.find((x) => x.id === this.activeChatVisitorId)
        if (!currentVisitor) {
          this.setVisitorId(null)
        }
      }
    }
  },
  computed: {
    ...mapState({
      files: (state) => state.files,
      pickedFiles: (state) => state.pickedFiles
    }),
    ...mapGetters({
      users: 'users',
      visitors: 'incoming',
      campaigns: 'campaigns',
      dialogs: 'dialogs',
      dateRangeIncoming: 'dateRangeIncoming',
      activeUserInfo: 'activeUser',
      chatFilesListCurrent: 'chatFilesListCurrent',
      company: 'company',
      callCenterSettings: 'call_center_settings',
      screenSharingStatus: 'webrtc/screenSharingStatus',
      SCREEN_SHARING_STATUS: 'webrtc/SCREEN_SHARING_STATUS',
      messageModes: 'webrtc/messageModes',
      messageMode: 'webrtc/messageMode',
      audioVideoStarted: 'webrtc/audioVideoStarted',
      activeChatVisitorId: 'webrtc/visitorId',
      activeChatVisitor: 'webrtc/visitor',
      blurBackground: 'webrtc/blurBackground',
      visitorVideoOn: 'webrtc/visitorVideoOn',
      visitorAudioOn: 'webrtc/visitorAudioOn',
      loadingIds: 'chatFiles/loadingIds',
      showRatingScreen: 'showRatingScreen',
      apiKeys: 'apiKeys'
    }),
    isAudioDisabled() {
      return this.company && this.company.disableAudio
    },
    isVideoDisabled() {
      return this.company && this.company.disableVideo
    },
    HAS_VISITOR_STARTED_AUDIOVIDEO() {
      return this.visitorVideoOn || this.visitorAudioOn
    },
    HAS_VIDEO_AUDIO_CHAT_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return (
        (claims.includes('video-audio-chat') || claims.includes('video-chat')) &&
        this.company.expiryDate &&
        dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
      )
    },
    IS_COBROWSING() {
      return (
        this.cobrowseStatus === this.COBROWSE_STATUS.COBROWSING &&
        ((this.activeChatVisitor && this.activeChatVisitor.type === 'embed') || this.activeChatVisitor.type === 'campaign')
      )
    },
    IS_SCREEN_SHARING() {
      return this.screenSharingStatus && this.screenSharingStatus.status === this.SCREEN_SHARING_STATUS.SCREEN_SHARING
    },
    HAS_VISITOR_INITIATED_SCREENSHARING() {
      return (
        this.screenSharingStatus && this.screenSharingStatus.initiatedBy === 'visitor' && this.screenSharingStatus.status !== this.SCREEN_SHARING_STATUS.STARTED
      )
    },
    IS_VISITOR_SCREEN_SHARING() {
      return (
        this.screenSharingStatus &&
        this.screenSharingStatus.status === this.SCREEN_SHARING_STATUS.SCREEN_SHARING &&
        this.screenSharingStatus.initiatedBy === 'visitor'
      )
    },
    IS_SCREEN_MINIMIZED() {
      return this.screen === this.SCREEN.MINIMIZED
    },
    agentName() {
      return this.activeUserInfo.displayName || this.activeUserInfo.firstname || ''
    },
    visitorList() {
      let incomingRequests = this.visitors.filter((x) => {
        return x.surlfyURL || this.checkAgentRequest(x) || this.checkMissedRequest(x) || x.end_time || (x.chats && x.chats.length > 0)
      })

      if (this.filterRequests && this.filterRequests === 'filter-sessions') {
        incomingRequests = incomingRequests.filter((x) => {
          return x.surlfyURL || this.checkAgentRequest(x) || this.checkMissedRequest(x) || ((x.agentId || x.chats) && x.end_time)
        })
      }

      // incomingRequests = incomingRequests.map((_visitor) => {
      //   const widget = this.dialogs && this.dialogs.find((x) => x.id === _visitor.dialogId)
      //   if (widget) {
      //     _visitor.isVegaWidgetEnabled = widget.isVegaWidget && widget.isVegaWidgetEnabled
      //   }
      //   return _visitor
      // })

      const search = this.search.toLowerCase().trim()
      if (search.length > 0) {
        incomingRequests = incomingRequests.filter((x) => {
          const foundCity = search.length > 0 && x.city && x.city.toLowerCase().indexOf(search) > -1

          const foundCountry = search.length > 0 && x.country && x.country.toLowerCase().indexOf(search) > -1

          const foundISP = search.length > 0 && x.companyName && x.companyName.toLowerCase().indexOf(search) > -1

          const foundName = search.length > 0 && x.name && x.name.toLowerCase().indexOf(search) > -1

          const foundNetName = search.length > 0 && x.netname && x.netname.toLowerCase().indexOf(search) > -1

          const foundRegion = search.length > 0 && x.region && x.region.toLowerCase().indexOf(search) > -1

          const filteredDialogs =
            search.length > 0 &&
            this.dialogs
              .map((f) => {
                return { name: f.name.toLowerCase(), id: f.id }
              })
              .filter((x) => x.name.indexOf(search) > -1)
          const foundDialog = search.length > 0 && filteredDialogs.filter((d) => d.id === x.dialogId).length > 0

          const filteredCampaigns =
            search.length > 0 &&
            this.campaigns
              .map((f) => {
                return { name: f.name.toLowerCase(), id: f.id }
              })
              .filter((x) => x.name.indexOf(search) > -1)
          const foundCampaign = search.length > 0 && filteredCampaigns.filter((d) => d.id === x.campaignId).length > 0

          const foundChats = !x.chats || search.length === 0 ? false : x.chats.filter((x) => x.message.toLowerCase().indexOf(search) > -1).length > 0

          return foundCity || foundCountry || foundISP || foundName || foundNetName || foundRegion || foundDialog || foundCampaign || foundChats
        })
      }

      if (this.selectedVisitors) {
        incomingRequests.forEach((v) => (v.isSelected = false))
      }
      return _(incomingRequests)
        .chain()

        .sortBy(function (obj) {
          return obj.orderIncomingRequests
        })
        .value()
    },
    displayAudioVideoModal() {
      return (
        ((this.messageMode === 'audio' || this.messageMode === 'video' || this.HAS_VISITOR_STARTED_AUDIOVIDEO || this.audioVideoStarted) &&
          !this.IS_VISITOR_SCREEN_SHARING) ||
        (this.cobrowseStatus === this.COBROWSE_STATUS.COBROWSING && (this.activeChatVisitor.type === 'embed' || this.activeChatVisitor.type === 'campaign'))
      )
    },
    isNewFlowCampaign() {
      return this.activeChatVisitor && this.activeChatVisitor.type === 'campaign' && this.activeChatVisitor.isNewFlowCampaign
    }
  },
  methods: {
    ...mapActions({
      updateChatFilesUpload: 'updateChatFilesUpload'
    }),
    ...mapMutations({
      setPickedFiles: 'setPickedFiles',
      filterPickedFiles: 'filterPickedFiles',
      clearPickedFiles: 'clearPickedFiles',
      setChatFilesUpload: 'setChatFilesUpload',
      filterChatFilesUpload: 'filterChatFilesUpload',
      clearChatFilesUpload: 'clearChatFilesUpload',
      updateVisitorIsTyping: 'UPDATE_VISITOR_IS_TYPING',
      setLocalStream: 'webrtc/setLocalStream',
      setScreenSharingStatus: 'webrtc/setScreenSharingStatus',
      setVideoEnabled: 'webrtc/setVideoEnabled',
      setMicEnabled: 'webrtc/setMicEnabled',
      setMessageModes: 'webrtc/setMessageModes',
      setMessageMode: 'webrtc/setMessageMode',
      setAudioVideoCallStarted: 'webrtc/setAudioVideoCallStarted',
      setVisitorVideoOn: 'webrtc/setVisitorVideoOn',
      setVisitorAudioOn: 'webrtc/setVisitorAudioOn',
      setVisitorId: 'webrtc/setVisitorId',
      setVisitor: 'webrtc/setVisitor',
      setBlurBackground: 'webrtc/setBlurBackground',
      addLoadingId: 'chatFiles/addLoadingId',
      removeLoadingId: 'chatFiles/removeLoadingId',
      updateRatingScreen: 'UPDATE_RATING_SCREEN',
      setAudioOutputDevices: 'UDPATE_AUDIO_OUTPUT_DEVICES'
    }),

    async checkIsMobileApp() {
      try {
        const { platform } = await Device.getInfo()

        if (['ios', 'android'].includes(platform)) {
          this.isMobileApp = true
        }
      } catch (error) {
        console.log(error)
      }
    },

    async checkAudioVideoPermissionsOnMobile() {
      try {
        const { platform } = await Device.getInfo()

        if (!['ios', 'android'].includes(platform)) {
          return
        }

        const cameraPermissions = await CapacitorCamera.checkPermissions()
        const microphonePermissions = await CapacitorMicrophone.checkPermissions()

        if (cameraPermissions.camera !== 'granted' || microphonePermissions.microphone !== 'granted') {
          setTimeout(() => {
            this.$serverBus.$emit('first-setup-av-settings-on-mobile')
          }, 500)
        }
      } catch (error) {
        console.log(error)
      }
    },

    async onConversationRatingSaved(conversation) {
      const ratingSessionId = this.ratingSessionId
      this.updateRatingScreen(false)

      const _rating = {
        company: this.activeUserInfo.company,
        connectedAgentId: this.activeUserInfo.uid,
        sessionId: ratingSessionId,
        visitorId: conversation.visitor.visitorId,
        dialogId: conversation.visitor.dialogId,
        testId: conversation.visitor.abTestId,
        created: dayjs().utc().toDate(),
        rating: conversation.rating,
        vegaSessionId: conversation.visitor.vegaSessionId || this.activeChatVisitor.vegaSessionId || null,
        score: conversation.visitor.score || null,
        scoreHighest: conversation.visitor.scoreHighest || null,
        isVegaPopupDisplayed: conversation.visitor.isVegaPopupDisplayed || false,
        isVegaWidget: conversation.visitor.isVegaWidget || this.activeChatVisitor.isVegaWidget || false
      }
      const payload = {
        visitorId: conversation.visitor.visitorId,
        sessionId: ratingSessionId,
        companyId: conversation.visitor.company,
        rating: conversation.rating,
        vegaSessionId: conversation.visitor.vegaSessionId || this.activeChatVisitor.vegaSessionId || null,
        isVegaPopupDisplayed: conversation.visitor.isVegaPopupDisplayed
      }
      this.onConversationCompleted()
      try {
        await this.$db.collection('visitors-rating').add(_rating)

        /* Save Rating in vega */
        if (
          this.apiKeys &&
          this.apiKeys.vega &&
          ratingSessionId &&
          this.company.isVegaAccount &&
          conversation &&
          conversation.visitor &&
          conversation.visitor.isVegaWidget &&
          conversation.visitor.isVegaWidgetEnabled
        ) {
          setTimeout(async () => {
            /* Give time for the session to be added in vega before updating */
            await this.callHttpRequestVega('update-session', payload, this.apiKeys.vega, this.$vegaEndPoint)
          }, 800)
        }
      } catch (error) {
        console.log('could not update rating')
      }

      this.ratingSessionId = null
    },

    setScreenOverflow(isOverflow = true) {
      const $html = document.querySelector('html')
      const $body = document.querySelector('body')

      if ($html) {
        $html.style.overflowY = isOverflow ? 'hidden' : ''
      }

      if ($body) {
        $body.style.overflowY = isOverflow ? 'hidden' : ''
      }
    },

    stopAudioTracks() {
      if (this.localStream) {
        this.localStream.getAudioTracks().forEach(function (track) {
          track.stop()
        })
      }
    },
    stopVideoTracks() {
      if (this.localStream) {
        this.localStream.getVideoTracks().forEach(function (track) {
          track.stop()
        })
      }
    },

    stopAllTracks() {
      if (this.localStream) {
        if (typeof this.localStream.getTracks === 'undefined') {
          this.localStream.stop()
        } else {
          this.localStream.getAudioTracks().forEach(function (track) {
            track.stop()
          })

          this.localStream.getVideoTracks().forEach(function (track) {
            track.stop()
          })
        }
        this.localStream = null
        this.setLocalStream(null)
      }
    },

    onCloseAudioVideoSettings() {
      this.stopAudioTracks()
      this.stopVideoTracks()
      this.stopAllTracks()
    },

    getStatusColor(type) {
      const status = this.statusColors.find((status) => status.type === type)

      if (!status || !status.color) {
        return ''
      }

      return status.color
    },

    async setUserState(state) {
      if (this.activeUserInfo.company && this.activeUserInfo.uid) {
        const ref = this.$firebase.database().ref(`/users/${this.activeUserInfo.company}/${this.activeUserInfo.uid}`)

        const data = {
          state,
          displayName: this.activeUserInfo.displayName,
          last_changed: this.$firebase.database.ServerValue.TIMESTAMP
        }

        await ref.update(data)
      }
    },

    openReworkDataPopup() {
      this.reworkData.activeChatVisitorId = this.activeChatVisitorId
      this.isReworkDataPopupOpened = true
      this.reworkData.reworkTimeStartAt = new Date()

      this.reworkData.reworkTimeCounter = setInterval(() => {
        const now = new Date().getTime()
        const distance = now - this.reworkData.reworkTimeStartAt.getTime()

        const hours = Math.floor(distance / (1000 * 60 * 60))
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((distance % (1000 * 60)) / 1000)

        this.reworkData.reworkTimeFormated = `${hours > 9 ? hours : `0${hours}`}:${minutes > 9 ? minutes : `0${minutes}`}:${
          seconds > 9 ? seconds : `0${seconds}`
        }`
      }, 1000)
    },

    async sendReworkData() {
      if (!this.reworkData.status || !this.reworkData.comment) {
        return
      }

      await this.$vs.loading()

      const reworkData = {
        status: this.reworkData.status,
        comment: this.reworkData.comment,
        agentId: this.activeUserInfo.uid,
        supervisorId: this.activeUserInfo.supervisorId || null,
        callCenterId: this.activeUserInfo.company,
        sessionId: this.reworkData.sessionId,
        dialogTrackingId: this.reworkData.dialogTrackingId,
        dialogId: this.reworkData.dialogId,
        abTestId: this.reworkData.abTestId,
        reworkTimeStartAt: this.reworkData.reworkTimeStartAt,
        reworkTimeFinishAt: new Date()
      }

      const visitorRef = await this.$db.collection('visitors').doc(this.reworkData.activeChatVisitorId)

      await visitorRef.set({ reworkData }, { merge: true })

      const message = {
        reworkData,
        type: 'agent-private-message',
        message: '',
        time: new Date(),
        chatTime: dayjs().tz(this.$defaultTimezone).diff('2021-01-01'),
        agentName: this.agentName,
        agentId: this.activeUserInfo.uid,
        sender: 'agent'
      }

      await visitorRef.update({ chats: this.$firebase.firestore.FieldValue.arrayUnion(message) })

      await this.setUserState('online')

      this.isReworkDataPopupOpened = false

      clearInterval(this.reworkData.reworkTimeCounter)

      this.reworkData.reworkTimeCounter = null
      this.reworkData.activeChatVisitorId = ''
      this.reworkData.status = ''
      this.reworkData.comment = ''
      this.reworkData.sessionId = ''
      this.reworkData.dialogTrackingId = ''
      this.reworkData.reworkTimeFormated = ''

      await this.$vs.loading.close()
    },

    async onStartCommunicationWithVisitor(visitorId) {
      let visitor = this.visitors.find((x) => x.id === visitorId)
      if (!visitor) {
        const visitorRef = await this.$db.collection('visitors').doc(visitorId).get()
        visitor = visitorRef.data()
        visitor.id = visitorRef.id
        visitor.scoring = visitor.score ? visitor.score * 10 : 40
        // const widget = this.dialogs && this.dialogs.find((x) => x.id === visitor.dialogId)
        // if (widget) {
        //   visitor.isVegaWidgetEnabled = widget.isVegaWidget && widget.isVegaWidgetEnabled
        // }
      }
      this.setVisitor(visitor)

      if (
        this.activeChatVisitor &&
        this.activeChatVisitor.callTypeState !== 'missed-call-added' &&
        this.activeChatVisitor.callTypeState !== 'visitor-disconnected' &&
        this.activeChatVisitor.callTypeState !== 'visitor-chat-complete' &&
        this.activeChatVisitor.callTypeState !== 'displayed-contact-form'
      ) {
        const previousVisitorId = this.activeChatVisitorId
        const activeChatVisitorId = visitorId || previousVisitorId
        this.startCommunication = true
        if (previousVisitorId && previousVisitorId !== activeChatVisitorId) {
          this.$database.ref(`/chats/${previousVisitorId}`).off('value')
          this.$database.ref(`/chats/${previousVisitorId}`).update({ agentIsTyping: false })
        }

        await this.updateActiveChatUser(activeChatVisitorId, false, this.startCommunication)
      }
    },
    onGetWhenLastReadChatAgent() {
      /* Chats last read */
      let lastReadChatsAgentInfo = []
      if (this.activeChatVisitor) {
        lastReadChatsAgentInfo = this.activeChatVisitor.lastReadChatsAgentInfo ? [...this.activeChatVisitor.lastReadChatsAgentInfo] : []
        if (lastReadChatsAgentInfo.length === 0 || lastReadChatsAgentInfo.filter((x) => x.agentId === this.activeUserInfo.uid).length === 0) {
          lastReadChatsAgentInfo.push({
            agentId: this.activeUserInfo.uid,
            lastRead: dayjs().tz(this.$defaultTimezone).diff('2021-01-01')
          })
        } else {
          lastReadChatsAgentInfo = lastReadChatsAgentInfo.filter((x) => x.agentId !== this.activeUserInfo.uid)
          lastReadChatsAgentInfo.push({
            agentId: this.activeUserInfo.uid,
            lastRead: dayjs().tz(this.$defaultTimezone).diff('2021-01-01')
          })
        }
      }
      return lastReadChatsAgentInfo
    },
    async onAgentJoining(lastReadChatsAgentInfo) {
      await this.$vs.loading()
      if (this.activeChatVisitorId && this.activeUserInfo && this.activeUserInfo.uid) {
        if (this.activeChatVisitor && this.activeChatVisitor.agentRequestType === 'video') {
          this.setVisitorVideoOn(true)
        }
        if (this.activeChatVisitor && this.activeChatVisitor.agentRequestType === 'audio') {
          this.setVisitorAudioOn(true)
        }
        const agentInfo = {
          agentId: this.activeUserInfo.uid,
          connectedAgentId: this.activeUserInfo.uid,
          connectedAgent: this.agentName,
          connectedAgentImage: this.activeUserInfo.photoURL || null,
          connectedAgentDate: dayjs().utc().toDate(),
          connectedAgentChatDate: dayjs().utc().toDate(),
          connectedAgentCompany: this.activeUserInfo.company,
          lastConnectedAgentId: this.activeUserInfo.uid,
          lastConnectedAgent: this.agentName,
          lastConnectedAgentImage: this.activeUserInfo.photoURL || null,
          modified: dayjs().utc().toDate(),
          ttlDate: dayjs().utc().add(6, 'month').toDate(),
          callTypeState: 'agent-joined',
          start_time: dayjs().utc().toDate(),
          agentRequest: false,
          lastReadChatsAgentInfo,
          isCallStarted: true,
          end_time: null,
          visitorMessages: this.$firebase.firestore.FieldValue.delete(),
          declinedByAgents: this.$firebase.firestore.FieldValue.delete()
        }

        if (!lastReadChatsAgentInfo) {
          const _lastReadChatsAgentInfo = this.onGetWhenLastReadChatAgent()
          if (_lastReadChatsAgentInfo) {
            agentInfo.lastReadChatsAgentInfo = _lastReadChatsAgentInfo
          }
        }

        if (this.activeChatVisitor && this.activeUserInfo && this.activeChatVisitor.company === this.activeUserInfo.company) {
          agentInfo.previousCallCenterId = this.$firebase.firestore.FieldValue.delete()
          agentInfo.previousCallCenterName = this.$firebase.firestore.FieldValue.delete()
        }

        if (this.company && this.company.isCallCenter) {
          agentInfo.callCenterId = this.activeUserInfo.company
        }

        await this.$db.collection('visitors').doc(this.activeChatVisitorId).set(agentInfo, { merge: true })

        this.clearWebRtc = false

        if (this.activeChatVisitorId) {
          const activeChatVisitorId = this.activeChatVisitorId
          this.$database.ref(`/status/${activeChatVisitorId}`).on('value', async (snapshot) => {
            if (!snapshot.exists()) {
              return
            }

            const data = snapshot.val()

            if (data && data.state === 'offline' && !this.isStartedResettingCall) {
              this.onResetAVCallSettings(activeChatVisitorId)
            }
          })
        }
      }

      await this.$vs.loading.close()
    },
    watchVisitorOffer() {
      this.$database.ref(`/webrtc/${this.activeChatVisitorId}/visitor/offer`).on('value', async (snapshot) => {
        const data = snapshot.val()
        if (
          data &&
          data.type === 'offer' &&
          data.messageMode &&
          this.activeChatVisitor &&
          !this.activeChatVisitor.agentRequest &&
          this.activeChatVisitor.connectedAgentId
        ) {
          if (!this.audioVideoStarted) {
            this.hasVisitorMadeOffer = true
            const messageModes = ['chat']
            const session = {
              messageMode: data.messageMode,
              started: true,
              messageModes
            }
            this.onAudioVideoStarted(session)
          }
        }
      })
    },
    onReadyToCommunicateWithVisitor() {
      const lastReadChatsAgentInfo = this.onGetWhenLastReadChatAgent()
      this.onAgentJoining(lastReadChatsAgentInfo)
      if (this.activeChatVisitor && this.activeChatVisitor.agentRequestType !== 'chat') {
        this.onStartAudioVideo()
      }
      this.watchVisitorOffer()
    },
    onStartAudioVideo() {
      const messageModes = ['chat']
      switch (this.activeChatVisitor.agentRequestType) {
        case 'audio':
          messageModes.push('audio')
          break

        case 'video':
          messageModes.push('audio')
          messageModes.push('video')
          break
      }
      const session = {
        messageMode: this.activeChatVisitor.agentRequestType,
        started: true,
        messageModes
      }
      this.onAudioVideoStarted(session)
    },
    onStartAudioVideoByAgent(messageMode) {
      this.setMessageMode(messageMode)
      const messageModes = ['chat']
      switch (this.messageMode) {
        case 'audio':
          this.setMicEnabled(true)
          messageModes.push('audio')
          break

        case 'video':
          this.setMicEnabled(true)
          this.setVideoEnabled(true)
          messageModes.push('audio')
          messageModes.push('video')
          break
      }
      const session = {
        messageMode: this.messageMode,
        started: true,
        messageModes
      }
      this.onAudioVideoStarted(session)
    },

    onConversationStarted() {
      this.conversationStatus = this.CONVERSATION_STATUS.STARTED
    },
    onConversationStopped() {
      this.conversationStatus = this.CONVERSATION_STATUS.STOPPED
    },
    onCobrowseStarted() {
      const cobrowseWithVisitor = this.$functions.httpsCallable('cobrowseWithVisitor')
      const request = {
        url: this.isNewFlowCampaign ? this.activeChatVisitor.campaignLocation : this.activeChatVisitor.location,
        visitorId: this.activeChatVisitorId,
        company: this.activeChatVisitor.company,
        agentCompany: this.activeUserInfo.company,
        b2b: this.activeChatVisitor.b2b,
        agentId: this.activeUserInfo.uid,
        type: this.activeChatVisitor.type,
        campaignId: this.activeChatVisitor.campaignId,
        dialogId: this.activeChatVisitor.dialogId,
        abtest_id: this.activeChatVisitor.abTestId
      }
      if (this.activeUserInfo.firstname) {
        request.agent = `${this.activeUserInfo.firstname} ${this.activeUserInfo.lastname}`
      } else {
        request.agent = this.activeUserInfo.displayName
      }
      if (this.activeChatVisitor.dialogId) {
        request.dialogId = this.activeChatVisitor.dialogId
      }
      if (this.activeChatVisitor.abTestId) {
        request.abTestId = this.activeChatVisitor.abTestId
      }
      if (this.activeChatVisitor.locale) {
        request.locale = this.activeChatVisitor.locale
      }
      if (this.activeChatVisitor.client_sessions) {
        request.client_sessions = this.activeChatVisitor.client_sessions
      }
      cobrowseWithVisitor(request)
        .then((response) => {
          if (response && response.data && response.data.session_id) {
            // eslint-disable-next-line
            console.log(response.data.session_id)
          }
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.log(err)
        })
    },
    async onChangeScreeenSharingStatus() {
      if (this.activeChatVisitorId) {
        switch (this.screenSharingStatus.status) {
          case this.SCREEN_SHARING_STATUS.ENDED:
            await this.$database
              .ref(`/screen-share/${this.activeChatVisitorId}`)
              .set({ status: this.SCREEN_SHARING_STATUS.STARTED, initiatedBy: 'agent', updateKey: Math.random().toString(36).substring(2, 15) })
            break
          case this.SCREEN_SHARING_STATUS.STOPPED:
            await this.$database
              .ref(`/screen-share/${this.activeChatVisitorId}`)
              .set({ status: this.SCREEN_SHARING_STATUS.STARTED, initiatedBy: 'agent', updateKey: Math.random().toString(36).substring(2, 15) })
            break
          case this.SCREEN_SHARING_STATUS.STARTED:
            await this.$database
              .ref(`/screen-share/${this.activeChatVisitorId}`)
              .set({ status: this.SCREEN_SHARING_STATUS.SCREEN_SHARING, initiatedBy: 'agent', updateKey: Math.random().toString(36).substring(2, 15) })
            break
          case this.SCREEN_SHARING_STATUS.SCREEN_SHARING:
            await this.$database
              .ref(`/screen-share/${this.activeChatVisitorId}`)
              .set({ status: this.SCREEN_SHARING_STATUS.STOPPED, initiatedBy: 'agent', updateKey: Math.random().toString(36).substring(2, 15) })
            break
        }
      }
    },
    async onClickLeadForm() {
      const visitorRef = this.$db.collection('visitors').doc(this.activeChatVisitorId)

      const activeLeadFormId = Math.random().toString(36).substring(2, 15)

      const message = {
        message: '',
        time: new Date(),
        chatTime: dayjs().tz(this.$defaultTimezone).diff('2021-01-01'),
        agentName: this.agentName,
        agentId: this.activeUserInfo.uid,
        sender: 'visitor',
        id: activeLeadFormId,
        type: 'lead-form'
      }

      await visitorRef.update({
        chats: this.$firebase.firestore.FieldValue.arrayUnion(message)
      })

      this.$db.collection('visitors').doc(this.activeChatVisitorId).set({ activeLeadFormId: activeLeadFormId }, { merge: true })
    },
    onChangeCallRecordingStatus(status) {
      if (status) {
        this.callRecordingStatus = status
      } else {
        switch (this.callRecordingStatus) {
          case this.CALL_RECORDING_STATUS.STOPPED:
            this.callRecordingStatus = this.CALL_RECORDING_STATUS.STARTED
            break
          case this.CALL_RECORDING_STATUS.CALL_RECORDING:
            this.callRecordingStatus = this.CALL_RECORDING_STATUS.STOPPING
            break
        }
      }
    },
    onStopCobrowsing() {
      this.cobrowseStatus = this.COBROWSE_STATUS.STOPPED
      const cobrowse = {
        cobrowse: false,
        clientSessions: this.$firebase.firestore.FieldValue.delete(),
        surfly: this.$firebase.firestore.FieldValue.delete()
      }
      this.$db.collection('visitors').doc(this.activeChatVisitorId).set(cobrowse, { merge: true })
    },
    onChangeCobrowseStatus() {
      switch (this.cobrowseStatus) {
        case this.COBROWSE_STATUS.STOPPED:
          this.cobrowseStatus = this.COBROWSE_STATUS.STARTED
          break
        case this.COBROWSE_STATUS.STARTED:
          this.cobrowseStatus = this.COBROWSE_STATUS.COBROWSING
          break
        case this.COBROWSE_STATUS.COBROWSING:
          this.onStopCobrowsing()
          break
      }
    },
    checkAgentRequest(visitor) {
      let validated = false
      if (visitor && (visitor.agentRequest || visitor.callTypeState === 'agent-joined') && typeof visitor.agentsToBeNotified !== 'undefined') {
        if (visitor.agentsToBeNotified.length === 0 || visitor.agentsToBeNotified.includes(this.activeUserInfo.uid)) {
          validated = true
        }
      }
      return validated
    },
    checkMissedRequest(visitor) {
      let validated = false
      if (visitor && typeof visitor.agentRequest !== 'undefined' && !visitor.agentRequest && !visitor.agentId) {
        validated = true
      }
      return validated
    },
    updateFilterBySessions(filter) {
      this.filterRequests = filter
    },
    async updateActiveChatUser(visitorId, selectVisitors, startCommunication = false, type) {
      if (this.isMobile && !selectVisitors) {
        this.setScreenOverflow(true)

        this.isActiveChatMobile = true
      }

      document.getElementById('pa-overlay').style.display = 'block'
      const visitor = this.visitorList.find((x) => x.id === visitorId)

      if (visitor && visitor.connectedAgentId && visitor.connectedAgentId !== this.activeUserInfo.uid) {
        document.getElementById('pa-overlay').style.display = 'none'
        return
      }

      //await this.setUserState('offline')

      if (this.conversationStatus === this.CONVERSATION_STATUS.STARTED && this.audioVideoStarted) {
        document.getElementById('pa-overlay').style.display = 'none'
        return
      }
      if (this.previousVisitorId) {
        this.$database.ref(`/webrtc/${this.previousVisitorId}/visitor/offer`).off('value')
        this.$database.ref(`/chats/${this.previousVisitorId}`).off('value')
        this.$database.ref(`/chats/${this.previousVisitorId}`).update({ agentIsTyping: false })
      }
      this.startCommunication = startCommunication

      this.setAudioVideoCallStarted(false)
      this.setVisitorVideoOn(false)
      this.setVisitorAudioOn(false)

      if (selectVisitors) {
        this.setVisitorId(null)
        this.setVisitor(null)
      } else {
        this.setVisitorId(visitorId)
        this.setVisitor(visitor)
        // if (this.visitorList.find((x) => x.id === visitorId)) {
        //   const visitor = this.visitors.find((x) => x.id === visitorId)
        //   this.setVisitor(visitor)
        // }
        this.toggleChatSidebar()
        const messageMode = this.activeChatVisitor && this.activeChatVisitor.agentRequest ? this.activeChatVisitor.agentRequestType : this.defaultRequest
        this.setMessageMode(messageMode)
        this.previousVisitorId = this.activeChatVisitorId

        try {
          if (type === 'update-active-chat-visitor') {
            const lastReadChatsAgentInfo = this.onGetWhenLastReadChatAgent()
            this.$db
              .collection('visitors')
              .doc(visitorId)
              .set({ lastReadChatsAgentInfo, visitorMessages: this.$firebase.firestore.FieldValue.delete() }, { merge: true })
          }
        } catch (error) {
          /* eslint-disable no-console */
          console.log(error)
          document.getElementById('pa-overlay').style.display = 'none'
        }
      }

      if (this.startCommunication) {
        this.onReadyToCommunicateWithVisitor()

        if (this.company && this.company.isCallCenter && this.activeChatVisitorId) {
          let data = this.activeChatVisitor
          if (this.activeChatVisitor && this.activeChatVisitor.id !== this.activeChatVisitorId) {
            const visitorDoc = await this.$db.collection('visitors').doc(this.activeChatVisitorId).get()
            data = visitorDoc.data()
            data.id = visitorDoc.id
            // const widget = this.dialogs && this.dialogs.find((x) => x.id === data.dialogId)
            // if (widget) {
            //   data.isVegaWidgetEnabled = widget.isVegaWidget && widget.isVegaWidgetEnabled
            // }
            this.setVisitor(data)
          }

          this.reworkData.sessionId = data.sessionId
          this.reworkData.dialogTrackingId = data.dialogTrackingId
          this.reworkData.abTestId = data.abTestId
          this.reworkData.dialogId = data.dialogId
        }
      }

      this.watchVisitorOffer()

      document.getElementById('pa-overlay').style.display = 'none'
    },

    upgradeAddonCC() {
      /* Feature to be implemented */
    },
    closeProfileSidebar(value) {
      this.activeProfileSidebar = value
    },
    getUserStatus(isActiveUser) {
      // return "active"
      return isActiveUser ? this.activeUserInfo.status : false
    },

    toggleChatSidebar(value = false) {
      if (!value && this.clickNotClose) return
      this.isChatSidebarActive = value
    },

    showProfileSidebar(userId, openOnLeft = false) {
      this.userProfileId = userId
      this.isLoggedInUserProfileView = openOnLeft
      this.activeProfileSidebar = !this.activeProfileSidebar
    },
    onSelectVisitors(isSelectVisitors) {
      this.selectedVisitors = isSelectVisitors
    },
    onVisitorDeleted() {
      this.setVisitorId(null)
      this.setVisitor(null)
    },
    onSearchVisitor(searchText) {
      this.search = searchText
    },
    onChangeMessageModeAudio(isMuted) {
      this.setMessageMode('audio')
      if (isMuted) {
        this.setMessageModes([...this.messageModes.filter((x) => x !== 'audio')])
      } else {
        const found = this.messageModes.find((x) => x === 'audio')
        if (!found) {
          const messageModes = [...new Set([...this.messageModes])]
          messageModes.push('audio')
          this.setMessageModes([...messageModes])
        }
      }
    },
    onChangeMessageModeVideo(isMuted) {
      this.setMessageMode('video')
      if (isMuted) {
        this.setMessageModes([...this.messageModes.filter((x) => x !== 'video')])
      } else {
        const found = this.messageModes.find((x) => x === 'video')
        if (!found) {
          const messageModes = [...new Set([...this.messageModes])]
          messageModes.push('video')
          this.setMessageModes([...messageModes])
        }
      }
    },
    onMuteAudioVideo() {
      if (this.localStream) {
        const videoTracks = this.localStream.getVideoTracks()
        //console.log('Toggling video mute state.')
        for (let i = 0; i < videoTracks.length; ++i) {
          videoTracks[i].enabled = !videoTracks[i].enabled
        }

        const audioTracks = this.localStream.getAudioTracks()
        //console.log('Toggling audio mute state.')
        for (let i = 0; i < audioTracks.length; ++i) {
          audioTracks[i].enabled = !audioTracks[i].enabled
        }
      }
    },
    onChangeCommunicationControl(mode, _messageModes, avstarted) {
      const modes = ['audio', 'video']
      if (typeof _messageModes === 'undefined') {
        this.setMessageMode(mode)
        this.setMessageModes([mode])
        return
      }

      this.setVideoEnabled(_messageModes.includes('video'))
      this.setMicEnabled(_messageModes.includes('audio'))

      if (!modes.includes(mode)) {
        this.setMessageModes(_messageModes || [mode])
        return
      }

      if (this.messageModes.length === 1 && this.messageModes.includes('chat')) {
        const messageModes = [...new Set([...this.messageModes])]
        messageModes.push(mode)
        this.setMessageModes([...messageModes])
      }

      this.setMessageMode(mode)

      if (typeof _messageModes !== 'undefined') {
        this.setMessageModes([...new Set([..._messageModes])])
      }
      if (typeof avstarted !== 'undefined') {
        this.setAudioVideoCallStarted(avstarted)
      }
    },

    onRemoveStreams() {
      this.onRemoveLocalStream()
      this.onRemoveRemoteStream()
      this.onRemoveWindowStreams()
    },
    onRemoveWindowStreams() {
      if (window.stream) {
        window.stream.getTracks().forEach((track) => {
          track.stop()
        })
      }
    },
    onRemoveLocalStream() {
      if (this.localStream) {
        if (typeof this.localStream.getTracks === 'undefined') {
          this.localStream.stop()
        } else {
          this.localStream.getTracks().forEach(function (track) {
            track.stop()
          })
        }
        this.localStream = null
      }
    },
    onRemoveRemoteStream() {
      if (this.remoteStream) {
        if (typeof this.remoteStream.getTracks === 'undefined') {
          this.remoteStream.stop()
        } else {
          this.remoteStream.getTracks().forEach(function (track) {
            track.stop()
          })
        }
        this.remoteStream = null
      }
    },
    onResetAVCallSettings(activeChatVisitorId) {
      const agentInfo = {
        cobrowse: false,
        surfly: false,
        agentRequestTypeUpdated: 'chat',
        callTypeState: 'agent-joined'
      }

      this.$db.collection('visitors').doc(activeChatVisitorId).set(agentInfo, { merge: true })

      this.$database.ref(`/chats/${activeChatVisitorId}`).set({
        agentIsTyping: false
      })

      this.$database.ref(`/av-call/${activeChatVisitorId}`).set({
        callDisconnected: 'visitor',
        callTypeState: 'visitor-disconnected'
      })

      this.onRemoveStreams()

      this.$nextTick(() => {
        this.setBlurBackground(false)
        this.screen = SCREEN.MINIMIZED
        this.setMessageMode(this.defaultRequest)
        this.setAudioVideoCallStarted(false)
        this.setVisitorVideoOn(false)
        this.setVisitorAudioOn(false)
        this.setMessageModes([])
        this.volumeLevel = 0.3
        this.selectedAudio = null
        this.selectedVideo = null
        this.selectedSpeaker = null
        this.displayMediaStream = null
        this.setScreenSharingStatus({ status: this.SCREEN_SHARING_STATUS.STOPPED, initiatedBy: 'agent' })
        this.cobrowseStatus = COBROWSE_STATUS.STOPPED
        this.hasVisitorMadeOffer = false
        this.clearWebRtc = true

        setTimeout(() => {
          this.isStartedResettingCall = false
          this.clearWebRtc = false
        }, 400)
      })
    },
    async onSetDefaultValues() {
      if (this.unsubscribeVisitor) {
        this.unsubscribeVisitor()
      }
      this.selectedVisitors = false
      this.clickNotClose = true
      this.isChatSidebarActive = true
      this.setVisitorId(null)
      this.setVisitor(null)
      this.setMessageMode(this.defaultRequest)
      this.setAudioVideoCallStarted(false)
      this.setVisitorVideoOn(false)
      this.setVisitorAudioOn(false)
      this.setMessageModes([])
      this.localStream = null
      this.setLocalStream(null)
      this.remoteStream = null
      this.volumeLevel = 0.3
      this.displayMediaStream = null
      this.setScreenSharingStatus({ status: this.SCREEN_SHARING_STATUS.STOPPED, initiatedBy: 'agent' })
      this.conversationStatus = CONVERSATION_STATUS.STOPPED
      this.callRecordingStatus = CALL_RECORDING_STATUS.STOPPED
      this.cobrowseStatus = COBROWSE_STATUS.STOPPED
      this.startCommunication = false
      this.hasVisitorMadeOffer = false
      this.screen = SCREEN.MINIMIZED
      this.updateVisitorIsTyping(false)
      this.clearWebRtc = true
      this.$serverBus.$emit('close-linked-visitor-info')
      await this.setUserState('online')
    },
    async onCloseCommunicationControl(mode, callTypeState) {
      if (this.activeChatVisitorId) {
        if (
          this.activeChatVisitor.callTypeState !== 'visitor-chat-complete' &&
          this.activeChatVisitor.callTypeState !== 'visitor-disconnected' &&
          this.activeChatVisitor.callTypeState !== 'incoming'
        ) {
          const agentInfo = {
            agentRequest: false,
            audioPlayed: false,
            end_time: dayjs().utc().toDate(),
            modified: dayjs().utc().toDate(),
            ttlDate: dayjs().utc().add(6, 'month').toDate(),
            connectedAgentId: this.$firebase.firestore.FieldValue.delete(),
            callCenterId: this.$firebase.firestore.FieldValue.delete(),
            callCenterName: this.$firebase.firestore.FieldValue.delete(),
            connectedAgent: this.$firebase.firestore.FieldValue.delete(),
            connectedAgentImage: this.$firebase.firestore.FieldValue.delete(),
            connectedAgentCompany: this.$firebase.firestore.FieldValue.delete(),
            connectedAgentDate: this.$firebase.firestore.FieldValue.delete(),
            connectedAgentChatDate: this.$firebase.firestore.FieldValue.delete(),
            //agentsToBeNotified: this.$firebase.firestore.FieldValue.delete(), DELETE AGENTS TO BE NOTIFIED
            clientSessions: this.$firebase.firestore.FieldValue.delete(),
            declinedByAgents: this.$firebase.firestore.FieldValue.delete(),
            callTypeState: 'agent-chat-complete',
            cobrowse: this.$firebase.firestore.FieldValue.delete(),
            surfly: this.$firebase.firestore.FieldValue.delete(),
            agentRequestTypeUpdated: this.$firebase.firestore.FieldValue.delete(),
            sessionId: null,
            isCallStarted: this.$firebase.firestore.FieldValue.delete(),
            visitorMessages: this.$firebase.firestore.FieldValue.delete(),
            agentMessages: this.$firebase.firestore.FieldValue.delete(),
            initiatedBy: this.$firebase.firestore.FieldValue.delete()
          }
          if (callTypeState) {
            agentInfo.callTypeState = callTypeState
          }
          await this.$db.collection('visitors').doc(this.activeChatVisitorId).set(agentInfo, { merge: true })
        }
        this.setScreenSharingStatus({ status: this.SCREEN_SHARING_STATUS.STOPPED, initiatedBy: 'agent' })
        this.screen = SCREEN.MINIMIZED
        this.setMessageMode(this.defaultRequest)
        this.setMessageModes([])
        this.setAudioVideoCallStarted(false)
        this.setVisitorVideoOn(false)
        this.setVisitorAudioOn(false)
        this.onRemoveStreams()

        this.conversationStatus = this.CONVERSATION_STATUS.STOPPED
        this.startCommunication = false
        this.$database.ref(`/webrtc/${this.activeChatVisitorId}/agent`).off('value')
        this.$database.ref(`/webrtc/${this.activeChatVisitorId}/visitor`).off('value')
        this.$database.ref(`/webrtc/${this.activeChatVisitorId}/visitor/offer`).off('value')
        this.$database.ref(`/av-call/${this.activeChatVisitorId}`).off('value')
        this.$database.ref(`/screen-share/${this.activeChatVisitorId}`).off('value')
        this.$database.ref(`/chats/${this.activeChatVisitorId}`).off('value')
        this.$database.ref(`/webrtc/${this.activeChatVisitorId}/visitor`).set(null)
        this.$database.ref(`/webrtc/${this.activeChatVisitorId}/agent`).set(null)
        this.$database.ref(`/av-call/${this.activeChatVisitorId}`).set(null)
        this.$database.ref(`/screen-share/${this.activeChatVisitorId}`).set(null)
        this.$database.ref(`/chats/${this.activeChatVisitorId}`).set(null)
        this.startCommunication = false

        /* To clear tooltips if they are not already cleared */
        this.clearToolTips()

        if (this.isMobile) {
          this.isActiveChatMobile = false
        }

        setTimeout(() => {
          if (this.company && this.company.isCallCenter && this.activeChatVisitor && this.activeChatVisitor.type !== 'campaign') {
            this.openReworkDataPopup()
            this.onConversationCompleted()
          } else if (this.shouldDisplayRatingScreen()) {
            this.updateRatingScreen(true)
          } else {
            this.onConversationCompleted()
          }
        }, 500)
      }
    },
    shouldDisplayRatingScreen() {
      if (!this.activeChatVisitor || !this.activeChatVisitor.dialogId || this.activeChatVisitor.type !== 'embed') {
        return false
      }

      const dialog = this.dialogs.find((x) => x.id === this.activeChatVisitor.dialogId)
      if (!dialog) {
        return false
      }

      if (typeof dialog.widgetUse !== 'undefined' && dialog.widgetUse === 'support') {
        return false
      }

      if (typeof dialog.widgetUse === 'undefined' || dialog.widgetUse === 'sales') {
        /* Display Rating Screen for Calls iniated By agents only if visitor has typed message */
        if (this.chatInitiatedByAgent && this.chatInitiatedByAgent.visitorId === this.activeChatVisitor.visitorId) {
          const messages = this.activeChatVisitor.chats
          let _shouldDisplayRatingScreen = messages && messages.some((x) => x.chatTime > this.chatInitiatedByAgent.initiatedTime)

          this.chatInitiatedByAgent = { visitorId: null, initiatedBy: null, initiatedTime: null }
          return _shouldDisplayRatingScreen || false
        }
        return true
      }

      return false
    },
    clearToolTips() {
      const tooltips = document.getElementsByClassName('vs-tooltip') //divsToHide is an array
      for (let i = 0; i < tooltips.length; i++) {
        tooltips[i].remove()
      }
    },
    onAudioVideoStarted(session) {
      this.setMessageMode(session.messageMode)
      this.setMessageModes([...session.messageModes])
      if (session.messageModes && session.messageModes.includes('audio')) {
        this.setMicEnabled(true)
      }
      if (session.messageModes && session.messageModes.includes('video')) {
        this.setVideoEnabled(true)
      }

      this.setAudioVideoCallStarted(session.started)
    },
    onConversationCompleted() {
      this.onSetDefaultValues()
    },
    onWebrtcStreamUpdated(webrtc) {
      this.localStream = webrtc && webrtc.localStream ? webrtc.localStream : null
      this.remoteStream = webrtc && webrtc.remoteStream ? webrtc.remoteStream : null
    },

    dowloadFileFromBlob(refUrl, metadata) {
      fetch(refUrl, { method: 'GET' })
        .then((response) => response.blob())
        .then((response) => {
          const [name, ext] = metadata.name.split(/\.(?=[^.]+$)/)
          const blob = new Blob([response], { type: metadata.contentType })
          const link = document.createElement('a')

          link.href = URL.createObjectURL(blob)
          link.download = `${name}.${ext}`
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch((err) => {
          this.$vs.notify({
            time: 8800,
            title: this.$i18n.t('vue.error'),
            text: err.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    },

    onVisitorEntityChange() {
      const visitorEntity = this.$db.collection('visitors').doc(this.activeChatVisitorId)

      this.unsubscribeVisitor = visitorEntity.onSnapshot((doc) => {
        const visitor = doc.data()
        visitor.visitorId = doc.id
        visitor.id = doc.id

        /* Display Rating Screen for Calls iniated By agents only if visitor has typed message */
        if (visitor.initiatedBy && visitor.initiatedBy === 'agent' && this.chatInitiatedByAgent.visitorId !== this.activeChatVisitorId) {
          this.chatInitiatedByAgent = {
            visitorId: visitor.visitorId,
            initiatedBy: visitor.initiatedBy,
            initiatedTime: dayjs().tz(this.$defaultTimezone).diff('2021-01-01')
          }
        }

        // const widget = this.dialogs && this.dialogs.find((x) => x.id === visitor.dialogId)
        // if (widget) {
        //   visitor.isVegaWidgetEnabled = widget.isVegaWidget && widget.isVegaWidgetEnabled
        // }
        this.setVisitor(visitor)
        if (visitor.connectedAgentId && visitor.sessionId) {
          this.ratingSessionId = visitor.sessionId
        }

        if (visitor && visitor.isCallStarted && !visitor.online && visitor.disconnect && visitor.disconnect.seconds) {
          const visitorId = doc.id
          const disconnectDate = dayjs.unix(visitor.disconnect.seconds)

          setTimeout(() => {
            if (
              visitor &&
              this.activeChatVisitor &&
              this.activeChatVisitor.disconnect &&
              this.activeChatVisitor.connectedAgentId &&
              !this.activeChatVisitor.agentRequest &&
              !['audio', 'video'].includes(this.activeChatVisitor.agentRequestTypeUpdated)
            ) {
              const activeVisiorDisconnectDate = dayjs.unix(this.activeChatVisitor.disconnect.seconds)
              const isSameDisconnectDate = disconnectDate.isSame(activeVisiorDisconnectDate)
              const isExpiredDisconnectDate = dayjs().diff(activeVisiorDisconnectDate, 'seconds') > 300

              if (
                this.activeChatVisitorId === visitorId &&
                this.activeChatVisitor.isCallStarted &&
                !this.activeChatVisitor.online &&
                isSameDisconnectDate &&
                isExpiredDisconnectDate
              ) {
                this.onCloseCommunicationControl()
              }
            }
          }, 1000 * 60)
        }
      })
    },

    async downloadFile(url, id) {
      this.addLoadingId(id)

      const httpsReference = await this.$firebase.storage().refFromURL(url)
      const metadata = await httpsReference.getMetadata()

      httpsReference
        .getDownloadURL()
        .then((refUrl) => {
          this.dowloadFileFromBlob(refUrl, metadata)
          this.removeLoadingId(id)
        })
        .catch((err) => {
          this.$vs.notify({
            time: 8800,
            title: this.$i18n.t('vue.error'),
            text: err.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
          this.removeLoadingId(id)
        })
    },

    async onDeleteFile(url, index) {
      const fileRef = this.$firebase.storage().refFromURL(url)

      try {
        await fileRef.delete()
        this.filterPickedFiles(index)
        this.filterChatFilesUpload(url)

        this.$vs.notify({
          time: 3000,
          title: this.$i18n.t('vue.success'),
          text: this.$i18n.t('uploads.deleteFile'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        })
      } catch (err) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
    },

    mapFilesToUpload(files, ref) {
      this.chatFilesUploadTasks = []

      this.$serverBus.$emit('chat-files-upload-complete', false)
      this.setPickedFiles(files)

      this.pickedFiles.forEach((_, index) => {
        this.uploadFile(this.pickedFiles[index], index, ref)
      })

      this.completeChatFilesUploadTasks()
    },

    completeChatFilesUploadTasks() {
      Promise.all(this.chatFilesUploadTasks)
        .then(() => {
          this.chatFilesUploadTasks = []
          this.$serverBus.$emit('chat-files-upload-complete', true)
          this.$vs.notify({
            time: 3000,
            title: this.$i18n.t('vue.success'),
            text: this.$i18n.t('uploads.uploadFile'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          })
        })
        .catch((err) => {
          this.$vs.notify({
            time: 8800,
            title: this.$i18n.t('vue.error'),
            text: err.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    },

    onUploadFiles(files) {
      this.mapFilesToUpload(files)
    },

    onDropzoneUploadFiles(files, ref) {
      this.mapFilesToUpload(files, ref)
    },
    uploadFile(file, index, ref) {
      if (this.activeChatVisitorId) {
        const storageRef = this.$firebase.storage().ref(`uploads/chat-files/${this.activeChatVisitorId}/${file.name}`)

        this.updateChatFilesUpload({
          index,
          file: {
            percentage: 0,
            name: file.name,
            url: ''
          }
        })

        this.uploadTask = storageRef.put(file)
        this.chatFilesUploadTasks.push(this.uploadTask)

        if (ref) {
          ref.removeFile(file)
        }

        this.uploadTask.on(
          'state_changed',
          (snapshot) => {
            this.progressUpload = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            this.updateChatFilesUpload({
              index,
              file: {
                percentage: this.progressUpload,
                name: snapshot.ref.name
              }
            })
          },
          (err) => {
            this.$vs.notify({
              time: 8800,
              title: this.$i18n.t('vue.error'),
              text: err.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        )
        this.uploadTask
          .then(({ ref }) => {
            return ref.getDownloadURL()
          })
          .then((url) => {
            const currentFile = this.chatFilesListCurrent(index)

            this.updateChatFilesUpload({
              index,
              file: {
                ...currentFile,
                url
              }
            })
          })
      }
    }
  }
}
</script>

<style lang="scss">
.chat-mobile-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
  z-index: 99999;

  .av-call-video {
    width: 100% !important;
  }

  .video-audio-chat-audio-minimized {
    width: 100%;
  }

  .video-audio-chat-video-visitor-video-minimized {
    width: 100%;
  }

  .video-audio-chat-video-agent-video-minimized {
    bottom: 130px;
    z-index: 99999;

    &--moved {
      bottom: 425px;
    }
  }

  .communication-controls {
    position: static;
    display: flex;
    width: 100%;
    padding: 0 20px;

    .video-screen-minimized-navbar {
      &-top-right {
        &-button {
          margin-left: 10px;
          width: 35px;
          height: 35px;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          &:hover {
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
          }

          .pa-icon-default {
            color: rgba(var(--vs-secondary), 1) !important;
            path {
              stroke: rgba(var(--vs-secondary), 1) !important;
            }
          }
        }
      }
    }

    .communication-controls-bar {
      flex: 1;

      &-button {
        width: calc(100% / 3);
      }
    }
  }

  .pa-chat-log {
    margin-top: 16px !important;
  }
}

.custom-popup-rework-data {
  background: rgba(0, 0, 0, 0.4);

  .vs-popup--background {
    display: none;
  }
}

.scrollbar-track-y {
  width: 4px !important;
}
.scrollbar-thumb-y {
  background: #275d73 !important;
  width: 4px !important;
}
.vs-tooltip {
  background: rgba(var(--vs-secondary), 1) !important;
  z-index: 100000;
}
.agent-inr {
  .requests-container {
    position: relative;
    .upgrade-message-incoming-requests {
      align-items: center;
      justify-content: center;
    }
  }
  .incoming-requests {
    position: static;
    &._with-cobrowsing {
      position: fixed;
    }
    &-chat-interface {
      background: #ffffff;
      border-radius: 6px;
      font-family: 'Lato';
      @media only screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
      }
      @media only screen and (min-width: 601px) {
        display: flex;
        flex-direction: row;
      }
    }
    .sidebar-spacer--wide {
      width: 100%;
      margin-left: 0px;
    }
    .chat-scroll {
      min-height: calc(100vh - 280px);
    }
    &-chat-interface {
      font-family: 'Lato';

      @media only screen and (max-width: 1024px) {
        margin-left: -20px;
        margin-right: -20px;
      }
    }
  }
}

.custom-vs-textarea {
  .vs-textarea {
    min-height: 80px;
  }
}
</style>

<style lang="scss" scoped>
.option__color {
  margin-left: 8px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid rgb(251, 255, 235);
}
</style>
