<template>
  <div class="visitor-list">
    <div>
      <scroll-view class="visitor-list-scroll-area--search">
        <ul class="visitor-list__ul">
          <li
            class="cursor-pointer"
            v-for="(_visitor, index) in visitors"
            :id="`visitor-search-${_visitor.id}`"
            :key="index"
            @click="$emit('update-active-chat-visitor', _visitor.id, selectVisitors, false, 'select-active-chat-visitor')"
          >
            <visitor :visitor="_visitor"></visitor>
          </li>
        </ul>
        <div class="visitor-list--load-more w-full">
          <vs-button v-if="visitors.length > 0" :disabled="isLoadMoreDisabled" class="w-full" @click="$emit('load-more')"> {{ $t('vue.loadMore') }} </vs-button>
        </div>
      </scroll-view>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ScrollView from '@blackbp/vue-smooth-scrollbar'
import Visitor from './Visitor.vue'

export default {
  components: {
    ScrollView,
    Visitor
  },
  data() {
    return {
      selectVisitors: false,
      showConfirmDelete: false
    }
  },
  props: {
    visitors: {
      type: Array,
      required: true
    },
    isLoadMoreDisabled: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      visitorId: 'webrtc/visitorId'
    })
  },
  methods: {
    onSelectVisitors() {
      this.selectVisitors = !this.selectVisitors
      if (this.selectVisitors) {
        this.$emit('update-active-chat-visitor', null, this.selectVisitors)
      }
      this.$emit('select-visitors', this.selectVisitors)
    },
    onDeleteVisitors() {
      this.showConfirmDelete = true
    },
    onDeleteVisitorsConfirmed() {
      this.showConfirmDelete = false
      const visitorsToBeDeleted = this.visitors.filter((x) => x.isSelected).map((x) => x.id)
      if (visitorsToBeDeleted.length > 0) {
        this.$vs.loading()
        visitorsToBeDeleted.forEach(async (x) => await this.deleteVisitor(x))
      }
      this.onSelectVisitors()
      setTimeout(() => {
        this.$vs.loading.close()
      }, 500)
    },
    async deleteVisitor(id) {
      await this.$db.collection('visitors').doc(id).delete()
    },
    onCancelDelete() {
      this.selectVisitors = false
      this.showConfirmDelete = false
      this.$emit('select-visitors', this.selectVisitors)
    }
  }
}
</script>
<style lang="scss">
.visitor-list-scroll-area {
  .scrollbar-track {
    &.scrollbar-track-y {
      right: 4px;
      .scrollbar-thumb-y {
        background: #626262 !important;
      }
    }
  }
}
.visitor-list {
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
  @media only screen and (min-width: 601px) {
    width: 100%;
    max-width: 360px;
  }
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }

  .align-items-center {
    align-items: center;
  }
  .bd-danger {
    border-color: rgba(240, 85, 65, 0.8) !important;
    border-style: solid;
    border-width: 1.5px;
  }
  .delete-button {
    background: #e6ecf1;
  }
  .delete-button:hover {
    background: rgba(240, 85, 65, 1);
    color: #fff !important;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.3);
  }

  .cancel-button {
    background: #eaeaea;
  }
  .cancel-button:hover {
    background: #fff;
    color: #000 !important;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.3);
  }

  &--load-more {
    padding: 10px;
  }

  &__incoming-title {
    margin-left: 27px;

    @media only screen and (max-width: 1024px) {
      margin-top: 20px !important;
      margin-bottom: 20px !important;
    }
  }

  &__ul {
    margin-right: 13px;
    border-top-right-radius: 13px;
    overflow: hidden;
    border-right: 0.5px solid #e8f6ff;

    @media only screen and (max-width: 1024px) {
      margin-right: 0;
      border-radius: 0px;
      border-right: none;
    }
  }

  &-heading {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: #262629;
  }

  &-delete-all {
    border-color: rgba(240, 85, 65, 0.8) !important;
    border-top: none !important;
    border-left: none;
    border-right: none;
    border-style: solid;
    border-width: 1.5px;
    &-button {
      cursor: pointer;
      padding: 10px;
      width: 100px;
      text-align: center;
      color: rgba(var(--vs-secondary), 1) !important;
      font-weight: 600;
      border-radius: 10px;
    }
  }

  &-button {
    width: 35px;
    height: 35px;
    background: rgba(247, 247, 247, 0.5);
    border-radius: 6px;
    display: flex;
    cursor: pointer;

    justify-content: center;
    align-items: center;
    transition: 0.5s;
  }
  &-button:hover {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.5);
  }

  &-scroll-area--search {
    height: calc(90vh);
    @media only screen and (max-width: 600px) {
      height: calc(100vh - 400px);
    }

    margin-bottom: 10px;
  }

  h3 {
    font-size: 20px;
    line-height: 26px;
    font-weight: bold;
  }
}
</style>
