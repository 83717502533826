var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "agent-header" }, [
    _c(
      "div",
      {
        staticClass: "flex flex-row items-center ht-100p",
        class: { "ml-4": !_vm.isMobile, "ml-6 pl-1": _vm.isMobile },
      },
      [
        _c("Avatar", {
          staticClass: "agent-header-avatar",
          class: {
            "agent-header-avatar__with-border":
              _vm.activeUserInfo && _vm.activeUserInfo.photoURL === "/user.png",
          },
          attrs: {
            size: "45px",
            fontSize: "18px",
            photoURL: _vm.activeUserInfo.photoURL,
            name: _vm.agent,
          },
        }),
        _vm.showSettings
          ? _c("audio-video-settings", {
              attrs: {
                show: _vm.showSettings,
                "selected-audio": _vm.selectedAudio,
                "selected-video": _vm.selectedVideo,
                "selected-speaker": _vm.selectedSpeaker,
                "volume-level": _vm.volumeLevel,
                isMobile: _vm.isMobile,
                isFirstSetupOnMobile: _vm.isFirstSetupOnMobile,
              },
              on: {
                close: function ($event) {
                  return _vm.onCloseSettings()
                },
              },
            })
          : _vm._e(),
        _c("div", { staticClass: "text-white" }, [
          _c("small", { staticClass: "agent-header-company capitalize" }, [
            _vm._v(_vm._s(_vm.company)),
          ]),
          _c("h6", { staticClass: "agent-header-agent capitalize" }, [
            _vm._v(_vm._s(_vm.agent)),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass:
              "chat-header-navbar-button ml-auto agent-header-settings-button",
            on: {
              click: function ($event) {
                _vm.showSettings = true
              },
            },
          },
          [
            _c(
              "vx-tooltip",
              {
                staticClass: "inline-flex",
                attrs: { text: _vm.$t("vue.settings"), position: "top" },
              },
              [
                _c("vs-button", {
                  attrs: {
                    "text-color": "#00A9F5",
                    color: "#E8F6FF",
                    type: "filled",
                    size: "large",
                    "icon-pack": "feather",
                    icon: "icon-settings",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "agent-header-edit",
            class: { "mr-5": !_vm.isMobile, "mr-3": _vm.isMobile },
          },
          [
            _vm.activeUserInfo.role !== "agent"
              ? _c(
                  "div",
                  {
                    staticClass: "agent-header-button ml-2",
                    on: {
                      click: function ($event) {
                        return _vm.onSelectVisitors()
                      },
                    },
                  },
                  [
                    !_vm.isSelectable
                      ? _c(
                          "vx-tooltip",
                          {
                            staticClass: "inline-flex",
                            attrs: {
                              text: _vm.$t("vue.edit"),
                              position: "top",
                            },
                          },
                          [
                            _c("vs-button", {
                              attrs: {
                                "text-color": "#00A9F5",
                                color: "#E8F6FF",
                                type: "filled",
                                size: "large",
                                "icon-pack": "feather",
                                icon: "icon-edit",
                              },
                            }),
                          ],
                          1
                        )
                      : _c(
                          "vx-tooltip",
                          {
                            staticClass: "inline-flex",
                            attrs: {
                              text: _vm.$t("vue.close"),
                              position: "top",
                            },
                          },
                          [
                            _c("vs-button", {
                              attrs: {
                                "text-color": "#00A9F5",
                                color: "#E8F6FF",
                                type: "filled",
                                size: "large",
                                "icon-pack": "feather",
                                icon: "icon-x",
                              },
                            }),
                          ],
                          1
                        ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }