var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 67 66",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _vm.hover
        ? _c("circle", {
            attrs: {
              cx: _vm.cx,
              cy: _vm.cy,
              r: _vm.cx,
              fill: _vm.stroke,
              "fill-opacity": "0.2",
            },
          })
        : _vm._e(),
      _c("path", {
        attrs: {
          d: "M33.4091 0C15.3624 0 0.687012 14.6754 0.687012 32.7221C0.687012 50.7687 15.3624 65.4441 33.4091 65.4441C51.4558 65.4441 66.1311 50.7687 66.1311 32.7221C66.1311 14.6754 51.4558 0 33.4091 0ZM33.4091 4.26835C49.149 4.26835 61.863 16.9824 61.863 32.7223C61.863 48.4622 49.149 61.1763 33.4091 61.1763C17.6692 61.1763 4.95511 48.4622 4.95511 32.7223C4.95511 16.9824 17.6692 4.26835 33.4091 4.26835ZM22.0272 18.4953C19.2774 18.4953 17.0479 20.7248 17.0479 23.4747C17.0479 26.2245 19.2774 28.454 22.0272 28.454C24.7771 28.454 27.0066 26.2245 27.0066 23.4747C27.0066 20.7248 24.7771 18.4953 22.0272 18.4953ZM44.7904 18.4953C42.0405 18.4953 39.8111 20.7248 39.8111 23.4747C39.8111 26.2245 42.0405 28.454 44.7904 28.454C47.5403 28.454 49.7697 26.2245 49.7697 23.4747C49.7697 20.7248 47.5403 18.4953 44.7904 18.4953Z",
          fill: _vm.stroke,
        },
      }),
      _c("line", {
        attrs: {
          x1: "18.1781",
          y1: "41.9569",
          x2: "48.6462",
          y2: "41.9569",
          stroke: _vm.stroke,
          "stroke-width": "5.25815",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }