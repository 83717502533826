<template>
  <div
    class="chat-messages border border-solid d-theme-border-grey-light border-t-0 border-r-0 border-b-0"
    :class="{
      'flex items-center justify-center': visitorId === null
    }"
  >
    <template>
      <div class="flex flex-col chat-messages-midcontainer">
        <div class="chat-messages-chat-av-container" :style="avContainerStyle">
          <chat-log v-if="visitorId" :class="messageMode === 'chat' || audioVideoStarted ? 'w-full' : 'flex-50'"></chat-log>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ChatLog from './ChatLog.vue'

export default {
  components: {
    ChatLog
  },

  data() {
    return {
      typedMessage: ''
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      visitorIsTyping: 'visitorIsTyping',
      messageMode: 'webrtc/messageMode',
      audioVideoStarted: 'webrtc/audioVideoStarted',
      visitorId: 'webrtc/visitorId',
      visitor: 'webrtc/visitor'
    }),
    canChatWithVisitor() {
      return (
        this.visitor &&
        (this.isVisitorConnectedToTheCurrentAgent || !this.visitor.connectedAgentId) &&
        (this.visitor.type === 'embed' || this.visitor.type === 'campaign')
      )
    },
    isVisitorConnectedToTheCurrentAgent() {
      return this.visitor && this.visitor.connectedAgentId && this.activeUserInfo.uid === this.visitor.connectedAgentId
    },
    avContainerStyle() {
      return {
        //height: this.visitor.connectedAgentId === this.activeUserInfo.uid ? 'calc(51vh)' : 'calc(100% - 65px)'
        height: 'calc(100%)',
        maxHeight: 'calc(85vh)'
      }
    }
  }
}
</script>
<style lang="scss">
.chat-messages {
  border-radius: 6px;
  border-top-left-radius: 0;
  background-color: white;

  width: 100%;

  @media only screen and (max-width: 1024px) {
    display: none !important;
  }

  &--active-mobile {
    @media only screen and (max-width: 1024px) {
      display: block !important;
      position: absolute;
      left: -2.2rem;
      width: calc(100% + 1.8rem + 2.2rem) !important;
      opacity: 1;
      visibility: visible;
      z-index: 999;
    }
  }

  &-midcontainer {
    height: 100%;
    width: 100%;
  }
  .pa-icon-default {
    color: rgba(var(--vs-secondary), 1) !important;
    path {
      stroke: rgba(var(--vs-secondary), 1) !important;
    }
  }
  .flex-50 {
    flex: 0 0 50%;
  }

  &-chat-av-container {
    display: flex;
    flex-direction: row;
    flex-flow: row nowrap;
  }

  &-toolbar {
    &-mt-auto {
      margin-top: auto;
    }
    &-mt-10 {
      margin-top: 2.5rem !important;
    }
  }
}
</style>
