<template>
  <div class="communication-controls" :class="{ 'communication-controls--mobile': isMobile }">
    <div class="communication-controls-bar">
      <div
        class="communication-controls-bar-button"
        :class="{
          'pa-bk-active': messageModes.includes('chat'),
          'communication-controls-bar-button--unread': !messageModes.includes('chat') && unreadChatMessages.length
        }"
        @click="onCommunicationControlsClick('chat')"
      >
        <chat-icon :class="{ 'pa-icon-default': !messageModes.includes('chat') && !unreadChatMessages.length }" :width="16" :height="16">
          <div v-if="!messageModes.includes('chat') && unreadChatMessages.length" class="communication-controls-bar-button__chat-unread-count" />
        </chat-icon>
      </div>

      <div class="communication-controls-bar-button" :class="audioButtonClass" @click="onCommunicationControlsClick('audio')">
        <microphone-icon
          v-if="!isAudioDisabled && (messageModes.includes('audio') || micEnabled)"
          :class="audioButtonIconClass"
          :width="16"
          :height="16"
        ></microphone-icon>
        <microphone-off-icon v-else :class="audioButtonIconClass" :width="16" :height="16"></microphone-off-icon>
      </div>
      <div class="communication-controls-bar-button" :class="videoButtonClass" @click="onCommunicationControlsClick('video')">
        <video-call-icon
          v-if="!isVideoDisabled && (messageModes.includes('video') || videoEnabled)"
          :class="videoButtonIconClass"
          :width="16"
          :height="16"
        ></video-call-icon>
        <video-call-off-icon v-else :class="videoButtonIconClass" :width="16" :height="16"></video-call-off-icon>
      </div>
    </div>

    <div v-if="isMobile" class="flex flex-row">
      <div class="video-screen-minimized-navbar-top-right-button red-bk" @click="$emit('on-close-communication-control')">
        <phone-icon :width="20" :height="20"></phone-icon>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ChatIcon from '../../../components/icons/ChatIcon.vue'
import MicrophoneIcon from '../../../components/icons/MicrophoneIcon.vue'
import MicrophoneOffIcon from '../../../components/icons/MicrophoneOffIcon.vue'
import VideoCallIcon from '../../../components/icons/VideoCallIcon.vue'
import VideoCallOffIcon from '../../../components/icons/VideoCallOffIcon.vue'
import PhoneIcon from '../../../components/icons/PhoneIcon.vue'
export default {
  props: {
    unreadChatMessages: {
      required: true
    },
    isMobile: {
      type: Boolean,
      required: false
    }
  },
  components: {
    ChatIcon,
    MicrophoneIcon,
    MicrophoneOffIcon,
    VideoCallIcon,
    VideoCallOffIcon,
    PhoneIcon
  },
  computed: {
    ...mapGetters({
      micEnabled: 'webrtc/micEnabled',
      videoEnabled: 'webrtc/videoEnabled',
      messageModes: 'webrtc/messageModes',
      company: 'company'
    }),
    isAudioDisabled() {
      return this.company && this.company.disableAudio
    },
    audioButtonClass() {
      if (this.isAudioDisabled) return 'communication-controls-bar-button-disabled'
      if (this.messageModes.includes('audio') || this.micEnabled) return 'pa-bk-active'
      return ''
    },
    audioButtonIconClass() {
      if (this.isAudioDisabled) return 'communication-controls-bar-button-icon-disabled'
      if (!this.messageModes.includes('audio') && !this.micEnabled) return 'pa-icon-default'
      return ''
    },
    isVideoDisabled() {
      return this.company && this.company.disableVideo
    },
    videoButtonClass() {
      if (this.isVideoDisabled) return 'communication-controls-bar-button-disabled'
      if (this.messageModes.includes('video') || this.videoEnabled) return 'pa-bk-active'
      return ''
    },
    videoButtonIconClass() {
      if (this.isVideoDisabled) return 'communication-controls-bar-button-icon-disabled'
      if (!this.messageModes.includes('video') && !this.videoEnabled) return 'pa-icon-default'
      return ''
    }
  },
  methods: {
    onCommunicationControlsClick(mode) {
      if (this.isAudioDisabled && mode === 'audio') return
      if (this.isVideoDisabled && mode === 'video') return
      this.$emit('on-click-communications-controls', mode)
    }
  }
}
</script>

<style lang="scss">
.communication-controls {
  &--mobile {
    .communication-controls {
      &-bar {
        &-button {
          &--unread {
            background: #ffffff !important;

            svg {
              stroke: #275D73 !important;
            }
          }
        }
      }
    }
  }

  &-bar {
    &-button {
      &-disabled {
        background: #d3d3d3 !important;
        cursor: default !important;
      }
      &-disabled:hover {
        background: #d3d3d3 !important;
        box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.5);
      }

      &-icon-disabled {
        path {
          stroke: #fff !important;
          fill: #d3d3d3 !important;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.communication-controls {
  .pa-bk-active {
    background: #275D73 !important;
  }
  .pa-icon-default {
    color: rgba(var(--vs-primary), 1) !important;
    path {
      stroke: rgba(var(--vs-primary), 1) !important;
    }
  }
  position: absolute;
  bottom: 2px;

  &--mobile {
    .pa-icon-default {
      color: #275D73 !important;
      path {
        stroke: #275D73 !important;
      }
    }
  }

  &-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &-button {
      width: 93.5px;
      height: 35px;
      background: #f7f7f7;
      border-radius: 6px;
      display: flex;
      box-shadow: 0px 2px 4px rgba(196, 196, 196, 0.25);
      cursor: pointer;
      justify-content: center;
      align-items: center;
      transition: 0.5s;

      &--unread {
        background: #12598d;
      }

      &__chat-unread-count {
        position: absolute;
        top: -2px;
        right: -2px;
        height: 7px;
        width: 7px;
        color: #fff;
        background-color: #f05541;
        border-radius: 50%;
      }
    }
    &-button:hover {
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.5);
    }
  }

  &--mobile {
    .pa-icon-default {
      color: #275D73 !important;
      path {
        stroke: #275D73 !important;
      }
    }
  }
}

.red-bk {
  background: #f05541;
}
</style>
