<template>
  <div class="cobrowsing flex flex-col">
    <div class="cobrowsing-window relative">
      <iframe
        v-if="visitor && visitor.surfly && visitor.surfly.joinURL"
        :src="visitor.surfly.joinURL"
        frameborder="0"
        allowfullscreen
        style="position: relative; width: 100%; height: 100%"
      ></iframe>
    </div>
    <div class="cobrowsing-toolbar" style="justify-content: flex-start; flex-grow: 1">
      <toolbar
        :cobrowse-status="cobrowseStatus"
        v-if="visitor.connectedAgentId === activeUserInfo.uid"
        :call-recording-status="callRecordingStatus"
      ></toolbar>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Toolbar from './messages/Toolbar.vue'

const COBROWSE_STATUS = {
  STOPPED: 'stopped',
  STARTED: 'started',
  COBROWSING: 'cobrowsing'
}

export default {
  components: {
    Toolbar
  },
  props: {
    cobrowseStatus: {
      type: String,
      required: false,
      default: COBROWSE_STATUS.STOPPED
    },
    callRecordingStatus: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      visitor: 'webrtc/visitor'
    })
  }
}
</script>
<style lang="scss" scoped>
.cobrowsing {
  height: calc(100vh) !important;
  width: 100% !important;
  top: 0px !important;
  bottom: 0px !important;
  position: fixed;
  z-index: 54000 !important;
  right: 0px !important;
  left: 0px !important;
  background: green !important;
  &-window {
    width: 100%;
    height: 100%;
  }
  &-toolbar {
    margin-top: auto;
  }
}
</style>
