var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-visitors" },
    [
      _c("div", {
        staticStyle: {
          position: "fixed",
          "z-index": "54000",
          top: "50%",
          left: "100%",
        },
        attrs: { id: "visitor-search-connected-agent-info" },
      }),
      _c(
        "div",
        { staticClass: "search-visitors--action" },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchInput,
                expression: "searchInput",
              },
            ],
            staticClass: "search-visitors--input w-full mr-2",
            attrs: {
              type: "text",
              name: "search",
              placeholder: _vm.$t("vue.searchVisitors"),
            },
            domProps: { value: _vm.searchInput },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.searchInput = $event.target.value
              },
            },
          }),
          _c(
            "vs-button",
            {
              staticClass: "search-visitors--close-button ml-2",
              attrs: { color: "#eaeeea" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.onCloseSearchVisitors.apply(null, arguments)
                },
              },
            },
            [
              _c("CloseIcon", {
                attrs: { width: 18, height: 18, color: "#53535B" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isLoading
        ? _c(
            "vs-progress",
            { attrs: { indeterminate: "", color: "primary" } },
            [_vm._v("primary")]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "search-visitors--container" },
        [
          _c("visitors", {
            attrs: {
              visitors: _vm.filteredVisitors,
              isLoadMoreDisabled: _vm.isLoadMoreDisabled,
            },
            on: {
              "update-active-chat-visitor": _vm.updateActiveChatUser,
              "load-more": _vm.loadMore,
            },
          }),
          _c("messages"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }