<template>
  <div class="agent-header">
    <Avatar
      class="ml-4"
      size="45px"
      :photoURL="agentImage"
      :name="agent"
    />

    <div class="ml-2 text-white">
      <div class="agent-header-company">{{ company }}</div>
      <div>
        <h6 class="agent-header-agent">{{ agent }}</h6>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import Avatar from '@/components/Avatar'

export default {
  components: {
    Avatar
  },

  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      companyInfo: 'company'
    }),

    agent() {
      let agentName = null
      if (this.activeUserInfo && this.activeUserInfo.firstname) {
        agentName = this.activeUserInfo.firstname
        if (this.activeUserInfo.lastname) {
          agentName = `${agentName} ${this.activeUserInfo.lastname}`
        }
      } else {
        agentName = this.activeUserInfo.displayName ? this.activeUserInfo.displayName : ''
      }
      return agentName ? agentName.toUpperCase() : agentName
    },

    agentImage() {
      if (this.activeUserInfo && this.activeUserInfo.photoURL) {
        return this.activeUserInfo.photoURL
      }

      return ''
    },

    company() {
      let companyName = null
      if (this.companyInfo && this.companyInfo.name) {
        companyName = this.companyInfo.name
      }
      return companyName ? companyName.toUpperCase() : companyName
    }
  }
}
</script>
<style lang="scss" scoped>
.agent-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 65px;
  &-company {
    font-weight: 100;
    font-size: 0.9rem;
  }
  &-agent {
    font-weight: 600;
    font-size: 1.3rem;
    color: white;
  }
}
</style>
