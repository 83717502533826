var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "video-screen-minimized-navbar" }, [
    _c("div", { staticClass: "video-screen-minimized-navbar-top-right" }, [
      _c("div", { staticClass: "flex flex-row" }, [
        _c(
          "div",
          {
            staticClass:
              "video-screen-minimized-navbar-top-right-button white-bk mr-2",
            on: {
              click: function ($event) {
                return _vm.$emit("on-maximize-screen")
              },
            },
          },
          [
            _c("maximize-icon", {
              staticClass: "pa-icon-default",
              attrs: { width: 20, height: 20 },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "video-screen-minimized-navbar-top-right-button red-bk",
            on: {
              click: function ($event) {
                return _vm.$emit("on-close-communication-control")
              },
            },
          },
          [_c("phone-icon", { attrs: { width: 20, height: 20 } })],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }