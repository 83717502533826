var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "request-header" },
    [
      _c("subscriptions", { attrs: { isMobile: _vm.isMobile } }),
      _c("search-visitor", {
        staticClass: "request-header-align-right mb-2",
        attrs: { "conversation-status": _vm.conversationStatus },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }