var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 66 66",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _vm.hover
        ? _c("circle", {
            attrs: {
              cx: _vm.cx,
              cy: _vm.cy,
              r: _vm.cx,
              fill: _vm.stroke,
              "fill-opacity": "0.2",
            },
          })
        : _vm._e(),
      _c("path", {
        attrs: {
          d: "M32.7221 0C14.6754 0 0 14.6754 0 32.7221C0 50.7687 14.6754 65.4441 32.7221 65.4441C50.7687 65.4441 65.4441 50.7687 65.4441 32.7221C65.4441 14.6754 50.7687 0 32.7221 0ZM32.7221 4.26835C48.4619 4.26835 61.176 16.9824 61.176 32.7223C61.176 48.4622 48.4619 61.1763 32.7221 61.1763C16.9822 61.1763 4.2681 48.4622 4.2681 32.7223C4.2681 16.9824 16.9822 4.26835 32.7221 4.26835ZM21.3402 18.4953C18.5904 18.4953 16.3609 20.7248 16.3609 23.4747C16.3609 26.2245 18.5904 28.454 21.3402 28.454C24.0901 28.454 26.3195 26.2245 26.3195 23.4747C26.3195 20.7248 24.0901 18.4953 21.3402 18.4953ZM44.1034 18.4953C41.3535 18.4953 39.1241 20.7248 39.1241 23.4747C39.1241 26.2245 41.3535 28.454 44.1034 28.454C46.8533 28.454 49.0827 26.2245 49.0827 23.4747C49.0827 20.7248 46.8533 18.4953 44.1034 18.4953Z",
          fill: _vm.stroke,
        },
      }),
      _c("path", {
        attrs: {
          d: "M47.2539 44.5859C42.5249 39.9129 30.0738 33.3706 18.1013 44.5859",
          stroke: _vm.stroke,
          "stroke-width": "5.25815",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }