<template>
  <div v-if="activeUserInfo && !activeUserInfo.impersonated" class="btn-add-new mw-255 mb-2 rounded-lg cursor-pointer flex items-center justify-center font-medium text-base text-primary" :class="{ 'mr-auto': isMobile }">
    <template v-if="!isMobile">
      <feather-icon icon="BellIcon" svgClasses="h-4 w-4" />
      <label class="ml-2 mr-2" style="font-size: 12px">{{ $t('vue.notifications') }}</label>
    </template>
    <vs-dropdown vs-custom-content vs-trigger-click class="mr-2" :class="{ 'pa-dropdown--mobile': isMobile }">
      <template v-if="isMobile">
        <feather-icon icon="BellIcon" svgClasses="h-4 w-4" />
        <label class="ml-2 mr-2" style="font-size: 12px">{{ $t('vue.notifications') }}</label>
      </template>

      <a class="a-icon" href.prevent :class="{ flex: isMobile }">
        <vs-icon class="" icon="expand_more"></vs-icon>
      </a>
      <vs-dropdown-menu class="notification-type">
        <vs-checkbox class="notification-type__checkbox" @change="updateSubscription" v-model="subscriptions.email">{{ $t('vue.email') }}</vs-checkbox>
        <vs-checkbox class="notification-type__checkbox" @change="updateSubscription" v-model="subscriptions.push">{{ $t('vue.push') }}</vs-checkbox>
        <vs-checkbox class="notification-type__checkbox" @change="updateSubscription" v-model="subscriptions.audio">{{ $t('vue.ringtone') }}</vs-checkbox>

        <vs-collapse v-if="subscriptions.audio" class="notification-type__accordion" accordion>
          <vs-collapse-item class="notification-type__accordion-header">
            <div slot="header">
              {{ $t('vue.ringtoneOutput') }}
            </div>
            <div class="notification-type__output" v-for="device in audioOutputDevices" :key="device.value" @click="selectAudioOutputDevice(device)">
              <div>
                <input class="notification-type__output-device" type="radio" name="audio-output-devices" v-model="subscriptions.audioOutputDevice" :value="device" />
              </div>
              <div>
                <label class="notification-type__output-label">{{ device.text }}</label>
              </div>
            </div>
          </vs-collapse-item>
        </vs-collapse>

      </vs-dropdown-menu>

    </vs-dropdown>

    <popper v-if="isMobile" trigger="clickToToggle" :options="{
        placement: 'top',
        modifiers: { offset: { offset: '0,10px' } }
      }">
      <div class="popper custom-popper">
        {{ $t('info.visitorNotifications') }}
      </div>

      <label slot="reference" class="flex">
        <img width="17" height="17" :src="infoImg" style="display: inline-block" />
      </label>
    </popper>

    <vx-tooltip v-else style="width: 17px" :text="$t('info.visitorNotifications')" position="top" class="flex items-center">
      <img width="17" height="17" :src="infoImg" style="display: inline-block" />
    </vx-tooltip>
  </div>
</template>
<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

import Popper from 'vue-popperjs'
import 'vue-popperjs/dist/vue-popper.css'

import { mapGetters } from 'vuex'
import chromeNotifications from '@/assets/utils/chrome-notifications.js'

export default {
  components: {
    Popper
  },

  props: {
    isMobile: {
      type: Boolean,
      required: false
    }
  },

  data() {
    return {
      infoImg: require('@/assets/images/elements/info.svg'),
      subscriptions: {
        email: false,
        push: false,
        audio: false,
        audioOutputDevice: null
      },
      endpoints: []
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      company: 'company',
      audioOutputDevices: 'audioOutputDevices'
    })
  },
  watch: {
    'subscriptions.push'() {
      this.subscribePushNotification()
    },
    'subscriptions.audio'() {
      if (this.subscriptions.audio) {
        /* eslint-disable no-console */
        // console.log('watch subscriptions.audio: emit check-if-audio-needs-to-be-played')
        this.$serverBus.$emit('check-if-audio-needs-to-be-played')
      } else {
        const audioElement = document.getElementById('visitor-request-audio')
        audioElement.pause()
      }
    }
  },
  async created() {
    await this.checkPushNotifications()
  },
  methods: {
    async checkPushNotifications() {
      const userRef = await this.$db.collection('users').doc(this.activeUserInfo.uid).get()
      if (userRef.data().subscriptions) {
        this.subscriptions.push = !!userRef.data().subscriptions.push
        this.subscriptions.email = !!userRef.data().subscriptions.email
        this.subscriptions.audio = typeof userRef.data().subscriptions.audio === 'undefined' ? true : !!userRef.data().subscriptions.audio
        this.subscriptions.audioOutputDevice = typeof userRef.data().subscriptions.audioOutputDevice === 'undefined' ? null : userRef.data().subscriptions.audioOutputDevice
      }
      if (userRef.data().endpoints) {
        this.endpoints = userRef.data().endpoints
      }
    },
    async updateSubscription() {
      if (this.subscriptions && !this.subscriptions.audio && this.subscriptions.audioOutputDevice) {
        this.subscriptions.audioOutputDevice = null
      }
      this.$store.commit('UPDATE_USER_INFO', { subscriptions: { ...this.subscriptions } })
      const userRef = this.$db.collection('users').doc(this.activeUserInfo.uid)
      await userRef.set({ subscriptions: this.subscriptions }, { merge: true })
    },
    async selectAudioOutputDevice(device) {
      this.subscriptions.audioOutputDevice = device
      this.$store.commit('UPDATE_USER_INFO', { subscriptions: { ...this.subscriptions } })
      const userRef = this.$db.collection('users').doc(this.activeUserInfo.uid)
      await userRef.set({ subscriptions: this.subscriptions }, { merge: true })
      this.$serverBus.$emit('check-if-audio-needs-to-be-played')
    },
    removePreviousSubscription() {
      /*
          Check if subscription exists in localstorage. If it exists in localstorage, check if it exists in the endpoints array.
          If it  exists in endpoints array then remove it.
      */
      if (localStorage.getItem('pa-push-notification')) {
        const endpoint = JSON.parse(localStorage.getItem('pa-push-notification'))
        this.endpoints = this.endpoints.filter((x) => x.id !== endpoint.id)
      }

      /*
          Check if total items in array is equal or greater than 10.
          If there are 10 items then remove the last one.
     */
      if (this.endpoints.length >= 10) {
        this.endpoints.pop()
      }
    },
    async subscribePushNotification() {
      if (this.activeUserInfo && !this.activeUserInfo.impersonated) {
        /* Get the endpoints from server, to ensure the endpoints are updated with latest endpoints if user has opened tab in multiple browsers */
        const userRef = await this.$db.collection('users').doc(this.activeUserInfo.uid).get()
        this.endpoints = userRef.data().endpoints || []

        if (this.subscriptions.push) {
          chromeNotifications.checkSupported()
          const subData = await chromeNotifications.subscribeUserToPush()
          if (subData) {
            this.removePreviousSubscription()

            const endpoint = {
              id: window.btoa(JSON.stringify(subData)),
              subscription: JSON.stringify(subData)
            }
            /* Add subscription to the front of the array */
            this.endpoints.unshift(endpoint)

            await this.$db.collection('users').doc(this.activeUserInfo.uid).set({ endpoints: this.endpoints }, { merge: true })

            localStorage.setItem('pa-push-notification', JSON.stringify(endpoint))
          }
        } else {
          chromeNotifications.unsubscribeUser()
          this.removePreviousSubscription()
          localStorage.removeItem('pa-push-notification')
          await this.$db.collection('users').doc(this.activeUserInfo.uid).set({ endpoints: this.endpoints }, { merge: true })
        }
      }
    }
  }
}
</script>

<style lang="scss">
.pa-dropdown--mobile {
  display: flex;
  align-items: center;
  font-family: inherit;
}

input[type='radio'] {
  background-color: transparent;
  border: 0.0625em solid rgba(var(--vs-primary), 1);
  border-radius: 50%;
  box-shadow: inset 0 0 0 0 white;
  cursor: pointer;
  font: inherit;
  height: 1em;
  outline: none;
  width: 1em;
  -moz-appearance: none;
  -webkit-appearance: none;
  &::before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--vs-primary);
  }
  &:checked::before {
    transform: scale(1);
  }

  &:checked {
    background-color: rgba(var(--vs-primary), 1);
    box-shadow: inset 0 0 0 0.1875em white;
    -webkit-transition: background 0.15s, box-shadow 0.1s;
    transition: background 0.15s, box-shadow 0.1s;
  }
}

.notification-type__output {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  &-device {
    cursor: pointer;
    height: 11px;
  }
  &-label {
    cursor: pointer;
    margin-left: 0.5em;
  }
}

.notification-type__checkbox {
  .vs-checkbox {
    min-width: 20px;
    min-height: 20px;
  }
}

.notification-type__accordion {
  padding: 0px;
  font-size: inherit;
  header {
    padding: 0px;
    font-size: 14px;
    height: 100%;
    width: 100%;
    line-height: 1.5;
    letter-spacing: 0.01rem;
  }
}

.custom-popper {
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  font-size: 12px !important;
  line-height: 14.4px !important;
  font-weight: 500 !important;
  background: rgb(48, 52, 247);
  border: none !important;
  box-shadow: none !important;
  border-radius: 6px;

  &[x-placement^='top'] .popper__arrow {
    border-width: 5px 5px 1px 5px !important;
    border-color: rgb(48, 52, 247) transparent transparent transparent !important;
  }
}
</style>
