var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visitorId != null
    ? _c(
        "div",
        { staticClass: "video-screen-maximized" },
        [
          _vm.showSettings
            ? _c("audio-video-settings", {
                attrs: {
                  show: _vm.showSettings,
                  "selected-audio": _vm.selectedAudio,
                  "selected-video": _vm.selectedVideo,
                  "selected-speaker": _vm.selectedSpeaker,
                  "volume-level": _vm.volumeLevel,
                },
                on: {
                  close: function ($event) {
                    return _vm.onCloseSettings()
                  },
                },
              })
            : _vm._e(),
          _c("div", { staticClass: "video-screen-maximized-navbar" }, [
            _c(
              "div",
              { staticClass: "video-screen-maximized-navbar-left-container" },
              [_c("agent-modal-header")],
              1
            ),
            _c(
              "div",
              { staticClass: "video-screen-maximized-navbar-right-container" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "video-screen-maximized-navbar-communications-control mr-2",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "video-screen-maximized-navbar-communications-control-button",
                        class: {
                          "pa-bk-active": _vm.messageModes.includes("chat"),
                          "video-screen-maximized-navbar-communications-control-button--unread":
                            !_vm.messageModes.includes("chat") &&
                            _vm.unreadChatMessages.length,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onChangeCommunicationControl("chat")
                          },
                        },
                      },
                      [
                        _c(
                          "chat-icon",
                          {
                            class: {
                              "pa-icon-default":
                                !_vm.messageModes.includes("chat") &&
                                !_vm.unreadChatMessages.length,
                            },
                            attrs: { width: 16, height: 16 },
                          },
                          [
                            !_vm.messageModes.includes("chat") &&
                            _vm.unreadChatMessages.length
                              ? _c("div", {
                                  staticClass:
                                    "video-screen-maximized-navbar-communications-control-button__chat-unread-count",
                                })
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "video-screen-maximized-navbar-communications-control-button",
                        class: _vm.audioButtonClass,
                        on: {
                          click: function ($event) {
                            return _vm.onChangeCommunicationControl("audio")
                          },
                        },
                      },
                      [
                        !_vm.isAudioDisabled &&
                        _vm.messageModes.includes("audio")
                          ? _c("microphone-icon", {
                              class: _vm.audioButtonIconClass,
                              attrs: { width: 16, height: 16 },
                            })
                          : _c("microphone-off-icon", {
                              class: _vm.audioButtonIconClass,
                              attrs: { width: 16, height: 16 },
                            }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "video-screen-maximized-navbar-communications-control-button",
                        class: _vm.videoButtonClass,
                        on: {
                          click: function ($event) {
                            return _vm.onChangeCommunicationControl("video")
                          },
                        },
                      },
                      [
                        !_vm.isVideoDisabled &&
                        _vm.messageModes.includes("video")
                          ? _c("video-call-icon", {
                              class: _vm.videoButtonIconClass,
                              attrs: { width: 16, height: 16 },
                            })
                          : _c("video-call-off-icon", {
                              class: _vm.videoButtonIconClass,
                              attrs: { width: 16, height: 16 },
                            }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "video-screen-maximized-navbar-button mr-2",
                    on: {
                      click: function ($event) {
                        _vm.showSettings = true
                      },
                    },
                  },
                  [
                    _c("settings-icon", {
                      staticClass:
                        "video-screen-maximized-navbar-icon pa-icon-default",
                      attrs: { width: 16, height: 16 },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "video-screen-maximized-navbar-button white-bk mr-2",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("minimize-screen")
                      },
                    },
                  },
                  [
                    _c("minimize-icon", {
                      staticClass: "pa-icon-default",
                      attrs: { width: 20, height: 20 },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "video-screen-maximized-navbar-button pa-bk-danger mr-4",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("close-communication-control")
                      },
                    },
                  },
                  [
                    _c("phone-icon", {
                      staticClass: "video-screen-maximized-navbar-icon",
                      attrs: { width: 20, height: 20 },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }