<template>
  <svg :width="width" :height="height" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle :cx="cx" :cy="cy" :r="cx" :fill="stroke" />
    <path d="M46.3333 24L27.5417 42.7917L19 34.25" stroke="white" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'RatingSavedIcon',
  props: {
    stroke: {
      type: String,
      default: '#3034F7'
    },
    width: {
      type: Number,
      default: 65
    },
    height: {
      type: Number,
      default: 65
    }
  },
  computed: {
    cx() {
      const half = this.width / 2
      return half - 0.5
    },
    cy() {
      const half = this.height / 2
      return half - 0.5
    }
  }
}
</script>
