<template>
  <div class="av-call flex flex-col" :style="SCREEN_HEIGHT" :class="{ 'av-call--mobile': isMobile }">
    <div v-if="visitor && isMobile" class="av-call__header">
      <button class="av-call__header__button-back" @click.prevent="goBackToIncomingRequestsList">
        <arrow-icon :color="'#275D73'" :direction="'left'" :width="18" :height="28" @click="goBackToIncomingRequestsList" />
      </button>

      <div class="av-call__header__info">
        <div v-if="!!getDialogOrCampaignName(visitor)" class="av-call__header__info__name">
          <span>{{ getDialogOrCampaignName(visitor) }}</span>
        </div>

        <div v-if="visitor">
          <div class="av-call__header__info__isp">{{ getVisitorInfo(visitor) }}</div>
          <div class="flex flex-row av-call__header__info__footer">
            <div>
              <vx-tooltip :text="countryName(visitor.country)" position="top">
                <img v-if="visitor.country" width="20" :alt="countryName(visitor.country)" :src="`https://storage.googleapis.com/pathadvice-app.appspot.com/flags/${visitor.country}.svg`" />
              </vx-tooltip>
            </div>
            <div class="av-call__header__info__time">
              <span>{{ lastSeenTimestamp }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="av-call__header__controls">
        <div class="av-call__header__controls__button mr-6" @click="handleClickCommunicationControlOnMobile('video')">
          <video-call-icon :color="activeChatVisitor.connectedAgentId && !messageModes.includes('video') ? '#275D73' : 'rgb(208, 208, 208)'" :width="22" :height="22" @click="handleClickCommunicationControlOnMobile('video')" />
        </div>

        <div class="av-call__header__controls__button" @click="handleClickCommunicationControlOnMobile('audio')">
          <vertical-phone-icon :color="activeChatVisitor.connectedAgentId && !messageModes.includes('audio') ? '#275D73' : 'rgb(208, 208, 208)'" :width="20" :height="20" @click="handleClickCommunicationControlOnMobile('audio')" />
        </div>
      </div>

      <vs-prompt :title="$t('vue.endConversation')" @cancel="deletePrompt = false" @accept="onCloseCommunicationControl" @close="deletePrompt = false" color="danger" class="end-conversation-dialog" :cancel-text="$t('vue.cancel')" :accept-text="$t('inputs.exit')" :active.sync="deletePrompt">
        <div class="con-exemple-prompt">
          <p>
            <strong>{{ $t('vue.shouldEndConversationText') }}</strong>
          </p>
        </div>
      </vs-prompt>
      <div v-if="IS_CALL_ACTIVE && visitorId && (!isMobile || (isMobile && !(audioVideoStarted || HAS_VISITOR_STARTED_AUDIOVIDEO)))" class="av-call__header__endcall pa-bk-danger" @click="deletePrompt = true">
        <phone-icon class="chat-header-navbar-icon" :width="18" :height="18"></phone-icon>
      </div>
    </div>

    <div v-if="(!isMobile && (audioVideoStarted || HAS_VISITOR_STARTED_AUDIOVIDEO)) || (isMobile && isAudioVideoStartedBySomeone)" class="av-call-video flex flex-col" :style="SCREEN_SIZE">
      <vs-prompt :title="$t('vue.endConversation')" @cancel="deletePrompt = false" @accept="onCloseCommunicationControl" @close="deletePrompt = false" color="danger" class="end-conversation-dialog" :cancel-text="$t('vue.cancel')" :accept-text="$t('inputs.exit')" :active.sync="deletePrompt">
        <div class="con-exemple-prompt">
          <p>
            <strong>{{ $t('vue.shouldEndConversationText') }}</strong>
          </p>
        </div>
      </vs-prompt>

      <template v-if="!isMobile">
        <video-screen-minimized-navbar v-if="screen === SCREEN.MINIMIZED" @on-maximize-screen="onMaximizeScreen" @on-close-communication-control="deletePrompt = true"></video-screen-minimized-navbar>

        <video-screen-maximized-navbar v-else :selected-audio="selectedAudio" :selected-video="selectedVideo" :selected-speaker="selectedSpeaker" :volume-level="volumeLevel" :unread-chat-messages="unreadChatMessages" @minimize-screen="onMinimizeScreen" @close-communication-control="deletePrompt = true" @change-communication-control="onClickCommunicationControl"></video-screen-maximized-navbar>
      </template>

      <video-audio-chat v-if="audioVideoStarted || HAS_VISITOR_STARTED_AUDIOVIDEO" :screen="screen" :local-stream="localStream" :remote-stream="remoteStream" :volumeLevel="volumeLevel" :selected-video="selectedVideo" :selected-speaker="selectedSpeaker" :cobrowse-status="cobrowseStatus" :call-recording-status="callRecordingStatus" :isMobile="isMobile" :isSwitchButton="isSwitchButton" :cameraOptions="cameraOptions"></video-audio-chat>

      <screen-sharing v-if="IS_VISITOR_SCREEN_SHARING && isMobile" :remote-stream="remoteStream" :isMobile="isMobile"></screen-sharing>
    </div>

    <div v-if="isMobile && isAudioVideoStartedBySomeone" class="av-call__communication-wrapper" :class="{ 'av-call__communication-wrapper_mobile': messageModes.includes('chat') }">
      <div class="av-call__communication__btn-toggle-chat-wrapper">
        <button class="av-call__communication__btn-toggle-chat" @click.prevent="onClickCommunicationControl('chat')">
          <ArrowIcon :width="16" :height="20" :color="'#275D73'" :direction="messageModes.includes('chat') ? 'bottom' : 'top'" @click="onClickCommunicationControl('chat')" />
        </button>
      </div>

      <communication-controls-minimized v-if="(!isMobile && screen === SCREEN.MINIMIZED) || (isMobile && isAudioVideoStartedBySomeone)" :unread-chat-messages="unreadChatMessages" :isMobile="isMobile" @on-click-communications-controls="onClickCommunicationControl" @on-close-communication-control="deletePrompt = true"></communication-controls-minimized>

      <visitor-chats v-if="(!isMobile && screen === SCREEN.MINIMIZED) || isMobile" :screen="screen" :cobrowse-status="cobrowseStatus" :call-recording-status="callRecordingStatus" :isMobile="isMobile" :isAudioVideoStartedBySomeone="isAudioVideoStartedBySomeone"></visitor-chats>
    </div>

    <template v-else>
      <communication-controls-minimized v-if="(!isMobile && screen === SCREEN.MINIMIZED) || (isMobile && isAudioVideoStartedBySomeone)" :unread-chat-messages="unreadChatMessages" @on-click-communications-controls="onClickCommunicationControl" @on-close-communication-control="deletePrompt = true"></communication-controls-minimized>

      <visitor-chats v-if="(!isMobile && screen === SCREEN.MINIMIZED) || isMobile" :screen="screen" :cobrowse-status="cobrowseStatus" :call-recording-status="callRecordingStatus" :isMobile="isMobile"></visitor-chats>
    </template>

    <toolbar v-if="screen === SCREEN.MAXIMIZED" :cobrowse-status="cobrowseStatus" :call-recording-status="callRecordingStatus"></toolbar>
  </div>
</template>
<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

import { mapGetters } from 'vuex'
import VideoScreenMinimizedNavbar from './VideoScreenMinimizedNavbar.vue'
import VideoScreenMaximizedNavbar from './VideoScreenMaximizedNavbar.vue'
import ScreenSharing from '../ScreenSharing.vue'

import CommunicationControlsMinimized from './CommunicationControlsMinimized.vue'
import VisitorChats from './VisitorChats.vue'
import Toolbar from './Toolbar.vue'
import VideoAudioChat from './VideoAudioChat.vue'
import ArrowIcon from '@/components/icons/ArrowIcon'
import VideoCallIcon from '@/components/icons/VideoCallIcon'
import VerticalPhoneIcon from '@/components/icons/VerticalPhoneIcon'
import PhoneIcon from '@/components/icons/PhoneIcon.vue'

const COBROWSE_STATUS = {
  STOPPED: 'stopped',
  STARTED: 'started',
  COBROWSING: 'cobrowsing'
}

const CALL_RECORDING_STATUS = {
  STOPPED: 'stopped',
  STARTED: 'started',
  CALL_RECORDING: 'call-recording'
}

const SCREEN = {
  MINIMIZED: 'minimzed',
  MAXIMIZED: 'maximized'
}

export default {
  components: {
    ScreenSharing,
    VideoScreenMinimizedNavbar,
    VideoScreenMaximizedNavbar,
    CommunicationControlsMinimized,
    VisitorChats,
    Toolbar,
    VideoAudioChat,
    ArrowIcon,
    VideoCallIcon,
    VerticalPhoneIcon,
    PhoneIcon
  },
  emits: ['close-communication-control'],
  data() {
    return {
      deletePrompt: false,
      typedMessage: '',
      SCREEN,
      COBROWSE_STATUS,
      CALL_RECORDING_STATUS,
      hideChatTime: null,
      wasAudioVideoStarted: false,
      wasVideoStarted: false,
      isCameraOptionsList: false,
      cameraOptions: [],
      countryCodes: require('@/assets/countryCodes.json'),
      lastSeenTimestamp: '',
      isHiddenChatWhenAudioVideoRequestCreated: false
    }
  },
  props: {
    localStream: {
      type: MediaStream,
      required: false,
      default: null
    },
    remoteStream: {
      type: MediaStream,
      required: false,
      default: null
    },
    selectedAudio: {
      type: Object,
      required: false,
      default: null
    },
    selectedSpeaker: {
      type: Object,
      required: false,
      default: null
    },
    selectedVideo: {
      type: Object,
      required: false,
      default: null
    },
    cobrowseStatus: {
      type: String,
      required: false,
      default: COBROWSE_STATUS.STOPPED
    },
    callRecordingStatus: {
      type: String,
      required: true
    },
    volumeLevel: {
      type: Number,
      required: true
    },
    screen: {
      type: String,
      required: true
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false
    },
    isAudioVideoStartedBySomeone: {
      type: Boolean,
      required: false
    },
    IS_VISITOR_SCREEN_SHARING: {
      type: Boolean,
      required: false
    }
  },

  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      micEnabled: 'webrtc/micEnabled',
      videoEnabled: 'webrtc/videoEnabled',
      messageModes: 'webrtc/messageModes',
      messageMode: 'webrtc/messageMode',
      visitorId: 'webrtc/visitorId',
      visitor: 'webrtc/visitor',
      audioVideoStarted: 'webrtc/audioVideoStarted',
      visitorVideoOn: 'webrtc/visitorVideoOn',
      visitorAudioOn: 'webrtc/visitorAudioOn',
      company: 'company',
      campaigns: 'campaigns',
      activeChatVisitor: 'webrtc/visitor'
    }),
    IS_CALL_ACTIVE() {
      return Boolean(this.visitor && this.visitor.connectedAgentId)
    },
    HAS_VISITOR_STARTED_AUDIOVIDEO() {
      return this.visitorVideoOn || this.visitorAudioOn
    },
    SCREEN_HEIGHT() {
      if (this.screen === this.SCREEN.MAXIMIZED) {
        return {
          height: 'calc(100vh)'
        }
      } else {
        return {
          height: '280px'
        }
      }
    },
    SCREEN_SIZE() {
      if (this.screen === this.SCREEN.MAXIMIZED) {
        return {
          width: '100%',
          height: '92%',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
          background: 'white'
        }
      } else {
        return {
          width: '280px',
          height: '245px',
          borderRadius: '6px'
        }
      }
    },

    filteredMessages() {
      if (!this.visitor || !this.visitor.chats) {
        return []
      }

      return this.visitor.chats
    },

    unreadChatMessages() {
      /* eslint-disable implicit-arrow-linebreak */
      return this.filteredMessages.filter(
        (message) =>
          message.sender === 'visitor' && this.hideChatTime && message.time && message.time.seconds && this.hideChatTime < message.time.seconds * 1000
      )
    },
    HAS_B2B_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('b2b-identification') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },

    isSwitchButton() {
      return this.isCameraOptionsList && this.messageModes.includes('video')
    }
  },
  watch: {
    screen() {
      if (this.screen === this.SCREEN.MAXIMIZED) {
        document.getElementById('draggable-modal').className = 'draggable-modal-maximized'
        document.getElementById('draggable-modal').removeAttribute('style')
      }
      if (this.screen === this.SCREEN.MINIMIZED) {
        document.getElementById('draggable-modal').className = 'draggable-modal-minimized'
        document.getElementById('draggable-modal').removeAttribute('style')
      }
    },
    cobrowseStatus() {
      if (this.cobrowseStatus === this.COBROWSE_STATUS.COBROWSING) {
        this.$serverBus.$emit('minimize-screen')
      }
    },
    isAudioVideoStartedBySomeone(val) {
      if (val && !this.isHiddenChatWhenAudioVideoRequestCreated) {
        this.isHiddenChatWhenAudioVideoRequestCreated = true

        if (this.messageModes.includes('chat')) {
          setTimeout(() => {
            this.onClickCommunicationControl('chat')
          })
        }
      }
    }
  },
  mounted() {
    this.$serverBus.$on('close-chat-log', () => {
      this.onClickCommunicationControl('chat')
    })

    if (
      this.isMobile &&
      ((this.isAudioVideoStartedBySomeone && this.messageModes.includes('chat')) || (!this.isAudioVideoStartedBySomeone && this.messageModes.length === 0))
    ) {
      setTimeout(() => {
        this.onClickCommunicationControl('chat')
      })
    }

    const { lastSeenTimestamp } = this.visitor

    this.lastSeenTimestamp = lastSeenTimestamp

    if (!this.wasVideoStarted && this.messageModes.includes('video')) {
      this.wasVideoStarted = true

      if (this.isMobile) {
        this.getCameraList()
      }
    }
  },

  beforeDestroy() {
    if (this.isMobile) {
      this.$serverBus.$emit('set-screen-overflow', false)
    }

    this.$serverBus.$off('close-chat-log')
  },

  methods: {
    gotStream() {
      return navigator.mediaDevices.enumerateDevices()
    },

    async getCameraList() {
      const gotDevices = (deviceInfos) => {
        const cameraOptions = []

        for (let i = 0; i !== deviceInfos.length; ++i) {
          const deviceInfo = deviceInfos[i]
          const option = {}
          option.value = deviceInfo.deviceId

          if (deviceInfo.kind === 'videoinput') {
            option.text = deviceInfo.label || `Camera ${cameraOptions.length + 1}`
            cameraOptions.push(option)
          }
        }

        this.cameraOptions = cameraOptions

        return cameraOptions
      }

      const getSwitchedCamera = (cameraOptions) => {
        if (cameraOptions.length > 1) {
          this.isCameraOptionsList = true
        }
      }

      const catchError = (err) => {
        /* eslint-disable no-console */
        console.log('err: ', err)

        this.cameraOptions = []
        this.isCameraOptionsList = false
      }

      const constraints = {
        video: { deviceId: 'default', width: 1280, height: 720 },
        audio: { deviceId: 'default' }
      }

      navigator.mediaDevices.getUserMedia(constraints).then(this.gotStream).then(gotDevices).then(getSwitchedCamera).catch(catchError)
    },

    handleClickCommunicationControlOnMobile(type) {
      if (this.activeChatVisitor.connectedAgentId) {
        this.onClickCommunicationControl(type)
      }
    },
    goBackToIncomingRequestsList() {
      this.$serverBus.$emit('back-to-visitors-list')
    },
    getVisitorInfo(visitor) {
      if (!visitor) {
        return ''
      }

      if (visitor.b2b && !this.HAS_B2B_ACCESS) {
        return this.$i18n.t('vue.businessConnection')
      }

      if (visitor.visitorCompanyName) {
        return visitor.visitorCompanyName
      }

      if ((visitor.callCenterId && visitor.callCenterName) || (visitor.previousCallCenterId && visitor.previousCallCenterName)) {
        return visitor.dialogName || (visitor.dialogInfo && visitor.dialogInfo.name) || visitor.campaignName
      } else {
        return visitor.companyName || visitor.city
      }
    },
    getDialogOrCampaignName(data) {
      let campaignName = null
      if (data.campaignId && this.campaigns) {
        const hasCampaign = this.campaigns.filter((x) => x.id === data.campaignId).length > 0
        campaignName = hasCampaign ? this.campaigns.filter((x) => x.id === data.campaignId)[0].name : data.campaignName || null
      }

      // Call Center
      if (this.company && this.company.isCallCenter) {
        if ((data.callCenterId && data.callCenterName) || (data.previousCallCenterId && data.previousCallCenterName)) {
          return data.dialogCompanyName || data.campaignCompanyName
        } else {
          return data.dialogName || (data.dialogInfo && data.dialogInfo.name) || data.campaignName
        }
      } else if ((data.callCenterId && data.callCenterName) || (data.previousCallCenterId && data.previousCallCenterName)) {
        return data.callCenterName || data.previousCallCenterName
      } else {
        return data.dialogName || (data.dialogInfo && data.dialogInfo.name) || campaignName
      }
    },
    countryName(countryCode) {
      const countryCodes = this.countryCodes.filter((country) => country.code === (countryCode ? countryCode.toLowerCase() : ''))
      return countryCodes && countryCodes.length > 0 ? countryCodes[0].countryName : ''
    },
    onMaximizeScreen() {
      this.$serverBus.$emit('maximize-screen')
    },
    onMinimizeScreen() {
      this.$serverBus.$emit('minimize-screen')
    },
    onClickCommunicationControl(mode) {
      if (mode === 'chat') {
        this.hideChatTime = new Date().getTime()
      }

      let messageModes = [...this.messageModes]
      // Make sure to scroll to the bottom
      if (mode === 'chat' && !messageModes.includes(mode)) {
        this.$serverBus.$emit('chat-scroll-to-bottom', true)
      }

      if (!this.wasAudioVideoStarted && mode === 'video' && !messageModes.findIndex((item) => ['audio', 'video'].includes(item) > -1)) {
        this.wasAudioVideoStarted = true
        messageModes.push('audio')
      }

      if (!this.wasVideoStarted && mode === 'video') {
        this.wasVideoStarted = true

        if (this.isMobile) {
          this.getCameraList()
        }
      }

      if (!messageModes.includes(mode)) {
        messageModes.push(mode)
      } else {
        messageModes = messageModes.filter((x) => x !== mode)
      }

      if (this.videoEnabled && mode === 'video') {
        messageModes = messageModes.filter((x) => x !== 'video')
      }
      if (this.micEnabled && mode === 'audio') {
        messageModes = messageModes.filter((x) => x !== 'audio')
      }

      this.$emit('change-communication-control', mode, messageModes, true)
    },
    onCloseCommunicationControl() {
      this.$emit('close-communication-control', this.messageMode)
    }
  }
}
</script>
<style lang="scss">
.draggable-modal-minimized {
  width: 280px !important;
  height: 280px !important;
  position: absolute !important;
  top: 100px;
  right: 100px;
  z-index: 99999 !important;
}
.draggable-modal-maximized {
  width: 100% !important;
  height: calc(100vh) !important;
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  background: white !important;
  position: fixed;
  z-index: 99999 !important;
  cursor: default !important;
}
</style>
<style lang="scss" scoped>
.av-call {
  display: flex;
  height: calc(100vh);
  flex-direction: column;
  .red-bk {
    background: #f05541;
  }

  &-top-right {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 9999;
    &-button {
      cursor: pointer;
      width: 30px;
      height: 30px;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      .pa-icon-default {
        color: rgba(var(--vs-primary), 1) !important;
        path {
          stroke: rgba(var(--vs-primary), 1) !important;
        }
      }
    }
    &-button:hover {
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    }
  }
  &-audio {
    position: relative;
    width: 280px;
    height: 280px;
    &-header {
      background: #275D73;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      border-radius: 10px;
    }
  }

  &__communication-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 12px 0 24px;
    width: 100%;
    height: 100px;
    background: #f7f7f6;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    z-index: 99999;

    &_mobile {
      height: 380px;
    }

    .communication-controls {
      &-bar {
        &-button {
          background: white;
        }
      }
    }

    .av-call__communication__btn-toggle-chat {
      padding: 2px;
      background: none;
      border: none;

      &:hover {
        cursor: pointer;
        opacity: 0.95;
      }

      &-wrapper {
        display: flex;
        justify-content: center;
        margin-bottom: 14px;
        width: 100%;
      }
    }
  }

  &--mobile {
    background: rgb(255, 255, 255);
  }

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    padding: 10px 24px;
    background: rgb(235, 245, 254);
    z-index: 99999;

    &__button-back {
      background: none;
      border: none;
    }

    &__info {
      margin-left: 30px;

      &__name {
        color: #262629;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
      }

      &__isp {
        margin-top: 4px;
        color: #262628;
        font-size: 15px;
        font-weight: 700;
        line-height: 18px;
      }

      &__footer {
        margin-top: 4px;
      }

      &__time {
        margin-left: 10px;
        color: #b2b2b2;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    &__controls {
      display: flex;
      align-items: center;
      margin-left: auto;
    }

    &__endcall {
      display: flex;
      position: absolute;
      padding: 5px;
      right: 20px;
      top: 70px;
      border-radius: 6px;
    }
  }
}
.con-vs-dialog.end-conversation-dialog {
  z-index: 100000;
}
</style>
