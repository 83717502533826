var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _c("img", {
        staticStyle: {
          "pointer-events": "none",
          "z-index": "548878",
          top: "0px",
          left: "0px",
          opacity: "0",
          width: "300px",
          height: "200px",
          position: "fixed",
        },
        attrs: {
          id: "background-canvas",
          src: _vm.videoBackgroundImage,
          width: "1280px",
          height: "720px",
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }