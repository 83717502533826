var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chat-header" },
    [
      _c(
        "vs-sidebar",
        {
          staticClass: "visitors-sidebar items-no-padding",
          attrs: {
            "position-right": "",
            parent: "body",
            "default-index": "1",
            color: "primary",
            spacer: "",
          },
          model: {
            value: _vm.showVisitors,
            callback: function ($$v) {
              _vm.showVisitors = $$v
            },
            expression: "showVisitors",
          },
        },
        [
          _vm.isSearchVisitorsOn
            ? _c("SearchVisitors", { key: _vm.searchVisitorsKey })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "vs-sidebar",
        {
          staticClass: "visitors-sidebar items-no-padding",
          attrs: {
            "position-right": "",
            parent: "body",
            "default-index": "1",
            color: "primary",
            spacer: "",
          },
          model: {
            value: _vm.showGptChats,
            callback: function ($$v) {
              _vm.showGptChats = $$v
            },
            expression: "showGptChats",
          },
        },
        [
          _vm.isSearchGptChatsOn
            ? _c("GptChats", { key: _vm.searchGptChatsKey })
            : _vm._e(),
        ],
        1
      ),
      _vm.visitor && _vm.showLinkedCCVisitor && _vm.linkedCCFormFields
        ? _c(
            "LinkedVisitorDraggableModal",
            {
              attrs: {
                show:
                  _vm.visitor &&
                  _vm.showLinkedCCVisitor &&
                  _vm.linkedCCFormFields,
                width: 400,
                height: 600,
                bkcolor: "#F7F7F7",
              },
            },
            [
              _c("LinkedVisitorInfo", {
                attrs: { linkedCCFormFields: _vm.linkedCCFormFields },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "vs-prompt",
        {
          attrs: {
            title: _vm.$t("vue.endConversation"),
            color: "danger",
            "cancel-text": _vm.$t("vue.cancel"),
            "accept-text": _vm.$t("inputs.exit"),
            active: _vm.deletePrompt,
          },
          on: {
            cancel: function ($event) {
              _vm.deletePrompt = false
            },
            accept: _vm.onEndSessionWithVisitor,
            close: function ($event) {
              _vm.deletePrompt = false
            },
            "update:active": function ($event) {
              _vm.deletePrompt = $event
            },
          },
        },
        [
          _c("div", { staticClass: "con-exemple-prompt" }, [
            _c("p", [
              _c("strong", [
                _vm._v(_vm._s(_vm.$t("vue.shouldEndConversationText"))),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: "" + _vm.$t("vue.deleteVisitor"),
            active: _vm.showConfirmDelete,
          },
          on: {
            "update:active": function ($event) {
              _vm.showConfirmDelete = $event
            },
          },
        },
        [
          _c("p", { staticClass: "mb-2" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("vue.confirmQuestion")) + "\n    "
            ),
          ]),
          _c(
            "vs-button",
            {
              staticClass: "mr-2",
              attrs: { color: "#e8546f", type: "filled" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.onDeleteVisitorConfirmed.apply(null, arguments)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("vue.confirm")))]
          ),
          _c(
            "vs-button",
            {
              attrs: { type: "border", color: "#e8546f" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  _vm.showConfirmDelete = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("vue.cancel")))]
          ),
        ],
        1
      ),
      _vm.visitorId
        ? _c(
            "connect-modal",
            {
              staticClass: "visitor-modal",
              attrs: {
                show: _vm.showVisitor,
                width: "100%",
                height: "100%",
                bkcolor: "#F7F7F7",
              },
            },
            [_c("visitor")],
            1
          )
        : _vm._e(),
      _vm.showSettings
        ? _c("audio-video-settings", {
            attrs: {
              show: _vm.showSettings,
              "selected-audio": _vm.selectedAudio,
              "selected-video": _vm.selectedVideo,
              "selected-speaker": _vm.selectedSpeaker,
              "volume-level": _vm.volumeLevel,
            },
            on: {
              close: function ($event) {
                return _vm.onCloseSettings()
              },
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "chat-header-navbar" }, [
        _c(
          "div",
          { staticClass: "chat-header-navbar-left-container" },
          [
            _c("vs-button", {
              staticClass: "chat-header-navbar-back-button",
              attrs: {
                "text-color": "#00A9F5",
                color: "#E8F6FF",
                type: "filled",
                size: "large",
                "icon-pack": "feather",
                icon: "icon-arrow-left",
              },
              on: {
                click: function ($event) {
                  return _vm.$serverBus.$emit("back-to-visitors-list")
                },
              },
            }),
            _c(
              "div",
              {
                staticClass: "chat-header-navbar-button ml-4",
                on: {
                  click: function ($event) {
                    _vm.showSettings = true
                  },
                },
              },
              [
                _c(
                  "vx-tooltip",
                  {
                    staticClass: "inline-flex",
                    attrs: { text: _vm.$t("vue.settings"), position: "top" },
                  },
                  [
                    _c("vs-button", {
                      attrs: {
                        "text-color": "#00A9F5",
                        color: "#E8F6FF",
                        type: "filled",
                        size: "large",
                        "icon-pack": "feather",
                        icon: "icon-settings",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "chat-header-navbar-button ml-4",
                on: { click: _vm.onShowSearchVisitors },
              },
              [
                _c(
                  "vx-tooltip",
                  {
                    staticClass: "inline-flex",
                    attrs: {
                      text: _vm.$t("vue.searchVisitors"),
                      position: "top",
                    },
                  },
                  [
                    _c("vs-button", {
                      attrs: {
                        "text-color": "#00A9F5",
                        color: "#E8F6FF",
                        type: "filled",
                        size: "large",
                        "icon-pack": "feather",
                        icon: "icon-book-open",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "chat-header-navbar-button chat-header-navbar-button--gpt ml-4",
                on: { click: _vm.onShowSearchGptChats },
              },
              [
                _c(
                  "vx-tooltip",
                  {
                    staticClass: "inline-flex",
                    attrs: { text: "GPT Chats", position: "top" },
                  },
                  [
                    _c("gpt-connect-icon", {
                      staticStyle: { "margin-bottom": "2px" },
                      attrs: {
                        width: 19,
                        height: 18,
                        color: "rgb(0, 169, 245)",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            !_vm.audioVideoStarted && _vm.visitorId
              ? _c(
                  "div",
                  { staticClass: "chat-header-navbar-communications-control" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "chat-header-navbar-communications-control-button ml-4",
                        class: {
                          "chat-header-active": _vm.messageMode === "chat",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onClickCommunicationControl("chat")
                          },
                        },
                      },
                      [
                        _c("chat-icon", {
                          class: {
                            "pa-icon-default": _vm.messageMode !== "chat",
                          },
                          attrs: { width: 16, height: 16 },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "chat-header-navbar-communications-control-button",
                        class: _vm.audioButtonClass,
                        on: {
                          click: function ($event) {
                            return _vm.onClickCommunicationControl("audio")
                          },
                        },
                      },
                      [
                        _vm.messageModes.includes("audio") &&
                        !_vm.isAudioDisabled
                          ? _c("microphone-icon", {
                              class: _vm.audioButtonIconClass,
                              attrs: { width: 16, height: 16 },
                            })
                          : _c("microphone-off-icon", {
                              class: _vm.audioButtonIconClass,
                              attrs: { width: 16, height: 16 },
                            }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "chat-header-navbar-communications-control-button",
                        class: _vm.videoButtonClass,
                        on: {
                          click: function ($event) {
                            return _vm.onClickCommunicationControl("video")
                          },
                        },
                      },
                      [
                        _vm.messageModes.includes("video") &&
                        !_vm.isVideoDisabled
                          ? _c("video-call-icon", {
                              class: _vm.videoButtonIconClass,
                              attrs: { width: 16, height: 16 },
                            })
                          : _c("video-call-off-icon", {
                              class: _vm.videoButtonIconClass,
                              attrs: { width: 16, height: 16 },
                            }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _vm.visitor
              ? _c(
                  "div",
                  { staticClass: "chat-header-navbar-visitor-status ml-4" },
                  [
                    _c("VisitorStatusIconWithText", {
                      attrs: {
                        score: _vm.visitor.scoreHighest || _vm.visitor.score,
                        online: _vm.visitor.online,
                        isVegaWidgetEnabled: _vm.visitor.isVegaWidgetEnabled,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.IS_CALL_ACTIVE && _vm.visitorId
              ? _c(
                  "div",
                  {
                    staticClass: "chat-header-navbar-button pa-bk-danger ml-2",
                    on: {
                      click: function ($event) {
                        _vm.deletePrompt = true
                      },
                    },
                  },
                  [
                    _c("phone-icon", {
                      staticClass: "chat-header-navbar-icon",
                      attrs: { width: 18, height: 18 },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _vm.visitorId
          ? _c(
              "div",
              { staticClass: "chat-header-navbar-right-container" },
              [
                _vm.activeUserInfo.role === "admin"
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "chat-header-navbar-button pa-bk-danger mr-2",
                        on: {
                          click: function ($event) {
                            return _vm.onDeleteVisitor()
                          },
                        },
                      },
                      [
                        _c("trash-icon", {
                          staticClass: "chat-header-navbar-icon",
                          attrs: { width: 16, height: 16 },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.IS_VISITOR_FROM_CALL_CENTER_CUSTOMER
                  ? [
                      _c(
                        "div",
                        {
                          staticClass: "chat-header-navbar-button mr-4",
                          class:
                            _vm.visitor.b2b && !_vm.HAS_B2B_ACCESS
                              ? "chat-header-navbar-button-disable"
                              : "",
                          on: {
                            click: function ($event) {
                              return _vm.onShowVisitor()
                            },
                          },
                        },
                        [
                          _c("info-icon", {
                            staticClass:
                              "chat-header-navbar-icon pa-icon-default",
                            attrs: { width: 16, height: 16 },
                          }),
                        ],
                        1
                      ),
                    ]
                  : [
                      _c(
                        "div",
                        {
                          staticClass: "chat-header-navbar-button mr-4",
                          class:
                            _vm.visitor.b2b && !_vm.HAS_B2B_ACCESS
                              ? "chat-header-navbar-button-disable"
                              : "",
                          on: {
                            click: function ($event) {
                              return _vm.onShowLinkedCCVisitor()
                            },
                          },
                        },
                        [
                          _c("info-icon", {
                            staticClass:
                              "chat-header-navbar-icon pa-icon-default",
                            attrs: { width: 16, height: 16 },
                          }),
                        ],
                        1
                      ),
                    ],
              ],
              2
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }