<template>
  <div v-show="IS_VISITOR_SCREEN_SHARING" class="flex flex-col" :class="{ screensharing: IS_VISITOR_SCREEN_SHARING || true }">
    <div class="loader-container" v-if="isLoading">
      <div class="loader"></div>
    </div>
    <video
      v-show="!isLoading && IS_VISITOR_SCREEN_SHARING"
      autoplay
      playsinline
      class="screensharing-video"
      ref="screenShareDisplay"
      id="screenshare-video"
      muted="muted"
      :class="{
        screensharing_bck: IS_VISITOR_SCREEN_SHARING
      }"
    />

    <template v-if="!isMobile">
      <ScreenSharingChat @on-click-communications-controls="onClickCommunicationControl" />
      <ScreenSharingLocalVideo />
      <ScreenSharingToolBar @on-click-communications-controls="onClickCommunicationControl" />
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ScreenSharingToolBar from './messages/ScreenSharingToolBar.vue'
import ScreenSharingChat from './messages/ScreenSharingChat.vue'
import ScreenSharingLocalVideo from './messages/ScreenSharingLocalVideo.vue'

export default {
  components: {
    ScreenSharingToolBar,
    ScreenSharingChat,
    ScreenSharingLocalVideo
  },
  data() {
    return {
      isLoading: true,
      wasAudioVideoStarted: false
    }
  },
  props: {
    remoteStream: {
      type: MediaStream,
      required: false,
      default: null
    },
    isMobile: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      screenSharingStatus: 'webrtc/screenSharingStatus',
      SCREEN_SHARING_STATUS: 'webrtc/SCREEN_SHARING_STATUS',
      visitor: 'webrtc/visitor',
      messageModes: 'webrtc/messageModes'
    }),
    IS_VISITOR_SCREEN_SHARING() {
      return (
        this.screenSharingStatus &&
        this.screenSharingStatus.status === this.SCREEN_SHARING_STATUS.SCREEN_SHARING &&
        this.screenSharingStatus.initiatedBy === 'visitor'
      )
    }
  },
  mounted() {
    if (this.IS_VISITOR_SCREEN_SHARING && this.remoteStream) {
      this.updateVideoSource()
    }
  },
  watch: {
    'screenSharingStatus.status'() {
      if (this.IS_VISITOR_SCREEN_SHARING) {
        this.updateVideoSource()
      }
    },
    remoteStream() {
      if (this.IS_VISITOR_SCREEN_SHARING) {
        this.updateVideoSource()
      }
    }
  },
  methods: {
    updateVideoSource() {
      if (this.$refs.screenShareDisplay && this.remoteStream) {
        this.$refs.screenShareDisplay.srcObject = this.remoteStream
      } else {
        this.$refs.screenShareDisplay.srcObject = null
      }
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
      }, 500)
    },
    onClickCommunicationControl(mode) {
      let messageModes = [...this.messageModes]
      // Make sure to scroll to the bottom
      if (mode === 'chat' && !messageModes.includes(mode)) {
        this.$serverBus.$emit('chat-scroll-to-bottom', true)
      }

      if (!this.wasAudioVideoStarted && mode === 'video' && !messageModes.findIndex((item) => ['audio', 'video'].includes(item) > -1)) {
        this.wasAudioVideoStarted = true
        messageModes.push('audio')
      }

      if (!messageModes.includes(mode)) {
        messageModes.push(mode)
      } else {
        messageModes = messageModes.filter((x) => x !== mode)
      }

      if (this.videoEnabled && mode === 'video') {
        messageModes = messageModes.filter((x) => x !== 'video')
      }
      if (this.micEnabled && mode === 'audio') {
        messageModes = messageModes.filter((x) => x !== 'audio')
      }
      this.$emit('change-communication-control', mode, messageModes, true)
    }
  }
}
</script>
<style lang="scss" scoped>
.screensharing {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 54000;

  &-toolbar {
    margin-top: auto;
  }
  &-video {
    object-fit: contain;
    width: calc(100vw);
    height: calc(100vh);
  }
  &_bck {
    background-color: #3034f7;
  }

  .loader-container {
    //background-image: url('https://app.letsconnect.at/favicon.ico');
    background-color: #3034f7;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    .loader,
    .loader:after {
      border-radius: 50%;
      width: 10em;
      height: 10em;
    }
    .loader {
      margin: 60px auto;
      font-size: 10px;
      text-indent: -9999em;
      border-top: 1.1em solid rgba(255, 255, 255, 0.2);
      border-right: 1.1em solid rgba(255, 255, 255, 0.2);
      border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
      border-left: 1.1em solid #ffffff;
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-animation: load8 1.1s infinite linear;
      animation: load8 1.1s infinite linear;
    }
    @-webkit-keyframes load8 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes load8 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
}
</style>
