<template>
  <svg :width="width" :height="height" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_7357_1544)">
      <rect opacity="0.9" x="2" y="1" width="35" height="35" rx="6" fill="#EBF5FE"/>
      <path d="M24.8863 15.4678V18.1295H22.2246" stroke="#275D73" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15.127 22.566V19.9043H17.7887" stroke="#275D73" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16.2404 17.6861C16.4654 17.0503 16.8478 16.4818 17.3519 16.0338C17.856 15.5857 18.4654 15.2727 19.1232 15.1238C19.7811 14.975 20.4659 14.9952 21.1137 15.1826C21.7616 15.37 22.3515 15.7185 22.8283 16.1955L24.8867 18.1297M15.127 19.9042L17.1854 21.8384C17.6621 22.3154 18.252 22.6639 18.8999 22.8513C19.5478 23.0387 20.2326 23.0589 20.8904 22.9101C21.5482 22.7612 22.1576 22.4482 22.6617 22.0001C23.1658 21.5521 23.5482 20.9836 23.7732 20.3478" stroke="#275D73" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M31 25C31 25.5304 30.7893 26.0391 30.4142 26.4142C30.0391 26.7893 29.5304 27 29 27H11C10.4696 27 9.96086 26.7893 9.58579 26.4142C9.21071 26.0391 9 25.5304 9 25V14C9 13.4696 9.21071 12.9609 9.58579 12.5858C9.96086 12.2107 10.4696 12 11 12H15L17 9H23L25 12H29C29.5304 12 30.0391 12.2107 30.4142 12.5858C30.7893 12.9609 31 13.4696 31 14V25Z" stroke="#275D73" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <filter id="filter0_d_7357_1544" x="0" y="0" width="39" height="39" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7357_1544"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7357_1544" result="shape"/>
      </filter>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'CameraSwitchIcon',
  props: {
    width: {
      type: Number,
      default: 35,
      required: false
    },
    height: {
      type: Number,
      default: 35,
      required: false
    }
  }
}
</script>
