var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.width,
        viewBox: "0 0 66 66",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _vm.hover
        ? _c("circle", {
            attrs: {
              cx: _vm.cx,
              cy: _vm.cy,
              r: _vm.cx,
              fill: _vm.stroke,
              "fill-opacity": "0.2",
            },
          })
        : _vm._e(),
      _c("path", {
        attrs: {
          d: "M32.9441 0.179688C15.0199 0.179688 0.444092 14.7555 0.444092 32.6797C0.444092 50.6039 15.0199 65.1797 32.9441 65.1797C50.8683 65.1797 65.4441 50.6039 65.4441 32.6797C65.4441 14.7555 50.8683 0.179688 32.9441 0.179688ZM32.9441 4.41907C48.5772 4.41907 61.205 17.0469 61.205 32.6799C61.205 48.313 48.5772 60.9408 32.9441 60.9408C17.311 60.9408 4.68322 48.313 4.68322 32.6799C4.68322 17.0469 17.311 4.41907 32.9441 4.41907ZM21.6395 18.5495C18.9083 18.5495 16.694 20.7638 16.694 23.495C16.694 26.2262 18.9083 28.4406 21.6395 28.4406C24.3707 28.4406 26.585 26.2262 26.585 23.495C26.585 20.7638 24.3707 18.5495 21.6395 18.5495ZM44.2482 18.5495C41.517 18.5495 39.3027 20.7638 39.3027 23.495C39.3027 26.2262 41.517 28.4406 44.2482 28.4406C46.9794 28.4406 49.1937 26.2262 49.1937 23.495C49.1937 20.7638 46.9794 18.5495 44.2482 18.5495ZM17.1796 39.0386C16.4985 39.0781 15.8771 39.463 15.5015 40.1426C15.1437 40.8261 15.192 41.6959 15.634 42.3283C19.4229 47.9958 25.7701 51.7562 32.9438 51.7562C40.1175 51.7562 46.4649 47.9958 50.2536 42.3283C50.8721 41.4037 50.6045 40.0105 49.6795 39.392C48.7544 38.7736 47.3395 39.0411 46.721 39.9662C43.661 44.5435 38.6445 47.5174 32.9438 47.5174C27.2432 47.5174 22.2268 44.5442 19.1667 39.9662C18.593 39.293 17.8606 38.9992 17.1796 39.0386Z",
          fill: _vm.stroke,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }