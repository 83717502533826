<template>
  <div class="search-visitors">
    <div id="visitor-search-connected-agent-info" style="position: fixed; z-index: 54000; top: 50%; left: 100%"></div>
    <div class="search-visitors--action">
      <input type="text" name="search" :placeholder="$t('vue.searchVisitors')" class="search-visitors--input w-full mr-2" v-model="searchInput" />
      <vs-button class="search-visitors--close-button ml-2" color="#eaeeea" @click.prevent="onCloseSearchGptChats">
        <CloseIcon :width="18" :height="18" color="#53535B" />
      </vs-button>
    </div>
    <vs-progress v-if="isLoading" indeterminate color="primary">primary</vs-progress>
    <div class="search-visitors--container">
      <!-- LEFT COLUMN : VISITORS -->
      <visitors
        :visitors="filteredVisitors"
        @update-active-chat-visitor="updateActiveChatUser"
        @load-more="loadMore"
        :isLoadMoreDisabled="isLoadMoreDisabled"
      />
      <!-- RIGHT COLUMN : MESSAGES -->
      <messages />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import CloseIcon from '@/components/icons/CloseIcon'
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

import Visitors from './Visitors.vue'
import Messages from './Messages.vue'
export default {
  name: 'SearchVisitors',
  components: {
    CloseIcon,
    Visitors,
    Messages
  },
  data() {
    return {
      query: null,
      loadMoreQuery: null,
      isLoading: false,
      searchInput: ''
    }
  },
  computed: {
    ...mapGetters({
      isSearchVisitorsOn: 'isSearchVisitorsOn',
      dialogs: 'dialogs',
      visitors: 'searchGptChats',
      campaigns: 'campaigns',
      company: 'company',
      widgetsAssigned: 'widgetsAssigned',
      activeUserInfo: 'activeUser',
      widgetsFilterForVisitor: 'widgetsFilterForVisitor'
    }),
    isLoadMoreDisabled() {
      return this.isLoading || this.visitors.length === 0
    },
    filteredVisitors() {
      let visitors = this.visitors

      const search = this.searchInput.toLowerCase().trim()
      if (search.length > 0) {
        visitors = visitors.filter((x) => {
          const foundCity = search.length > 0 && x.city && x.city.toLowerCase().indexOf(search) > -1

          const foundCountry = search.length > 0 && x.country && x.country.toLowerCase().indexOf(search) > -1

          const foundISP = search.length > 0 && x.companyName && x.companyName.toLowerCase().indexOf(search) > -1

          const foundName = search.length > 0 && x.name && x.name.toLowerCase().indexOf(search) > -1

          const foundNetName = search.length > 0 && x.netname && x.netname.toLowerCase().indexOf(search) > -1

          const foundRegion = search.length > 0 && x.region && x.region.toLowerCase().indexOf(search) > -1

          const filteredDialogs =
            search.length > 0 &&
            this.dialogs
              .map((f) => {
                return { name: f.name && f.name.toLowerCase(), id: f.id }
              })
              .filter((x) => x.name.indexOf(search) > -1)
          const foundDialog = search.length > 0 && filteredDialogs.filter((d) => d.id === x.dialogId).length > 0

          const foundChats =
            !x.chats || search.length === 0 ? false : x.chats.filter((x) => x.message && x.message.toLowerCase().indexOf(search) > -1).length > 0

          return foundCity || foundCountry || foundISP || foundName || foundNetName || foundRegion || foundDialog || foundChats
        })
      }

      return visitors
    }
  },
  async mounted() {
    this.isLoading = true
    this.setSearchQuery('search')
    if (this.visitors.length === 0 || !window.__pa_last_gptchats_search_doc) {
      await this.searchGptChats()
    }

    setTimeout(() => {
      this.isLoading = false
    }, 500)
  },
  methods: {
    ...mapMutations({
      setIsSearchVisitors: 'SET_IS_SEARCH_VISITORS',
      setIsSearchGptChats: 'SET_IS_SEARCH_GPT_CHATS',
      setSearchGptChats: 'SET_SEARCH_GPT_CHATS',
      setVisitorId: 'webrtc/setVisitorId',
      setVisitor: 'webrtc/setVisitor'
    }),

    async searchGptChats() {
      this.setSearchGptChats([])
      const _gptChatsRef = await this.query.get()
      const documents = _gptChatsRef.docs

      window.__pa_last_gptchats_search_doc = documents[documents.length - 1]

      _gptChatsRef.docs.map(async (_gptChatRef) => {
        const data = _gptChatRef.data()
        data.id = _gptChatRef.id

        const visitorRef = await this.$db.collection('visitors').doc(data.id).get()
        const visitorData = visitorRef.data()
        visitorData.id = data.id
        const messages = visitorData && visitorData.chats ? visitorData.chats : []
        const gptMessages = data && data.chats ? data.chats : []

        const chats = [...messages, ...gptMessages].sort((a, b) => a.time - b.time)
        visitorData.chats = chats

        this.processData(visitorData)

        this.visitors.push(visitorData)
      })
    },

    setSearchQuery(type) {
      let query = null
      query = this.$db.collection('gpt-chats').where('companyId', '==', this.activeUserInfo.company)

      query = query.where('chats', '>', [])
      if (this.activeUserInfo.role === 'agent' && this.widgetsAssigned && this.widgetsAssigned.length > 0) {
        query = query.where('dialogId', 'in', this.widgetsAssigned)
      }
      if (this.activeUserInfo.role === 'admin' && this.widgetsFilterForVisitor && this.widgetsFilterForVisitor.length > 0) {
        query = query.where('dialogId', 'in', this.widgetsFilterForVisitor)
      }
      query = query.orderBy('chats', 'desc').limit(30)
      if (type === 'search') {
        this.query = query
        this.setSearchQuery('loadmore')
      } else {
        this.loadMoreQuery = query
      }
    },
    onCloseSearchVisitors() {
      this.setIsSearchVisitors(false)
    },
    onCloseSearchGptChats() {
      this.setIsSearchGptChats(false)
    },
    async loadMore() {
      this.isLoading = true
      this.loadMoreQuery = this.loadMoreQuery.startAfter(window.__pa_last_gptchats_search_doc)
      const _gptChatsRef = await this.loadMoreQuery.get()
      const documents = _gptChatsRef.docs
      if (documents && documents.length > 0) {
        window.__pa_last_gptchats_search_doc = documents[documents.length - 1]
        _gptChatsRef.docs.map(async (gptChatsRef) => {
          const data = gptChatsRef.data()
          data.id = gptChatsRef.id

          const visitorRef = await this.$db.collection('visitors').doc(data.id).get()
          const visitorData = visitorRef.data()
          visitorData.id = data.id
          const messages = visitorData && visitorData.chats ? visitorData.chats : []
          const gptMessages = data && data.chats ? data.chats : []

          const chats = [...messages, ...gptMessages].sort((a, b) => a.time - b.time)
          visitorData.chats = chats

          this.processData(visitorData)

          this.visitors.push(visitorData)
        })
      }

      setTimeout(() => {
        this.isLoading = false
      }, 500)
    },

    processData(data) {
      data.name = data.owner || data.isp || data.netname
      data.city = data.city ? data.city.charAt(0).toUpperCase() + data.city.slice(1) : null
      data.scoring = data.score ? data.score * 10 : 40

      let lastSeen = null
      let lastSeenTimestamp = null
      if (data.modified) {
        lastSeen = dayjs.unix(data.modified.seconds).locale(this.$i18n.locale).fromNow()
        lastSeenTimestamp = dayjs.unix(data.modified.seconds).locale(this.$i18n.locale).format('LLL')
      }
      if (data.disconnect) {
        lastSeen = dayjs(data.disconnect.toDate()).locale(this.$i18n.locale).fromNow()
        lastSeenTimestamp = dayjs(data.disconnect.toDate()).tz(this.$defaultTimezone).format('LLL')
      }
      if (data.lastRequestDate) {
        lastSeen = dayjs.unix(data.lastRequestDate.seconds).locale(this.$i18n.locale).fromNow()
        lastSeenTimestamp = dayjs.unix(data.lastRequestDate.seconds).locale(this.$i18n.locale).format('LLL')
      }

      data.lastSeen = lastSeen
      data.lastSeenTimestamp = lastSeenTimestamp
    },
    async updateActiveChatUser(visitorId) {
      const visitor = this.visitors.find((x) => x.id === visitorId)
      if (visitor) {
        this.setVisitorId(visitorId)
        this.setVisitor(visitor)
      }
    }
  },
  beforeDestroy() {
    this.setVisitor(null)
    this.setVisitorId(null)
    this.$serverBus.$off('show-search-connected-agent-info')
  }
}
</script>
<style lang="scss" scoped>
.search-visitors {
  position: relative;
  display: flex;
  flex-direction: column;

  &--action {
    display: flex;
    flex-direction: row;
    padding: 10px;
    border-bottom-width: 1px;
    border-bottom-color: #ececec;
    border-bottom-style: solid;
  }

  &--input {
    border-radius: 6px;
    padding: 5px 10px;
    border-width: 1px;
    border-color: #ececec;
  }

  &--close-button {
    padding: 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    //position: absolute;
    // top: 10px;
    // right: 10px;
    width: 35px;
    height: 35px;

    ::v-deep .vs-button-text {
      width: 18px !important;
      height: 18px !important;
    }
  }

  &--container {
    background: #ffffff;
    border-radius: 6px;
    font-family: 'Lato';
    display: flex;
    flex-direction: row;
  }
}
</style>
