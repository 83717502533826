<template>
  <vs-input class="search-visitor-input" icon="search" v-model="searchText" :disabled="HAS_CONVERSATION_STARTED" />
</template>
<script>
const CONVERSATION_STATUS = {
  STOPPED: 'stopped',
  STARTED: 'started'
}
export default {
  data() {
    return {
      searchText: this.search,
      CONVERSATION_STATUS
    }
  },
  props: {
    conversationStatus: {
      type: String,
      required: true
    }
  },
  computed: {
    HAS_CONVERSATION_STARTED() {
      return this.conversationStatus === this.CONVERSATION_STATUS.STARTED
    }
  },
  inject: ['search'],
  emits: ['search-visitor'],
  watch: {
    searchText() {
      this.$parent.$emit('search-visitor', this.searchText)
    }
  }
}
</script>
<style lang="scss">
.search-visitor-input {
  &.vs-con-input-label .vs-con-input {
    .vs-input--icon {
      display: flex;
      align-items: center;
      top: 0;
      bottom: 0;
    }
  }
}
</style>
