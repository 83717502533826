var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "communication-controls",
      class: { "communication-controls--mobile": _vm.isMobile },
    },
    [
      _c("div", { staticClass: "communication-controls-bar" }, [
        _c(
          "div",
          {
            staticClass: "communication-controls-bar-button",
            class: {
              "pa-bk-active": _vm.messageModes.includes("chat"),
              "communication-controls-bar-button--unread":
                !_vm.messageModes.includes("chat") &&
                _vm.unreadChatMessages.length,
            },
            on: {
              click: function ($event) {
                return _vm.onCommunicationControlsClick("chat")
              },
            },
          },
          [
            _c(
              "chat-icon",
              {
                class: {
                  "pa-icon-default":
                    !_vm.messageModes.includes("chat") &&
                    !_vm.unreadChatMessages.length,
                },
                attrs: { width: 16, height: 16 },
              },
              [
                !_vm.messageModes.includes("chat") &&
                _vm.unreadChatMessages.length
                  ? _c("div", {
                      staticClass:
                        "communication-controls-bar-button__chat-unread-count",
                    })
                  : _vm._e(),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "communication-controls-bar-button",
            class: _vm.audioButtonClass,
            on: {
              click: function ($event) {
                return _vm.onCommunicationControlsClick("audio")
              },
            },
          },
          [
            !_vm.isAudioDisabled &&
            (_vm.messageModes.includes("audio") || _vm.micEnabled)
              ? _c("microphone-icon", {
                  class: _vm.audioButtonIconClass,
                  attrs: { width: 16, height: 16 },
                })
              : _c("microphone-off-icon", {
                  class: _vm.audioButtonIconClass,
                  attrs: { width: 16, height: 16 },
                }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "communication-controls-bar-button",
            class: _vm.videoButtonClass,
            on: {
              click: function ($event) {
                return _vm.onCommunicationControlsClick("video")
              },
            },
          },
          [
            !_vm.isVideoDisabled &&
            (_vm.messageModes.includes("video") || _vm.videoEnabled)
              ? _c("video-call-icon", {
                  class: _vm.videoButtonIconClass,
                  attrs: { width: 16, height: 16 },
                })
              : _c("video-call-off-icon", {
                  class: _vm.videoButtonIconClass,
                  attrs: { width: 16, height: 16 },
                }),
          ],
          1
        ),
      ]),
      _vm.isMobile
        ? _c("div", { staticClass: "flex flex-row" }, [
            _c(
              "div",
              {
                staticClass:
                  "video-screen-minimized-navbar-top-right-button red-bk",
                on: {
                  click: function ($event) {
                    return _vm.$emit("on-close-communication-control")
                  },
                },
              },
              [_c("phone-icon", { attrs: { width: 20, height: 20 } })],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }