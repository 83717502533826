<template>
  <svg :width="width" :height="height" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="25" cy="25" r="24" :stroke="color" stroke-width="2" />
    <line x1="43.936" y1="10.182" x2="9.47653" y2="43.2901" :stroke="color" stroke-width="2" />
  </svg>
</template>

<script>
export default {
  name: 'EmptyIcon',
  props: {
    color: {
      type: String,
      default: '#12598D'
    },
    width: {
      type: Number,
      default: 50
    },
    height: {
      type: Number,
      default: 50
    }
  }
}
</script>
