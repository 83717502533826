var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "visitor-list" },
    [
      _c(
        "vs-prompt",
        {
          attrs: {
            title: _vm.$t("modals.deleteSelectedRequests"),
            color: "danger",
            "cancel-text": _vm.$t("vue.cancel"),
            "accept-text": _vm.$t("vue.delete"),
            active: _vm.showConfirmDelete,
          },
          on: {
            cancel: _vm.onCancelDelete,
            accept: _vm.onDeleteVisitorsConfirmed,
            close: _vm.onCancelDelete,
            "update:active": function ($event) {
              _vm.showConfirmDelete = $event
            },
          },
        },
        [
          _c("div", { staticClass: "con-exemple-prompt" }, [
            _c("p", [
              _c("strong", [
                _vm._v(_vm._s(_vm.$t("vue.deleteAccountWarningMessage"))),
              ]),
            ]),
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("modals.deleteSelectedRequestsQuestion")) +
                "\n    "
            ),
          ]),
        ]
      ),
      _c("agent-header", {
        attrs: {
          "select-visitors": _vm.selectVisitors,
          "selected-audio": _vm.selectedAudio,
          "selected-video": _vm.selectedVideo,
          "selected-speaker": _vm.selectedSpeaker,
          "volume-level": _vm.volumeLevel,
          isMobile: _vm.isMobile,
        },
        on: {
          "select-visitors": function ($event) {
            return _vm.onSelectVisitors()
          },
        },
      }),
      _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass:
                "visitor-list__incoming-title flex flex-row align-items-center mb-5 mt-8",
            },
            [
              _c("div", [
                _c("h3", { staticClass: "visitor-list-heading" }, [
                  _vm._v(_vm._s(_vm.$t("routes.incomingRequests"))),
                ]),
              ]),
              _vm.selectVisitors && _vm.activeUserInfo.role === "admin"
                ? _c(
                    "div",
                    { staticStyle: { "margin-left": "auto" } },
                    [
                      _c(
                        "vx-tooltip",
                        {
                          staticClass: "inline-flex mr-4",
                          attrs: {
                            text: _vm.$t("vue.delete"),
                            position: "top",
                          },
                        },
                        [
                          _c("vs-button", {
                            attrs: {
                              color: "#F05541",
                              type: "border",
                              size: "medium",
                              "icon-pack": "feather",
                              icon: "icon-trash",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onDeleteVisitors()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c("scroll-view", { staticClass: "visitor-list-scroll-area" }, [
            _c(
              "ul",
              { staticClass: "visitor-list__ul" },
              _vm._l(_vm.visitors, function (_visitor, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    staticClass: "cursor-pointer",
                    attrs: { id: "incoming-request-" + _visitor.id },
                    on: {
                      click: function ($event) {
                        return _vm.$emit(
                          "update-active-chat-visitor",
                          _visitor.id,
                          _vm.selectVisitors,
                          false,
                          "select-active-chat-visitor"
                        )
                      },
                    },
                  },
                  [
                    _c("visitor", {
                      attrs: {
                        isSelectVisitors: _vm.selectVisitors,
                        visitor: _visitor,
                        isMobile: _vm.isMobile,
                      },
                      on: {
                        "start-communication-with-visitor": function ($event) {
                          return _vm.$emit(
                            "start-communication-with-visitor",
                            _visitor.id,
                            _vm.selectVisitors,
                            false,
                            "start-communication-with-visitor"
                          )
                        },
                        "update-active-chat-visitor": function ($event) {
                          return _vm.$emit(
                            "update-active-chat-visitor",
                            _visitor.id,
                            false,
                            false,
                            "update-active-chat-visitor"
                          )
                        },
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }