var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "send-chat-message w-full",
      class: {
        "send-chat-message--mobile": _vm.isMobile,
      },
    },
    [
      _c(
        "div",
        {
          ref: "sendChatMessageContainer",
          staticClass: "send-chat-message-container flex flex-col",
        },
        [
          _c(
            "div",
            { staticClass: "send-chat-message-container-top w-full relative" },
            [
              _vm.hasFilesizeError
                ? _c(
                    "div",
                    { staticClass: "send-chat-message-container-top__error" },
                    [_vm._v(_vm._s(_vm.$t("errors.chatFileUploadLimit")))]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "w-full" },
                [
                  _vm.isDropzoneVisible && !_vm.isFileSharingDisabled
                    ? _c("message-dropzone", {
                        ref: "filesDropzone",
                        on: {
                          "files-added": _vm.onFilesAdded,
                          "on-reset": _vm.hideDropzone,
                          "vd-drag-enter": function ($event) {
                            _vm.isDropzoneVisible = true
                          },
                          "vd-drag-over": function ($event) {
                            _vm.isDropzoneVisible = true
                          },
                          "vd-drag-drop": function ($event) {
                            _vm.isDropzoneVisible = true
                          },
                        },
                      })
                    : _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.typedMessage,
                            expression: "typedMessage",
                          },
                        ],
                        staticClass: "send-chat-message-container-input",
                        class: {
                          "focus-input": _vm.isModal,
                        },
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t("vue.writeMessage"),
                        },
                        domProps: { value: _vm.typedMessage },
                        on: {
                          click: _vm.scrollToBottom,
                          keyup: [
                            function ($event) {
                              return _vm.onAgentIsTyping()
                            },
                            function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              if (
                                $event.ctrlKey ||
                                $event.shiftKey ||
                                $event.altKey ||
                                $event.metaKey
                              ) {
                                return null
                              }
                              $event.preventDefault()
                              return _vm.sendChatMessage()
                            },
                          ],
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.typedMessage = $event.target.value
                          },
                        },
                      }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "send-chat-message-container-button",
                  on: {
                    click: function ($event) {
                      return _vm.sendChatMessage()
                    },
                  },
                },
                [
                  _c("send-icon", {
                    staticClass: "send-chat-message-container-icon",
                    class: {
                      "pa-icon-default": _vm.canSendMessage,
                      "pa-icon-disabled": !_vm.canSendMessage,
                    },
                    attrs: { width: 25, height: 25 },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm.files && _vm.files.length
            ? _c(
                "div",
                {
                  staticClass: "flex -mb-4 flex-wrap",
                  class: {
                    "pt-1 px-2": _vm.isMobile,
                    "pt-2 px-0": !_vm.isMobile,
                  },
                },
                _vm._l(_vm.files, function (file, index) {
                  return _c("message-file", {
                    key: index,
                    staticClass: "mb-4",
                    attrs: { file: file, index: index },
                  })
                }),
                1
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }