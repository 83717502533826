<template>
  <connect-modal class="audio-video-settings" :class="{ 'audio-video-settings--mobile': isMobile }" :bkcolor="isMobile ? '#FFFFFF' : '#F7F7F7'" :show="show">
    <div class="audio-video-settings__inner">
      <vs-popup style="z-index: 999999" fullscreen :title="$t('vue.cropImage')" :active.sync="showCroppa" button-close-hidden class="image-popup">
        <div class="relative">
          <div disabled style="cursor: pointer" @click="showCroppa = false">
            <div class="flex justify-center items-center check-circle-icon" @click="onCroppaSave">
              <check-circle-icon color="#E8F6FF" :width="35" :height="35"></check-circle-icon>
            </div>
          </div>

          <croppa
            v-show="myPhoto.img"
            v-model="myPhoto"
            placeholder="Click here"
            :show-remove-button="false"
            canvas-color="transparent"
            prevent-white-space
            initial-size="cover"
            :image-border-radius="10"
            accept=".jpeg,.jpg,.gif,.png"
            :disable-drag-to-move="false"
            :file-size-limit="2048000"
            :placeholder-font-size="14"
            :disabled="false"
            :width="1280"
            :height="720"
            @init="onCroppaInit"
            @file-type-mismatch="onFileTypeMismatch"
            @file-size-exceed="onFileSizeExceed"
            @file-choose="onFileChoose"
            class="resizable-croppa"
          >
          </croppa>
        </div>
      </vs-popup>

      <input type="hidden" id="hdnVideo" :value="JSON.stringify(selectedVideo)" />
      <input type="hidden" id="hdnAudio" :value="JSON.stringify(selectedAudio)" />
      <input type="hidden" id="hdnSpeaker" :value="JSON.stringify(selectedSpeaker)" />
      <div v-show="!isChangeVirtualBackground">
        <div class="flex flex-row space-between">
          <div v-if="!isMobile" class="audio-video-settings-icon-settings">
            <settings-icon class="audio-video-settings-icon settings-icon" :width="20" :height="20"></settings-icon>
          </div>
          <div
            v-if="!isFirstSetupOnMobile || isRequestedPermissions"
            class="audio-video-settings-button"
            style="margin-left: auto"
            @click="onCloseAudioVideoSettings"
          >
            <close-circle-icon
              v-if="!isMobile"
              class="audio-video-settings-icon pa-bk-secondary"
              :show-circle="false"
              :width="14"
              :height="14"
            ></close-circle-icon>
            <close-icon v-else class="mt-6 mb-6" color="#626262" :width="30" :height="30" />
          </div>
        </div>
        <div class="audio-video-settings-heading" :class="{ 'mt-0': isFirstSetupOnMobile }">{{ $t('vue.videoAudioSettings') }}</div>
        <div class="flex flex-row audio-video-settings-av-settings-wrapper">
          <div class="flex flex-col audio-video-settings-av-settings">
            <div class="relative">
              <div :style="`width: ${currentVideoWidth}px; height: ${currentVideoHeight}px; margin-left: auto; margin-right: auto;`">
                <div>
                  <img
                    id="settings-video-background"
                    :src="videoBackgroundImage"
                    width="1280px"
                    height="720px"
                    :style="`pointer-events: none; z-index: 548878; top: 0px; left: 0px; opacity: 0; width: ${currentVideoWidth}px; height: ${currentVideoHeight}px; position: fixed`"
                  />
                </div>
                <video
                  v-show="isCameraOn"
                  ref="videoCam"
                  id="video-cam"
                  :width="currentVideoWidth"
                  :height="currentVideoHeight"
                  autoplay
                  playsinline
                  muted
                  class="video"
                  :style="`${isMobile ? 'border-radius: 6px;' : ''}`"
                ></video>
                <canvas
                  v-show="isCameraOn"
                  class="audio-video-settings-av-settings__canvas-video-output"
                  :style="`position: absolute; pointer-events: none; z-index: 58000; top: 0px; right: 0px; width: ${currentVideoWidth}px; ${
                    isMobile ? 'max-width: 100%;' : ''
                  } height: ${currentVideoHeight}px; border-radius: 6px`"
                  width="1280px"
                  height="720px"
                  id="video-output"
                >
                </canvas>
                <div v-if="!isCameraOn" class="audio-video-settings-no-camera flex justify-center items-center">
                  <div class="audio-video-settings-no-camera-container flex justify-center items-center relative">
                    <settings-video-cam-off color="#ffffff" :width="187" :height="168.75" style="position: absolute"> </settings-video-cam-off>
                  </div>
                </div>
              </div>

              <div
                v-if="!isMobile"
                class="flex flex-row items-center audio-video-settings-pointer audio-video-settings-pointer-blurtext-button"
                :class="{
                  'mt-2 justify-center': !isMobile
                }"
                @click="onChangeVirtualBackground()"
              >
                <magic-icon :color="isMobile ? '#262629' : isCameraOn ? '#275D73' : '#B2B2B2'" :width="isMobile ? 19 : 16" :height="isMobile ? 19 : 16" />
                <div class="ml-5 audio-video-settings-blurtext" :class="!isCameraOn ? 'audio-video-settings-disabled' : ''">
                  {{ $t('vue.changeVirtualBackground') }}
                </div>
              </div>
            </div>
          </div>

          <div v-if="isFirstSetupOnMobile && !isRequestedPermissions">
            <p class="mt-3">{{ $t('buttonAndHints.permissionsWording5') }}</p>
            <p class="mt-3">{{ $t('buttonAndHints.permissionsWording6') }}</p>

            <div class="mt-5 text-center">
              <vs-button class="mb-base" color="primary" type="filled" @click="requestPermissions">
                {{ $t('buttonAndHints.requestPermissions') }}
              </vs-button>
            </div>
          </div>

          <div v-else class="ml-4 flex flex-col justify-center audio-video-settings-av-settings-options">
            <div>
              <div class="mt-2" v-if="speakerOptions.length > 0 || microphoneOptions.length > 0">
                <span v-if="isMobile">{{ $t('vue.mic') }}</span>
                <span v-else>{{ $t('vue.microphoneAndSpeakers') }}</span>
              </div>
              <div class="mt-2 flex flex-row flex-grow bk-select" v-if="microphoneOptions.length > 0">
                <div
                  :class="isMicrophoneOn ? 'audio-video-settings-communication-button on' : 'audio-video-settings-communication-button off'"
                  @click="toggleMicrophone"
                >
                  <microphone-icon
                    v-if="selectedAudio && selectedAudio.value !== 'microphone-off'"
                    class="audio-video-settings-communication-icon"
                    :width="isMobile ? 19 : 16"
                    :height="isMobile ? 19 : 16"
                  ></microphone-icon>
                  <microphone-off-icon
                    v-if="!selectedAudio || selectedAudio.value === 'microphone-off'"
                    class="audio-video-settings-communication-icon"
                    :width="isMobile ? 19 : 16"
                    :height="isMobile ? 19 : 16"
                    color="#275D73"
                  >
                  </microphone-off-icon>
                </div>
                <div class="w-full custom-select-wrapper">
                  <connect-select
                    :options="microphoneOptions"
                    :default="selectedAudio"
                    @input-selected="onAudioInputSelected"
                    @input-click="onMicrophoneClicked"
                    @input-blur="onMicrophoneSelectBlur"
                  ></connect-select>
                </div>
              </div>

              <template v-if="!isMobile && speakerOptions.length > 0">
                <div class="mt-4">
                  <div class="flex flex-row flex-grow bk-select">
                    <div
                      :class="isSpeakerOn ? 'audio-video-settings-communication-button on' : 'audio-video-settings-communication-button off'"
                      @click="toggleSpeaker"
                    >
                      <speaker-on-icon
                        v-if="isSpeakerOn"
                        class="audio-video-settings-communication-icon"
                        color="#ffffff"
                        :width="16"
                        :height="16"
                      ></speaker-on-icon>
                      <speaker-off-icon v-else class="audio-video-settings-communication-icon" color="#275D73" :width="16" :height="16"></speaker-off-icon>
                    </div>
                    <div class="w-full custom-select-wrapper">
                      <connect-select
                        :options="speakerOptions"
                        :default="selectedSpeaker"
                        @input-selected="onAudioOutputSelected"
                        @input-click="onSpeakerClicked"
                        @input-blur="onSpeakerSelectBlur"
                        v-show="!hideSpeaker"
                      ></connect-select>
                    </div>
                  </div>
                </div>
              </template>

              <div class="mt-6" v-if="cameraOptions.length > 1">
                <span>{{ $t('vue.camera') }}</span>
              </div>

              <div class="mt-2">
                <div class="flex flex-row flex-grow bk-select">
                  <div
                    :class="isCameraOn ? 'audio-video-settings-communication-button on' : 'audio-video-settings-communication-button off'"
                    @click="toggleCamera"
                  >
                    <video-call-icon
                      v-if="isCameraOn"
                      class="audio-video-settings-communication-icon"
                      :width="isMobile ? 19 : 16"
                      :height="isMobile ? 19 : 16"
                    ></video-call-icon>
                    <video-call-off-icon
                      v-else
                      class="audio-video-settings-communication-icon"
                      color="#275D73"
                      :width="isMobile ? 19 : 16"
                      :height="isMobile ? 19 : 16"
                    ></video-call-off-icon>
                  </div>
                  <div class="w-full custom-select-wrapper">
                    <connect-select
                      :options="cameraOptions"
                      :default="selectedVideo"
                      @input-selected="onVideoInputSelected"
                      v-show="!hideCamera"
                    ></connect-select>
                  </div>
                </div>
              </div>

              <div v-if="isFirstSetupOnMobile" class="mt-6 text-center">
                <vs-button class="mb-base" color="primary" type="filled" @click="onCloseAudioVideoSettings">
                  {{ $t('vue.permissionsNotice.denied.btn') }}
                </vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="isChangeVirtualBackground">
        <div class="flex flex-row space-between">
          <div @click="onBackToSettings()" class="flex justify-center items-center audio-video-settings-pointer">
            <back-icon class="audio-video-settings-icon pa-icon-default mr-2" :width="7" :height="12"></back-icon>
            <div class="audio-video-settings-back">
              {{ $t('vue.back') }}
            </div>
          </div>
        </div>
        <div class="audio-video-settings-heading">{{ $t('vue.changeVirtualBackground') }}</div>

        <vs-button class="mb-2" :class="{ 'mt-6': isMobile }" color="primary" type="filled" icon="add" @click.prevent="onAddVirtualBackground()">
          {{ $t('vue.addYourOwnBackground') }}
        </vs-button>

        <div class="audio-video-settings-bkgrid">
          <scroll-view v-if="!isMobile" style="height: 300px">
            <div class="photos">
              <div v-for="(background, i) in backgrounds" :key="i" class="photos-item">
                <div
                  v-if="background.type === 'img' && !background.default"
                  class="flex justify-center items-center delete-icon"
                  @click="onDeleteVirtualBackground(i)"
                >
                  <span class="material-icons" :class="background.selected ? 'audio-video-settings-grey' : 'audio-video-settings-red'"> delete </span>
                </div>
                <div class="audio-video-settings-pointer" @click="onSelectVirtualBackground(i)">
                  <div class="photos-item-check" v-if="background.selected"></div>
                  <img v-if="background.type === 'img'" :src="background.source" :class="background.selected ? 'audio-video-settings-selected' : ''" />
                </div>
                <div
                  v-if="background.type === 'none'"
                  @click="onSelectVirtualBackground(i)"
                  class="photos-item-none audio-video-settings-pointer"
                  :class="background.selected ? 'audio-video-settings-selected' : ''"
                >
                  <div class="photos-item-check" v-if="background.selected"></div>
                  <empty-icon></empty-icon>
                </div>
                <div
                  v-if="background.type === 'blur'"
                  @click="onSelectVirtualBackground(i)"
                  class="photos-item-blur flex flex-col justify-center audio-video-settings-pointer"
                  :class="background.selected ? 'audio-video-settings-selected' : ''"
                >
                  <div class="photos-item-check" v-if="background.selected"></div>
                  <blur-background-icon></blur-background-icon>
                  <div class="mt-4">{{ $t('vue.blurYourBackground') }}</div>
                </div>
              </div>
            </div>
          </scroll-view>

          <div v-else class="photos">
            <div v-for="(background, i) in backgrounds" :key="i" class="photos-item">
              <div
                v-if="background.type === 'img' && !background.default"
                class="flex justify-center items-center delete-icon"
                @click="onDeleteVirtualBackground(i)"
              >
                <span class="material-icons" :class="background.selected ? 'audio-video-settings-grey' : 'audio-video-settings-red'"> delete </span>
              </div>
              <div class="audio-video-settings-pointer" @click="onSelectVirtualBackground(i)">
                <div class="photos-item-check" v-if="background.selected"></div>
                <img v-if="background.type === 'img'" :src="background.source" :class="background.selected ? 'audio-video-settings-selected' : ''" />
              </div>
              <div
                v-if="background.type === 'none'"
                @click="onSelectVirtualBackground(i)"
                class="photos-item-none audio-video-settings-pointer"
                :class="background.selected ? 'audio-video-settings-selected' : ''"
              >
                <div class="photos-item-check" v-if="background.selected"></div>
                <empty-icon></empty-icon>
              </div>
              <div
                v-if="background.type === 'blur'"
                @click="onSelectVirtualBackground(i)"
                class="photos-item-blur flex flex-col justify-center audio-video-settings-pointer"
                :class="background.selected ? 'audio-video-settings-selected' : ''"
              >
                <div class="photos-item-check" v-if="background.selected"></div>
                <blur-background-icon></blur-background-icon>
                <div class="mt-4">{{ $t('vue.blurYourBackground') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </connect-modal>
</template>
<script>
/* eslint-disable no-useless-escape */
import ConnectModal from '../../../components/ConnectModal.vue'
import ConnectSelect from '../../../components/ConnectSelect.vue'
import SettingsIcon from '../../../components/icons/SettingsIcon.vue'
import BackIcon from '../../../components/icons/BackIcon.vue'
import EmptyIcon from '../../../components/icons/EmptyIcon.vue'
import BlurBackgroundIcon from '../../../components/icons/BlurBackgroundIcon.vue'
import CloseCircleIcon from '../../../components/icons/CloseCircleIcon.vue'
import MagicIcon from '../../../components/icons/MagicIcon.vue'
import MicrophoneIcon from '../../../components/icons/MicrophoneIcon.vue'
import MicrophoneOffIcon from '../../../components/icons/MicrophoneOffIcon.vue'
import VideoCallIcon from '../../../components/icons/VideoCallIcon.vue'
import SpeakerOnIcon from '../../../components/icons/SpeakerOnIcon.vue'
import SpeakerOffIcon from '../../../components/icons/SpeakerOffIcon.vue'
import VideoCallOffIcon from '../../../components/icons/VideoCallOffIcon.vue'
import SettingsVideoCamOff from '../../../components/icons/SettingsVideoCamOff.vue'
import CheckCircleIcon from '../../../components/icons/CheckCircleIcon.vue'
import CloseIcon from '../../../components/icons/CloseIcon.vue'

import ScrollView from '@blackbp/vue-smooth-scrollbar'

import { mapGetters, mapMutations } from 'vuex'

import { Device } from '@capacitor/device'
import { Camera as CapacitorCamera } from '@capacitor/camera'
import { Microphone as CapacitorMicrophone } from '@mozartec/capacitor-microphone'

export default {
  components: {
    ConnectModal,
    ConnectSelect,
    SettingsIcon,
    CloseCircleIcon,
    MicrophoneIcon,
    MicrophoneOffIcon,
    VideoCallIcon,
    SpeakerOnIcon,
    SpeakerOffIcon,
    VideoCallOffIcon,
    SettingsVideoCamOff,
    MagicIcon,
    BackIcon,
    EmptyIcon,
    BlurBackgroundIcon,
    CheckCircleIcon,
    ScrollView,
    CloseIcon
  },
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false
    },
    selectedSpeaker: {
      type: Object,
      required: false,
      default: null
    },
    selectedAudio: {
      type: Object,
      required: false,
      default: null
    },
    selectedVideo: {
      type: Object,
      required: false,
      default: null
    },
    volumeLevel: {
      type: Number,
      required: true
    },
    isMobile: {
      type: Boolean,
      required: false
    },
    isFirstSetupOnMobile: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      defaultVideoBackground: require('@/assets/images/embed/video-background.png'),
      volume: 30,
      microphoneOptions: [],
      speakerOptions: [],
      cameraOptions: [],
      options: [],
      isRequestedPermissions: false,
      hideCamera: false,
      hideSpeaker: false,
      isChangeVirtualBackground: false,
      defaultBackgrounds: [
        { type: 'none', selected: true, default: true },
        { type: 'blur', selected: false, default: true },
        { type: 'img', source: '/img/vb/vb01.jpg', selected: false, default: true, id: 3 },
        { type: 'img', source: '/img/vb/vb02.jpg', selected: false, default: true, id: 4 },
        { type: 'img', source: '/img/vb/vb03.jpg', selected: false, default: true, id: 5 },
        { type: 'img', source: '/img/vb/vb04.jpg', selected: false, default: true, id: 6 },
        { type: 'img', source: '/img/vb/vb05.jpg', selected: false, default: true, id: 7 },
        { type: 'img', source: '/img/vb/vb06.jpg', selected: false, default: true, id: 8 },
        { type: 'img', source: '/img/vb/vb07.jpg', selected: false, default: true, id: 9 }
      ],
      backgroundImage: null,
      selectedBackground: null,
      selfieSegmentation: null,
      camera: null,
      showCroppa: false,
      myPhoto: {},
      isPhotoSelected: false,
      canvasStream: null,
      videoElement: null,
      canvasElement: null,
      canvasCtx: null,
      videoWidthDesktop: 300,
      videoHeightDesktop: 168.75,
      videoWidthMobile: 348,
      MAX_VIDEO_WIDTH_MOBILE: 480,
      videoHeightMobile: 232,
      HORIZONTAL_OFFSET: 40
    }
  },
  beforeDestroy() {
    this.camera = null
    this.canvasStream = null
    this.stopAllTracks()
    this.setLocalStream(null)
  },
  beforeMount() {
    const selectedBackground = this.backgrounds.find((x) => x.selected)
    this.selectedBackground = selectedBackground || this.defaultBackgrounds.find((x) => x.selected)
  },
  created() {
    this.setVideoWidth()
  },
  async mounted() {
    if (this.volumeLevel) {
      this.volume = this.volumeLevel * 100
    }

    await this.turnOffAVOnMobileApp()

    if (!this.isFirstSetupOnMobile) {
      this.initAudioVideoSettings()
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      localStream: 'webrtc/localStream',
      cameraStream: 'webrtc/cameraStream',
      audioVideoStarted: 'webrtc/audioVideoStarted',
      company: 'company'
    }),
    isAudioDisabled() {
      return this.company && this.company.disableAudio
    },
    isVideoDisabled() {
      return this.company && this.company.disableVideo
    },
    currentVideoWidth() {
      return this.isMobile ? this.videoWidthMobile : this.videoWidthDesktop
    },
    currentVideoHeight() {
      return this.isMobile ? this.videoHeightMobile : this.videoHeightDesktop
    },
    videoBackgroundImage() {
      return !this.isMobile && this.selectedBackground && this.selectedBackground.source ? this.selectedBackground.source : '/img/vb01.jpg'
    },
    backgrounds() {
      let backgrounds = this.activeUserInfo && this.activeUserInfo.backgrounds ? this.activeUserInfo.backgrounds : this.defaultBackgrounds
      backgrounds = backgrounds.map((x) => {
        return { ...x }
      })
      const results = this.defaultBackgrounds.filter(
        function (a) {
          return !this[a.id]
        },
        backgrounds.reduce(function (r, a) {
          r[a.id] = true
          return r
        }, Object.create(null))
      )
      if (results) {
        results.forEach((x) => {
          backgrounds.push(x)
        })
      }

      backgrounds = backgrounds.filter((x) => {
        return !(x.id && !this.defaultBackgrounds.find((y) => x.id === y.id))
      })

      return backgrounds
    },
    isMicrophoneOn() {
      let result = false
      if (this.selectedAudio && this.selectedAudio.value !== 'microphone-off') {
        result = true
      }
      return result
    },
    isCameraOn() {
      let result = false
      if (this.selectedVideo && this.selectedVideo.value !== 'camera-off') {
        result = true
      }
      return result
    },
    isSpeakerOn() {
      let result = false
      if (this.selectedSpeaker && this.selectedSpeaker.value !== 'speaker-off') {
        result = true
      }
      return result
    }
  },
  watch: {
    volume() {
      const volumeLevel = this.volume / 100
      localStorage.setItem('pa-volume', volumeLevel)
      this.$refs.videoCam.volume = volumeLevel
      this.$serverBus.$emit('on-volume-changed', { level: volumeLevel })
    },
    'activeUserInfo.backgrounds'() {
      if (this.canvasCtx) {
        this.canvasCtx.clearRect(0, 0, 1280, 720)
        this.canvasCtx.save()
      }

      const selectedBackground = this.backgrounds.find((x) => x.selected)
      this.selectedBackground = selectedBackground || this.defaultBackgrounds.find((x) => x.selected)
      if (this.selectedBackground && this.selectedBackground.type === 'img' && this.selectedBackground.source) {
        this.backgroundImage = document.getElementById('settings-video-background')
        if (this.backgroundImage) {
          this.backgroundImage.crossOrigin = 'anonymous'
        }
      }
      this.onBackToSettings()
    }
  },
  methods: {
    ...mapMutations({
      setLocalStream: 'webrtc/setLocalStream',
      setCameraStream: 'webrtc/setCameraStream'
    }),

    async requestPermissions() {
      try {
        await CapacitorCamera.requestPermissions()
        await CapacitorMicrophone.requestPermissions()
      } catch (error) {
        /* eslint-disable-next-line */
        console.log(error)
      }

      this.isRequestedPermissions = true
      this.initAudioVideoSettings()
    },

    async turnOffAVOnMobileApp() {
      // Audio and video are not activated properly in the mobile app if they were previously enabled.

      const { platform } = await Device.getInfo()

      if (['ios', 'android'].includes(platform)) {
        if (this.isCameraOn) {
          this.toggleCamera()
        }

        if (this.isMicrophoneOn) {
          this.toggleMicrophone()
        }
      }
    },

    setVideoWidth() {
      if (this.isMobile) {
        const screenWidth = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth
        const modifiedScreenWidth = screenWidth - this.HORIZONTAL_OFFSET

        this.videoWidthMobile = modifiedScreenWidth > this.MAX_VIDEO_WIDTH_MOBILE ? this.MAX_VIDEO_WIDTH_MOBILE : modifiedScreenWidth
      }
    },

    async initAudioVideoSettings() {
      if ((this.isVideoDisabled || !this.company) && this.selectedVideo && this.selectedVideo.value && this.selectedVideo.value !== 'camera-off') {
        this.selectedVideo.value = 'camera-off'
      }
      if ((this.isAudioDisabled || !this.company) && this.selectedAudio && this.selectedAudio.value && this.selectedAudio.value !== 'microphone-off') {
        this.selectedAudio.value = 'microphone-off'
      }
      if ((this.isAudioDisabled || !this.company) && this.selectedSpeaker && this.selectedSpeaker.value && this.selectedSpeaker.value !== 'speaker-off') {
        this.selectedSpeaker.value = 'speaker-off'
      }
      const deviceInfos = await navigator.mediaDevices.enumerateDevices()
      this.speakerOptions = []
      this.microphoneOptions = []
      this.cameraOptions = []
      for (let i = 0; i !== deviceInfos.length; ++i) {
        const deviceInfo = deviceInfos[i]
        const option = {}
        option.value = deviceInfo.deviceId
        if (deviceInfo.kind === 'audioinput' && !this.isAudioDisabled) {
          option.text = deviceInfo.label || `Microphone ${this.options.length + 1}`
          this.microphoneOptions.push(option)
        } else if (deviceInfo.kind === 'audiooutput' && !this.isAudioDisabled) {
          option.text = deviceInfo.label || `Speaker ${this.options.length + 1}`
          this.speakerOptions.push(option)
        } else if (deviceInfo.kind === 'videoinput' && !this.isVideoDisabled) {
          option.text = deviceInfo.label || `Camera ${this.options.length + 1}`
          this.cameraOptions.push(option)
        }
      }
      this.microphoneOptions.push({ text: this.translate('vue.microphoneOff'), value: 'microphone-off' })
      this.speakerOptions.push({ text: this.translate('vue.loudSpeakerOff'), value: 'speaker-off' })
      this.cameraOptions.push({ text: this.translate('vue.cameraOff'), value: 'camera-off' })

      if (
        !this.isAudioDisabled &&
        this.company &&
        this.selectedAudio &&
        this.selectedAudio.value &&
        deviceInfos &&
        this.selectedAudio.value !== 'microphone-off'
      ) {
        const foundAudio = deviceInfos.find((x) => x.deviceId === this.selectedAudio.value && x.kind === 'audioinput')
        const defaultAudio = deviceInfos.find((x) => x.deviceId === 'default' && x.kind === 'audioinput') || deviceInfos.find((x) => x.kind === 'audioinput')
        if (!foundAudio && defaultAudio) {
          this.selectedAudio.text = defaultAudio.label || 'Default Microphone'
          this.selectedAudio.value = defaultAudio.deviceId
          const option = { text: this.selectedAudio.text, value: this.selectedAudio.value }
          localStorage.setItem('pa-audio', JSON.stringify(option))
        }
      }

      if (!this.isVideoDisabled && this.company && this.selectedVideo && this.selectedVideo.value && deviceInfos && this.selectedVideo.value !== 'camera-off') {
        const foundVideo = deviceInfos.find((x) => x.deviceId === this.selectedVideo.value && x.kind === 'videoinput')
        const defaultVideo = deviceInfos.find((x) => x.deviceId === 'default' && x.kind === 'videoinput') || deviceInfos.find((x) => x.kind === 'videoinput')
        if (!foundVideo && defaultVideo) {
          this.selectedVideo.text = defaultVideo.label || 'Default Camera'
          this.selectedVideo.value = defaultVideo.deviceId
          const option = { text: this.selectedVideo.text, value: this.selectedVideo.value }
          localStorage.setItem('pa-video', JSON.stringify(option))
        }
      }

      if (
        !this.isAudioDisabled &&
        this.company &&
        this.selectedSpeaker &&
        this.selectedSpeaker.value &&
        deviceInfos &&
        this.selectedSpeaker.value !== 'speaker-off'
      ) {
        const foundSpeaker = deviceInfos.find((x) => x.deviceId === this.selectedSpeaker.value && x.kind === 'audiooutput')
        const defaultSpeaker =
          deviceInfos.find((x) => x.deviceId === 'default' && x.kind === 'audiooutput') || deviceInfos.find((x) => x.kind === 'audiooutput')
        if (!foundSpeaker && defaultSpeaker) {
          this.selectedSpeaker.text = defaultSpeaker.label || 'Default Speaker'
          this.selectedSpeaker.value = defaultSpeaker.deviceId
          const option = { text: this.selectedSpeaker.text, value: this.selectedSpeaker.value }
          localStorage.setItem('pa-speaker', JSON.stringify(option))
        }
      }

      const constraints = {
        video: { deviceId: this.selectedVideo && this.selectedVideo.value ? this.selectedVideo.value : 'default', width: 1280, height: 720 },
        audio: { deviceId: this.selectedAudio && this.selectedAudio.value ? this.selectedAudio.value : 'default' }
      }

      if (this.selectedVideo) {
        if (this.selectedVideo.value && this.selectedVideo.value === 'camera-off') {
          constraints.video = false
        } else if (this.selectedVideo.value && this.selectedVideo.value !== 'default') {
          constraints.video = {
            deviceId: this.selectedVideo.value,
            width: 1280,
            height: 720
          }
        }
      }

      if (this.selectedAudio) {
        if (this.selectedAudio.value && this.selectedAudio.value === 'microphone-off') {
          constraints.audio = false
        } else if (this.selectedAudio.value && this.selectedAudio.value !== 'default') {
          constraints.audio = {
            deviceId: this.selectedAudio.value,
            echoCancellation: true
          }
        }
      }

      if (this.audioVideoStarted && this.cameraStream) {
        const stream = new MediaStream()
        this.cameraStream.getTracks().forEach((track) => {
          stream.addTrack(track.clone())
        })
        this.$refs.videoCam.srcObject = stream
        this.setLocalStream(stream)
        this.replaceVideoTrack()
      } else {
        this.stopAllTracks()
        if (constraints.audio || constraints.video) {
          const stream = await navigator.mediaDevices.getUserMedia(constraints)
          this.$refs.videoCam.srcObject = stream
          if (stream) {
            this.setLocalStream(stream)
            this.replaceVideoTrack()
          }
        }
      }
    },
    async sendVideoTrack() {
      if (this.localStream || this.cameraStream) {
        this.videoElement.style['opacity'] = 1
        this.videoElement.style['position'] = 'absolute'
        this.videoElement.style['width'] = '100%'
        this.videoElement.style['height'] = this.isMobile ? '100%' : '88%'
        this.canvasElement.style['opacity'] = 0
      }
    },

    async sendCanvasTrack() {
      if (this.canvasStream || this.cameraStream) {
        this.videoElement.style['opacity'] = 0
        this.videoElement.style['position'] = 'absolute'
        this.videoElement.style['width'] = '1px'
        this.videoElement.style['height'] = '1px'
        this.canvasElement.style['opacity'] = 1
      }
    },
    onFileTypeMismatch() {
      this.isPhotoSelected = false
      this.$vs.notify({
        time: 8800,
        title: this.$i18n.t('vue.fileTypeNotSupported'),
        text: this.$i18n.t('vue.fileTypeNotSupportedMessage'),
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    },
    onFileSizeExceed() {
      this.isPhotoSelected = false
      this.myPhoto.refresh()
      this.showCroppa = false
      this.$vs.notify({
        time: 8800,
        title: this.$i18n.t('vue.fileSizeLimitExceeded'),
        text: this.$i18n.t('vue.maximumSize'),
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    },
    initCanvas() {
      this.videoElement = document.getElementById('video-cam')
      this.canvasElement = document.getElementById('video-output')
      this.backgroundImage = document.getElementById('settings-video-background')

      this.videoElement.width = this.canvasElement.width
      this.videoElement.height = this.canvasElement.height
      this.canvasCtx = this.canvasElement.getContext('2d')
    },
    replaceVideoBackground(results) {
      if (!this.backgroundImage) {
        this.backgroundImage = document.getElementById('background-canvas')
      }

      if (this.backgroundImage && results.segmentationMask) {
        this.canvasCtx.globalCompositeOperation = 'copy'

        // Flip the canvas and prevent mirror behavior.
        this.canvasCtx.scale(-1, 1)
        this.canvasCtx.translate(-1280, 0)

        try {
          this.canvasCtx.drawImage(results.segmentationMask, 0, 0, 1280, 720, 0, 0, 1280, 720)
        } catch (error) {
          // eslint-disable-next-line
        }

        // Reset the transformations
        this.canvasCtx.setTransform(1, 0, 0, 1, 0, 0)

        this.canvasCtx.globalCompositeOperation = 'source-in'
        this.canvasCtx.filter = 'none'

        // Flip the canvas and prevent mirror behavior.
        this.canvasCtx.scale(-1, 1)
        this.canvasCtx.translate(-1280, 0)

        this.canvasCtx.drawImage(results.image, 0, 0)

        // Reset the transformations
        this.canvasCtx.setTransform(1, 0, 0, 1, 0, 0)

        this.canvasCtx.globalCompositeOperation = 'destination-over'
        this.canvasCtx.filter = 'blur(2px)'
        this.canvasCtx.drawImage(this.backgroundImage, 0, 0, 1280, 720)
      }
    },
    blurVideoBackground(results, blur) {
      if (blur === 'none') {
        this.canvasCtx.save()
        this.canvasCtx.filter = 'none'
        this.canvasCtx.clearRect(0, 0, 1280, 720)
        this.canvasCtx.globalCompositeOperation = 'xor'
        this.canvasCtx.drawImage(results.image, 0, 0, 1280, 720)
        this.canvasCtx.restore()
      } else if (results && results.segmentationMask && this.canvasCtx && this.canvasElement) {
        this.canvasCtx.globalCompositeOperation = 'copy'

        // Smooth out the edges.
        this.canvasCtx.filter = blur

        // Save current context before applying transformations.
        this.canvasCtx.save()

        // Flip the canvas and prevent mirror behaviour.
        this.canvasCtx.scale(-1, 1)
        this.canvasCtx.translate(-1280, 0)

        this.canvasCtx.drawImage(
          results.segmentationMask,
          0,
          0,
          this.canvasElement.width,
          this.canvasElement.height,
          0,
          0,
          this.videoElement.width,
          this.videoElement.height
        )
        this.canvasCtx.restore()

        this.canvasCtx.globalCompositeOperation = 'source-in'
        this.canvasCtx.filter = 'none'

        // Draw the foreground video.
        // Save current context before applying transformations.
        this.canvasCtx.save()

        // Flip the canvas and prevent mirror behaviour.
        this.canvasCtx.scale(-1, 1)
        this.canvasCtx.translate(-1280, 0)
        this.canvasCtx.drawImage(results.image, 0, 0, this.videoElement.width, this.videoElement.height)
        this.canvasCtx.restore()

        // Draw the background.
        // Save current context before applying transformations.
        this.canvasCtx.save()
        this.canvasCtx.globalCompositeOperation = 'destination-over'
        this.canvasCtx.filter = blur
        this.canvasCtx.scale(-1, 1)
        this.canvasCtx.translate(-1280, 0)
        this.canvasCtx.drawImage(results.image, 0, 0, this.videoElement.width, this.videoElement.height)
        this.canvasCtx.restore()
      }
    },

    onResults(results) {
      const selectedBackground = this.selectedBackground
      const type = selectedBackground && selectedBackground.type ? selectedBackground.type : 'none'
      switch (type) {
        case 'none':
          this.blurVideoBackground(results, 'none')
          break

        case 'img':
          this.replaceVideoBackground(results)
          break

        case 'blur':
          this.blurVideoBackground(results, 'blur(16px)')
          break

        default:
          break
      }
    },
    async uploadCroppedImage() {
      const vm = this
      vm.$vs.loading()
      vm.isPhotoSelected = false
      let storagePath = ''
      const randomId = Math.random().toString(36).substring(2, 15)
      storagePath = `virtual_backgrounds/${this.activeUserInfo.uid}/${randomId}`

      try {
        this.myPhoto.generateBlob(
          async (blob) => {
            const storageService = this.$firebase.storage()
            const storageRef = storageService.ref()
            const file = blob

            const metadata = {
              contentType: 'image/png',
              cacheControl: 'max-age=604800, public'
            }

            let uploadTask = null

            uploadTask = storageRef.child(storagePath).put(file, metadata)
            this.uploadTask = true

            uploadTask.on(
              this.$firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
              (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                this.progressUpload = progress
              },
              function (error) {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                  case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    vm.text = 'fields.storage/unauthorized'
                    break
                  case 'storage/canceled':
                    // User canceled the upload
                    vm.text = 'fields.storage/canceled'
                    break
                  case 'storage/unknown':
                    // Unknown error occurred, inspect error.serverResponse
                    vm.text = 'fields.storage/unknown'
                    break
                }

                vm.$vs.notify({
                  time: 8800,
                  title: vm.$i18n.t('vue.error'),
                  text: error.message,
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'danger'
                })

                vm.uploadTask = false
              },
              async () => {
                // Upload completed successfully, now we can get the download URL
                this.uploadTask = false
                const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()

                //const backgrounds = this.backgrounds.map((x)=> {return {...x})
                const backgrounds = this.backgrounds.map((x) => {
                  {
                    return { ...x }
                  }
                })
                backgrounds.push({ type: 'img', source: downloadURL, selected: false })
                await this.$db.collection('users').doc(this.activeUserInfo.uid).set({ backgrounds }, { merge: true })

                this.$vs.loading.close()
                this.$vs.notify({
                  time: 8800,
                  title: vm.$i18n.t('vue.success'),
                  text: vm.$i18n.t('vue.photoSavedSuccessfully'),
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'success'
                })
              }
            )
          },
          'image/jpg',
          0.8
        ) // 80% compressed jpeg file
      } catch (err) {
        this.$vs.notify({
          time: 8800,
          title: vm.$i18n.t('vue.error'),
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        // eslint-disable-next-line
        console.error(err.message)
      }
    },
    onCroppaInit() {
      const canvas = this.myPhoto.getCanvas()
      canvas.style.height = 'auto'
      canvas.style.width = '100%'
    },
    async onCroppaSave() {
      this.showCroppa = false
      this.uploadCroppedImage()
    },
    onFileChoose() {
      this.isPhotoSelected = true
    },
    async onDeleteVirtualBackground(i) {
      if (this.backgrounds[i] && this.backgrounds[i].selected) {
        return false
      }

      this.$vs.loading()
      const backgrounds = this.backgrounds.map((x) => {
        {
          return { ...x }
        }
      })
      const url = backgrounds[i].source
      backgrounds.splice(i, 1)

      const fileRef = this.$firebase.storage().refFromURL(url)

      try {
        await fileRef.delete()
        await this.$db.collection('users').doc(this.activeUserInfo.uid).set({ backgrounds }, { merge: true })
        this.onBackToSettings()
        this.$vs.loading.close()
      } catch (err) {
        await this.$db.collection('users').doc(this.activeUserInfo.uid).set({ backgrounds }, { merge: true })
        this.$vs.loading.close()
      }
    },
    async onSelectVirtualBackground(i) {
      this.$vs.loading()
      const backgrounds = this.backgrounds.map((x) => {
        {
          return { ...x }
        }
      })
      backgrounds.forEach((x) => {
        x.selected = false
      })
      backgrounds[i].selected = true
      this.$db.collection('users').doc(this.activeUserInfo.uid).set({ backgrounds }, { merge: true })
      setTimeout(() => {
        this.isChangeVirtualBackground = false
        this.$vs.loading.close()
      }, 500)
    },
    onChangeVirtualBackground() {
      if (!this.isCameraOn) {
        return
      }

      if (this.isMobile) {
        this.$serverBus.$emit('set-screen-overflow', true)
      }

      this.isChangeVirtualBackground = true
    },
    onBackToSettings() {
      if (this.isMobile) {
        this.$serverBus.$emit('set-screen-overflow', false)
      }

      this.isChangeVirtualBackground = false
      if (this.isCameraOn) {
        const selectedVideo = this.selectedVideo || this.cameraOptions[0]
        if (selectedVideo && selectedVideo.value !== 'camera-off') {
          this.replaceVideoTrack()
        } else {
          this.$refs.videoCam.srcObject = this.localStream
        }
      }
      this.$vs.loading.close()
    },
    onAddVirtualBackground() {
      this.showCroppa = true
      this.myPhoto.chooseFile()
    },
    async onCloseAudioVideoSettings() {
      if (this.camera && this.camera.stop) {
        this.camera.stop()
      }
      await this.turnOffAVOnMobileApp()
      this.stopAudioTracks()
      this.stopVideoTracks()
      this.stopAllTracks()
      this.canvasStream = null
      this.setLocalStream(null)
      this.camera = null
      this.$emit('close')
    },
    translate(code) {
      return this.$i18n.t(code)
    },
    toggleMicrophone() {
      if (this.isMicrophoneOn) {
        const mic = this.microphoneOptions.find((x) => x.value === 'microphone-off')
        this.onAudioInputSelected(mic)
        this.stopAudioTracks()
      } else {
        const mic = this.microphoneOptions.find((x) => x.value !== 'microphone-off')
        if (mic) {
          this.onAudioInputSelected(mic)
        }
      }
    },
    toggleCamera() {
      if (this.isCameraOn) {
        const camera = this.cameraOptions.find((x) => x.value === 'camera-off')
        this.onVideoInputSelected(camera)
        this.stopVideoTracks()
      } else {
        const camera = this.cameraOptions.find((x) => x.value !== 'camera-off')
        if (camera) {
          this.onVideoInputSelected(camera)
        }
      }
    },
    toggleSpeaker() {
      if (this.isSpeakerOn) {
        const speaker = this.speakerOptions.find((x) => x.value === 'speaker-off')
        this.onAudioOutputSelected(speaker)
      } else {
        const speaker = this.speakerOptions.find((x) => x.value !== 'speaker-off')
        if (speaker) {
          this.onAudioOutputSelected(speaker)
        }
      }
    },
    stopAudioTracks() {
      try {
        if (this.localStream) {
          this.localStream.getAudioTracks().forEach(function (track) {
            track.stop()
          })
        }
        if (this.camera && this.camera.video && this.camera.video.srcObject) {
          this.camera.video.srcObject.getAudioTracks().forEach(function (track) {
            track.stop()
          })
        }
        const videoCamStream = this.$refs.videoCam.srcObject
        if (videoCamStream) {
          videoCamStream.getAudioTracks().forEach(function (track) {
            track.stop()
          })
        }
        if (this.canvasStream) {
          this.canvasStream.getAudioTracks().forEach(function (track) {
            track.stop()
          })
        }
      } catch (error) {
        // eslint-disable-next-line
        console.log(error.message)
      }
    },
    stopVideoTracks() {
      try {
        if (this.localStream) {
          this.localStream.getVideoTracks().forEach(function (track) {
            track.stop()
          })
        }
        if (this.camera && this.camera.video && this.camera.video.srcObject) {
          this.camera.video.srcObject.getVideoTracks().forEach(function (track) {
            track.stop()
          })
        }
        const videoCamStream = this.$refs.videoCam.srcObject
        if (videoCamStream) {
          videoCamStream.getVideoTracks().forEach(function (track) {
            track.stop()
          })
        }
        if (this.canvasStream) {
          this.canvasStream.getVideoTracks().forEach(function (track) {
            track.stop()
          })
        }
      } catch (error) {
        // eslint-disable-next-line
        console.log(error.message)
      }
    },
    stopAllTracks() {
      try {
        if (this.camera && this.camera.video && this.camera.video.srcObject) {
          if (typeof this.camera.video.srcObject.getTracks === 'undefined') {
            this.camera.video.srcObject.stop()
          } else {
            this.camera.video.srcObject.getAudioTracks().forEach(function (track) {
              track.stop()
            })
            this.camera.video.srcObject.getVideoTracks().forEach(function (track) {
              track.stop()
            })
          }
        }
        if (this.localStream) {
          if (typeof this.localStream.getTracks === 'undefined') {
            this.localStream.stop()
          } else {
            this.localStream.getAudioTracks().forEach(function (track) {
              track.stop()
            })
            this.localStream.getVideoTracks().forEach(function (track) {
              track.stop()
            })
          }
        }
        const videoCamStream = this.$refs.videoCam.srcObject
        if (videoCamStream) {
          // Through the MediaStream, you can get the MediaStreamTracks with getTracks():
          const tracks = videoCamStream.getTracks()
          // Tracks are returned as an array, so if you know you only have one, you can stop it with:
          tracks[0].stop()
          // Or stop all like so:
          tracks.forEach((track) => track.stop())
        }
      } catch (error) {
        // eslint-disable-next-line
        console.log(error.message)
      }
      this.$refs.videoCam.srcObject = null
      this.setLocalStream(null)
    },
    onSpeakerClicked() {
      this.hideCamera = !this.hideCamera
    },
    onSpeakerSelectBlur() {
      this.hideCamera = false
    },
    onMicrophoneClicked() {
      this.hideCamera = !this.hideCamera
      this.hideSpeaker = !this.hideSpeaker
    },
    onMicrophoneSelectBlur() {
      this.hideCamera = false
      this.hideSpeaker = false
    },
    onAudioOutputSelected(option) {
      if (option) {
        localStorage.setItem('pa-speaker', JSON.stringify(option))
        this.$serverBus.$emit('audio-output-selected', option)
      }
    },
    replaceVideoTrack() {
      this.initCanvas()

      if (this.isMobile || !this.selectedBackground || this.selectedBackground.type === 'none') {
        this.sendVideoTrack()
      } else {
        if (!this.selfieSegmentation) {
          // eslint-disable-next-line
          this.selfieSegmentation = new SelfieSegmentation({
            locateFile: (file) => {
              return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation@0.1.1675465747/${file}`
            }
          })

          this.selfieSegmentation.setOptions({
            modelSelection: 1
          })
          this.selfieSegmentation.onResults(this.onResults)
        }

        if (!this.camera) {
          const deviceId = this.selectedVideo && this.selectedVideo.value ? this.selectedVideo.value : 'default'
          // eslint-disable-next-line
          this.camera = new Camera(this.videoElement, {
            onFrame: async () => {
              if (this.selfieSegmentation && this.videoElement) {
                if (this.selectedBackground && this.selectedBackground.type !== 'none') {
                  await this.selfieSegmentation.send({ image: this.videoElement, background: this.selectedBackground.type })
                }
              }
            },
            width: 1280,
            height: 720,
            deviceId
          })
          this.camera.start()
        }

        this.canvasStream = this.canvasElement.captureStream(30)

        this.sendCanvasTrack()
      }
    },
    async onAudioInputSelected(option) {
      this.hideCamera = false
      this.hideSpeaker = false
      /* If option text is not defined get it from the array */
      if (!option.text && option.value) {
        const _option = this.microphoneOptions.find((x) => x.value === option.value)
        if (_option) {
          option = _option
        }
      }

      if (option) {
        localStorage.setItem('pa-audio', JSON.stringify(option))
      }

      if (option && option.value && option.value !== 'microphone-off') {
        this.stopAllTracks()
        this.$serverBus.$emit('audio-input-selected', option)
        const constraints = {}
        constraints.audio = { deviceId: option.value, echoCancellation: true }
        if (this.isCameraOn) {
          constraints.video = { deviceId: this.selectedVideo.value, width: 1280, height: 720 }
        } else {
          constraints.video = false
        }
        const stream = await navigator.mediaDevices.getUserMedia(constraints)
        if (stream) {
          this.setLocalStream(stream)
          if (this.audioVideoStarted && this.cameraStream) {
            const _cameraStream = new MediaStream()
            stream.getTracks().forEach((track) => {
              _cameraStream.addTrack(track.clone())
            })
            this.setCameraStream(_cameraStream)
          }
          this.$refs.videoCam.srcObject = stream
          this.replaceVideoTrack()
        }
      } else {
        this.stopAudioTracks()
      }
      this.$serverBus.$emit('audio-input-selected', option)
    },
    async onVideoInputSelected(option) {
      /* If option text is not defined get it from the array */
      if (!option.text && option.value) {
        const _option = this.cameraOptions.find((x) => x.value === option.value)
        if (_option) {
          option = _option
        }
      }

      if (option) {
        localStorage.setItem('pa-video', JSON.stringify(option))
      }

      if (option && option.value && option.value !== 'camera-off') {
        this.stopAllTracks()
        const constraints = {}
        constraints.video = { deviceId: option.value, width: 1280, height: 720 }
        if (this.isMicrophoneOn) {
          constraints.audio = { deviceId: this.selectedAudio.value, echoCancellation: true }
        } else {
          constraints.audio = false
        }
        const stream = await navigator.mediaDevices.getUserMedia(constraints)
        if (stream) {
          this.setLocalStream(stream)
          if (this.audioVideoStarted && this.cameraStream) {
            const _cameraStream = new MediaStream()
            stream.getTracks().forEach((track) => {
              _cameraStream.addTrack(track.clone())
            })
            this.setCameraStream(_cameraStream)
          }
          this.$refs.videoCam.srcObject = stream
          this.replaceVideoTrack()
        }
      } else {
        this.stopVideoTracks()
      }
      this.$serverBus.$emit('video-input-selected', option)
    }
  }
}
</script>
<style lang="scss" scoped>
.audio-video-settings-button {
  .pa-bk-secondary {
    background: #275D73 !important;
  }
}

@-webkit-keyframes glowing {
  0% {
    -webkit-box-shadow: 0 0 3px #000000;
  }

  50% {
    -webkit-box-shadow: 0 0 8px #202020;
  }

  100% {
    -webkit-box-shadow: 0 0 3px #000000;
  }
}

@keyframes glowing {
  0% {
    transform: scale(1);
    box-shadow: 0 0 3px #000000;
  }

  50% {
    transform: scale(0.97);
    box-shadow: 0 0 8px #202020;
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 3px #000000;
  }
}

.delete-icon {
  position: absolute;
  cursor: pointer;
  top: 10px;
  left: 10px;
  z-index: 999;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}

.check-circle-icon {
  position: absolute;
  top: 15px;
  width: 50px;
  height: 50px;
  right: 20px;
  z-index: 999;
  border-radius: 6px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}

.check-circle-icon:hover {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  background-color: #026e02 !important;
  opacity: 0.6;
}

.audio-video-settings {
  &-pointer {
    cursor: pointer;
    background: #e8f6ff;
  }

  &-red {
    color: #f05541 !important;
  }

  &-grey {
    color: #ebebe4 !important;
  }

  &-selected {
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.8);
    border-radius: 6px;

    background: #000;
    -webkit-animation: glowing 1.6s infinite;
    -moz-animation: glowing 1.6s infinite;
    -o-animation: glowing 1.6s infinite;
    animation: glowing 1.6s infinite;
  }

  &-back {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #262629;
    text-transform: uppercase;
  }

  &-bkgrid {
    max-width: 714px;
    margin: 0 auto;

    .photos {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;

      &-item {
        margin: 10px;
        height: 135px;
        width: 218px;
        position: relative;
        box-sizing: border-box;

        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #262629;

        &-check {
          z-index: 1;
          display: inline-block;
          transform: rotate(45deg);
          height: 10px;
          width: 5px;
          border-bottom: 1px solid #fff;
          border-right: 1px solid #fff;
          position: absolute;
          mix-blend-mode: difference;
          right: 15px;
          top: 10px;
        }

        img {
          object-fit: cover;
          width: 218px;
          height: 135px;
          border-radius: 6px;
        }

        &-none {
          background: #e6ecf1;
          width: 100%;
          height: 100%;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &-blur {
          background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(215, 215, 215, 1) 100%);
          width: 100%;
          height: 100%;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  &-no-camera {
    width: 300px;
    height: 168.75px;
    background: #e8f6ff;

    @media only screen and (max-width: 1024px) {
      width: 100%;
      height: 232px;
    }

    &-container {
      width: 187px;
      height: 187px;

      &-message {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        color: #262629;
      }
    }
  }

  &-disabled {
    color: #b2b2b2 !important;
  }

  &-blurtext {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 15px !important;
    line-height: 19px !important;
    color: #262629;
  }

  .video {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    transform: scale(-1, 1);
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    width: 300px;
    height: 168.75px;
    opacity: 0;

    @media only screen and (max-width: 1024px) {
      width: 100%;
      height: 232px;
    }
  }

  span {
    font-size: 15px;
    line-height: 19px;
    color: #262629;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 20px !important;
  }

  .bk-select {
    background-color: #e6ecf1 !important;
    border-radius: 6px;
  }

  .bk-transparent {
    background-color: transparent !important;
  }

  .fw-600 {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    color: #b2b2b2;
  }

  .align-items-center {
    align-items: center !important;
  }

  .ht-36 {
    height: 36px !important;
  }

  &-icon {
    margin-left: -1px;
  }

  &-button {
    width: 35px;
    height: 35px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
  }

  &-communication-button {
    width: 48px;
    height: 30px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;

    border-radius: 6px;
  }

  .on {
    background: #275D73;
  }

  .off {
    background: #ffffff;
  }

  &-heading {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    color: #262629;
  }

  &-av-settings {
    height: 204.75px;
    width: 300px;
    background: #e8f6ff;
    border-radius: 6px;

    @media only screen and (max-width: 1024px) {
      width: 100%;
      max-width: 420px;
      height: 232px;
    }

    video {
      object-fit: cover;
    }
  }

  &-av-settings-background {
    height: 168.75px;
    width: 300px;

    @media only screen and (max-width: 1024px) {
      width: 100%;
      height: 232px;
    }
  }

  &-av-settings-footer {
    padding: 2px;
  }

  &--mobile {
    .audio-video-settings {
      &__inner {
        padding: 25px 10px 10px;
      }

      &-heading {
        margin-top: 30px;
      }

      &-pointer {
        &-blurtext-button {
          padding: 5px 16px;
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }

      &-blurtext {
        color: rgb(38, 38, 41) !important;
        font-size: 18px !important;
        line-height: 21px !important;
      }

      &-communication {
        &-button {
          width: 55px !important;
          min-width: 55px;
          height: 35px;
        }
      }
    }

    .photos {
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      margin: 0 -5px;
      height: calc(100vh - 200px);
      overflow-y: scroll;

      &-item {
        margin: 5px;
        width: calc(50% - 10px);

        img {
          width: 100%;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.audio-video-settings {
  .custom-select {
    .selected {
      background: #e8f6ff !important;
      color: #262629 !important;

      &:after {
        border-color: #262629 transparent transparent transparent !important;
      }
    }

    .items {
      color: #fff;
      overflow: auto;
      position: absolute;
      background-color: #e8f6ff;
      left: 0;
      right: 0;
      width: calc(100% + 15px);
      max-height: 150px;
      z-index: 1;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    .items div {
      color: #262629;
      padding-left: 1em;
      cursor: pointer;
      user-select: none;
    }

    .items div:hover {
      color: #fff;
      background-color: #262629;
    }
  }
}

.audio-video-settings-icon {
  &.settings-icon {
    color: #275D73 !important;
  }
}

.audio-video-settings {
  @media only screen and (max-width: 1024px) {
    .modal-container {
      width: 100% !important;
      height: 100% !important;
      border-radius: 0px;
    }

    span {
      font-size: 18px !important;
      font-weight: 500 !important;
      line-height: 22px !important;
    }

    .audio-video-settings {
      &-icon-settings {
        .audio-video-settings-icon {
          display: none;
        }
      }

      &-heading {
        font-size: 24px;
        font-weight: 400;
        line-height: 28.8px;
      }

      &-av-settings {
        margin: 0 auto;
        // width: 100%;

        &-wrapper {
          flex-direction: column !important;
          margin-top: 18px;
        }

        &-options {
          margin-top: 30px;
          margin-left: 0px !important;
        }

        &__canvas-video-output {
          border-bottom-left-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
        }
      }
    }
  }

  &--mobile {
    background: rgb(255, 255, 255) !important;

    .modal-container {
      box-shadow: none !important;
    }

    .custom-select {
      height: 35px !important;
      line-height: 35px !important;

      .selected {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
      }

      &-wrapper {
        width: calc(100% - 55px) !important;
      }
    }
  }
}
</style>
