var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "visitor-list" }, [
    _c(
      "div",
      [
        _c("scroll-view", { staticClass: "visitor-list-scroll-area--search" }, [
          _c(
            "ul",
            { staticClass: "visitor-list__ul" },
            _vm._l(_vm.visitors, function (_visitor, index) {
              return _c(
                "li",
                {
                  key: index,
                  staticClass: "cursor-pointer",
                  attrs: { id: "visitor-search-" + _visitor.id },
                  on: {
                    click: function ($event) {
                      return _vm.$emit(
                        "update-active-chat-visitor",
                        _visitor.id,
                        _vm.selectVisitors,
                        false,
                        "select-active-chat-visitor"
                      )
                    },
                  },
                },
                [_c("visitor", { attrs: { visitor: _visitor } })],
                1
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "visitor-list--load-more w-full" },
            [
              _vm.visitors.length > 0
                ? _c(
                    "vs-button",
                    {
                      staticClass: "w-full",
                      attrs: { disabled: _vm.isLoadMoreDisabled },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("load-more")
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("vue.loadMore")) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }