<template>
  <div
    class="send-chat-message w-full"
    :class="{
      'send-chat-message--mobile': isMobile
    }"
  >
    <div class="send-chat-message-container flex flex-col" ref="sendChatMessageContainer">
      <div class="send-chat-message-container-top w-full relative">
        <div v-if="hasFilesizeError" class="send-chat-message-container-top__error">{{ $t('errors.chatFileUploadLimit') }}</div>
        <div class="w-full">
          <message-dropzone
            v-if="isDropzoneVisible && !isFileSharingDisabled"
            ref="filesDropzone"
            @files-added="onFilesAdded"
            @on-reset="hideDropzone"
            @vd-drag-enter="isDropzoneVisible = true"
            @vd-drag-over="isDropzoneVisible = true"
            @vd-drag-drop="isDropzoneVisible = true"
          />
          <textarea
            v-else
            class="send-chat-message-container-input"
            v-model="typedMessage"
            type="text"
            :placeholder="$t('vue.writeMessage')"
            :class="{
              'focus-input': isModal
            }"
            @click="scrollToBottom"
            @keyup="onAgentIsTyping()"
            @keyup.enter.exact.prevent="sendChatMessage()"
          />
        </div>
        <div class="send-chat-message-container-button" @click="sendChatMessage()">
          <send-icon
            class="send-chat-message-container-icon"
            :class="{
              'pa-icon-default': canSendMessage,
              'pa-icon-disabled': !canSendMessage
            }"
            :width="25"
            :height="25"
          ></send-icon>
        </div>
      </div>
      <div class="flex -mb-4 flex-wrap" v-if="files && files.length" :class="{ 'pt-1 px-2': isMobile, 'pt-2 px-0': !isMobile }">
        <message-file class="mb-4" v-for="(file, index) in files" :key="index" :file="file" :index="index" />
      </div>
    </div>
  </div>
</template>

<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

import { mapGetters, mapState, mapMutations } from 'vuex'
import MessageFile from '@/views/incoming/messages/MessageFile.vue'
import SendIcon from '../../../components/icons/SendIcon.vue'
import MessageDropzone from '@/views/incoming/messages/MessageDropzone'
import { mvisitors } from '@/mixins/mvisitors'
import sanitizeHtml from 'sanitize-html'

export default {
  mixins: [mvisitors],
  components: {
    SendIcon,
    MessageFile,
    MessageDropzone
  },
  props: {
    isModal: {
      type: Boolean,
      required: false,
      default: false
    },
    isMobile: {
      type: Boolean,
      required: false
    }
  },
  mounted() {
    /* Get reference of real-time db */
    this.rtdVisitorRef = this.$database.ref(`/chats/${this.visitorId}`)
    /* Initially set the typing to false */
    this.$serverBus.$emit('visitor-is-typing', false)
    /* Listen for updates in the real-time db for visitors typing */
    this.rtdVisitorRef.on('value', (snapshot) => {
      const data = snapshot.val()
      if (data && typeof data.visitorIsTyping !== 'undefined') {
        this.visitorIsTyping = data.visitorIsTyping
        this.$serverBus.$emit('visitor-is-typing', data.visitorIsTyping)
      }

      if (data && typeof data.agentIsTyping !== 'undefined') {
        this.agentIsTyping = data.agentIsTyping
      }
      if (data && (typeof data.visitorVideoOn !== 'undefined' || typeof data.visitorAudioOn !== 'undefined')) {
        this.$serverBus.$emit('visitor-media-state', { visitorVideoOn: data.visitorVideoOn, visitorAudioOn: data.visitorAudioOn })
      }

      if (data && typeof data.visitorVideoOn !== 'undefined') {
        this.$serverBus.$emit('visitor-video-toggle', data.visitorVideoOn)
      }

      if (data) {
        this.setVisitorVideoOn(Boolean(data.visitorVideoOn))
        this.setVisitorAudioOn(Boolean(data.visitorAudioOn))
      }
    })
    /* Automatically reduce timeout milli-seconds to 2000 (default) if it is greater than 2000 */
    // setInterval(this.reduceTimeoutMs, 500)

    let dragTimer = null
    let lastTarget = null
    const sendChatMessageContainer = this.$refs.sendChatMessageContainer

    function isFile(e) {
      const { dataTransfer } = e

      for (let i = 0; i < dataTransfer.types.length; i++) {
        if (dataTransfer.types[i] === 'Files') {
          return true
        }
      }
      return false
    }
    document.addEventListener(
      'dragover',
      (e) => {
        e.preventDefault()
      },
      false
    )

    document.addEventListener(
      'dragleave',
      (e) => {
        e.preventDefault()
        if (e.target === document || e.target === lastTarget) {
          dragTimer = setTimeout(() => {
            this.isDropzoneVisible = false
          }, 25)
        }
      },
      false
    )
    document.addEventListener(
      'dragenter',
      (e) => {
        e.preventDefault()
        if (isFile(e)) {
          lastTarget = e.target
          this.isDropzoneVisible = true
          window.clearTimeout(dragTimer)
        }
      },
      false
    )

    document.addEventListener(
      'drop',
      (e) => {
        // prevent default action (open as link for some elements)
        e.preventDefault()
        if (e.target !== sendChatMessageContainer) {
          this.isDropzoneVisible = false
        }
      },
      false
    )

    this.$serverBus.$on('chat-files-upload-complete', (isChatFilesUploadComplete) => {
      this.isChatFilesUploadComplete = isChatFilesUploadComplete
    })
  },
  beforeDestroy() {
    this.$serverBus.$off('chat-files-upload-complete')
    if (this.rtdVisitorRef) {
      this.rtdVisitorRef.off('value')
    }
  },
  data() {
    return {
      typedMessage: '',
      agentIsTyping: false,
      visitorIsTyping: false,
      //timeoutMs: 2000,
      rtdVisitorRef: null,
      isDropzoneVisible: false,
      isChatFilesUploadComplete: true,
      agentIsTypingDelayInMS: 5000,
      agentIsTypingRemainingDelayInMs: 0
    }
  },
  created() {
    if (window.agentTypingDelayInterval) {
      window.clearInterval(window.agentTypingDelayInterval)
    }

    window.agentTypingDelayInterval = setInterval(() => {
      if (this.agentIsTypingRemainingDelayInMs > 0) {
        this.agentIsTypingRemainingDelayInMs = this.agentIsTypingRemainingDelayInMs - 1000
        if (this.agentIsTypingRemainingDelayInMs <= 0) {
          this.rtdVisitorRef.update({ agentIsTyping: false })
        }
      }
    }, 1000)
  },
  computed: {
    ...mapState({
      files: (state) => state.files
    }),
    ...mapGetters({
      activeUserInfo: 'activeUser',
      company: 'company',
      visitorId: 'webrtc/visitorId',
      visitor: 'webrtc/visitor',
      hasFilesizeError: 'hasFilesizeError'
    }),

    isFileSharingDisabled() {
      return this.company && this.company.disableFileSharing
    },

    agentName() {
      let name = ''
      if (this.activeUserInfo.displayName) {
        name = this.activeUserInfo.displayName
        return name
      }

      if (this.activeUserInfo.firstname) {
        name = this.activeUserInfo.firstname
        if (this.activeUserInfo.lastname) {
          name = `${name} ${this.activeUserInfo.lastname}`
        }
      }
      return name
    },
    canSendMessage() {
      const chatMessage = this.typedMessage.trim()

      if ((chatMessage.length > 0 || (this.files && this.files.length)) && this.isChatFilesUploadComplete) {
        return true
      }

      return false
    }
  },
  watch: {
    visitorId() {
      if (this.visitorId) {
        this.typedMessage = ''
        /* Get reference of real-time db */
        this.rtdVisitorRef = this.$database.ref(`/chats/${this.visitorId}`)
        /* Initially set the typing to false */
        this.$serverBus.$emit('visitor-is-typing', false)
        /* Listen for updates in the real-time db for visitors typing */
        this.rtdVisitorRef.on('value', (snapshot) => {
          const data = snapshot.val()
          if (data) {
            this.setVisitorVideoOn(Boolean(data.visitorVideoOn))
            this.setVisitorAudioOn(Boolean(data.visitorAudioOn))
          }

          if (data && typeof data.visitorIsTyping !== 'undefined') {
            this.visitorIsTyping = data.visitorIsTyping
            this.$serverBus.$emit('visitor-is-typing', data.visitorIsTyping)
          }
          if (data && typeof data.agentIsTyping !== 'undefined') {
            this.agentIsTyping = data.agentIsTyping
          }
          if (data && typeof data.visitorVideoOn !== 'undefined') {
            this.$serverBus.$emit('visitor-video-toggle', data.visitorVideoOn)
          }
        })
      }
    },
    typedMessage() {
      if (this.typedMessage && this.typedMessage.trim().length > 0) {
        this.updateHasFileSizeError(false)
      }
    }
  },
  methods: {
    ...mapMutations({
      setVisitorVideoOn: 'webrtc/setVisitorVideoOn',
      setVisitorAudioOn: 'webrtc/setVisitorAudioOn',
      updateHasFileSizeError: 'UPDATE_HAS_FILE_SIZE_ERROR'
    }),

    scrollToBottom() {
      window.scrollTo(0, document.body.scrollHeight)
    },

    sanitizeHtml,
    hideDropzone() {
      this.isDropzoneVisible = false
    },

    onFilesAdded(uploads) {
      if (uploads.length > 0) {
        const ref = this.$refs.filesDropzone.$refs.messageDropzone

        this.$serverBus.$emit('dropzone-upload-files', uploads, ref)
      } else {
        this.isDropzoneVisible = false
      }
    },

    onFilesSend() {
      if (this.files && this.files.length) {
        const files = this.files.map((file) => {
          file = {
            ...file,
            createdAt: dayjs().tz(this.$defaultTimezone).$d,
            expiresAt: dayjs().tz(this.$defaultTimezone).add(7, 'day').$d
          }
          return file
        })
        return files
      }
      return null
    },

    async sendChatMessage() {
      const chatMessage = this.typedMessage.trim()

      if (this.canSendMessage) {
        this.typedMessage = ''
        const visitorRef = this.$db.collection('visitors').doc(this.visitorId)
        const files = this.onFilesSend()
        const message = {
          message: this.sanitizeHtml(chatMessage),
          time: new Date(),
          chatTime: dayjs().tz(this.$defaultTimezone).diff('2021-01-01'),
          agentName: this.agentName,
          agentId: this.activeUserInfo.uid,
          sender: 'agent',
          files
        }
        if (this.visitor) {
          let lastReadChatsAgentInfo = []

          lastReadChatsAgentInfo = this.visitor.lastReadChatsAgentInfo ? [...this.visitor.lastReadChatsAgentInfo] : []
          if (lastReadChatsAgentInfo.length === 0 || lastReadChatsAgentInfo.filter((x) => x.agentId === this.activeUserInfo.uid).length === 0) {
            lastReadChatsAgentInfo.push({
              agentId: this.activeUserInfo.uid,
              lastRead: dayjs().tz(this.$defaultTimezone).diff('2021-01-01')
            })
          } else {
            lastReadChatsAgentInfo = lastReadChatsAgentInfo.filter((x) => x.agentId !== this.activeUserInfo.uid)
            lastReadChatsAgentInfo.push({
              agentId: this.activeUserInfo.uid,
              lastRead: dayjs().tz(this.$defaultTimezone).diff('2021-01-01')
            })
          }

          if (this.visitor.agentRequest && !this.visitor.connectedAgentId) {
            const agentInfo = {
              agentId: this.activeUserInfo.uid,
              connectedAgentId: this.activeUserInfo.uid,
              connectedAgent: this.agentName,
              connectedAgentImage: this.activeUserInfo.photoURL || null,
              connectedAgentDate: dayjs().utc().toDate(),
              connectedAgentChatDate: dayjs().utc().toDate(),
              connectedAgentCompany: this.activeUserInfo.company,
              lastConnectedAgentId: this.activeUserInfo.uid,
              lastConnectedAgent: this.agentName,
              lastConnectedAgentImage: this.activeUserInfo.photoURL || null,
              modified: dayjs().utc().toDate(),
              ttlDate: dayjs().utc().add(6, 'month').toDate(),
              callTypeState: 'agent-joined',
              agentRequest: false,
              declinedByAgents: this.$firebase.firestore.FieldValue.delete(),
              lastReadChatsAgentInfo,
              isCallStarted: true,
              end_time: null
            }

            if (this.visitor && this.activeUserInfo && this.visitor.company === this.activeUserInfo.company) {
              agentInfo.previousCallCenterId = this.$firebase.firestore.FieldValue.delete()
              agentInfo.previousCallCenterName = this.$firebase.firestore.FieldValue.delete()
            }

            if (this.company && this.company.isCallCenter) {
              agentInfo.callCenterId = this.activeUserInfo.company
            }

            agentInfo.chats = this.$firebase.firestore.FieldValue.arrayUnion(message)
            agentInfo.callTypeState = 'agent-joined'
            await visitorRef.update(agentInfo).then(() => {
              this.cleanSentFiles()
            })
          } else {
            await visitorRef
              .update({
                chats: this.$firebase.firestore.FieldValue.arrayUnion(message),
                lastReadChatsAgentInfo,
                callTypeState: 'agent-chatting',
                connectedAgentChatDate: dayjs().utc().toDate(),
                agentMessages: this.$firebase.firestore.FieldValue.increment(1),
                visitorMessages: this.$firebase.firestore.FieldValue.delete()
              })
              .then(() => {
                this.cleanSentFiles()
              })
          }
        }

        await this.rtdVisitorRef.update({ agentIsTyping: false })
      }
    },

    cleanSentFiles() {
      this.$serverBus.$emit('clean-sent-files')
    },

    onAgentIsTyping() {
      this.agentIsTypingRemainingDelayInMs = this.agentIsTypingDelayInMS
      if (this.typedMessage.length === 0 && this.agentIsTyping) {
        this.rtdVisitorRef.update({ agentIsTyping: false })
      }
      if (this.typedMessage.length > 0 && !this.agentIsTyping) {
        this.rtdVisitorRef.update({ agentIsTyping: true })
      }

      if (this.visitorId) {
        this.updateAgentChatStartDateOnTyping(this.visitorId)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.send-chat-message {
  &-container {
    &-top {
      &__error {
        text-align: center;
        color: #fff;
        padding: 0 14px;
        font-size: 12px;
        line-height: 16px;
        background: rgb(240, 85, 65);
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        width: max-content;
        margin: 0 auto;
      }
    }
    &-input {
      font-family: 'Lato';
      border: none;
      width: 100%;
      padding: 5px;
      font-size: 15px;
      line-height: 19px;
      color: #12598d;
      font-weight: normal;
      padding: 10px 40px 10px 10px;
      outline: none;
      resize: none;
      overflow: auto;
      width: 100%;
    }
    &-input::placeholder {
      color: #12598d;
      opacity: 0.5;
    }
    &-button {
      position: absolute;
      right: 12px;
      top: 15px;
      width: 35px;
      height: 35px;
      background: transparent;
      border-radius: 6px;
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      transition: 1s;
      .pa-icon-disabled {
        color: rgba(0, 0, 0, 0.2) !important;
        path {
          stroke: rgba(0, 0, 0, 0.2) !important;
        }
      }
    }
    &-button:hover {
      background: rgba(var(--vs-secondary), 0.2) !important;
    }
  }

  &--mobile {
    .send-chat-message-container {
      &-top {
        &__error {
          text-align: center;
          color: #fff;
          padding: 0 14px;
          font-size: 12px;
          line-height: 16px;
          background: rgb(240, 85, 65);
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
          width: max-content;
          margin: 0 auto;
        }
      }

      &-input {
        -webkit-appearance: none;
        appearance: none;
        font-size: 16px;
      }

      &-button {
        .pa-icon-default {
          color: #275D73 !important;
        }

        &:hover {
          background: none !important;

          .pa-icon-default {
            color: rgba(40, 167, 249, 0.8) !important;
          }
        }
      }
    }
  }
}
</style>
