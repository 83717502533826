var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "video-call" }, [
    _c("div", { staticClass: "video-call-start" }, [
      _c("div", { staticClass: "video-call-row" }, [
        _c(
          "div",
          {
            staticClass:
              "video-call-button flex flex-row justify-center items-center",
            class: _vm.isVideoActive
              ? " video-call-pulse active"
              : " video-call-pulse inactive",
            on: { click: _vm.onStartVideo },
          },
          [
            _c("video-call-icon", {
              staticClass: "video-call-button-icon",
              attrs: { width: 25, height: 16 },
            }),
            _c("span", [_vm._v(_vm._s(_vm.$t("vue.startVideo")) + " ")]),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }