<template>
  <svg :width="width" :height="height" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M34.3333 7L15.5417 25.7917L7 17.25" :stroke="stroke" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>
<script>
export default {
  name: 'RatingSupportSavedIcon',
  props: {
    stroke: {
      type: String,
      default: '#fff'
    },
    width: {
      type: Number,
      default: 41
    },
    height: {
      type: Number,
      default: 41
    }
  }
}
</script>
