var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.IS_CHAT_ON,
          expression: "IS_CHAT_ON",
        },
      ],
      staticClass: "ss-chat-container",
    },
    [
      _c(
        "div",
        {
          staticClass: "ss-chat-container-close-btn",
          on: { click: _vm.onCloseChatClicked },
        },
        [
          _c("close-icon", {
            attrs: { color: "#3034F7", width: 24, height: 24 },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "ss-chat-container-chats" },
        [
          _c("chat-log", {
            attrs: { "is-inside-modal": true, screen: _vm.SCREEN.MAXIMIZED },
          }),
          _vm.canChatWithVisitor
            ? _c("div", [_c("send-chat-message")], 1)
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }