var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.IS_VISITOR_SCREEN_SHARING,
          expression: "IS_VISITOR_SCREEN_SHARING",
        },
      ],
      staticClass: "flex flex-col",
      class: { screensharing: _vm.IS_VISITOR_SCREEN_SHARING || true },
    },
    [
      _vm.isLoading
        ? _c("div", { staticClass: "loader-container" }, [
            _c("div", { staticClass: "loader" }),
          ])
        : _vm._e(),
      _c("video", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoading && _vm.IS_VISITOR_SCREEN_SHARING,
            expression: "!isLoading && IS_VISITOR_SCREEN_SHARING",
          },
        ],
        ref: "screenShareDisplay",
        staticClass: "screensharing-video",
        class: {
          screensharing_bck: _vm.IS_VISITOR_SCREEN_SHARING,
        },
        attrs: {
          autoplay: "",
          playsinline: "",
          id: "screenshare-video",
          muted: "muted",
        },
        domProps: { muted: true },
      }),
      !_vm.isMobile
        ? [
            _c("ScreenSharingChat", {
              on: {
                "on-click-communications-controls":
                  _vm.onClickCommunicationControl,
              },
            }),
            _c("ScreenSharingLocalVideo"),
            _c("ScreenSharingToolBar", {
              on: {
                "on-click-communications-controls":
                  _vm.onClickCommunicationControl,
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }