var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "chat-messages border border-solid d-theme-border-grey-light border-t-0 border-r-0 border-b-0",
      class: {
        "sidebar-spacer--wide": _vm.clickNotClose,
        "flex items-center justify-center": _vm.visitorId === null,
        relative: _vm.conversationCompleted,
        "chat-messages--active-mobile": _vm.isActiveChatMobile,
      },
    },
    [
      [
        _c(
          "div",
          { staticClass: "flex flex-col chat-messages-midcontainer" },
          [
            _vm.conversationCompleted && _vm.visitorId
              ? _c("conversation-completed")
              : _vm._e(),
            _c("chat-navbar", {
              attrs: {
                "selected-audio": _vm.selectedAudio,
                "selected-video": _vm.selectedVideo,
                "selected-speaker": _vm.selectedSpeaker,
                "volume-level": _vm.volumeLevel,
              },
            }),
            _c(
              "div",
              {
                staticClass: "chat-messages-chat-av-container",
                style: _vm.avContainerStyle,
              },
              [
                _vm.visitorId
                  ? _c("chat-log", {
                      class:
                        _vm.messageMode === "chat" || _vm.audioVideoStarted
                          ? "w-full"
                          : "flex-50",
                    })
                  : _vm._e(),
                _vm.messageMode === "audio" || _vm.messageMode === "video"
                  ? _c(
                      "div",
                      { class: !_vm.audioVideoStarted ? "flex-50" : "" },
                      [
                        _vm.messageMode === "audio" && !_vm.audioVideoStarted
                          ? _c("audio-call")
                          : _vm._e(),
                        _vm.messageMode === "video" && !_vm.audioVideoStarted
                          ? _c("video-call")
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm.visitorId
              ? _c(
                  "div",
                  {
                    class: _vm.visitorIsTyping
                      ? "chat-messages-toolbar-mt-10"
                      : "chat-messages-toolbar-mt-auto",
                  },
                  [
                    _vm.canChatWithVisitor
                      ? _c(
                          "div",
                          [
                            _c("toolbar", {
                              attrs: {
                                "cobrowse-status": _vm.cobrowseStatus,
                                "call-recording-status":
                                  _vm.callRecordingStatus,
                              },
                            }),
                            _c("send-chat-message"),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }