<template>
  <div
    class="send-chat-message-file"
    :class="{
      _white: isWhite,
      _expired: isExpired
    }"
    @click.prevent.stop="downloadFile()"
  >
    <svg class="send-chat-message-file-logo" width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.7952 1H3.13576C2.55536 1 1.99872 1.23178 1.58832 1.64436C1.17791 2.05695 0.947342 2.61652 0.947342 3.2V20.8C0.947342 21.3835 1.17791 21.9431 1.58832 22.3556C1.99872 22.7682 2.55536 23 3.13576 23H16.2663C16.8467 23 17.4033 22.7682 17.8137 22.3556C18.2241 21.9431 18.4547 21.3835 18.4547 20.8V8.7L10.7952 1Z"
        stroke="#12598D"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M10.7953 1V8.7H18.4548" stroke="#12598D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <span>{{ file.name }} </span>
    <a href="#" class="send-chat-message-file-close" @click.prevent="deleteFile()" v-if="hasDeleteIcon && file && file.percentage === 100">
      <img src="@/assets/images/elements/close.svg" />
    </a>
    <div v-if="file && file.percentage < 100" class="send-chat-message-file-progress-bar" :style="{ width: file && file.percentage + '%' }" />
  </div>
</template>

<script>
const dayjs = require('dayjs')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrAfter)

import { mapGetters } from 'vuex'
export default {
  name: 'MessageFile',

  props: {
    id: {
      type: Number,
      required: false
    },
    file: {
      type: Object,
      required: false
    },
    index: {
      type: Number
    },
    hasDeleteIcon: {
      type: Boolean,
      default: true
    },
    hasDownload: {
      type: Boolean,
      default: false
    },
    isWhite: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      chatFilesListCurrent: 'chatFilesListCurrent'
    }),
    expiresAt() {
      if (this.file && this.file.expiresAt) {
        return dayjs.unix(this.file.expiresAt.seconds)
      }
      return null
    },
    isExpired() {
      if (this.expiresAt) {
        return dayjs().isSameOrAfter(this.expiresAt, 'day')
      }
      return false
    }
  },
  methods: {
    deleteFile() {
      this.$serverBus.$emit('delete-file', this.file.url, this.index)
    },
    downloadFile() {
      if (this.hasDownload && !this.isExpired) {
        this.$serverBus.$emit('download-file', this.file.url, this.id)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.send-chat-message {
  &-file {
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    padding: 5px 10px;
    font-size: 15px;
    background: #e6ecf1;
    border-radius: 6px;
    color: #12598d;
    font-weight: 500;
    margin-right: 10px;
    position: relative;
    padding-right: 40px;
    cursor: pointer;
    word-break: break-word;
    &._white {
      background-color: #fff;
    }
    &._expired {
      svg {
        path {
          stroke: #b2b2b2;
        }
      }
      span {
        color: #b2b2b2;
      }
    }
    svg {
      min-width: 18px;
    }
    span {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
    }
    &-logo {
      margin-right: 10px;
    }
    &-close {
      position: absolute;
      right: 10px;
    }
    &-progress-bar {
      position: absolute;
      background: #12598d;
      border-radius: 6px;
      height: 1.5px;
      bottom: 0;
      left: 0;
    }
  }
}
</style>
