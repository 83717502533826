var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "chat-messages border border-solid d-theme-border-grey-light border-t-0 border-r-0 border-b-0",
      class: {
        "flex items-center justify-center": _vm.visitorId === null,
      },
    },
    [
      [
        _c("div", { staticClass: "flex flex-col chat-messages-midcontainer" }, [
          _c(
            "div",
            {
              staticClass: "chat-messages-chat-av-container",
              style: _vm.avContainerStyle,
            },
            [
              _vm.visitorId
                ? _c("chat-log", {
                    class:
                      _vm.messageMode === "chat" || _vm.audioVideoStarted
                        ? "w-full"
                        : "flex-50",
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }