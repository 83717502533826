var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vue-dropzone", {
    ref: "messageDropzone",
    attrs: { id: "customdropzone", options: _vm.dropzoneOptions },
    on: {
      "vdropzone-reset": _vm.onReset,
      "vdropzone-files-added": _vm.afterFilesAdded,
      "vdropzone-drag-enter": _vm.vdDragEnter,
      "vdropzone-drop": _vm.vdDragDrop,
      "vdropzone-drag-over": _vm.vdDragOver,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }