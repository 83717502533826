<template>
  <svg :width="width" :height="height" viewBox="0 0 93 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.6">
      <path
        d="M11.2671 18.151C11.2671 13.3099 11.2671 8.46874 11.2671 3.6276C11.2671 2.56166 11.808 1.90433 12.7745 1.77109C13.5548 1.6645 14.3617 2.22411 14.5125 2.99692C14.5657 3.24564 14.5834 3.49436 14.5834 3.74308C14.5834 13.4076 14.5834 23.0721 14.5923 32.7366C14.5923 33.465 14.4504 34.149 13.7588 34.5221C12.544 35.1794 11.3026 34.3711 11.276 32.9321C11.2582 32.1504 11.276 31.3598 11.276 30.5781C11.2671 26.4387 11.2671 22.2993 11.2671 18.151Z"
        fill="#E6ECF1"
      />
      <path
        d="M9.334 18.2754C9.334 20.8159 9.334 23.3653 9.334 25.9058C9.334 26.572 9.15665 27.1316 8.56255 27.4869C7.40982 28.1886 6.11521 27.4336 6.0354 26.0301C6.0354 25.9679 6.0354 25.9146 6.0354 25.8525C6.0354 20.7448 6.0354 15.6461 6.0354 10.5385C6.0354 9.50806 6.70044 8.77078 7.64923 8.72637C8.44728 8.69084 9.21872 9.33928 9.3074 10.1387C9.334 10.3608 9.334 10.5829 9.334 10.805C9.334 13.3099 9.334 15.7882 9.334 18.2754Z"
        fill="#E6ECF1"
      />
      <path
        d="M19.8237 18.3287C19.8237 20.2652 19.8237 22.2016 19.8237 24.147C19.8237 24.8398 19.6197 25.435 18.9813 25.7903C17.7931 26.4565 16.5251 25.6038 16.5074 24.1292C16.4985 22.6369 16.5074 21.1357 16.5074 19.6434C16.5074 17.2006 16.4985 14.7578 16.5074 12.315C16.5074 11.0004 17.5803 10.2009 18.7596 10.6007C19.4158 10.8227 19.8148 11.4889 19.8148 12.4216C19.8237 14.3936 19.8237 16.3567 19.8237 18.3287Z"
        fill="#E6ECF1"
      />
      <path
        d="M25.0732 18.3021C25.0732 19.1193 25.091 19.9276 25.0644 20.7449C25.0289 22.0329 23.9914 22.7879 22.8032 22.406C22.218 22.2194 21.748 21.5532 21.7392 20.7626C21.7126 19.0571 21.7126 17.3605 21.748 15.655C21.7569 14.9444 22.1205 14.3848 22.8121 14.1094C23.4151 13.8696 23.9737 13.985 24.4614 14.3936C24.9136 14.7756 25.0644 15.2908 25.0644 15.8593C25.0821 16.6676 25.0732 17.4849 25.0732 18.3021Z"
        fill="#E6ECF1"
      />
      <path
        d="M4.08469 18.3287C4.08469 19.1993 4.12016 20.0787 4.07582 20.9492C4.02262 22.0684 3.02063 22.7435 1.95656 22.4237C1.28266 22.2194 0.812695 21.6065 0.803828 20.8159C0.786094 19.066 0.786094 17.325 0.803828 15.5751C0.812695 14.6957 1.53981 14.0206 2.44426 13.985C3.21571 13.9495 3.99602 14.598 4.04922 15.4418C4.11129 16.4012 4.06696 17.3605 4.06696 18.3287C4.07582 18.3287 4.07582 18.3287 4.08469 18.3287Z"
        fill="#E6ECF1"
      />
      <path
        d="M82.5234 18.3376C82.5234 23.7739 82.5234 29.2013 82.5234 34.6376C82.5234 35.6947 81.9737 36.3609 81.0072 36.4852C80.2534 36.5918 79.4465 36.0322 79.2869 35.2861C79.2337 35.0551 79.2071 34.8153 79.2071 34.5843C79.2071 23.7206 79.2071 12.848 79.1982 1.98433C79.1982 1.24706 79.3667 0.589728 80.0584 0.216649C81.22 -0.396266 82.4259 0.349892 82.5146 1.71785C82.5234 1.85109 82.5146 1.98433 82.5146 2.11757C82.5234 7.52722 82.5234 12.928 82.5234 18.3376Z"
        fill="#E6ECF1"
      />
      <path
        d="M73.9757 18.1689C73.9757 15.0777 73.9757 11.9864 73.9757 8.89521C73.9757 8.20235 74.153 7.59832 74.7826 7.23412C75.9708 6.55015 77.2388 7.3496 77.2831 8.78862C77.292 9.18835 77.2831 9.58807 77.2831 9.9878C77.2831 15.8416 77.2831 21.7043 77.2831 27.558C77.2831 28.3753 77.026 29.0415 76.2279 29.3879C75.2525 29.8054 74.153 29.1569 74.02 28.0999C73.9934 27.8689 73.9668 27.6291 73.9668 27.3981C73.9757 24.3247 73.9757 21.2423 73.9757 18.1689Z"
        fill="#E6ECF1"
      />
      <path
        d="M66.802 18.3287C66.802 20.2652 66.802 22.2016 66.802 24.147C66.802 24.8398 66.598 25.435 65.9596 25.7903C64.7714 26.4565 63.5034 25.6038 63.4856 24.1292C63.4768 22.6369 63.4856 21.1357 63.4856 19.6434C63.4856 17.2006 63.4768 14.7578 63.4856 12.315C63.4856 11.0004 64.5586 10.2009 65.7379 10.6007C66.3941 10.8227 66.7931 11.4889 66.7931 12.4216C66.802 14.3936 66.802 16.3567 66.802 18.3287Z"
        fill="#E6ECF1"
      />
      <path
        d="M84.4654 18.1955C84.4654 16.2146 84.4565 14.2249 84.4654 12.244C84.4742 10.9737 85.5028 10.2276 86.6733 10.6095C87.3383 10.8227 87.7551 11.5067 87.7551 12.4128C87.7551 15.1398 87.7551 17.8579 87.7551 20.585C87.7551 21.8286 87.764 23.0722 87.7551 24.3158C87.7374 25.5416 86.6378 26.3144 85.4851 25.888C84.8821 25.6659 84.5363 25.1951 84.492 24.5556C84.4476 23.9071 84.4654 23.2587 84.4654 22.6014C84.4654 21.1268 84.4654 19.6611 84.4654 18.1955Z"
        fill="#E6ECF1"
      />
      <path
        d="M72.0513 18.3021C72.0513 19.1193 72.069 19.9276 72.0424 20.7449C72.0069 22.0329 70.9695 22.7879 69.7813 22.406C69.196 22.2194 68.7261 21.5532 68.7172 20.7626C68.6906 19.0571 68.6906 17.3605 68.7261 15.655C68.7349 14.9444 69.0985 14.3848 69.7901 14.1094C70.3931 13.8696 70.9517 13.985 71.4394 14.3936C71.8917 14.7756 72.0424 15.2908 72.0424 15.8593C72.0601 16.6676 72.0513 17.4849 72.0513 18.3021Z"
        fill="#E6ECF1"
      />
      <path
        d="M92.9956 18.311C92.9956 19.1371 92.9956 19.9632 92.9956 20.7982C92.9867 21.5976 92.4547 22.2994 91.7364 22.4859C91.0537 22.6636 90.2734 22.326 89.9098 21.642C89.7679 21.3667 89.7059 21.0202 89.7059 20.7093C89.6881 19.0394 89.6881 17.3694 89.697 15.6994C89.7059 14.8822 90.2202 14.2249 90.965 14.0472C91.6921 13.8696 92.4458 14.216 92.7916 14.9444C92.9247 15.2286 92.969 15.5662 92.9779 15.886C93.0133 16.6854 92.9956 17.5026 92.9956 18.311Z"
        fill="#E6ECF1"
      />
    </g>
    <rect opacity="0.6" x="26.8091" width="34.6914" height="36.4983" rx="6" fill="#E6ECF1" />
    <path d="M33.3137 7.23157L54.9959 28.9137" stroke="#E6ECF1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M47.1116 15.4499V10.1871C47.1123 9.45383 46.8406 8.74649 46.3491 8.20235C45.8577 7.6582 45.1816 7.31608 44.452 7.24239C43.7225 7.1687 42.9917 7.36871 42.4013 7.80358C41.811 8.23845 41.4033 8.87716 41.2574 9.59572M41.1982 15.1148V18.0715C41.1988 18.6559 41.3724 19.227 41.6974 19.7127C42.0223 20.1985 42.4838 20.577 43.0238 20.8006C43.5637 21.0242 44.1578 21.0827 44.731 20.9689C45.3042 20.855 45.8308 20.5738 46.2443 20.1608L41.1982 15.1148Z"
      stroke="#E6ECF1"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M49.0824 22.9505C48.1203 23.9326 46.8867 24.6047 45.54 24.8808C44.1932 25.1569 42.7946 25.0244 41.5237 24.5001C40.2528 23.9759 39.1675 23.0839 38.4071 21.9385C37.6467 20.7932 37.2457 19.4468 37.2558 18.0721V16.101L41.0829 18.4299M51.0535 16.101V18.0721C51.0532 18.4785 51.0169 18.8842 50.9451 19.2843"
      stroke="#E6ECF1"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M44.1548 24.9706V28.9128" stroke="#E6ECF1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M40.2129 28.913H48.0973" stroke="#E6ECF1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'AudioVideoMutedIcon',
  props: {
    color: {
      type: String,
      default: '#E6ECF1'
    },
    width: {
      type: Number,
      default: 35
    },
    height: {
      type: Number,
      default: 35
    }
  }
}
</script>
