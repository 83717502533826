var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        _vm.screen === _vm.SCREEN.MAXIMIZED
          ? "video-audio-chat-video-visitor-maximized"
          : "",
    },
    [
      _c(
        "div",
        {
          staticClass: "flex video-audio-chat-video-container",
          class: {
            "video-audio-chat-video-container--mobile": _vm.isMobile,
          },
        },
        [
          _c(
            "div",
            {
              class: {
                "video-audio-chat-video-visitor-video-minimized":
                  _vm.screen === _vm.SCREEN.MINIMIZED,
                "video-audio-chat-video-visitor-video-maximized ":
                  _vm.screen !== _vm.SCREEN.MINIMIZED,
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        !_vm.visitorVideoOn || _vm.IS_VISITOR_SCREEN_SHARING,
                      expression:
                        "!visitorVideoOn || IS_VISITOR_SCREEN_SHARING",
                    },
                  ],
                  staticClass: "video-audio-chat-audio",
                  class: {
                    "video-audio-chat-audio-maximized":
                      _vm.screen === _vm.SCREEN.MAXIMIZED,
                    "video-audio-chat-audio-minimized":
                      _vm.screen !== _vm.SCREEN.MAXIMIZED,
                  },
                  style: _vm.isMobile
                    ? "width: 100%; height: " +
                      _vm.videoAudioHeight +
                      "px !important;"
                    : "",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "video-audio-chat-audio-header flex flex-col justify-center",
                    },
                    [
                      _vm.visitorAudioOn &&
                      !_vm.remoteAudioReady &&
                      !_vm.IS_VISITOR_SCREEN_SHARING
                        ? _c(
                            "div",
                            {
                              staticClass: "video-audio-chat-typing-indicator",
                            },
                            [
                              _c("span", {
                                staticStyle: {
                                  "background-color":
                                    "rgba(255, 255, 255, 0.6)",
                                },
                              }),
                              _c("span", {
                                staticStyle: {
                                  "background-color":
                                    "rgba(255, 255, 255, 0.8)",
                                },
                              }),
                              _c("span", {
                                staticStyle: {
                                  "background-color": "rgba(255, 255, 255, 1)",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm.isRemoteAudioVideoMuted
                        ? _c("audio-video-muted-icon", {
                            attrs: {
                              width: _vm.remoteVideoMutedWidth,
                              height: _vm.remoteVideoMutedHeight,
                            },
                          })
                        : _vm.visitorAudioOn
                        ? _c("audio-icon", {
                            attrs: { width: 199, height: 79 },
                          })
                        : _vm._e(),
                      !_vm.visitorAudioOn && !_vm.isRemoteAudioVideoMuted
                        ? _c(
                            "div",
                            { staticClass: "video-audio-chat-muted-icon" },
                            [
                              _c("audio-crossed-icon", {
                                attrs: { height: 20, width: 20 },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.visitorVideoOn && !_vm.IS_VISITOR_SCREEN_SHARING,
                      expression:
                        "visitorVideoOn && !IS_VISITOR_SCREEN_SHARING",
                    },
                  ],
                  staticClass: "video-audio-chat-video__wrapper",
                  style: _vm.isMobile
                    ? "width: 100%; height: " +
                      _vm.videoAudioHeight +
                      "px !important;"
                    : "",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "video-audio-chat-audio-header flex flex-col justify-center",
                    },
                    [
                      _vm.visitorVideoOn && !_vm.remoteVideoReady
                        ? _c(
                            "div",
                            {
                              staticClass: "video-audio-chat-typing-indicator",
                            },
                            [
                              _c("span", {
                                staticStyle: {
                                  "background-color":
                                    "rgba(255, 255, 255, 0.6)",
                                },
                              }),
                              _c("span", {
                                staticStyle: {
                                  "background-color":
                                    "rgba(255, 255, 255, 0.8)",
                                },
                              }),
                              _c("span", {
                                staticStyle: {
                                  "background-color": "rgba(255, 255, 255, 1)",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _c("video", {
                        ref: "remoteVideo",
                        attrs: {
                          id: "remote-video",
                          autoplay: "",
                          playsinline: "",
                        },
                      }),
                      !_vm.visitorAudioOn
                        ? _c(
                            "div",
                            { staticClass: "video-audio-chat-muted-icon" },
                            [
                              _c("audio-crossed-icon", {
                                attrs: { height: 20, width: 20 },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              class:
                _vm.screen === _vm.SCREEN.MINIMIZED
                  ? "video-audio-chat-video-agent-video-minimized " +
                    (_vm.isMobile && _vm.messageModes.includes("chat")
                      ? "video-audio-chat-video-agent-video-minimized--moved"
                      : "")
                  : "video-audio-chat-video-agent-video-maximized mt-2 mb-2 ml-4 mr-4",
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.showLocalVideo && _vm.showRemoteVideo,
                      expression: "!showLocalVideo && showRemoteVideo",
                    },
                  ],
                  staticClass: "video-audio-chat-local-audio",
                  class:
                    _vm.screen === _vm.SCREEN.MINIMIZED
                      ? "video-audio-chat-local-audio-small"
                      : "",
                },
                [
                  _c(
                    "div",
                    { staticClass: "video-audio-chat-local-audio-header" },
                    [
                      _c(
                        "div",
                        [
                          _vm.isLocalAudioVideoMuted
                            ? _c("audio-video-muted-icon", {
                                attrs: {
                                  width: _vm.localVideoMutedWidth,
                                  height: _vm.localVideoMutedHeight,
                                },
                              })
                            : _c("audio-icon", {
                                attrs: {
                                  width: _vm.localVideoMutedWidth,
                                  height: _vm.localVideoMutedHeight,
                                },
                              }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _c("video", {
                ref: "localVideo",
                staticClass: "video",
                staticStyle: {
                  opacity: "1",
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                },
                attrs: {
                  id: "local-video",
                  width: "300",
                  height: "400",
                  muted: "",
                  autoplay: "",
                  playsinline: "",
                },
                domProps: { muted: true },
              }),
              _c("canvas", {
                class:
                  _vm.screen === _vm.SCREEN.MINIMIZED
                    ? "video-audio-chat-local-audio-small"
                    : "video-audio-chat-video-visitor-video-maximized",
                staticStyle: {
                  "pointer-events": "none",
                  "z-index": "58000",
                  top: "0px",
                  right: "0px",
                  opacity: "0",
                  "object-fit": "cover",
                  "border-radius": "6px",
                },
                attrs: { width: "1280", height: "720", id: "output_canvas" },
              }),
            ]
          ),
        ]
      ),
      _vm.isSwitchButton
        ? _c(
            "button",
            {
              staticClass: "video-audio-chat__button-switch",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.switchCamera.apply(null, arguments)
                },
              },
            },
            [_c("camera-switch-icon", { on: { click: _vm.switchCamera } })],
            1
          )
        : _vm._e(),
      _vm.screen === _vm.SCREEN.MAXIMIZED
        ? _c("visitor-chats", {
            staticClass: "video-audio-chat-chat-messages",
            attrs: {
              screen: _vm.screen,
              "cobrowse-status": _vm.cobrowseStatus,
              "call-recording-status": _vm.callRecordingStatus,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }