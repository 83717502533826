<template>
  <div class="toolbar-tools flex">
    <div class="flex flex-row items-center" style="justify-content: flex-start; flex-grow: 1">
      <!-- FILE SHARING BUTTON -->
      <vx-tooltip :text="$t('tooltip.uploadFile')">
        <label for="files-sharing ">
          <div class="toolbar-tools-button" @click="onFileShareClick">
            <input
              ref="fileSharing"
              type="file"
              id="files-sharing"
              :disabled="isFileSharingDisabled"
              multiple
              style="display: none"
              @change="detectFiles"
              @click="onFileInputClick"
            />
            <clip-icon class="toolbar-container-icon" :width="20" :height="20" :color="fileSharingIconColor"></clip-icon>
          </div>
        </label>
      </vx-tooltip>

      <template v-if="isConnectedVisitor">
        <!-- SCREEN SHARING BUTTON -->

        <vx-tooltip
          v-if="!isMobile || (isMobile && isAudioVideoStartedBySomeone)"
          :text="HAS_SCREENSHARING_ACCESS ? $t('tooltip.screenSharing') : `${$t('tooltip.screenSharing')} - ${$t('upgrade-messages.locked-feature')}`"
          :showLockIcon="!HAS_SCREENSHARING_ACCESS"
        >
          <div class="toolbar-tools-button" @click="onScreenSharingClicked">
            <share-icon
              v-if="HAS_SCREEN_SHARING_STOPPED"
              class="toolbar-container-icon"
              :class="IS_SCREEN_SHARING ? 'toolbar-active' : ''"
              :width="25.41"
              :height="18"
              :color="SCREENSHARING_ICON_COLOR"
            ></share-icon>
            <loader-icon class="toolbar-container-icon" :width="20" :height="20" color="#12598d" v-if="HAS_SCREEN_SHARING_STARTED"></loader-icon>
            <share-icon
              v-if="IS_SCREEN_SHARING"
              class="toolbar-container-icon toolbar-tools-pulse"
              :class="IS_SCREEN_SHARING ? 'toolbar-active' : ''"
              :width="25.41"
              :height="18"
              :color="SCREENSHARING_ICON_COLOR"
            ></share-icon>
          </div>
        </vx-tooltip>

        <!-- LEAD FORM BUTTON -->

        <vx-tooltip :text="'Lead Form'">
          <div class="toolbar-tools-button" @click="onLeadFormClicked">
            <lead-form-icon class="toolbar-container-icon" :width="25.41" :height="18" color="#ffffff"></lead-form-icon>
          </div>
        </vx-tooltip>
      </template>


      <!-- COBROWSE BUTTON -->
      <!-- COBROWSE DISABLED
      <vx-tooltip
        v-if="!isMobile && !IS_APPSUMO_ACCOUNT && HAS_COBROWSING_ACCESS"
        :text="HAS_COBROWSING_ACCESS ? $t('tooltip.cobrowsing') : `${$t('tooltip.cobrowsing')} - ${$t('upgrade-messages.locked-feature')}`"
        :showLockIcon="!HAS_COBROWSING_ACCESS"
      >
        <div class="pl-1 pr-2 toolbar-tools-button" @click="onCobrowseClicked" :class="IS_COBROWSING ? 'toolbar-active toolbar-tools-pulse' : ''">
          <cobrowse-icon
            class="pl-1 toolbar-container-icon"
            :class="IS_COBROWSING ? 'toolbar-active' : ''"
            :width="30"
            :height="20"
            :color="!HAS_COBROWSING_ACCESS ? '#c4c4c4' : '#ffffff'"
            v-if="HAS_COBROWSING_STOPPED"
          ></cobrowse-icon>
          <loader-icon class="toolbar-container-icon" :width="20" :height="20" color="#12598d" v-if="HAS_COBROWSING_STARTED"></loader-icon>
          <cobrowse-icon class="toolbar-container-icon" :width="30" :height="20" v-if="IS_COBROWSING"></cobrowse-icon>
        </div>
      </vx-tooltip>
      -->
      <!-- RECORDING BUTTON -->
      <!-- <vx-tooltip :text="$t('tooltip.recordCall')">
        <div class="toolbar-tools-button" @click="onCallRecordingClicked">
          <recording-icon class="toolbar-container-icon" :width="20" :height="20" v-if="HAS_CALL_RECORDING_STOPPED"></recording-icon>
          <loader-icon class="toolbar-container-icon" :width="20" :height="20" color="#12598d" v-if="HAS_CALL_RECORDING_STARTED"></loader-icon>
          <recording-icon class="toolbar-container-icon toolbar-tools-pulse" color="#ff0000" :width="20" :height="20" v-if="IS_CALL_RECORDING"></recording-icon>
        </div>
      </vx-tooltip> -->
    </div>
    <!-- MORE ICON -->
    <!-- <div class="flex flex-row" style="justify-content: flex-end; flex-grow: 1">
      <div class="toolbar-tools-button">
        <more-icon class="toolbar-container-icon" :width="25" :height="25"></more-icon>
      </div>
    </div> -->
  </div>
</template>
<script>
import ClipIcon from '../../../components/icons/ClipIcon.vue'
import ShareIcon from '../../../components/icons/ShareIcon.vue'
import LeadFormIcon from '../../../components/icons/LeadFormIcon.vue'
//import CobrowseIcon from '../../../components/icons/CobrowseIcon.vue'
//import MoreIcon from '../../../components/icons/MoreIcon.vue'
import LoaderIcon from '../../../components/icons/LoaderIcon.vue'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
dayjs.extend(utc)

import { mapGetters, mapMutations } from 'vuex'

const COBROWSE_STATUS = {
  STOPPED: 'stopped',
  STARTED: 'started',
  COBROWSING: 'cobrowsing'
}

const CALL_RECORDING_STATUS = {
  STOPPED: 'stopped',
  STARTED: 'started',
  CALL_RECORDING: 'call-recording'
}

export default {
  components: {
    ClipIcon,
    ShareIcon,
    LeadFormIcon,
    //CobrowseIcon,
    //MoreIcon,
    LoaderIcon
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      company: 'company',
      screenSharingStatus: 'webrtc/screenSharingStatus',
      SCREEN_SHARING_STATUS: 'webrtc/SCREEN_SHARING_STATUS',
      visitor: 'webrtc/visitor'
    }),
    isFileSharingDisabled() {
      return this.company && this.company.disableFileSharing
    },
    fileSharingIconColor() {
      return this.isFileSharingDisabled ? '#d3d3d3' : '#ffffff'
    },
    isConnectedVisitor() {
      return this.visitor && this.activeUserInfo && (this.visitor.type === 'embed' || this.visitor.type === 'campaign') && (this.visitor.connectedAgentId === this.activeUserInfo.uid)
    },
    IS_APPSUMO_ACCOUNT() {
      return Boolean(this.company && this.company.isAppSumoAccount)
    },
    HAS_SCREENSHARING_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('screen-sharing') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },
    HAS_COBROWSING_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('co-browsing') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },
    IS_COBROWSING() {
      return this.cobrowseStatus === this.COBROWSE_STATUS.COBROWSING && (this.visitor.type === 'embed' || this.visitor.type === 'campaign')
    },
    HAS_COBROWSING_STARTED() {
      return this.cobrowseStatus === this.COBROWSE_STATUS.STARTED
    },
    HAS_COBROWSING_STOPPED() {
      return this.cobrowseStatus === this.COBROWSE_STATUS.STOPPED
    },
    IS_AGENT_SCREEN_SHARING() {
      return this.IS_SCREEN_SHARING && this.screenSharingStatus.initiatedBy === 'agent'
    },
    IS_SCREEN_SHARING() {
      return this.screenSharingStatus && this.screenSharingStatus.status === this.SCREEN_SHARING_STATUS.SCREEN_SHARING
    },
    HAS_SCREEN_SHARING_STARTED() {
      return this.screenSharingStatus && this.screenSharingStatus.status === this.SCREEN_SHARING_STATUS.STARTED
    },
    HAS_SCREEN_SHARING_STOPPED() {
      return (
        this.screenSharingStatus &&
        (this.screenSharingStatus.status === this.SCREEN_SHARING_STATUS.STOPPED || this.screenSharingStatus.status === this.SCREEN_SHARING_STATUS.ENDED)
      )
    },
    IS_CALL_RECORDING() {
      return this.callRecordingStatus === this.CALL_RECORDING_STATUS.CALL_RECORDING
    },
    HAS_CALL_RECORDING_STARTED() {
      return this.callRecordingStatus === this.CALL_RECORDING_STATUS.STARTED
    },
    HAS_CALL_RECORDING_STOPPED() {
      return this.callRecordingStatus === this.CALL_RECORDING_STATUS.STOPPED
    },
    SCREENSHARING_ICON_COLOR() {
      if (!this.HAS_SCREENSHARING_ACCESS) {
        return '#c4c4c4'
      }

      return this.HAS_SCREEN_SHARING_STOPPED || this.IS_AGENT_SCREEN_SHARING ? '#ffffff' : '#c4c4c4'
    }
  },
  props: {
    cobrowseStatus: {
      type: String,
      required: false,
      default: COBROWSE_STATUS.STOPPED
    },
    callRecordingStatus: {
      type: String,
      required: true
    },
    isMobile: {
      type: Boolean,
      required: false
    },
    isAudioVideoStartedBySomeone: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      COBROWSE_STATUS,
      CALL_RECORDING_STATUS
    }
  },
  methods: {
    ...mapMutations({
      updateHasFileSizeError: 'UPDATE_HAS_FILE_SIZE_ERROR'
    }),
    onCobrowseClicked() {
      if (this.cobrowseStatus !== this.COBROWSE_STATUS.STARTED && this.HAS_COBROWSING_ACCESS) {
        this.$serverBus.$emit('on-cobrowse-clicked')
      }
    },
    onScreenSharingClicked() {
      if (!this.HAS_SCREENSHARING_ACCESS) {
        return
      }

      if (
        this.screenSharingStatus &&
        this.screenSharingStatus.initiatedBy === 'visitor' &&
        (this.screenSharingStatus.status === this.SCREEN_SHARING_STATUS.STARTED ||
          this.screenSharingStatus.status === this.SCREEN_SHARING_STATUS.SCREEN_SHARING)
      ) {
        return
      }
      if (this.screenSharingStatus !== this.SCREEN_SHARING_STATUS.STARTED) {
        this.$serverBus.$emit('on-screen-sharing-clicked')
      }
    },

    onLeadFormClicked() {
      this.$serverBus.$emit('on-lead-form-clicked')
    },

    onCallRecordingClicked() {
      if (this.callRecordingStatus !== this.CALL_RECORDING_STATUS.STARTED) {
        this.$serverBus.$emit('on-call-recording-clicked')
      }
    },

    detectFiles(event) {
      const { files } = event.target
      let hasFilesizeError = false
      for (const file in files) {
        if (files[file] && files[file].size && files[file].size / 1024 > 10240) {
          hasFilesizeError = true
        }
      }

      this.updateHasFileSizeError(hasFilesizeError)

      if (!hasFilesizeError) {
        this.$serverBus.$emit('upload-files', hasFilesizeError ? null : files)
      }
    },

    onFileShareClick() {
      if (this.isFileSharingDisabled) return
      this.$refs.fileSharing.click()
    },

    onFileInputClick(event) {
      event.stopPropagation()
      /* makes it possible to send the same file twice,
      one after another
      (the browser caches the change event for performance purposes) */
      event.target.value = ''
    }
  }
}
</script>
<style lang="scss" scoped>
.toolbar {
  &-active {
    background-color: #f05541 !important;
  }
  &-disabled {
    background-color: #c4c4c4 !important;
  }
  &-container {
    &-icon:hover {
      margin-top: -2px;
    }
  }
  &-tools {
    margin-top: auto;
    background-color: #275D73 !important;
    height: 35px;

    &-button {
      padding-left: 6px;
      padding-right: 6px;
      width: auto;
      height: 30px;

      background: transparent;
      border-radius: 6px;
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      transition: 0.1s;
      margin-left: 3px;
      margin-right: 3px;
    }

    &-pulse {
      box-shadow: 0 0 0 rgb(240, 85, 65);
      animation: pulse 2s infinite;
      border-radius: 6px;
    }
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(240, 85, 65, 1);
  }
  70% {
    -webkit-box-shadow: 0 0 0 15px rgba(255, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(240, 85, 65, 1);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 15px rgba(255, 0, 0, 0);
    box-shadow: 0 0 0 15px rgba(255, 0, 0, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}
</style>
