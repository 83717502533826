<template>
  <div class="agent-header">
    <div class="flex flex-row items-center ht-100p" :class="{ 'ml-4': !isMobile, 'ml-6 pl-1': isMobile }">
      <Avatar
        class="agent-header-avatar"
        :class="{
          'agent-header-avatar__with-border': activeUserInfo && activeUserInfo.photoURL === '/user.png'
        }"
        size="45px"
        fontSize="18px"
        :photoURL="activeUserInfo.photoURL"
        :name="agent"
      />

      <audio-video-settings
        v-if="showSettings"
        @close="onCloseSettings()"
        :show="showSettings"
        :selected-audio="selectedAudio"
        :selected-video="selectedVideo"
        :selected-speaker="selectedSpeaker"
        :volume-level="volumeLevel"
        :isMobile="isMobile"
        :isFirstSetupOnMobile="isFirstSetupOnMobile"
      ></audio-video-settings>

      <div class="text-white">
        <small class="agent-header-company capitalize">{{ company }}</small>
        <h6 class="agent-header-agent capitalize">{{ agent }}</h6>
      </div>

      <div class="chat-header-navbar-button ml-auto agent-header-settings-button" @click="showSettings = true">
        <vx-tooltip :text="$t('vue.settings')" class="inline-flex" position="top">
          <vs-button :text-color="'#00A9F5'" :color="'#E8F6FF'" type="filled" size="large" icon-pack="feather" icon="icon-settings"></vs-button>
        </vx-tooltip>
      </div>

      <div class="agent-header-edit" :class="{ 'mr-5': !isMobile, 'mr-3': isMobile }">
        <div v-if="activeUserInfo.role !== 'agent'" class="agent-header-button ml-2" @click="onSelectVisitors()">
          <vx-tooltip :text="$t('vue.edit')" class="inline-flex" position="top" v-if="!isSelectable">
            <vs-button :text-color="'#00A9F5'" :color="'#E8F6FF'" type="filled" size="large" icon-pack="feather" icon="icon-edit"></vs-button>
          </vx-tooltip>
          <vx-tooltip :text="$t('vue.close')" class="inline-flex" position="top" v-else>
            <vs-button :text-color="'#00A9F5'" :color="'#E8F6FF'" type="filled" size="large" icon-pack="feather" icon="icon-x"></vs-button>
          </vx-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import Avatar from '@/components/Avatar'
import AudioVideoSettings from '../messages/AudioVideoSettings'

export default {
  components: {
    Avatar,
    AudioVideoSettings
  },
  props: {
    selectVisitors: {
      type: Boolean,
      required: true
    },
    selectedAudio: {
      type: Object,
      required: false,
      default: null
    },
    selectedSpeaker: {
      type: Object,
      required: false,
      default: null
    },
    selectedVideo: {
      type: Object,
      required: false,
      default: null
    },
    volumeLevel: {
      type: Number,
      required: true
    },
    isMobile: {
      type: Boolean,
      required: false
    }
  },
  watch: {
    selectVisitors() {
      if (!this.selectVisitors) {
        this.isSelectable = false
      }
    }
  },
  data() {
    return {
      isSelectable: false,
      showSettings: false,
      isFirstSetupOnMobile: false
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      companyInfo: 'company'
    }),

    agent() {
      let agentName = null
      if (this.activeUserInfo && this.activeUserInfo.displayName) {
        agentName = this.activeUserInfo.displayName ? this.activeUserInfo.displayName : ''
      } else if (this.activeUserInfo && this.activeUserInfo.firstname) {
        agentName = this.activeUserInfo.firstname
        if (this.activeUserInfo.lastname) {
          agentName = `${agentName} ${this.activeUserInfo.lastname}`
        }
      } else {
        agentName = this.activeUserInfo.displayName ? this.activeUserInfo.displayName : ''
      }
      return agentName
    },

    company() {
      let companyName = null
      if (this.companyInfo && this.companyInfo.companyDisplayName) {
        companyName = this.companyInfo.companyDisplayName ? this.companyInfo.companyDisplayName : ''
      } else if (this.companyInfo && this.companyInfo.name) {
        companyName = this.companyInfo.name
      }
      return companyName
    }
  },
  created() {
    this.$serverBus.$on('first-setup-av-settings-on-mobile', () => {
      this.isFirstSetupOnMobile = true
      this.showSettings = true
    })
  },
  methods: {
    onSelectVisitors() {
      this.isSelectable = !this.isSelectable
      this.$emit('select-visitors')
    },
    onCloseSettings() {
      this.showSettings = false
      this.isFirstSetupOnMobile = false
    }
  }
}
</script>

<style lang="scss" scoped>
.ht-100p {
  height: 100%;
}
.agent-logo-name {
  position: relative;
  width: 45px;
  height: 45px;
  background: rgb(230, 236, 241);
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  color: #12598d;
  font-size: 25px;
  font-weight: 700;
  line-height: 0;
}
.agent-header {
  background-color: #e8f6ff !important;
  border-top-left-radius: 6px;
  box-sizing: border-box;
  height: 65px;
  border: 0.5px solid #e6ecf1;
  border-right: none;
  &-avatar {
    margin-right: 15px;
    &.agent-header-avatar__with-border {
      border: 1px solid #00a9f5;
      border-radius: 50%;
    }
  }
  &-settings-button {
    display: none;

    @media only screen and (max-width: 1024px) {
      display: flex;
    }
  }
  &-edit {
    margin-left: auto;

    @media only screen and (max-width: 1024px) {
      margin-left: 0;
    }
  }
  &-company {
    font-weight: 300;
    font-size: 16px;
    color: #262629;
  }
  &-agent {
    font-weight: 700;
    font-size: 18px;
    color: #262629;
  }
  &-button {
    width: 35px;
    height: 35px;
    background: transparent;
    border-radius: 6px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    transition: 0.1s;
    &:hover {
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.8);
    }
  }
}
</style>
