var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.activeUserInfo && !_vm.activeUserInfo.impersonated
    ? _c(
        "div",
        {
          staticClass:
            "btn-add-new mw-255 mb-2 rounded-lg cursor-pointer flex items-center justify-center font-medium text-base text-primary",
          class: { "mr-auto": _vm.isMobile },
        },
        [
          !_vm.isMobile
            ? [
                _c("feather-icon", {
                  attrs: { icon: "BellIcon", svgClasses: "h-4 w-4" },
                }),
                _c(
                  "label",
                  {
                    staticClass: "ml-2 mr-2",
                    staticStyle: { "font-size": "12px" },
                  },
                  [_vm._v(_vm._s(_vm.$t("vue.notifications")))]
                ),
              ]
            : _vm._e(),
          _c(
            "vs-dropdown",
            {
              staticClass: "mr-2",
              class: { "pa-dropdown--mobile": _vm.isMobile },
              attrs: { "vs-custom-content": "", "vs-trigger-click": "" },
            },
            [
              _vm.isMobile
                ? [
                    _c("feather-icon", {
                      attrs: { icon: "BellIcon", svgClasses: "h-4 w-4" },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "ml-2 mr-2",
                        staticStyle: { "font-size": "12px" },
                      },
                      [_vm._v(_vm._s(_vm.$t("vue.notifications")))]
                    ),
                  ]
                : _vm._e(),
              _c(
                "a",
                {
                  staticClass: "a-icon",
                  class: { flex: _vm.isMobile },
                  attrs: { "href.prevent": "" },
                },
                [_c("vs-icon", { attrs: { icon: "expand_more" } })],
                1
              ),
              _c(
                "vs-dropdown-menu",
                { staticClass: "notification-type" },
                [
                  _c(
                    "vs-checkbox",
                    {
                      staticClass: "notification-type__checkbox",
                      on: { change: _vm.updateSubscription },
                      model: {
                        value: _vm.subscriptions.email,
                        callback: function ($$v) {
                          _vm.$set(_vm.subscriptions, "email", $$v)
                        },
                        expression: "subscriptions.email",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("vue.email")))]
                  ),
                  _c(
                    "vs-checkbox",
                    {
                      staticClass: "notification-type__checkbox",
                      on: { change: _vm.updateSubscription },
                      model: {
                        value: _vm.subscriptions.push,
                        callback: function ($$v) {
                          _vm.$set(_vm.subscriptions, "push", $$v)
                        },
                        expression: "subscriptions.push",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("vue.push")))]
                  ),
                  _c(
                    "vs-checkbox",
                    {
                      staticClass: "notification-type__checkbox",
                      on: { change: _vm.updateSubscription },
                      model: {
                        value: _vm.subscriptions.audio,
                        callback: function ($$v) {
                          _vm.$set(_vm.subscriptions, "audio", $$v)
                        },
                        expression: "subscriptions.audio",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("vue.ringtone")))]
                  ),
                  _vm.subscriptions.audio
                    ? _c(
                        "vs-collapse",
                        {
                          staticClass: "notification-type__accordion",
                          attrs: { accordion: "" },
                        },
                        [
                          _c(
                            "vs-collapse-item",
                            {
                              staticClass:
                                "notification-type__accordion-header",
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "header" }, slot: "header" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("vue.ringtoneOutput")) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._l(_vm.audioOutputDevices, function (device) {
                                return _c(
                                  "div",
                                  {
                                    key: device.value,
                                    staticClass: "notification-type__output",
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectAudioOutputDevice(
                                          device
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.subscriptions
                                                .audioOutputDevice,
                                            expression:
                                              "subscriptions.audioOutputDevice",
                                          },
                                        ],
                                        staticClass:
                                          "notification-type__output-device",
                                        attrs: {
                                          type: "radio",
                                          name: "audio-output-devices",
                                        },
                                        domProps: {
                                          value: device,
                                          checked: _vm._q(
                                            _vm.subscriptions.audioOutputDevice,
                                            device
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$set(
                                              _vm.subscriptions,
                                              "audioOutputDevice",
                                              device
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                    _c("div", [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "notification-type__output-label",
                                        },
                                        [_vm._v(_vm._s(device.text))]
                                      ),
                                    ]),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
          _vm.isMobile
            ? _c(
                "popper",
                {
                  attrs: {
                    trigger: "clickToToggle",
                    options: {
                      placement: "top",
                      modifiers: { offset: { offset: "0,10px" } },
                    },
                  },
                },
                [
                  _c("div", { staticClass: "popper custom-popper" }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("info.visitorNotifications")) +
                        "\n    "
                    ),
                  ]),
                  _c(
                    "label",
                    {
                      staticClass: "flex",
                      attrs: { slot: "reference" },
                      slot: "reference",
                    },
                    [
                      _c("img", {
                        staticStyle: { display: "inline-block" },
                        attrs: { width: "17", height: "17", src: _vm.infoImg },
                      }),
                    ]
                  ),
                ]
              )
            : _c(
                "vx-tooltip",
                {
                  staticClass: "flex items-center",
                  staticStyle: { width: "17px" },
                  attrs: {
                    text: _vm.$t("info.visitorNotifications"),
                    position: "top",
                  },
                },
                [
                  _c("img", {
                    staticStyle: { display: "inline-block" },
                    attrs: { width: "17", height: "17", src: _vm.infoImg },
                  }),
                ]
              ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }