var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "audio-call" }, [
    _c("div", { staticClass: "audio-call-start" }, [
      _c("div", { staticClass: "audio-call-row" }, [
        _c(
          "div",
          {
            staticClass:
              "audio-call-button flex flex-row justify-center items-center",
            class: _vm.isAudioActive
              ? " video-call-pulse active"
              : " video-call-pulse inactive",
            on: { click: _vm.onStartAudio },
          },
          [
            _c("microphone-icon", {
              staticClass: "audio-call-button-icon",
              attrs: { width: 15, height: 25 },
            }),
            _c("span", [_vm._v(_vm._s(_vm.$t("vue.startAudio")))]),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }