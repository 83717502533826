<template>
  <svg :width="width" :height="height" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M51 52V46C51 42.8174 49.683 39.7652 47.3388 37.5147C44.9946 35.2643 41.8152 34 38.5 34H13.5C10.1848 34 7.00537 35.2643 4.66116 37.5147C2.31696 39.7652 1 42.8174 1 46V52"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M25.5 26C32.4036 26 38 20.4036 38 13.5C38 6.59644 32.4036 1 25.5 1C18.5964 1 13 6.59644 13 13.5C13 20.4036 18.5964 26 25.5 26Z"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'BlurBackgroundIcon',
  props: {
    color: {
      type: String,
      default: '#12598D'
    },
    width: {
      type: Number,
      default: 52
    },
    height: {
      type: Number,
      default: 53
    }
  }
}
</script>
