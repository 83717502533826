<template>
  <div class="visitor flex pt-4 pb-2" :class="{ 'bg-secondary': isActiveChatUser, 'visitor--mobile': isMobile }">
    <div class="w-full flex flex-row items-center overflow-hidden">
      <div class="visitor-info-holder flex flex-col">
        <div v-if="!!getDialogOrCampaignName(visitor)" class="visitor-source" :class="VISITOR_SOURCE_CLASS">
          <VisitorStatusIcon
            :score="visitor.scoreHighest || visitor.score"
            :online="visitor.online"
            :isVegaWidgetEnabled="visitor.isVegaWidgetEnabled"
            class="visitor-source--status-icon"
          />
          <span>{{ getDialogOrCampaignName(visitor) }} </span>
        </div>
        <div class="visitor-card flex flex-row justify-content items-center">
          <div v-if="isSelectVisitors" class="mr-2">
            <vs-checkbox class="visitor-card__checkbox" v-model="visitor.isSelected"></vs-checkbox>
          </div>
          <div class="">
            <div class="visitor-isp">{{ getVisitorInfo(visitor) }}</div>
            <div class="flex flex-row">
              <div>
                <vx-tooltip :text="countryName(visitor.country)" position="top">
                  <img
                    v-if="visitor.country"
                    width="20"
                    :alt="countryName(visitor.country)"
                    :src="`https://storage.googleapis.com/pathadvice-app.appspot.com/flags/${visitor.country.toUpperCase()}.svg`"
                  />
                </vx-tooltip>
              </div>
              <div class="ml-2 visitor-lastseen">
                <!-- <span>{{ visitor.lastSeen }}</span> -->
                <span class="ml-2">{{ visitor.lastSeenTimestamp }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="showCommunicationControl"
        class="visitor-communication-button"
        :class="{
          'visitor-pulse': visitor.agentRequest
        }"
        @click="onStartCommunicationWithVisitor($event)"
      >
        <div class="flex" v-if="visitor.agentRequestType === 'chat' || (!visitor.agentRequest && !!unreadChatMessages)">
          <chat-icon :width="16" :height="16">
            <div class="visitor-chats-unread-count flex justify-center items-center" v-if="unreadChatMessages">
              {{ unreadChatMessages }}
            </div>
          </chat-icon>
        </div>

        <microphone-icon v-else-if="visitor.agentRequestType === 'audio'" :width="16" :height="16"></microphone-icon>

        <video-call-icon v-else-if="visitor.agentRequestType === 'video'" :width="16" :height="16" color="#fff"></video-call-icon>
      </div>

      <template v-else>
        <div v-if="showConnectedAgent" class="visitor-connected-agent visitor-pulse br-25 visitor-connected-agent-wh44">
          <Avatar
            class="circle bd-green"
            size="44px"
            :photoURL="agentImage(visitor.connectedAgentId, visitor.id)"
            :name="agentName(visitor.connectedAgentId)"
          />
          <div v-if="visitor.visitorMessages" class="visitor-chats-unread-count visitor-messages-unread-count flex justify-center items-center">
            {{ visitor.visitorMessages }}
          </div>
        </div>
        <div v-else-if="showCompletedChatAgent" class="visitor-connected-agent relative">
          <div style="position: absolute; width: 100px; height: 60px; right: 0px; z-index: 999999" @click="showConnectedAgentsInfo($event, visitor.id)"></div>
          <div class="visitor-connected-agents" v-if="visitorConnectedAgents">
            <div
              v-if="showCheckMarkForConnectedAgents"
              class="visitor-connected-agent-live"
              :style="{ marginRight: `${visitorConnectedAgents.length * 12 + 48}px`, top: '7px' }"
            ></div>
            <template v-for="(v, index) in visitorConnectedAgents">
              <div
                v-if="v.type === 'missed'"
                :key="index"
                class="visitor-connected-agents-agent visitor-connected-agents-agent-missed"
                :style="{ marginRight: `${index * 12}px` }"
              >
                missed
              </div>
              <Avatar
                v-else
                :key="index"
                class="visitor-connected-agents-agent visitor-connected-agents-agent-joined"
                :style="{ marginRight: `${index * 12}px` }"
                size="43px"
                :photoURL="agentImage(v.agentId)"
                :name="agentName(v.agentId)"
              />
            </template>

            <connected-agent-info :agents="visitorConnectedAgentsAll" :visitorId="visitor.id" />
          </div>
          <div v-else>
            <div class="visitor-connected-agent-live" :style="{ marginRight: '60px', top: '7px' }"></div>
            <Avatar class="visitor-connected-agents-agent" size="45px" :photoURL="agentImage(visitor.agentId)" :name="agentName(visitor.agentId)" />
          </div>
        </div>
        <div class="visitor-connected-agent relative" v-else-if="visitorConnectedAgents">
          <div style="position: absolute; width: 100px; height: 60px; right: 0px; z-index: 999999" @click="showConnectedAgentsInfo($event, visitor.id)"></div>
          <div class="visitor-connected-agents">
            <template v-for="(v, index) in visitorConnectedAgents">
              <div
                v-if="v.type === 'missed'"
                :key="index"
                class="visitor-connected-agents-agent visitor-connected-agents-agent-missed"
                :style="{ marginRight: `${index * 12}px` }"
              >
                missed
              </div>
              <Avatar
                v-else
                :key="index"
                class="visitor-connected-agents-agent visitor-connected-agents-agent-joined"
                :style="{ marginRight: `${index * 12}px` }"
                size="43px"
                :photoURL="agentImage(v.agentId)"
                :name="agentName(v.agentId)"
              />
            </template>

            <connected-agent-info :agents="visitorConnectedAgentsAll" :visitorId="visitor.id" />
          </div>
        </div>
        <div
          class="visitor-connected-agent relative"
          v-else-if="!visitor.connectedAgentId && (visitor.type === 'embed' || (visitor.type === 'campaign' && visitor.isNewFlowCampaign))"
        >
          <div class="visitor-connected-agents-agent visitor-connected-agents-agent-missed">missed</div>
        </div>

        <div v-else class="visitor-connected-agent"></div>
      </template>
      <div
        v-if="showCampaignCobrowseControl && !IS_APPSUMO_ACCOUNT"
        class="pl-1 pr-2 visitor-communication-button visitor-communication-button-campaign visitor-pulse"
        @click="onJoinSession()"
      >
        <cobrowse-icon :width="42" :height="22"></cobrowse-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ChatIcon from '../../../components/icons/ChatIcon.vue'
import CobrowseIcon from '../../../components/icons/CobrowseIcon.vue'
import VideoCallIcon from '../../../components/icons/VideoCallIcon.vue'
import MicrophoneIcon from '../../../components/icons/MicrophoneIcon.vue'

import VisitorStatusIcon from '@/components/vega/VisitorStatusIcon'

import ConnectedAgentInfo from './ConnectedAgentInfo.vue'

import Avatar from '@/components/Avatar'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

export default {
  components: {
    ChatIcon,
    CobrowseIcon,
    VideoCallIcon,
    MicrophoneIcon,
    Avatar,
    ConnectedAgentInfo,
    VisitorStatusIcon
  },
  data() {
    return {
      countryCodes: require('@/assets/countryCodes.json'),
      selected: false,
      defaultAgentImage: require('@/assets/images/logo/logo.svg'),
      maxAgentIcons: 3
    }
  },
  props: {
    visitor: { type: Object, required: true },
    isSelectVisitors: { type: Boolean, required: true },
    isMobile: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      company: 'company',
      campaigns: 'campaigns',
      dialogs: 'dialogs',
      users: 'users',
      visitorId: 'webrtc/visitorId'
    }),
    IS_APPSUMO_ACCOUNT() {
      return Boolean(this.company && this.company.isAppSumoAccount)
    },
    isActiveChatUser() {
      if (!this.visitor) {
        return false
      }
      return this.visitor.id === this.visitorId
    },
    HAS_B2B_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('b2b-identification') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },

    showCheckMarkForConnectedAgents() {
      if (this.visitorConnectedAgents) {
        const length = this.visitorConnectedAgents.length - 1
        const call = this.visitorConnectedAgents[length]
        return call && call.type !== 'missed'
      }

      return false
    },

    modifiedVisitorConnectedAgents() {
      if (!(this.visitor && this.visitor.connectedAgents)) {
        return []
      }

      const { chats, connectedAgents } = this.visitor

      try {
        return connectedAgents.map((item, index, array) => {
          if (item.type !== 'missed') {
            return item
          }

          const updateItem = { ...item, type: 'agent-answered' }
          let copyUpdateItemFound = false

          const callRequestTime = dayjs(item.missedCallDate.seconds * 1000)

          chats.some((chatItem) => {
            if (chatItem.sender === 'agent' && chatItem.time && chatItem.time.seconds) {
              const chatMessageTime = dayjs(chatItem.time.seconds * 1000)
              const nextCallRequest = array[index + 1]
              let timeCondition = false

              if (nextCallRequest) {
                const nextCallTime =
                  nextCallRequest.type === 'missed'
                    ? dayjs(nextCallRequest.missedCallDate.seconds * 1000)
                    : dayjs(nextCallRequest.sessionStartTime.seconds * 1000)

                timeCondition = chatMessageTime.isSameOrAfter(callRequestTime) && chatMessageTime.isSameOrBefore(nextCallTime)
              } else {
                timeCondition = chatMessageTime.isSameOrAfter(callRequestTime)
              }

              if (timeCondition) {
                updateItem.agentId = chatItem.agentId
                updateItem.agentName = chatItem.agentName
                updateItem.sessionStartTime = chatItem.time
                updateItem.sessionEndTime = chatItem.time
                updateItem.sortTime = chatItem.time.seconds * 1000
                copyUpdateItemFound = true

                return true
              }
            }
          })

          return copyUpdateItemFound ? updateItem : item
        })
      } catch (err) {
        return connectedAgents
      }
    },

    visitorConnectedAgents() {
      if (this.visitor && this.visitor.connectedAgents) {
        const connectedAgents = this.modifiedVisitorConnectedAgents.map((x) => {
          {
            return { ...x }
          }
        })
        const sortedAgents = connectedAgents.sort((a, b) => {
          return a.sortTime - b.sortTime
        })

        if (sortedAgents.length > this.maxAgentIcons) {
          return sortedAgents.slice(sortedAgents.length - this.maxAgentIcons, sortedAgents.length)
        }
        return sortedAgents
      }
      return null
    },

    visitorConnectedAgentsAll() {
      if (this.visitor && this.visitor.connectedAgents) {
        const connectedAgents = this.modifiedVisitorConnectedAgents.map((x) => {
          {
            return { ...x }
          }
        })
        const sortedAgents = connectedAgents.sort((a, b) => {
          return b.sortTime - a.sortTime
        })
        return sortedAgents
      }
      return null
    },
    VISITOR_SOURCE_CLASS() {
      if (
        this.visitor &&
        ((this.visitor.callCenterId && this.visitor.callCenterName) || (this.visitor.previousCallCenterId && this.visitor.previousCallCenterName))
      ) {
        return 'visitor-source-call-center'
      }
      if (this.visitor.type === 'campaign') {
        return 'visitor-source-campaign'
      }

      return 'visitor-source-embed'
    },

    showConnectedAgent() {
      return this.visitor && this.visitor.connectedAgentId && !this.visitor.agentRequest
    },
    showCompletedChatAgent() {
      return (
        this.visitor &&
        !this.visitor.connectedAgentId &&
        (this.visitor.agentId || (!this.visitor.agentId && this.visitor.connectedAgentIds && this.visitor.connectedAgentIds.length > 0))
      )
    },
    isCallCenterCompany() {
      return this.company && this.company.isCallCenter && this.visitor.company !== this.activeUserInfo.company
    },
    showCompanyAgentCommunicationControl() {
      return (
        this.visitor &&
        !this.visitor.connectedAgentId &&
        this.visitor.agentRequest &&
        this.activeUserInfo &&
        this.visitor.company === this.activeUserInfo.company &&
        (!this.visitor.agentsToBeNotified || this.visitor.agentsToBeNotified.length === 0 || this.visitor.agentsToBeNotified.includes(this.activeUserInfo.uid))
      )
    },

    isCurrentUserInAgentsToBeNotified() {
      return this.visitor && this.activeUserInfo && this.visitor.agentsToBeNotified && this.visitor.agentsToBeNotified.includes(this.activeUserInfo.uid)
    },

    unreadMessagesByAllAgents() {
      const visitor = this.visitor

      if (!visitor || visitor.connectedAgentId) {
        return []
      } else if (visitor.chats) {
        const lastMessage = visitor.chats[visitor.chats.length - 1]

        if (lastMessage && (lastMessage.sender === 'agent' || (lastMessage.agentInfo && lastMessage.agentInfo.connectedAgent))) {
          return []
        }
      }

      const unreadMessagesByAllAgents = []

      let lastReadTime = null
      const isConnectedAgents = visitor.connectedAgents && visitor.connectedAgents.length

      if (isConnectedAgents) {
        const lastConnectedAgent = [...visitor.connectedAgents].reverse().find((item) => item.type === 'agent-joined')

        if (lastConnectedAgent && lastConnectedAgent.sessionEndTime) {
          lastReadTime = dayjs(lastConnectedAgent.sessionEndTime.seconds * 1000)
            .tz(this.$defaultTimezone)
            .diff('2021-01-01')
        }
      }

      if (visitor.lastReadChatsAgentInfo) {
        visitor.lastReadChatsAgentInfo.forEach((item) => {
          if (!lastReadTime || item.lastRead > lastReadTime) {
            lastReadTime = item.lastRead
          }
        })
      }

      if (visitor.chats) {
        visitor.chats.forEach((item) => {
          if (item.sender === 'visitor' && item.chatTime > lastReadTime) {
            unreadMessagesByAllAgents.push(item)
          }
        })
      }

      return unreadMessagesByAllAgents
    },

    showCommunicationControl() {
      return this.visitor && !this.visitor.connectedAgentId && (this.isVisitorRequestToAcceptableAgents || !!this.unreadMessagesByAllAgents.length)
    },
    isVisitorRequestToAcceptableAgents() {
      return (
        this.visitor &&
        this.visitor.agentRequest &&
        (this.isCallCenterCompany ? this.isCurrentUserInAgentsToBeNotified : this.showCompanyAgentCommunicationControl)
      )
    },
    showCampaignCobrowseControl() {
      return (
        this.visitor &&
        this.visitor.online &&
        this.visitor.cobrowse &&
        this.visitor.type === 'campaign' &&
        this.visitor.surlfyURL &&
        this.visitor.surlfyURL.length > 0
      )
    },
    unreadChatMessages() {
      if (this.visitor && this.visitor.chats) {
        if (this.visitor.lastReadChatsAgentInfo) {
          if (this.visitor.lastReadChatsAgentInfo.find((x) => x.agentId === this.activeUserInfo.uid)) {
            const lastReadAgentInfo = this.visitor.lastReadChatsAgentInfo.find((x) => x.agentId === this.activeUserInfo.uid)
            const totalUnread = this.visitor.chats.filter((x) => x.sender === 'visitor' && x.chatTime > lastReadAgentInfo.lastRead).length
            return totalUnread > 0 ? totalUnread : null
          }
        }
        return this.unreadMessagesByAllAgents.length
      }
      return null
    }
  },
  watch: {
    selected() {
      if (this.selected) {
        this.visitor.isSelected = true
      } else {
        this.visitor.isSelected = false
      }
    },
    'visitor.agentRequest'() {
      if (!this.visitor.agentRequest) {
        const audioElement = document.getElementById('visitor-request-audio')
        audioElement.pause()
        /* eslint-disable no-console */
        // console.log('visitor.agentRequest: emit check-if-audio-needs-to-be-played')
        this.$serverBus.$emit('check-if-audio-needs-to-be-played')
      }
    },
    'visitor.connectedAgentId'() {
      if (!this.visitor.connectedAgentId) {
        /* eslint-disable no-console */
        // console.log('visitor.connectedAgentId: emit check-if-audio-needs-to-be-played')
        this.$serverBus.$emit('check-if-audio-needs-to-be-played')
      }
    }
  },
  methods: {
    showConnectedAgentsInfo(e, visitorId) {
      if (this.isMobile) {
        return
      }

      e.stopPropagation()
      const info = {
        event: e,
        visitorId
      }
      this.$serverBus.$emit('show-connected-agent-info', info)
    },

    getVisitorInfo(visitor) {
      if (!visitor) {
        return ''
      }

      if (visitor.b2b && !this.HAS_B2B_ACCESS) {
        return this.$i18n.t('vue.businessConnection')
      }

      if (visitor.visitorCompanyName) {
        return visitor.visitorCompanyName
      }

      if ((visitor.callCenterId && visitor.callCenterName) || (visitor.previousCallCenterId && visitor.previousCallCenterName)) {
        return visitor.dialogName || (visitor.dialogInfo && visitor.dialogInfo.name) || visitor.campaignName
      } else {
        return visitor.companyName || visitor.city
      }
    },
    getDialogOrCampaignName(data) {
      let campaignName = null
      if (data.campaignId && this.campaigns) {
        const hasCampaign = this.campaigns.filter((x) => x.id === data.campaignId).length > 0
        campaignName = hasCampaign ? this.campaigns.filter((x) => x.id === data.campaignId)[0].name : data.campaignName || null
      }

      // Call Center
      if (this.company && this.company.isCallCenter) {
        if ((data.callCenterId && data.callCenterName) || (data.previousCallCenterId && data.previousCallCenterName)) {
          return data.dialogCompanyName || data.campaignCompanyName
        } else {
          return data.dialogName || (data.dialogInfo && data.dialogInfo.name) || data.campaignName
        }
      } else if ((data.callCenterId && data.callCenterName) || (data.previousCallCenterId && data.previousCallCenterName)) {
        return data.callCenterName || data.previousCallCenterName
      } else {
        return data.dialogName || (data.dialogInfo && data.dialogInfo.name) || campaignName
      }
    },
    countryName(countryCode) {
      const countryCodes = this.countryCodes.filter((country) => country.code === (countryCode ? countryCode.toLowerCase() : ''))
      return countryCodes && countryCodes.length > 0 ? countryCodes[0].countryName : ''
    },

    agentImage(agentId) {
      const agent = this.users.find((x) => x.id === agentId)

      if (agent && agent.image) {
        return agent.image
      }

      return ''
    },

    agentName(agentId) {
      const agent = this.users.find((x) => x.id === agentId)

      if (!agent) {
        return ''
      }

      return agent.name
    },

    onStartCommunicationWithVisitor(event) {
      event.stopPropagation()

      if (this.visitor.agentRequest) {
        this.$emit('start-communication-with-visitor')
      } else {
        this.$emit('update-active-chat-visitor')
      }
    },

    async onJoinSession() {
      let surlfyURL = this.visitor.surlfyURL
      const joinURL = this.visitor.joinURL
      const visitorId = this.visitor.id

      const ref = this.$database.ref(`/status/${visitorId}`)
      const event = {}
      event.joinedSession = true
      event.joinURL = joinURL

      if (this.activeUserInfo.displayName) {
        event.agent = this.activeUserInfo.displayName
      } else {
        event.agent = this.activeUserInfo.firstname
      }
      surlfyURL += `?name=${this.activeUserInfo.firstname} ${this.activeUserInfo.lastname}&email=${this.activeUserInfo.email}&agent_id=${this.activeUserInfo.uid}`
      event.surlfyURL = surlfyURL
      await ref.update(event)
      const visitorData = {
        connectedAgent: event.agent,
        connectedAgentId: this.activeUserInfo.uid,
        agentId: this.activeUserInfo.uid,
        audioPlayed: true,
        connectedAgentImage: this.activeUserInfo.photoURL || null,
        connectedAgentDate: dayjs().utc().toDate(),
        connectedAgentChatDate: dayjs().utc().toDate(),
        lastConnectedAgentId: this.activeUserInfo.uid,
        lastConnectedAgent: event.agent,
        lastConnectedAgentImage: this.activeUserInfo.photoURL || null,
        callTypeState: 'agent-joined',
        agentRequest: false,
        modified: dayjs().utc().toDate(),
        ttlDate: dayjs().utc().add(6, 'month').toDate(),
        cobrowse: false,
        visitorMessages: this.$firebase.firestore.FieldValue.delete(),
        declinedByAgents: this.$firebase.firestore.FieldValue.delete()
      }
      if (this.company && this.company.isCallCenter) {
        visitorData.callCenterId = this.activeUserInfo.company
      }
      await this.$db.collection('visitors').doc(visitorId).set(visitorData, { merge: true })
      this.$store.commit('NOTIFICATIONS_REMOVE', { visitorId, type: 'visitor-request' })

      setTimeout(() => {
        if (this.isSafari) {
          location.href = surlfyURL
        } else {
          const win = window.open(surlfyURL, '_blank')
          if (win) {
            win.focus()
          } else {
            setTimeout(() => {
              if (win) win.focus()
            }, 2000)
          }
        }
      }, 2000)
    }
  }
}
</script>
<style lang="scss">
.visitor {
  .vs-component.con-vs-checkbox {
    margin-left: 0px;
    input:checked + .vs-checkbox {
      border-width: 2px !important;
      border-style: solid !important;
      border-color: #275d73 !important;
      background: #275d73 !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.visitor:hover .pa-agents {
  display: block;
  right: 30px;
}
.visitor {
  border-top: 0.5px solid #e8f6ff;
  border-bottom: 0.5px solid #e8f6ff;
  &-card {
    margin-left: 27px;
  }
  .red {
    font-weight: 600;
    color: red;
  }
  .bd-green {
    background: #1c8439;
    border: solid 0.1em #1c8439;
    border-radius: 50%;
  }
  .br-25 {
    border-radius: 25px;
  }
  &-info-holder {
    width: 100%;
    max-width: 240px;
  }
  &-source {
    max-width: 240px;
    display: flex;
    flex: 1;
    min-width: 0;
    justify-content: flex-start;
    align-items: center;
    &--status-icon {
      margin-left: 27px;
    }
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-embed {
      background: #e8f6ff;
      color: #262629;
    }
    &-campaign {
      background: #275d73;
      color: #fff;
    }
    &-call-center {
      background: #12598d;
      color: #fff;
    }

    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    padding: 2px;
    text-align: center;
    width: fit-content;

    span {
      margin-left: 18px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      margin-right: 1.5rem;
    }
  }

  &-isp {
    color: #262629 !important;
    font-weight: bold;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  &-lastseen {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
  }
  &-connected-agents {
    display: flex;
    flex-direction: row;
    position: relative;

    &-agent {
      &-missed {
        color: #ffffff;
        background: #f05541;
        border: 1px solid #ffffff;
        border-radius: 50%;
      }
      &-joined {
        color: #ffffff;
        background: #1c8439;
        border: 2px solid #1c8439;
        box-sizing: border-box;
        border-radius: 50%;
      }
      position: absolute;
      top: -25px;
      right: 10px;
      height: 45px;
      width: 45px;
      display: flex;
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      justify-content: center;
      align-items: center;
    }
  }
  &-connected-agent {
    &-wh44 {
      position: relative;
      width: 44px;
      height: 44px;
    }
    cursor: pointer;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: 10px;

    display: flex;

    &-live {
      font-family: 'Lato';
      font-size: 8px;
      position: relative; // because we want to position the circle in an absolute fashion
      z-index: 1;
    }
    &-live:before {
      content: ' '; // the circle itself is empty
      display: block;
      border: solid 0.8em #1c8439; // create a colored area...
      border-radius: 0.8em; // ... and turn it into a circle
      height: 0;
      width: 0;
      position: absolute; // the circle is then put into place
      left: 1em;
      top: 0;
      margin-top: 0.1em;
    }
    &-live:after {
      content: ' ';
      display: block;
      width: 0.3em; // the short bar of the mark is half as long as the long bar
      height: 0.6em;
      border: solid white;
      border-width: 0 0.1em 0.1em 0; // creates the inverted "L" shape
      position: absolute;
      left: 1.6em;
      top: 0;
      margin-top: 0.4em;
      -webkit-transform: rotate(45deg); // tilts the shape
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  &-communication-button {
    width: 30px;
    height: 30px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    background: #1c8439;
    border-radius: 6px;
    margin-left: auto;
    margin-right: 20px;
    &-campaign {
      width: 56px !important;
    }
  }
  &-pulse {
    background: #1c8439;
    box-shadow: 0 0 0 rgba(28, 132, 57, 1);
    animation: pulse 2s infinite;
  }
  &-communication-button:hover {
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.3);
  }
  &-chats-unread-count {
    height: 16px;
    width: 16px;
    background-color: #f05541;
    color: #fff;
    border-radius: 50%;
    top: -12px;
    right: -12px;
    position: absolute;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
  }

  &-messages-unread-count {
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 0px;
  }

  &--mobile {
    .visitor {
      &-source {
        span {
          margin: 0 20px;
        }
      }

      &-card {
        margin-left: 20px;
      }
    }
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(28, 132, 57, 1);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(28, 132, 57, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(28, 132, 57, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(28, 132, 57, 1);
    box-shadow: 0 0 0 0 rgba(28, 132, 57, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(28, 132, 57, 0);
    box-shadow: 0 0 0 10px rgba(28, 132, 57, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(28, 132, 57, 0);
    box-shadow: 0 0 0 0 rgba(28, 132, 57, 0);
  }
}

.bg-secondary {
  background-color: #e8f6ff !important;
}
.pa-bk-danger {
  background: rgba(240, 85, 65, 0.8) !important;
}
</style>
