var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "agent-inr" },
    [
      _c("rating-screen", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showRatingScreen,
            expression: "showRatingScreen",
          },
        ],
        attrs: { visitor: _vm.activeChatVisitor },
        on: { "conversation-rating-saved": _vm.onConversationRatingSaved },
      }),
      _c(
        "div",
        { staticClass: "requests-container" },
        [
          _c(
            "div",
            {
              staticClass: "incoming-requests",
              class: {
                "_with-cobrowsing": _vm.IS_COBROWSING,
              },
            },
            [
              _vm.IS_SCREEN_MINIMIZED
                ? _c("requests-header", {
                    attrs: {
                      "conversation-status": _vm.conversationStatus,
                      isMobile: _vm.isMobile,
                    },
                    on: {
                      "update-filter-by-sessions": _vm.updateFilterBySessions,
                      "search-visitor": _vm.onSearchVisitor,
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "incoming-requests-chat-interface" },
                [
                  _vm.IS_SCREEN_MINIMIZED
                    ? _c("visitors", {
                        attrs: {
                          visitors: _vm.visitorList,
                          "selected-audio": _vm.selectedAudio,
                          "selected-video": _vm.selectedVideo,
                          "selected-speaker": _vm.selectedSpeaker,
                          "volume-level": _vm.volumeLevel,
                          isMobile: _vm.isMobile,
                        },
                        on: {
                          "start-communication-with-visitor":
                            _vm.onStartCommunicationWithVisitor,
                          "update-active-chat-visitor":
                            _vm.updateActiveChatUser,
                          "select-visitors": _vm.onSelectVisitors,
                        },
                      })
                    : _vm._e(),
                  _vm.IS_SCREEN_MINIMIZED && !_vm.IS_COBROWSING
                    ? _c("messages", {
                        attrs: {
                          "click-not-close": _vm.clickNotClose,
                          "conversation-completed": _vm.conversationCompleted,
                          "local-stream": _vm.localStream,
                          "remote-stream": _vm.remoteStream,
                          "selected-audio": _vm.selectedAudio,
                          "selected-video": _vm.selectedVideo,
                          "selected-speaker": _vm.selectedSpeaker,
                          volumeLevel: _vm.volumeLevel,
                          "cobrowse-status": _vm.cobrowseStatus,
                          "call-recording-status": _vm.callRecordingStatus,
                          isActiveChatMobile: _vm.isActiveChatMobile,
                        },
                        on: {
                          "visitor-deleted": _vm.onVisitorDeleted,
                          "change-communication-control":
                            _vm.onChangeCommunicationControl,
                          "close-communication-control":
                            _vm.onCloseCommunicationControl,
                          "on-conversation-completed":
                            _vm.onConversationCompleted,
                          "on-audio-video-started": _vm.onAudioVideoStarted,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.HAS_VIDEO_AUDIO_CHAT_ACCESS &&
          _vm.SHOW_CLAIMS_MESSAGE &&
          !_vm.isMobileApp
            ? _c("incoming-requests-message")
            : _vm._e(),
        ],
        1
      ),
      _c("webrtc-communication", {
        attrs: {
          "selected-audio": _vm.selectedAudio,
          "selected-video": _vm.selectedVideo,
          "selected-speaker": _vm.selectedSpeaker,
          "call-recording-status": _vm.callRecordingStatus,
          clearWebRtc: _vm.clearWebRtc,
          "has-visitor-made-offer": _vm.hasVisitorMadeOffer,
          isMobile: _vm.isMobile,
        },
        on: {
          "update-webrtc-stream": _vm.onWebrtcStreamUpdated,
          "on-mute-audio-video": _vm.onMuteAudioVideo,
          "on-change-message-mode-audio": _vm.onChangeMessageModeAudio,
          "on-change-message-mode-video": _vm.onChangeMessageModeVideo,
        },
      }),
      _vm.audioVideoStarted && !_vm.isMobile
        ? _c(
            "draggable-modal",
            {
              attrs: {
                show: _vm.displayAudioVideoModal,
                width: 280,
                height: 280,
                bkcolor: "#F7F7F7",
              },
            },
            [
              _c("audio-video-call-modal", {
                attrs: {
                  "local-stream": _vm.localStream,
                  "remote-stream": _vm.remoteStream,
                  "selected-audio": _vm.selectedAudio,
                  "selected-video": _vm.selectedVideo,
                  "selected-speaker": _vm.selectedSpeaker,
                  volumeLevel: _vm.volumeLevel,
                  "cobrowse-status": _vm.cobrowseStatus,
                  "call-recording-status": _vm.callRecordingStatus,
                  screen: _vm.screen,
                },
                on: {
                  "change-communication-control":
                    _vm.onChangeCommunicationControl,
                  "close-communication-control":
                    _vm.onCloseCommunicationControl,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isMobile && _vm.isActiveChatMobile
        ? _c(
            "div",
            { staticClass: "chat-mobile-wrapper" },
            [
              _c("audio-video-call-modal", {
                attrs: {
                  "local-stream": _vm.localStream,
                  "remote-stream": _vm.remoteStream,
                  "selected-audio": _vm.selectedAudio,
                  "selected-video": _vm.selectedVideo,
                  "selected-speaker": _vm.selectedSpeaker,
                  volumeLevel: _vm.volumeLevel,
                  "cobrowse-status": _vm.cobrowseStatus,
                  "call-recording-status": _vm.callRecordingStatus,
                  screen: _vm.screen,
                  isMobile: _vm.isMobile,
                  isAudioVideoStartedBySomeone:
                    _vm.audioVideoStarted || _vm.HAS_VISITOR_STARTED_AUDIOVIDEO,
                  IS_VISITOR_SCREEN_SHARING: _vm.IS_VISITOR_SCREEN_SHARING,
                },
                on: {
                  "change-communication-control":
                    _vm.onChangeCommunicationControl,
                  "close-communication-control":
                    _vm.onCloseCommunicationControl,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.IS_COBROWSING
        ? _c("cobrowsing", {
            attrs: {
              "cobrowse-status": _vm.cobrowseStatus,
              visitor: _vm.activeChatVisitor,
              "call-recording-status": _vm.callRecordingStatus,
            },
          })
        : _vm._e(),
      _vm.IS_VISITOR_SCREEN_SHARING && !_vm.isMobile
        ? _c("screen-sharing", {
            attrs: { "remote-stream": _vm.remoteStream },
            on: {
              "change-communication-control": _vm.onChangeCommunicationControl,
            },
          })
        : _vm._e(),
      _vm.company &&
      _vm.company.isCallCenter &&
      _vm.callCenterSettings[0] &&
      _vm.callCenterSettings[0].statusList
        ? _c(
            "vs-popup",
            {
              staticClass: "custom-popup-rework-data",
              attrs: {
                "button-close-hidden": "",
                title: _vm.$t("vue.describeInfoAboutTheRework"),
                active: _vm.isReworkDataPopupOpened,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isReworkDataPopupOpened = $event
                },
              },
            },
            [
              _c("p", [
                _vm._v(
                  _vm._s(_vm.$t("vue.reworkTime")) +
                    ": " +
                    _vm._s(_vm.reworkData.reworkTimeFormated)
                ),
              ]),
              _c("multiselect", {
                staticClass: "mt-6",
                attrs: {
                  name: "status",
                  label: "name",
                  "track-by": "name",
                  maxHeight: 200,
                  options: _vm.callCenterSettings[0].statusList,
                  multiple: false,
                  "close-on-select": true,
                  "clear-on-select": false,
                  "preserve-search": true,
                  placeholder: _vm.$t("vue.pickOne"),
                  selectLabel: _vm.$t("vue.pressEnterToSelect"),
                  selectedLabel: _vm.$t("vue.selectedLabel"),
                  deselectLabel: _vm.$t("vue.pressEnterToRemove"),
                  "preselect-first": false,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "singleLabel",
                      fn: function (ref) {
                        var option = ref.option
                        return [
                          _vm._v(
                            "\n        " +
                              _vm._s(option.name + " (" + option.type + ")") +
                              "\n      "
                          ),
                        ]
                      },
                    },
                    {
                      key: "option",
                      fn: function (ref) {
                        var option = ref.option
                        return [
                          _c("div", { staticClass: "flex option__desc" }, [
                            _c("span", { staticClass: "option__title" }, [
                              _vm._v(_vm._s(option.name)),
                            ]),
                            _c("div", {
                              staticClass: "option__color",
                              style: {
                                background: _vm.getStatusColor(option.type),
                              },
                            }),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  96870744
                ),
                model: {
                  value: _vm.reworkData.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.reworkData, "status", $$v)
                  },
                  expression: "reworkData.status",
                },
              }),
              _c("vs-textarea", {
                staticClass: "mt-6 mb-2 w-full custom-vs-textarea",
                attrs: {
                  name: "comment",
                  label: _vm.$t("vue.comment"),
                  "data-vv-as": _vm.$t("vue.comment"),
                  height: "80px",
                },
                model: {
                  value: _vm.reworkData.comment,
                  callback: function ($$v) {
                    _vm.$set(_vm.reworkData, "comment", $$v)
                  },
                  expression: "reworkData.comment",
                },
              }),
              _c("br"),
              _c(
                "vs-button",
                {
                  staticClass: "mt-6",
                  attrs: { type: "border" },
                  on: { click: _vm.sendReworkData },
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("vue.finish")) + "\n    ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }