var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "conversation-completed" }, [
    _c(
      "div",
      {
        staticClass:
          "conversation-completed-close flex justify-center items-center",
        on: {
          click: function ($event) {
            return _vm.$parent.$emit("on-conversation-completed")
          },
        },
      },
      [_c("close-icon", { attrs: { width: 20, height: 20 } })],
      1
    ),
    _c(
      "div",
      {
        staticClass: "flex justify-center items-center",
        staticStyle: { width: "100%", height: "100%" },
      },
      [
        _c(
          "div",
          { staticClass: "flex flex-col conversation-completed-message" },
          [
            _c("span", [_vm._v(_vm._s(_vm.$t("vue.wellDone")))]),
            _c("span", [_vm._v(_vm._s(_vm.$t("vue.conversationCompleted")))]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }