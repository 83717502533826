<template>
  <div class="visitor-chats" v-if="messageModes.includes('chat')" :class="{ 'visitor-chats--mobile': isMobile }">
    <div class="visitor-chats-close-icon flex justify-center items-center" v-if="screen === SCREEN.MAXIMIZED" @click="onCloseChat">
      <close-icon color="#FFFFFF" :width="20" :height="20"></close-icon>
    </div>

    <chat-log
      class="w-full"
      :maxHeight="screenHeight"
      :is-inside-modal="true"
      :screen="screen"
      :isMobile="isMobile"
      :isAudioVideoStartedBySomeone="isAudioVideoStartedBySomeone"
    ></chat-log>
    <div class="visitor-chats-tools flex" :class="visitorIsTyping ? 'mt-10' : ''">
      <toolbar
        :cobrowse-status="cobrowseStatus"
        :screen-sharing-status="screenSharingStatus"
        :call-recording-status="callRecordingStatus"
        :isMobile="isMobile"
        :isAudioVideoStartedBySomeone="isAudioVideoStartedBySomeone"
      ></toolbar>
    </div>

    <div
      v-if="canChatWithVisitor"
      class="visitor-chats-container flex"
      :class="{
        _scrollable: screen === SCREEN.MAXIMIZED
      }"
    >
      <send-chat-message :is-modal="true" :isMobile="isMobile"></send-chat-message>
    </div>
  </div>
</template>
<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

import { mapState, mapGetters } from 'vuex'
import ChatLog from './ChatLog.vue'
import CloseIcon from '@/components/icons/CloseIcon.vue'
import SendChatMessage from './SendChatMessage.vue'
import Toolbar from '@/views/incoming/messages/Toolbar.vue'

const COBROWSE_STATUS = {
  STOPPED: 'stopped',
  STARTED: 'started',
  COBROWSING: 'cobrowsing'
}

const CALL_RECORDING_STATUS = {
  STOPPED: 'stopped',
  STARTED: 'started',
  CALL_RECORDING: 'call-recording'
}
const SCREEN = {
  MINIMIZED: 'minimzed',
  MAXIMIZED: 'maximized'
}
export default {
  components: {
    CloseIcon,
    ChatLog,
    SendChatMessage,
    Toolbar
  },
  props: {
    cobrowseStatus: {
      type: String,
      required: false,
      default: COBROWSE_STATUS.STOPPED
    },
    callRecordingStatus: {
      type: String,
      required: true
    },
    screenSharingStatus: {
      type: String
    },
    screen: {
      type: String,
      required: false,
      default: SCREEN.MINIMIZED
    },
    isMobile: {
      type: Boolean,
      required: false
    },
    isAudioVideoStartedBySomeone: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      typedMessage: '',
      COBROWSE_STATUS,
      SCREEN,
      CALL_RECORDING_STATUS,
      HEIGHT_AUDIO_VIDEO_CALL_HEADER_MOBILE: 105,
      HEIGHT_CHAT_FOOTER: 80,
      MARGIN_TOP_CHAT_LOG: 16
    }
  },
  computed: {
    ...mapState({
      files: (state) => state.files
    }),
    ...mapGetters({
      activeUserInfo: 'activeUser',
      company: 'company',
      visitorIsTyping: 'visitorIsTyping',
      messageModes: 'webrtc/messageModes',
      visitor: 'webrtc/visitor'
    }),
    HAS_COBROWSING_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('co-browsing') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },
    canChatWithVisitor() {
      return (
        this.visitor &&
        (this.isVisitorConnectedToTheCurrentAgent || !this.visitor.connectedAgentId) &&
        (this.visitor.type === 'embed' || this.visitor.type === 'campaign')
      )
    },
    isVisitorConnectedToTheCurrentAgent() {
      return this.visitor && this.visitor.connectedAgentId && this.activeUserInfo.uid === this.visitor.connectedAgentId
    },
    screenHeight() {
      if (this.isMobile) {
        if (this.isAudioVideoStartedBySomeone) {
          let chatHeight = 165

          if (this.files.length > 0) {
            chatHeight = chatHeight - 45
          }

          return chatHeight
        }

        const clientScreenHeight = document.documentElement.clientHeight || document.body.clientHeight || window.innerHeight
        let chatHeight = clientScreenHeight - this.HEIGHT_AUDIO_VIDEO_CALL_HEADER_MOBILE - this.MARGIN_TOP_CHAT_LOG - this.HEIGHT_CHAT_FOOTER

        if (this.files.length > 0) {
          chatHeight = chatHeight - 45
        }

        return chatHeight
      }

      return this.screen === this.SCREEN.MINIMIZED ? 200 : 330
    },
    IS_COBROWSING() {
      return this.cobrowseStatus === this.COBROWSE_STATUS.COBROWSING && (this.visitor.type === 'embed' || this.visitor.type === 'campaign')
    },
    HAS_COBROWSING_STARTED() {
      return this.cobrowseStatus === this.COBROWSE_STATUS.STARTED
    },
    HAS_COBROWSING_STOPPED() {
      return this.cobrowseStatus === this.COBROWSE_STATUS.STOPPED
    }
  },
  methods: {
    onCloseChat() {
      this.$serverBus.$emit('close-chat-log')
    }
  }
}
</script>
<style lang="scss">
.visitor-chats {
  .padding-2 {
    padding: 0.5rem !important;
  }
  &-close-icon {
    height: 35px;
    width: 35px;
    border-radius: 6px;
    background-color: rgba(var(--vs-secondary), 1);
    margin-left: auto;
    margin-right: 15px;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
  &-close-icon:hover {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  }

  width: auto;
  height: 100%;
  background: white;
  border-radius: 6px;
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.25);

  &--mobile {
    border-radius: 0px;
    box-shadow: none;

    .visitor-chats {
      &-container {
        border-radius: 0px;
        box-shadow: none;
      }
    }
  }

  &-tools {
    background-color: #275D73 !important;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    height: 35px;

    .toolbar-tools-button {
      padding-left: 6px;
      padding-right: 6px;
      width: auto;
      height: 30px;
      background: transparent;
      border-radius: 6px;
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      transition: 0.1s;
      margin-left: 3px;
      margin-right: 3px;
    }
  }

  &-container {
    background: #fff;
    box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.25);
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    &._scrollable {
      height: 100%;
      overflow: hidden auto;
      max-height: 80px;
    }
    &-input {
      font-family: 'Lato';
      border: none;
      width: 100%;
      padding: 5px;
      font-size: 15px;
      line-height: 19px;
      color: #12598d;
    }
    &-input::placeholder {
      color: #12598d;
      opacity: 0.5;
    }
    &-button {
      width: 106px;
      height: 35px;
      background: transparent;
      border-radius: 6px;
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      transition: 1s;
      .pa-icon-disabled {
        color: rgba(0, 0, 0, 0.2) !important;
        path {
          stroke: rgba(0, 0, 0, 0.2) !important;
        }
      }
    }
    &-button:hover {
      background: rgba(var(--vs-secondary), 0.2) !important;
    }
  }
}
.toolbar {
  &-active {
    background-color: #f05541 !important;
  }
  &-container {
    &-icon:hover {
      margin-top: -2px;
    }
  }
  &-tools {
    margin-top: auto;
    background-color: rgba(var(--vs-primary), 1) !important;
    height: 35px;

    &-button {
      padding-left: 6px;
      padding-right: 6px;
      width: auto;
      height: 30px;

      background: transparent;
      border-radius: 6px;
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      transition: 0.1s;
      margin-left: 3px;
      margin-right: 3px;
    }

    &-pulse {
      box-shadow: 0 0 0 rgb(240, 85, 65);
      animation: pulse 2s infinite;
      border-radius: 6px;
    }
  }
}
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(240, 85, 65, 1);
  }
  70% {
    -webkit-box-shadow: 0 0 0 15px rgba(255, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(240, 85, 65, 1);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 15px rgba(255, 0, 0, 0);
    box-shadow: 0 0 0 15px rgba(255, 0, 0, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}
</style>
