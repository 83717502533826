var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.messageModes.includes("chat")
    ? _c(
        "div",
        {
          staticClass: "visitor-chats",
          class: { "visitor-chats--mobile": _vm.isMobile },
        },
        [
          _vm.screen === _vm.SCREEN.MAXIMIZED
            ? _c(
                "div",
                {
                  staticClass:
                    "visitor-chats-close-icon flex justify-center items-center",
                  on: { click: _vm.onCloseChat },
                },
                [
                  _c("close-icon", {
                    attrs: { color: "#FFFFFF", width: 20, height: 20 },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("chat-log", {
            staticClass: "w-full",
            attrs: {
              maxHeight: _vm.screenHeight,
              "is-inside-modal": true,
              screen: _vm.screen,
              isMobile: _vm.isMobile,
              isAudioVideoStartedBySomeone: _vm.isAudioVideoStartedBySomeone,
            },
          }),
          _c(
            "div",
            {
              staticClass: "visitor-chats-tools flex",
              class: _vm.visitorIsTyping ? "mt-10" : "",
            },
            [
              _c("toolbar", {
                attrs: {
                  "cobrowse-status": _vm.cobrowseStatus,
                  "screen-sharing-status": _vm.screenSharingStatus,
                  "call-recording-status": _vm.callRecordingStatus,
                  isMobile: _vm.isMobile,
                  isAudioVideoStartedBySomeone:
                    _vm.isAudioVideoStartedBySomeone,
                },
              }),
            ],
            1
          ),
          _vm.canChatWithVisitor
            ? _c(
                "div",
                {
                  staticClass: "visitor-chats-container flex",
                  class: {
                    _scrollable: _vm.screen === _vm.SCREEN.MAXIMIZED,
                  },
                },
                [
                  _c("send-chat-message", {
                    attrs: { "is-modal": true, isMobile: _vm.isMobile },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }