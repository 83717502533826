<template>
  <svg :width="width" :height="height" viewBox="0 0 26 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.70679 13.8571C3.70679 14.4255 3.93255 14.9705 4.33441 15.3724C4.73628 15.7742 5.28132 16 5.84964 16L21.9211 16L22.9925 16C23.5608 16 24.1059 15.7742 24.5077 15.3724C24.9096 14.9705 25.1354 14.4255 25.1354 13.8571L25.1354 3.14286C25.1354 2.57454 24.9096 2.0295 24.5077 1.62763C24.1059 1.22577 23.5608 1 22.9925 1L5.84965 1C5.28132 1 4.73628 1.22576 4.33442 1.62763C3.93255 2.02949 3.70679 2.57454 3.70679 3.14286L3.70679 5.06015"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M17.875 7.76709L20.1304 13.1806L20.9311 10.8232L23.2885 10.0225L17.875 7.76709Z" :stroke="color" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M21.0648 10.9575L22.9789 12.8716" :stroke="color" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M6.41357 6.41357L4.1582 11.8271L3.3575 9.46965L1.00004 8.66895L6.41357 6.41357Z" :stroke="color" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M3.22375 9.60449L1.30972 11.5185" :stroke="color" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>
<script>
export default {
  name: 'CobrowseIcon',
  props: {
    color: {
      type: String,
      default: '#fff'
    },
    width: {
      type: Number,
      default: 42
    },
    height: {
      type: Number,
      default: 22
    }
  }
}
</script>
