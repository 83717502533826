var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "av-call flex flex-col",
      class: { "av-call--mobile": _vm.isMobile },
      style: _vm.SCREEN_HEIGHT,
    },
    [
      _vm.visitor && _vm.isMobile
        ? _c(
            "div",
            { staticClass: "av-call__header" },
            [
              _c(
                "button",
                {
                  staticClass: "av-call__header__button-back",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.goBackToIncomingRequestsList.apply(
                        null,
                        arguments
                      )
                    },
                  },
                },
                [
                  _c("arrow-icon", {
                    attrs: {
                      color: "#275D73",
                      direction: "left",
                      width: 18,
                      height: 28,
                    },
                    on: { click: _vm.goBackToIncomingRequestsList },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "av-call__header__info" }, [
                !!_vm.getDialogOrCampaignName(_vm.visitor)
                  ? _c("div", { staticClass: "av-call__header__info__name" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.getDialogOrCampaignName(_vm.visitor))
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.visitor
                  ? _c("div", [
                      _c("div", { staticClass: "av-call__header__info__isp" }, [
                        _vm._v(_vm._s(_vm.getVisitorInfo(_vm.visitor))),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-row av-call__header__info__footer",
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "vx-tooltip",
                                {
                                  attrs: {
                                    text: _vm.countryName(_vm.visitor.country),
                                    position: "top",
                                  },
                                },
                                [
                                  _vm.visitor.country
                                    ? _c("img", {
                                        attrs: {
                                          width: "20",
                                          alt: _vm.countryName(
                                            _vm.visitor.country
                                          ),
                                          src:
                                            "https://storage.googleapis.com/pathadvice-app.appspot.com/flags/" +
                                            _vm.visitor.country +
                                            ".svg",
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "av-call__header__info__time" },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.lastSeenTimestamp)),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "av-call__header__controls" }, [
                _c(
                  "div",
                  {
                    staticClass: "av-call__header__controls__button mr-6",
                    on: {
                      click: function ($event) {
                        return _vm.handleClickCommunicationControlOnMobile(
                          "video"
                        )
                      },
                    },
                  },
                  [
                    _c("video-call-icon", {
                      attrs: {
                        color:
                          _vm.activeChatVisitor.connectedAgentId &&
                          !_vm.messageModes.includes("video")
                            ? "#275D73"
                            : "rgb(208, 208, 208)",
                        width: 22,
                        height: 22,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleClickCommunicationControlOnMobile(
                            "video"
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "av-call__header__controls__button",
                    on: {
                      click: function ($event) {
                        return _vm.handleClickCommunicationControlOnMobile(
                          "audio"
                        )
                      },
                    },
                  },
                  [
                    _c("vertical-phone-icon", {
                      attrs: {
                        color:
                          _vm.activeChatVisitor.connectedAgentId &&
                          !_vm.messageModes.includes("audio")
                            ? "#275D73"
                            : "rgb(208, 208, 208)",
                        width: 20,
                        height: 20,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleClickCommunicationControlOnMobile(
                            "audio"
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "vs-prompt",
                {
                  staticClass: "end-conversation-dialog",
                  attrs: {
                    title: _vm.$t("vue.endConversation"),
                    color: "danger",
                    "cancel-text": _vm.$t("vue.cancel"),
                    "accept-text": _vm.$t("inputs.exit"),
                    active: _vm.deletePrompt,
                  },
                  on: {
                    cancel: function ($event) {
                      _vm.deletePrompt = false
                    },
                    accept: _vm.onCloseCommunicationControl,
                    close: function ($event) {
                      _vm.deletePrompt = false
                    },
                    "update:active": function ($event) {
                      _vm.deletePrompt = $event
                    },
                  },
                },
                [
                  _c("div", { staticClass: "con-exemple-prompt" }, [
                    _c("p", [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.$t("vue.shouldEndConversationText"))),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _vm.IS_CALL_ACTIVE &&
              _vm.visitorId &&
              (!_vm.isMobile ||
                (_vm.isMobile &&
                  !(
                    _vm.audioVideoStarted || _vm.HAS_VISITOR_STARTED_AUDIOVIDEO
                  )))
                ? _c(
                    "div",
                    {
                      staticClass: "av-call__header__endcall pa-bk-danger",
                      on: {
                        click: function ($event) {
                          _vm.deletePrompt = true
                        },
                      },
                    },
                    [
                      _c("phone-icon", {
                        staticClass: "chat-header-navbar-icon",
                        attrs: { width: 18, height: 18 },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      (!_vm.isMobile &&
        (_vm.audioVideoStarted || _vm.HAS_VISITOR_STARTED_AUDIOVIDEO)) ||
      (_vm.isMobile && _vm.isAudioVideoStartedBySomeone)
        ? _c(
            "div",
            {
              staticClass: "av-call-video flex flex-col",
              style: _vm.SCREEN_SIZE,
            },
            [
              _c(
                "vs-prompt",
                {
                  staticClass: "end-conversation-dialog",
                  attrs: {
                    title: _vm.$t("vue.endConversation"),
                    color: "danger",
                    "cancel-text": _vm.$t("vue.cancel"),
                    "accept-text": _vm.$t("inputs.exit"),
                    active: _vm.deletePrompt,
                  },
                  on: {
                    cancel: function ($event) {
                      _vm.deletePrompt = false
                    },
                    accept: _vm.onCloseCommunicationControl,
                    close: function ($event) {
                      _vm.deletePrompt = false
                    },
                    "update:active": function ($event) {
                      _vm.deletePrompt = $event
                    },
                  },
                },
                [
                  _c("div", { staticClass: "con-exemple-prompt" }, [
                    _c("p", [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.$t("vue.shouldEndConversationText"))),
                      ]),
                    ]),
                  ]),
                ]
              ),
              !_vm.isMobile
                ? [
                    _vm.screen === _vm.SCREEN.MINIMIZED
                      ? _c("video-screen-minimized-navbar", {
                          on: {
                            "on-maximize-screen": _vm.onMaximizeScreen,
                            "on-close-communication-control": function (
                              $event
                            ) {
                              _vm.deletePrompt = true
                            },
                          },
                        })
                      : _c("video-screen-maximized-navbar", {
                          attrs: {
                            "selected-audio": _vm.selectedAudio,
                            "selected-video": _vm.selectedVideo,
                            "selected-speaker": _vm.selectedSpeaker,
                            "volume-level": _vm.volumeLevel,
                            "unread-chat-messages": _vm.unreadChatMessages,
                          },
                          on: {
                            "minimize-screen": _vm.onMinimizeScreen,
                            "close-communication-control": function ($event) {
                              _vm.deletePrompt = true
                            },
                            "change-communication-control":
                              _vm.onClickCommunicationControl,
                          },
                        }),
                  ]
                : _vm._e(),
              _vm.audioVideoStarted || _vm.HAS_VISITOR_STARTED_AUDIOVIDEO
                ? _c("video-audio-chat", {
                    attrs: {
                      screen: _vm.screen,
                      "local-stream": _vm.localStream,
                      "remote-stream": _vm.remoteStream,
                      volumeLevel: _vm.volumeLevel,
                      "selected-video": _vm.selectedVideo,
                      "selected-speaker": _vm.selectedSpeaker,
                      "cobrowse-status": _vm.cobrowseStatus,
                      "call-recording-status": _vm.callRecordingStatus,
                      isMobile: _vm.isMobile,
                      isSwitchButton: _vm.isSwitchButton,
                      cameraOptions: _vm.cameraOptions,
                    },
                  })
                : _vm._e(),
              _vm.IS_VISITOR_SCREEN_SHARING && _vm.isMobile
                ? _c("screen-sharing", {
                    attrs: {
                      "remote-stream": _vm.remoteStream,
                      isMobile: _vm.isMobile,
                    },
                  })
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm.isMobile && _vm.isAudioVideoStartedBySomeone
        ? _c(
            "div",
            {
              staticClass: "av-call__communication-wrapper",
              class: {
                "av-call__communication-wrapper_mobile":
                  _vm.messageModes.includes("chat"),
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "av-call__communication__btn-toggle-chat-wrapper",
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "av-call__communication__btn-toggle-chat",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.onClickCommunicationControl("chat")
                        },
                      },
                    },
                    [
                      _c("ArrowIcon", {
                        attrs: {
                          width: 16,
                          height: 20,
                          color: "#275D73",
                          direction: _vm.messageModes.includes("chat")
                            ? "bottom"
                            : "top",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onClickCommunicationControl("chat")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              (!_vm.isMobile && _vm.screen === _vm.SCREEN.MINIMIZED) ||
              (_vm.isMobile && _vm.isAudioVideoStartedBySomeone)
                ? _c("communication-controls-minimized", {
                    attrs: {
                      "unread-chat-messages": _vm.unreadChatMessages,
                      isMobile: _vm.isMobile,
                    },
                    on: {
                      "on-click-communications-controls":
                        _vm.onClickCommunicationControl,
                      "on-close-communication-control": function ($event) {
                        _vm.deletePrompt = true
                      },
                    },
                  })
                : _vm._e(),
              (!_vm.isMobile && _vm.screen === _vm.SCREEN.MINIMIZED) ||
              _vm.isMobile
                ? _c("visitor-chats", {
                    attrs: {
                      screen: _vm.screen,
                      "cobrowse-status": _vm.cobrowseStatus,
                      "call-recording-status": _vm.callRecordingStatus,
                      isMobile: _vm.isMobile,
                      isAudioVideoStartedBySomeone:
                        _vm.isAudioVideoStartedBySomeone,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : [
            (!_vm.isMobile && _vm.screen === _vm.SCREEN.MINIMIZED) ||
            (_vm.isMobile && _vm.isAudioVideoStartedBySomeone)
              ? _c("communication-controls-minimized", {
                  attrs: { "unread-chat-messages": _vm.unreadChatMessages },
                  on: {
                    "on-click-communications-controls":
                      _vm.onClickCommunicationControl,
                    "on-close-communication-control": function ($event) {
                      _vm.deletePrompt = true
                    },
                  },
                })
              : _vm._e(),
            (!_vm.isMobile && _vm.screen === _vm.SCREEN.MINIMIZED) ||
            _vm.isMobile
              ? _c("visitor-chats", {
                  attrs: {
                    screen: _vm.screen,
                    "cobrowse-status": _vm.cobrowseStatus,
                    "call-recording-status": _vm.callRecordingStatus,
                    isMobile: _vm.isMobile,
                  },
                })
              : _vm._e(),
          ],
      _vm.screen === _vm.SCREEN.MAXIMIZED
        ? _c("toolbar", {
            attrs: {
              "cobrowse-status": _vm.cobrowseStatus,
              "call-recording-status": _vm.callRecordingStatus,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }