var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cobrowsing flex flex-col" }, [
    _c("div", { staticClass: "cobrowsing-window relative" }, [
      _vm.visitor && _vm.visitor.surfly && _vm.visitor.surfly.joinURL
        ? _c("iframe", {
            staticStyle: {
              position: "relative",
              width: "100%",
              height: "100%",
            },
            attrs: {
              src: _vm.visitor.surfly.joinURL,
              frameborder: "0",
              allowfullscreen: "",
            },
          })
        : _vm._e(),
    ]),
    _c(
      "div",
      {
        staticClass: "cobrowsing-toolbar",
        staticStyle: { "justify-content": "flex-start", "flex-grow": "1" },
      },
      [
        _vm.visitor.connectedAgentId === _vm.activeUserInfo.uid
          ? _c("toolbar", {
              attrs: {
                "cobrowse-status": _vm.cobrowseStatus,
                "call-recording-status": _vm.callRecordingStatus,
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }