var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 50 50",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("circle", {
        attrs: {
          cx: "25",
          cy: "25",
          r: "24",
          stroke: _vm.color,
          "stroke-width": "2",
        },
      }),
      _c("line", {
        attrs: {
          x1: "43.936",
          y1: "10.182",
          x2: "9.47653",
          y2: "43.2901",
          stroke: _vm.color,
          "stroke-width": "2",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }