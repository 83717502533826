var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "connect-modal",
    {
      staticClass: "audio-video-settings",
      class: { "audio-video-settings--mobile": _vm.isMobile },
      attrs: { bkcolor: _vm.isMobile ? "#FFFFFF" : "#F7F7F7", show: _vm.show },
    },
    [
      _c(
        "div",
        { staticClass: "audio-video-settings__inner" },
        [
          _c(
            "vs-popup",
            {
              staticClass: "image-popup",
              staticStyle: { "z-index": "999999" },
              attrs: {
                fullscreen: "",
                title: _vm.$t("vue.cropImage"),
                active: _vm.showCroppa,
                "button-close-hidden": "",
              },
              on: {
                "update:active": function ($event) {
                  _vm.showCroppa = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "relative" },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { cursor: "pointer" },
                      attrs: { disabled: "" },
                      on: {
                        click: function ($event) {
                          _vm.showCroppa = false
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex justify-center items-center check-circle-icon",
                          on: { click: _vm.onCroppaSave },
                        },
                        [
                          _c("check-circle-icon", {
                            attrs: { color: "#E8F6FF", width: 35, height: 35 },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("croppa", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.myPhoto.img,
                        expression: "myPhoto.img",
                      },
                    ],
                    staticClass: "resizable-croppa",
                    attrs: {
                      placeholder: "Click here",
                      "show-remove-button": false,
                      "canvas-color": "transparent",
                      "prevent-white-space": "",
                      "initial-size": "cover",
                      "image-border-radius": 10,
                      accept: ".jpeg,.jpg,.gif,.png",
                      "disable-drag-to-move": false,
                      "file-size-limit": 2048000,
                      "placeholder-font-size": 14,
                      disabled: false,
                      width: 1280,
                      height: 720,
                    },
                    on: {
                      init: _vm.onCroppaInit,
                      "file-type-mismatch": _vm.onFileTypeMismatch,
                      "file-size-exceed": _vm.onFileSizeExceed,
                      "file-choose": _vm.onFileChoose,
                    },
                    model: {
                      value: _vm.myPhoto,
                      callback: function ($$v) {
                        _vm.myPhoto = $$v
                      },
                      expression: "myPhoto",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c("input", {
            attrs: { type: "hidden", id: "hdnVideo" },
            domProps: { value: JSON.stringify(_vm.selectedVideo) },
          }),
          _c("input", {
            attrs: { type: "hidden", id: "hdnAudio" },
            domProps: { value: JSON.stringify(_vm.selectedAudio) },
          }),
          _c("input", {
            attrs: { type: "hidden", id: "hdnSpeaker" },
            domProps: { value: JSON.stringify(_vm.selectedSpeaker) },
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isChangeVirtualBackground,
                  expression: "!isChangeVirtualBackground",
                },
              ],
            },
            [
              _c("div", { staticClass: "flex flex-row space-between" }, [
                !_vm.isMobile
                  ? _c(
                      "div",
                      { staticClass: "audio-video-settings-icon-settings" },
                      [
                        _c("settings-icon", {
                          staticClass:
                            "audio-video-settings-icon settings-icon",
                          attrs: { width: 20, height: 20 },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.isFirstSetupOnMobile || _vm.isRequestedPermissions
                  ? _c(
                      "div",
                      {
                        staticClass: "audio-video-settings-button",
                        staticStyle: { "margin-left": "auto" },
                        on: { click: _vm.onCloseAudioVideoSettings },
                      },
                      [
                        !_vm.isMobile
                          ? _c("close-circle-icon", {
                              staticClass:
                                "audio-video-settings-icon pa-bk-secondary",
                              attrs: {
                                "show-circle": false,
                                width: 14,
                                height: 14,
                              },
                            })
                          : _c("close-icon", {
                              staticClass: "mt-6 mb-6",
                              attrs: {
                                color: "#626262",
                                width: 30,
                                height: 30,
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c(
                "div",
                {
                  staticClass: "audio-video-settings-heading",
                  class: { "mt-0": _vm.isFirstSetupOnMobile },
                },
                [_vm._v(_vm._s(_vm.$t("vue.videoAudioSettings")))]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-row audio-video-settings-av-settings-wrapper",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex flex-col audio-video-settings-av-settings",
                    },
                    [
                      _c("div", { staticClass: "relative" }, [
                        _c(
                          "div",
                          {
                            style:
                              "width: " +
                              _vm.currentVideoWidth +
                              "px; height: " +
                              _vm.currentVideoHeight +
                              "px; margin-left: auto; margin-right: auto;",
                          },
                          [
                            _c("div", [
                              _c("img", {
                                style:
                                  "pointer-events: none; z-index: 548878; top: 0px; left: 0px; opacity: 0; width: " +
                                  _vm.currentVideoWidth +
                                  "px; height: " +
                                  _vm.currentVideoHeight +
                                  "px; position: fixed",
                                attrs: {
                                  id: "settings-video-background",
                                  src: _vm.videoBackgroundImage,
                                  width: "1280px",
                                  height: "720px",
                                },
                              }),
                            ]),
                            _c("video", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isCameraOn,
                                  expression: "isCameraOn",
                                },
                              ],
                              ref: "videoCam",
                              staticClass: "video",
                              style:
                                "" +
                                (_vm.isMobile ? "border-radius: 6px;" : ""),
                              attrs: {
                                id: "video-cam",
                                width: _vm.currentVideoWidth,
                                height: _vm.currentVideoHeight,
                                autoplay: "",
                                playsinline: "",
                                muted: "",
                              },
                              domProps: { muted: true },
                            }),
                            _c("canvas", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isCameraOn,
                                  expression: "isCameraOn",
                                },
                              ],
                              staticClass:
                                "audio-video-settings-av-settings__canvas-video-output",
                              style:
                                "position: absolute; pointer-events: none; z-index: 58000; top: 0px; right: 0px; width: " +
                                _vm.currentVideoWidth +
                                "px; " +
                                (_vm.isMobile ? "max-width: 100%;" : "") +
                                " height: " +
                                _vm.currentVideoHeight +
                                "px; border-radius: 6px",
                              attrs: {
                                width: "1280px",
                                height: "720px",
                                id: "video-output",
                              },
                            }),
                            !_vm.isCameraOn
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "audio-video-settings-no-camera flex justify-center items-center",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "audio-video-settings-no-camera-container flex justify-center items-center relative",
                                      },
                                      [
                                        _c("settings-video-cam-off", {
                                          staticStyle: { position: "absolute" },
                                          attrs: {
                                            color: "#ffffff",
                                            width: 187,
                                            height: 168.75,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                        !_vm.isMobile
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "flex flex-row items-center audio-video-settings-pointer audio-video-settings-pointer-blurtext-button",
                                class: {
                                  "mt-2 justify-center": !_vm.isMobile,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onChangeVirtualBackground()
                                  },
                                },
                              },
                              [
                                _c("magic-icon", {
                                  attrs: {
                                    color: _vm.isMobile
                                      ? "#262629"
                                      : _vm.isCameraOn
                                      ? "#275D73"
                                      : "#B2B2B2",
                                    width: _vm.isMobile ? 19 : 16,
                                    height: _vm.isMobile ? 19 : 16,
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "ml-5 audio-video-settings-blurtext",
                                    class: !_vm.isCameraOn
                                      ? "audio-video-settings-disabled"
                                      : "",
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t("vue.changeVirtualBackground")
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _vm.isFirstSetupOnMobile && !_vm.isRequestedPermissions
                    ? _c("div", [
                        _c("p", { staticClass: "mt-3" }, [
                          _vm._v(
                            _vm._s(_vm.$t("buttonAndHints.permissionsWording5"))
                          ),
                        ]),
                        _c("p", { staticClass: "mt-3" }, [
                          _vm._v(
                            _vm._s(_vm.$t("buttonAndHints.permissionsWording6"))
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "mt-5 text-center" },
                          [
                            _c(
                              "vs-button",
                              {
                                staticClass: "mb-base",
                                attrs: { color: "primary", type: "filled" },
                                on: { click: _vm.requestPermissions },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "buttonAndHints.requestPermissions"
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _c(
                        "div",
                        {
                          staticClass:
                            "ml-4 flex flex-col justify-center audio-video-settings-av-settings-options",
                        },
                        [
                          _c(
                            "div",
                            [
                              _vm.speakerOptions.length > 0 ||
                              _vm.microphoneOptions.length > 0
                                ? _c("div", { staticClass: "mt-2" }, [
                                    _vm.isMobile
                                      ? _c("span", [
                                          _vm._v(_vm._s(_vm.$t("vue.mic"))),
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "vue.microphoneAndSpeakers"
                                              )
                                            )
                                          ),
                                        ]),
                                  ])
                                : _vm._e(),
                              _vm.microphoneOptions.length > 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt-2 flex flex-row flex-grow bk-select",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          class: _vm.isMicrophoneOn
                                            ? "audio-video-settings-communication-button on"
                                            : "audio-video-settings-communication-button off",
                                          on: { click: _vm.toggleMicrophone },
                                        },
                                        [
                                          _vm.selectedAudio &&
                                          _vm.selectedAudio.value !==
                                            "microphone-off"
                                            ? _c("microphone-icon", {
                                                staticClass:
                                                  "audio-video-settings-communication-icon",
                                                attrs: {
                                                  width: _vm.isMobile ? 19 : 16,
                                                  height: _vm.isMobile
                                                    ? 19
                                                    : 16,
                                                },
                                              })
                                            : _vm._e(),
                                          !_vm.selectedAudio ||
                                          _vm.selectedAudio.value ===
                                            "microphone-off"
                                            ? _c("microphone-off-icon", {
                                                staticClass:
                                                  "audio-video-settings-communication-icon",
                                                attrs: {
                                                  width: _vm.isMobile ? 19 : 16,
                                                  height: _vm.isMobile
                                                    ? 19
                                                    : 16,
                                                  color: "#275D73",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-full custom-select-wrapper",
                                        },
                                        [
                                          _c("connect-select", {
                                            attrs: {
                                              options: _vm.microphoneOptions,
                                              default: _vm.selectedAudio,
                                            },
                                            on: {
                                              "input-selected":
                                                _vm.onAudioInputSelected,
                                              "input-click":
                                                _vm.onMicrophoneClicked,
                                              "input-blur":
                                                _vm.onMicrophoneSelectBlur,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              !_vm.isMobile && _vm.speakerOptions.length > 0
                                ? [
                                    _c("div", { staticClass: "mt-4" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex flex-row flex-grow bk-select",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              class: _vm.isSpeakerOn
                                                ? "audio-video-settings-communication-button on"
                                                : "audio-video-settings-communication-button off",
                                              on: { click: _vm.toggleSpeaker },
                                            },
                                            [
                                              _vm.isSpeakerOn
                                                ? _c("speaker-on-icon", {
                                                    staticClass:
                                                      "audio-video-settings-communication-icon",
                                                    attrs: {
                                                      color: "#ffffff",
                                                      width: 16,
                                                      height: 16,
                                                    },
                                                  })
                                                : _c("speaker-off-icon", {
                                                    staticClass:
                                                      "audio-video-settings-communication-icon",
                                                    attrs: {
                                                      color: "#275D73",
                                                      width: 16,
                                                      height: 16,
                                                    },
                                                  }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "w-full custom-select-wrapper",
                                            },
                                            [
                                              _c("connect-select", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: !_vm.hideSpeaker,
                                                    expression: "!hideSpeaker",
                                                  },
                                                ],
                                                attrs: {
                                                  options: _vm.speakerOptions,
                                                  default: _vm.selectedSpeaker,
                                                },
                                                on: {
                                                  "input-selected":
                                                    _vm.onAudioOutputSelected,
                                                  "input-click":
                                                    _vm.onSpeakerClicked,
                                                  "input-blur":
                                                    _vm.onSpeakerSelectBlur,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                : _vm._e(),
                              _vm.cameraOptions.length > 1
                                ? _c("div", { staticClass: "mt-6" }, [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("vue.camera"))),
                                    ]),
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "mt-2" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex flex-row flex-grow bk-select",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        class: _vm.isCameraOn
                                          ? "audio-video-settings-communication-button on"
                                          : "audio-video-settings-communication-button off",
                                        on: { click: _vm.toggleCamera },
                                      },
                                      [
                                        _vm.isCameraOn
                                          ? _c("video-call-icon", {
                                              staticClass:
                                                "audio-video-settings-communication-icon",
                                              attrs: {
                                                width: _vm.isMobile ? 19 : 16,
                                                height: _vm.isMobile ? 19 : 16,
                                              },
                                            })
                                          : _c("video-call-off-icon", {
                                              staticClass:
                                                "audio-video-settings-communication-icon",
                                              attrs: {
                                                color: "#275D73",
                                                width: _vm.isMobile ? 19 : 16,
                                                height: _vm.isMobile ? 19 : 16,
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "w-full custom-select-wrapper",
                                      },
                                      [
                                        _c("connect-select", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.hideCamera,
                                              expression: "!hideCamera",
                                            },
                                          ],
                                          attrs: {
                                            options: _vm.cameraOptions,
                                            default: _vm.selectedVideo,
                                          },
                                          on: {
                                            "input-selected":
                                              _vm.onVideoInputSelected,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                              _vm.isFirstSetupOnMobile
                                ? _c(
                                    "div",
                                    { staticClass: "mt-6 text-center" },
                                    [
                                      _c(
                                        "vs-button",
                                        {
                                          staticClass: "mb-base",
                                          attrs: {
                                            color: "primary",
                                            type: "filled",
                                          },
                                          on: {
                                            click:
                                              _vm.onCloseAudioVideoSettings,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "vue.permissionsNotice.denied.btn"
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isChangeVirtualBackground,
                  expression: "isChangeVirtualBackground",
                },
              ],
            },
            [
              _c("div", { staticClass: "flex flex-row space-between" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex justify-center items-center audio-video-settings-pointer",
                    on: {
                      click: function ($event) {
                        return _vm.onBackToSettings()
                      },
                    },
                  },
                  [
                    _c("back-icon", {
                      staticClass:
                        "audio-video-settings-icon pa-icon-default mr-2",
                      attrs: { width: 7, height: 12 },
                    }),
                    _c("div", { staticClass: "audio-video-settings-back" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("vue.back")) +
                          "\n          "
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "audio-video-settings-heading" }, [
                _vm._v(_vm._s(_vm.$t("vue.changeVirtualBackground"))),
              ]),
              _c(
                "vs-button",
                {
                  staticClass: "mb-2",
                  class: { "mt-6": _vm.isMobile },
                  attrs: { color: "primary", type: "filled", icon: "add" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onAddVirtualBackground()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("vue.addYourOwnBackground")) +
                      "\n      "
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "audio-video-settings-bkgrid" },
                [
                  !_vm.isMobile
                    ? _c("scroll-view", { staticStyle: { height: "300px" } }, [
                        _c(
                          "div",
                          { staticClass: "photos" },
                          _vm._l(_vm.backgrounds, function (background, i) {
                            return _c(
                              "div",
                              { key: i, staticClass: "photos-item" },
                              [
                                background.type === "img" && !background.default
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex justify-center items-center delete-icon",
                                        on: {
                                          click: function ($event) {
                                            return _vm.onDeleteVirtualBackground(
                                              i
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "material-icons",
                                            class: background.selected
                                              ? "audio-video-settings-grey"
                                              : "audio-video-settings-red",
                                          },
                                          [_vm._v(" delete ")]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticClass: "audio-video-settings-pointer",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onSelectVirtualBackground(i)
                                      },
                                    },
                                  },
                                  [
                                    background.selected
                                      ? _c("div", {
                                          staticClass: "photos-item-check",
                                        })
                                      : _vm._e(),
                                    background.type === "img"
                                      ? _c("img", {
                                          class: background.selected
                                            ? "audio-video-settings-selected"
                                            : "",
                                          attrs: { src: background.source },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                                background.type === "none"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "photos-item-none audio-video-settings-pointer",
                                        class: background.selected
                                          ? "audio-video-settings-selected"
                                          : "",
                                        on: {
                                          click: function ($event) {
                                            return _vm.onSelectVirtualBackground(
                                              i
                                            )
                                          },
                                        },
                                      },
                                      [
                                        background.selected
                                          ? _c("div", {
                                              staticClass: "photos-item-check",
                                            })
                                          : _vm._e(),
                                        _c("empty-icon"),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                background.type === "blur"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "photos-item-blur flex flex-col justify-center audio-video-settings-pointer",
                                        class: background.selected
                                          ? "audio-video-settings-selected"
                                          : "",
                                        on: {
                                          click: function ($event) {
                                            return _vm.onSelectVirtualBackground(
                                              i
                                            )
                                          },
                                        },
                                      },
                                      [
                                        background.selected
                                          ? _c("div", {
                                              staticClass: "photos-item-check",
                                            })
                                          : _vm._e(),
                                        _c("blur-background-icon"),
                                        _c("div", { staticClass: "mt-4" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("vue.blurYourBackground")
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }),
                          0
                        ),
                      ])
                    : _c(
                        "div",
                        { staticClass: "photos" },
                        _vm._l(_vm.backgrounds, function (background, i) {
                          return _c(
                            "div",
                            { key: i, staticClass: "photos-item" },
                            [
                              background.type === "img" && !background.default
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex justify-center items-center delete-icon",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onDeleteVirtualBackground(
                                            i
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "material-icons",
                                          class: background.selected
                                            ? "audio-video-settings-grey"
                                            : "audio-video-settings-red",
                                        },
                                        [_vm._v(" delete ")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass: "audio-video-settings-pointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.onSelectVirtualBackground(i)
                                    },
                                  },
                                },
                                [
                                  background.selected
                                    ? _c("div", {
                                        staticClass: "photos-item-check",
                                      })
                                    : _vm._e(),
                                  background.type === "img"
                                    ? _c("img", {
                                        class: background.selected
                                          ? "audio-video-settings-selected"
                                          : "",
                                        attrs: { src: background.source },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                              background.type === "none"
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "photos-item-none audio-video-settings-pointer",
                                      class: background.selected
                                        ? "audio-video-settings-selected"
                                        : "",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onSelectVirtualBackground(
                                            i
                                          )
                                        },
                                      },
                                    },
                                    [
                                      background.selected
                                        ? _c("div", {
                                            staticClass: "photos-item-check",
                                          })
                                        : _vm._e(),
                                      _c("empty-icon"),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              background.type === "blur"
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "photos-item-blur flex flex-col justify-center audio-video-settings-pointer",
                                      class: background.selected
                                        ? "audio-video-settings-selected"
                                        : "",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onSelectVirtualBackground(
                                            i
                                          )
                                        },
                                      },
                                    },
                                    [
                                      background.selected
                                        ? _c("div", {
                                            staticClass: "photos-item-check",
                                          })
                                        : _vm._e(),
                                      _c("blur-background-icon"),
                                      _c("div", { staticClass: "mt-4" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("vue.blurYourBackground")
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }