var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        color: _vm.color,
        fill: "none",
        stroke: "currentColor",
        viewBox: "0 0 27 20",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M15.8284 14.1716C15.0783 13.4214 14.0609 13 13 13H5C3.93913 13 2.92172 13.4214 2.17157 14.1716C1.42143 14.9217 1 15.9391 1 17V19",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M9 9C11.2091 9 13 7.20914 13 5C13 2.79086 11.2091 1 9 1C6.79086 1 5 2.79086 5 5C5 7.20914 6.79086 9 9 9Z",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("line", {
        attrs: {
          x1: "16.5",
          y1: "11",
          x2: "25.5",
          y2: "11",
          "stroke-width": "3",
          "stroke-linecap": "round",
        },
      }),
      _c("line", {
        attrs: {
          x1: "16.5",
          y1: "15",
          x2: "25.5",
          y2: "15",
          "stroke-width": "3",
          "stroke-linecap": "round",
        },
      }),
      _c("line", {
        attrs: {
          x1: "15.75",
          y1: "18.25",
          x2: "26.25",
          y2: "18.25",
          "stroke-width": "2",
          "stroke-linecap": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }