var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 35 35",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M17.5 32.0807C25.5541 32.0807 32.0833 25.5515 32.0833 17.4974C32.0833 9.44324 25.5541 2.91406 17.5 2.91406C9.44584 2.91406 2.91666 9.44324 2.91666 17.4974C2.91666 25.5515 9.44584 32.0807 17.5 32.0807Z",
          fill: _vm.stroke,
          stroke: _vm.stroke,
          "stroke-width": "2.91667",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M13.2564 13.1185C13.5992 12.1439 14.276 11.322 15.1667 10.7985C16.0575 10.275 17.1048 10.0836 18.1231 10.2583C19.1414 10.433 20.0651 10.9624 20.7304 11.7528C21.3958 12.5432 21.76 13.5436 21.7585 14.5768C21.7585 17.4935 17.3835 18.9518 17.3835 18.9518",
          stroke: "white",
          "stroke-width": "2.91667",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M17.5 24.7891H17.5142",
          stroke: "white",
          "stroke-width": "2.91667",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }