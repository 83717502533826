var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "draggable-modal",
    {
      staticClass: "ss-video-container",
      attrs: {
        show: _vm.videoEnabled,
        width: 150,
        height: 100,
        top: 30,
        right: 30,
        bkcolor: "#F7F7F7",
      },
    },
    [
      _c("div", { staticClass: "ss-video-container-video" }, [
        _c("video", {
          ref: "localVideo",
          staticClass: "video",
          attrs: {
            width: "150",
            height: "100",
            muted: "muted",
            autoplay: "",
            playsinline: "",
          },
          domProps: { muted: true },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }