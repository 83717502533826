var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ss-main" },
    [
      _c(
        "vs-prompt",
        {
          attrs: {
            title: _vm.$t("vue.endConversation"),
            color: "danger",
            "cancel-text": _vm.$t("vue.cancel"),
            "accept-text": _vm.$t("inputs.exit"),
            active: _vm.endConversationPrompt,
          },
          on: {
            cancel: function ($event) {
              _vm.endConversationPrompt = false
            },
            accept: _vm.onEndSessionWithVisitor,
            close: function ($event) {
              _vm.endConversationPrompt = false
            },
            "update:active": function ($event) {
              _vm.endConversationPrompt = $event
            },
          },
        },
        [
          _c("div", { staticClass: "con-exemple-prompt" }, [
            _c("p", [
              _c("strong", [
                _vm._v(_vm._s(_vm.$t("vue.shouldEndConversationText"))),
              ]),
            ]),
          ]),
        ]
      ),
      _c("div", { staticClass: "ss-main-toolbar" }, [
        _c(
          "div",
          {
            staticClass: "ss-main-toolbar-action-button pa-bk-danger",
            on: { click: _vm.onScreenSharingClicked },
          },
          [
            _c("share-icon", {
              staticClass: "toolbar-container-icon",
              attrs: { width: 25.41, height: 18 },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "ss-main-toolbar-communication-controls" }, [
          _c("div", { staticClass: "ss-main-toolbar-screenshare" }),
          _c("div", { staticStyle: { position: "relative" } }, [
            _c(
              "div",
              {
                staticClass: "ss-main-toolbar-communication-controls-button",
                class: {
                  "pa-bk-active": _vm.messageModes.includes("chat"),
                },
                on: { click: _vm.onChatClicked },
              },
              [
                _c("chat-icon", {
                  class: {
                    "pa-icon-default": !_vm.messageModes.includes("chat"),
                  },
                  attrs: { width: 16, height: 16 },
                }),
              ],
              1
            ),
            !_vm.messageModes.includes("chat") && _vm.visitor.visitorMessages
              ? _c("div", { staticClass: "unread-messages" }, [
                  _vm._v(_vm._s(_vm.visitor.visitorMessages)),
                ])
              : _vm._e(),
          ]),
          _c(
            "div",
            {
              staticClass: "ss-main-toolbar-communication-controls-button",
              class: {
                "pa-bk-active":
                  _vm.messageModes.includes("audio") || _vm.micEnabled,
              },
              style: {
                background: _vm.disabledAudioColor,
                cursor: _vm.isAudioDisabled ? "default" : "pointer",
              },
              on: {
                click: function ($event) {
                  return _vm.onClickCommunicationControls("audio")
                },
              },
            },
            [
              _vm.messageModes.includes("audio") || _vm.micEnabled
                ? _c("microphone-icon", {
                    class: {
                      "pa-icon-default":
                        !_vm.messageModes.includes("audio") && !_vm.micEnabled,
                    },
                    attrs: { width: 16, height: 16 },
                  })
                : _c("microphone-off-icon", {
                    class: {
                      "pa-icon-default":
                        !_vm.messageModes.includes("audio") && !_vm.micEnabled,
                    },
                    attrs: { width: 16, height: 16 },
                  }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "ss-main-toolbar-communication-controls-button",
              class: {
                "pa-bk-active":
                  _vm.messageModes.includes("video") || _vm.videoEnabled,
              },
              style: {
                background: _vm.disabledVideoColor,
                cursor: _vm.isVideoDisabled ? "default" : "pointer",
              },
              on: {
                click: function ($event) {
                  return _vm.onClickCommunicationControls("video")
                },
              },
            },
            [
              _vm.messageModes.includes("video") || _vm.videoEnabled
                ? _c("video-call-icon", {
                    class: {
                      "pa-icon-default":
                        !_vm.messageModes.includes("video") &&
                        !_vm.videoEnabled,
                    },
                    attrs: { width: 16, height: 16 },
                  })
                : _c("video-call-off-icon", {
                    class: {
                      "pa-icon-default":
                        !_vm.messageModes.includes("video") &&
                        !_vm.videoEnabled,
                    },
                    attrs: { width: 16, height: 16 },
                  }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          {
            staticClass: "ss-main-toolbar-action-button pa-bk-danger ml-2",
            on: {
              click: function ($event) {
                _vm.endConversationPrompt = true
              },
            },
          },
          [
            _c("phone-icon", {
              staticClass: "chat-header-navbar-icon",
              attrs: { width: 18, height: 18 },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }