var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vs-input", {
    staticClass: "search-visitor-input",
    attrs: { icon: "search", disabled: _vm.HAS_CONVERSATION_STARTED },
    model: {
      value: _vm.searchText,
      callback: function ($$v) {
        _vm.searchText = $$v
      },
      expression: "searchText",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }