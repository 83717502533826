<template>
  <div class="rating-overlay">
    <div class="rating-overlay-content">
      <div v-show="!showHelp" class="rating-overlay-content-main">
        <div class="rating-overlay-content-main--message">{{ $t('ratingScreen.screenMessage') }}</div>
        <div class="rating-overlay-content-main-smiley">
          <div
            class="rating-overlay-content-main-smiley--icon"
            @mouseover="onMouseHover('bad')"
            @mouseleave="onMouseLeave('bad')"
            @click="onSaveConversation('bad')"
          >
            <rating-saved-icon v-if="isRatingFailureSaved" />
            <rating-failure-icon v-else :hover="ratingFailureHover"></rating-failure-icon>
          </div>
          <div
            class="rating-overlay-content-main-smiley--icon"
            @mouseover="onMouseHover('neutral')"
            @mouseleave="onMouseLeave('neutral')"
            @click="onSaveConversation('neutral')"
          >
            <rating-saved-icon v-if="isRatingNeutralSaved" />
            <rating-neutral-icon v-else :hover="ratingNeutralHover"></rating-neutral-icon>
          </div>
          <div
            class="rating-overlay-content-main-smiley--icon"
            @mouseover="onMouseHover('good')"
            @mouseleave="onMouseLeave('good')"
            @click="onSaveConversation('good')"
          >
            <rating-saved-icon v-if="isRatingSuccessSaved" />
            <rating-success-icon v-else :hover="ratingSuccessHover"></rating-success-icon>
          </div>
        </div>
        <div class="rating-overlay-content-main__support" @click="onSaveConversation('support')">
          <div v-if="isRatingSupportSaved" class="rating-overlay-content-main__support--saved">
            <rating-support-saved-icon />
          </div>
          <div v-else class="rating-overlay-content-main__support--btn">
            {{ $t('ratingScreen.supportButtonText') }}
          </div>
        </div>
        <div class="rating-overlay-content-main--info-icon" @click="onShowHelp">
          <rating-help-icon></rating-help-icon>
        </div>
      </div>
      <div v-show="showHelp" class="rating-overlay-content-help">
        <div class="rating-overlay-content-help--message-header">{{ $t('ratingScreen.helpHeader') }}</div>
        <div class="rating-overlay-content-help--message-description" v-html="$t('ratingScreen.helpDescription')"></div>
        <div class="rating-overlay-content-help--rating-info">{{ $t('ratingScreen.helpLevels') }}</div>
        <div class="rating-overlay-content-help-smiley">
          <rating-success-icon :width="31.51" :height="31.51"></rating-success-icon>
          <div class="rating-overlay-content-help-smiley--info">{{ $t('ratingScreen.helpSuccess') }}</div>
        </div>
        <div class="rating-overlay-content-help-smiley">
          <rating-neutral-icon :width="31.73" :height="31.73"></rating-neutral-icon>
          <div class="rating-overlay-content-help-smiley--info">{{ $t('ratingScreen.helpNeutral') }}</div>
        </div>
        <div class="rating-overlay-content-help-smiley">
          <rating-failure-icon :width="31.73" :height="31.73"></rating-failure-icon>
          <div class="rating-overlay-content-help-smiley--info">{{ $t('ratingScreen.helpWaste') }}</div>
        </div>
        <div class="rating-overlay-content-help--close-icon" @click="onHideHelp">
          <close-icon></close-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RatingSuccessIcon from '@/components/icons/ratings/RatingSuccessIcon.vue'
import RatingNeutralIcon from '@/components/icons/ratings/RatingNeutralIcon.vue'
import RatingFailureIcon from '@/components/icons/ratings/RatingFailureIcon.vue'
import RatingSavedIcon from '@/components/icons/ratings/RatingSavedIcon.vue'
import RatingHelpIcon from '@/components/icons/ratings/RatingHelpIcon.vue'
import RatingSupportSavedIcon from '@/components/icons/ratings/RatingSupportSavedIcon.vue'

import CloseIcon from '@/components/icons/CloseIcon.vue'

export default {
  name: 'RatingScreen',
  components: {
    RatingSuccessIcon,
    RatingNeutralIcon,
    RatingFailureIcon,
    RatingHelpIcon,
    RatingSavedIcon,
    CloseIcon,
    RatingSupportSavedIcon
  },
  props: ['visitor'],
  data() {
    return {
      showHelp: false,
      ratingSuccessHover: false,
      ratingNeutralHover: false,
      ratingFailureHover: false,
      conversationSaved: false,
      rating: null,
      isSavingConversation: false
    }
  },
  computed: {
    isRatingSupportSaved() {
      return this.rating === 'support' && this.conversationSaved === true
    },
    isRatingSuccessSaved() {
      return this.rating === 'good' && this.conversationSaved === true
    },
    isRatingFailureSaved() {
      return this.rating === 'bad' && this.conversationSaved === true
    },
    isRatingNeutralSaved() {
      return this.rating === 'neutral' && this.conversationSaved === true
    }
  },
  methods: {
    onMouseHover(type) {
      switch (type) {
        case 'bad':
          this.ratingFailureHover = true
          break

        case 'neutral':
          this.ratingNeutralHover = true
          break

        case 'good':
          this.ratingSuccessHover = true
          break
      }
    },
    onMouseLeave(type) {
      switch (type) {
        case 'bad':
          this.ratingFailureHover = false
          break

        case 'neutral':
          this.ratingNeutralHover = false
          break

        case 'good':
          this.ratingSuccessHover = false
          break
      }
    },
    onShowHelp() {
      this.showHelp = true
    },
    onHideHelp() {
      this.showHelp = false
    },
    async onSaveConversation(rating) {
      // If it is already saving then return
      if (this.isSavingConversation) return

      this.rating = rating
      this.conversationSaved = true
      this.isSavingConversation = true

      const conversation = {
        rating,
        visitor: this.visitor
      }

      setTimeout(() => {
        this.$emit('conversation-rating-saved', conversation)
        this.rating = null
        this.conversationSaved = false
        this.isSavingConversation = false
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.rating-overlay {
  position: fixed;
  z-index: 64000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ease;
  overflow-x: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  &-content {
    position: relative;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-main {
      width: 800px;
      height: 500px;
      border-radius: 30px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;

      display: flex;
      background-color: #fff;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &--message {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 38px;
        line-height: 46px;
        color: #262629;
        text-align: center;
      }

      &-smiley {
        display: flex;
        flex-direction: row;
        margin-top: 50px;

        &--icon {
          margin-right: 12.5px;
          margin-left: 12.5px;
          cursor: pointer;
        }
      }

      &__support {
        display: flex;
        margin-top: 40px;
        justify-content: center;
        align-items: center;

        &--saved {
          width: 245px;
          height: 56px;
          border: 3px solid #c4c4c4;
          background: #c4c4c4;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &--btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 245px;
          height: 56px;
          border: 3px solid #c4c4c4;
          border-radius: 8px;
          cursor: pointer;

          font-family: 'Lato';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          color: #c4c4c4;

          &:hover {
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
          }
        }
      }

      &--info-icon {
        position: absolute;
        right: 25px;
        bottom: 25px;
        cursor: pointer;

        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 70% auto;
        transition: all 0.3s ease;

        &:hover {
          box-shadow: 0 0 0 1px rgba(#fff, 0.4), 0 0 0 4px #3034f7;
        }
      }
    }

    &-help {
      display: flex;
      background-color: #3034f7;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 60px;

      width: 800px;
      height: 500px;
      border-radius: 30px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
      color: #fff;
      font-family: 'Lato';
      font-style: normal;

      &--message-header {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
      }

      &--message-description {
        font-weight: 400;
        font-size: 14px;
        line-height: 125%;
        margin-top: 30px;
      }

      &--rating-info {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        margin-top: 30px;
      }

      &-smiley {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        &--info {
          margin-left: 20px;
          font-weight: 700;
          font-size: 14px;
          line-height: 125%;
        }
      }

      &--close-icon {
        position: absolute;
        right: 25px;
        bottom: 25px;
        cursor: pointer;

        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 70% auto;
        transition: all 0.3s ease;

        &:hover {
          box-shadow: 0 0 0 1px rgba(#3034f7, 0.4), 0 0 0 4px #fff;
        }
      }
    }
  }
}

/* Responsive design - make the overlay and its content responsive to the screen size */
@media only screen and (max-width: 768px) {
  .rating-overlay-content {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    box-shadow: none;
    &-main {
      width: 100%;
      height: 100%;
      border-radius: 0px;
      box-shadow: none;
      &--info-icon {
        position: absolute;
        right: 25px;
        top: 25px;
        cursor: pointer;
      }
    }
    &-help {
      width: 100%;
      height: 100%;
      border-radius: 0px;
      box-shadow: none;
      &--close-icon {
        position: absolute;
        right: 25px;
        top: 25px;
        cursor: pointer;
      }
    }
  }
}

.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
