<template>
  <div v-if="visitorId != null" class="video-screen-maximized">
    <audio-video-settings
      v-if="showSettings"
      @close="onCloseSettings()"
      :show="showSettings"
      :selected-audio="selectedAudio"
      :selected-video="selectedVideo"
      :selected-speaker="selectedSpeaker"
      :volume-level="volumeLevel"
    ></audio-video-settings>
    <div class="video-screen-maximized-navbar">
      <div class="video-screen-maximized-navbar-left-container">
        <agent-modal-header></agent-modal-header>
      </div>
      <div class="video-screen-maximized-navbar-right-container">
        <div class="video-screen-maximized-navbar-communications-control mr-2">
          <div
            class="video-screen-maximized-navbar-communications-control-button"
            :class="{
              'pa-bk-active': messageModes.includes('chat'),
              'video-screen-maximized-navbar-communications-control-button--unread': !messageModes.includes('chat') && unreadChatMessages.length
            }"
            @click="onChangeCommunicationControl('chat')"
          >
            <chat-icon :class="{ 'pa-icon-default': !messageModes.includes('chat') && !unreadChatMessages.length }" :width="16" :height="16">
              <div
                v-if="!messageModes.includes('chat') && unreadChatMessages.length"
                class="video-screen-maximized-navbar-communications-control-button__chat-unread-count"
              />
            </chat-icon>
          </div>
          <div class="video-screen-maximized-navbar-communications-control-button" :class="audioButtonClass" @click="onChangeCommunicationControl('audio')">
            <microphone-icon v-if="!isAudioDisabled && messageModes.includes('audio')" :class="audioButtonIconClass" :width="16" :height="16"></microphone-icon>
            <microphone-off-icon v-else :class="audioButtonIconClass" :width="16" :height="16"></microphone-off-icon>
          </div>
          <div class="video-screen-maximized-navbar-communications-control-button" :class="videoButtonClass" @click="onChangeCommunicationControl('video')">
            <video-call-icon v-if="!isVideoDisabled && messageModes.includes('video')" :class="videoButtonIconClass" :width="16" :height="16"></video-call-icon>
            <video-call-off-icon v-else :class="videoButtonIconClass" :width="16" :height="16"></video-call-off-icon>
          </div>
        </div>
        <div class="video-screen-maximized-navbar-button mr-2" @click="showSettings = true">
          <settings-icon class="video-screen-maximized-navbar-icon pa-icon-default" :width="16" :height="16"></settings-icon>
        </div>
        <div class="video-screen-maximized-navbar-button white-bk mr-2" @click="$emit('minimize-screen')">
          <minimize-icon class="pa-icon-default" :width="20" :height="20"></minimize-icon>
        </div>
        <div class="video-screen-maximized-navbar-button pa-bk-danger mr-4" @click="$emit('close-communication-control')">
          <phone-icon class="video-screen-maximized-navbar-icon" :width="20" :height="20"></phone-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SettingsIcon from '../../../components/icons/SettingsIcon.vue'
import ChatIcon from '../../../components/icons/ChatIcon.vue'
import PhoneIcon from '../../../components/icons/PhoneIcon.vue'
import MicrophoneIcon from '../../../components/icons/MicrophoneIcon.vue'
import MicrophoneOffIcon from '../../../components/icons/MicrophoneOffIcon.vue'
import VideoCallIcon from '../../../components/icons/VideoCallIcon.vue'
import VideoCallOffIcon from '../../../components/icons/VideoCallOffIcon.vue'
import MinimizeIcon from '../../../components/icons/MinimizeIcon.vue'
import AgentModalHeader from './AgentHeader'
import AudioVideoSettings from './AudioVideoSettings.vue'

import { mapGetters } from 'vuex'

export default {
  components: {
    SettingsIcon,
    ChatIcon,
    PhoneIcon,
    MicrophoneIcon,
    MicrophoneOffIcon,
    VideoCallIcon,
    VideoCallOffIcon,
    MinimizeIcon,
    AudioVideoSettings,
    AgentModalHeader
  },
  computed: {
    ...mapGetters({
      messageModes: 'webrtc/messageModes',
      visitorId: 'webrtc/visitorId',
      company: 'company'
    }),
    isAudioDisabled() {
      return this.company && this.company.disableAudio
    },
    audioButtonClass() {
      if (this.isAudioDisabled) return 'video-screen-maximized-navbar-communications-control-button-disabled'
      if (this.messageModes.includes('audio') || this.micEnabled) return 'pa-bk-active'
      return ''
    },
    audioButtonIconClass() {
      if (this.isAudioDisabled) return 'video-screen-maximized-navbar-communications-control-button-icon-disabled'
      if (!this.messageModes.includes('audio')) return 'pa-icon-default'
      return ''
    },
    isVideoDisabled() {
      return this.company && this.company.disableVideo
    },
    videoButtonClass() {
      if (this.isVideoDisabled) return 'video-screen-maximized-navbar-communications-control-button-disabled'
      if (this.messageModes.includes('video')) return 'pa-bk-active'
      return ''
    },
    videoButtonIconClass() {
      if (this.isVideoDisabled) return 'video-screen-maximized-navbar-communications-control-button-icon-disabled'
      if (!this.messageModes.includes('video')) return 'pa-icon-default'
      return ''
    }
  },
  props: {
    selectedAudio: {
      type: Object,
      required: false,
      default: null
    },
    selectedSpeaker: {
      type: Object,
      required: false,
      default: null
    },
    selectedVideo: {
      type: Object,
      required: false,
      default: null
    },
    volumeLevel: {
      type: Number,
      required: true
    },
    unreadChatMessages: {
      required: true
    }
  },
  data() {
    return {
      showSettings: false
    }
  },
  created() {
    this.$serverBus.$on('close-company-info', () => {
      this.showVisitor = false
    })
  },
  methods: {
    onCloseSettings() {
      this.showSettings = false
    },
    onChangeCommunicationControl(mode) {
      if (this.isAudioDisabled && mode === 'audio') return
      if (this.isVideoDisabled && mode === 'video') return
      this.$emit('change-communication-control', mode)
    }
  }
}
</script>
<style lang="scss">
.pa-bk-active {
  background: #275D73 !important;
}
.pa-bk-active:hover {
  background: #275D73 !important;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.5);
}
.pa-bk-danger {
  background: rgba(240, 85, 65, 0.8) !important;
}
.pa-bk-danger:hover {
  background: #f05541 !important;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.5);
}

.pa-icon-default {
  color: rgba(var(--vs-secondary), 1) !important;
  path {
    stroke: rgba(var(--vs-secondary), 1) !important;
  }
}
.video-screen-maximized {
  &-navbar {
    display: flex;
    background-color: rgba(var(--vs-secondary), 1) !important;
    border: 0.5px solid #e6ecf1;
    box-sizing: border-box;
    height: 65px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;

    &-icon {
      margin-left: -1px;
      cursor: pointer;
    }

    &-button {
      width: 35px;
      height: 35px;
      background: white;
      border-radius: 6px;
      display: flex;
      cursor: pointer;

      justify-content: center;
      align-items: center;
      transition: 0.5s;
    }
    &-button:hover {
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.5);
    }
    &-left-container {
      width: 100%;
    }

    &-communications-control {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      @media only screen and (max-width: 600px) {
        &-button {
          max-width: 35px;
        }
      }

      &-button {
        width: 106px;
        height: 35px;
        background: #f7f7f7;
        border-radius: 6px;
        display: flex;
        box-shadow: 0px 2px 4px rgba(196, 196, 196, 0.25);
        cursor: pointer;
        justify-content: center;
        align-items: center;
        transition: 0.5s;

        &-disabled {
          background: #d3d3d3 !important;
          cursor: default !important;
        }
        &-disabled:hover {
          background: #d3d3d3 !important;
          box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.5);
        }

        &-icon-disabled {
          path {
            stroke: #fff !important;
            fill: #d3d3d3 !important;
          }
        }

        &--unread {
          background: #12598d;
        }

        &__chat-unread-count {
          position: absolute;
          top: -2px;
          right: -2px;
          height: 7px;
          width: 7px;
          color: #fff;
          background-color: #f05541;
          border-radius: 50%;
        }
      }
      &-button:hover {
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.5);
      }
    }

    &-right-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
    }

    &-chat-buttons {
      &-btn {
        width: 106.6px;
        height: 35px;
        &-highlighted {
          background-color: rgba(var(--vs-primary), 1) !important;
        }
      }
    }
  }
}
</style>
