var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "rating-overlay" }, [
    _c("div", { staticClass: "rating-overlay-content" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showHelp,
              expression: "!showHelp",
            },
          ],
          staticClass: "rating-overlay-content-main",
        },
        [
          _c("div", { staticClass: "rating-overlay-content-main--message" }, [
            _vm._v(_vm._s(_vm.$t("ratingScreen.screenMessage"))),
          ]),
          _c("div", { staticClass: "rating-overlay-content-main-smiley" }, [
            _c(
              "div",
              {
                staticClass: "rating-overlay-content-main-smiley--icon",
                on: {
                  mouseover: function ($event) {
                    return _vm.onMouseHover("bad")
                  },
                  mouseleave: function ($event) {
                    return _vm.onMouseLeave("bad")
                  },
                  click: function ($event) {
                    return _vm.onSaveConversation("bad")
                  },
                },
              },
              [
                _vm.isRatingFailureSaved
                  ? _c("rating-saved-icon")
                  : _c("rating-failure-icon", {
                      attrs: { hover: _vm.ratingFailureHover },
                    }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "rating-overlay-content-main-smiley--icon",
                on: {
                  mouseover: function ($event) {
                    return _vm.onMouseHover("neutral")
                  },
                  mouseleave: function ($event) {
                    return _vm.onMouseLeave("neutral")
                  },
                  click: function ($event) {
                    return _vm.onSaveConversation("neutral")
                  },
                },
              },
              [
                _vm.isRatingNeutralSaved
                  ? _c("rating-saved-icon")
                  : _c("rating-neutral-icon", {
                      attrs: { hover: _vm.ratingNeutralHover },
                    }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "rating-overlay-content-main-smiley--icon",
                on: {
                  mouseover: function ($event) {
                    return _vm.onMouseHover("good")
                  },
                  mouseleave: function ($event) {
                    return _vm.onMouseLeave("good")
                  },
                  click: function ($event) {
                    return _vm.onSaveConversation("good")
                  },
                },
              },
              [
                _vm.isRatingSuccessSaved
                  ? _c("rating-saved-icon")
                  : _c("rating-success-icon", {
                      attrs: { hover: _vm.ratingSuccessHover },
                    }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "rating-overlay-content-main__support",
              on: {
                click: function ($event) {
                  return _vm.onSaveConversation("support")
                },
              },
            },
            [
              _vm.isRatingSupportSaved
                ? _c(
                    "div",
                    {
                      staticClass:
                        "rating-overlay-content-main__support--saved",
                    },
                    [_c("rating-support-saved-icon")],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticClass: "rating-overlay-content-main__support--btn",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("ratingScreen.supportButtonText")) +
                          "\n        "
                      ),
                    ]
                  ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "rating-overlay-content-main--info-icon",
              on: { click: _vm.onShowHelp },
            },
            [_c("rating-help-icon")],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showHelp,
              expression: "showHelp",
            },
          ],
          staticClass: "rating-overlay-content-help",
        },
        [
          _c(
            "div",
            { staticClass: "rating-overlay-content-help--message-header" },
            [_vm._v(_vm._s(_vm.$t("ratingScreen.helpHeader")))]
          ),
          _c("div", {
            staticClass: "rating-overlay-content-help--message-description",
            domProps: {
              innerHTML: _vm._s(_vm.$t("ratingScreen.helpDescription")),
            },
          }),
          _c(
            "div",
            { staticClass: "rating-overlay-content-help--rating-info" },
            [_vm._v(_vm._s(_vm.$t("ratingScreen.helpLevels")))]
          ),
          _c(
            "div",
            { staticClass: "rating-overlay-content-help-smiley" },
            [
              _c("rating-success-icon", {
                attrs: { width: 31.51, height: 31.51 },
              }),
              _c(
                "div",
                { staticClass: "rating-overlay-content-help-smiley--info" },
                [_vm._v(_vm._s(_vm.$t("ratingScreen.helpSuccess")))]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "rating-overlay-content-help-smiley" },
            [
              _c("rating-neutral-icon", {
                attrs: { width: 31.73, height: 31.73 },
              }),
              _c(
                "div",
                { staticClass: "rating-overlay-content-help-smiley--info" },
                [_vm._v(_vm._s(_vm.$t("ratingScreen.helpNeutral")))]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "rating-overlay-content-help-smiley" },
            [
              _c("rating-failure-icon", {
                attrs: { width: 31.73, height: 31.73 },
              }),
              _c(
                "div",
                { staticClass: "rating-overlay-content-help-smiley--info" },
                [_vm._v(_vm._s(_vm.$t("ratingScreen.helpWaste")))]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "rating-overlay-content-help--close-icon",
              on: { click: _vm.onHideHelp },
            },
            [_c("close-icon")],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }