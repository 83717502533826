<template>
  <div class="linked-visitor-info">
    <div class="linked-visitor-info__close" @click.prevent="closeLinkedVisitorInfo">
      <close-icon :width="20" :height="20" color="#575757"></close-icon>
    </div>
    <div class="linked-visitor-info__content">
      <div class="linked-visitor-info__content-wrapper">
        <scroll-view class="linked-visitor-info__content-scroll-view" style="max-height: 460px">
          <div class="linked-visitor-info__item" v-if="linkedCCFormFields.salutation">
            <div class="linked-visitor-info__item__title">Salutation:</div>
            <div class="linked-visitor-info__item__subtitle">{{ linkedCCFormFields.salutation }}</div>
          </div>
          <div class="linked-visitor-info__item" v-if="linkedCCFormFields.companyName">
            <div class="linked-visitor-info__item__title">Company name:</div>
            <div class="linked-visitor-info__item__subtitle">{{ linkedCCFormFields.companyName }}</div>
          </div>
          <div class="linked-visitor-info__item" v-if="linkedCCFormFields.companyDescription">
            <div class="linked-visitor-info__item__title">Company Description:</div>
            <div class="linked-visitor-info__item__subtitle">{{ linkedCCFormFields.companyDescription }}</div>
          </div>
          <div class="linked-visitor-info__item" v-if="linkedCCFormFields.generalEmailAddress">
            <div class="linked-visitor-info__item__title">General Email:</div>
            <div class="linked-visitor-info__item__subtitle">{{ linkedCCFormFields.generalEmailAddress }}</div>
          </div>
          <div class="linked-visitor-info__item" v-if="linkedCCFormFields.generalPhoneNumber">
            <div class="linked-visitor-info__item__title">General Phone Number:</div>
            <div class="linked-visitor-info__item__subtitle">{{ linkedCCFormFields.generalPhoneNumber }}</div>
          </div>
          <div class="linked-visitor-info__item" v-if="linkedCCFormFields.internalContactPerson">
            <div class="linked-visitor-info__item__title">Contact Person:</div>
            <div class="linked-visitor-info__item__subtitle">{{ linkedCCFormFields.internalContactPerson }}</div>
          </div>
          <div class="linked-visitor-info__item" v-if="linkedCCFormFields.reasons">
            <div class="linked-visitor-info__item__title">General Contact Reasons:</div>
            <div class="linked-visitor-info__item__subtitle">{{ linkedCCFormFields.reasons }}</div>
          </div>
          <div class="linked-visitor-info__item" v-if="linkedCCFormFields.neededCustomerInformation">
            <div class="linked-visitor-info__item__title">Needed Customer Information:</div>
            <div class="linked-visitor-info__item__subtitle">
              <div class="linked-visitor-info__item__subtitle--list-item" v-for="(item, index) in linkedCCFormFields.neededCustomerInformation" :key="index">
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="linked-visitor-info__item" v-if="linkedCCFormFields.contactRequestsEmail">
            <div class="linked-visitor-info__item__title">Forward lead to:</div>
            <div class="linked-visitor-info__item__subtitle">{{ linkedCCFormFields.contactRequestsEmail }}</div>
          </div>
          <div class="linked-visitor-info__item" v-if="linkedCCFormFields.handleRequests && linkedCCFormFields.handleRequests.name">
            <div class="linked-visitor-info__item__title">Sales requests:</div>
            <div class="linked-visitor-info__item__subtitle">{{ linkedCCFormFields.handleRequests.name }}</div>
          </div>
        </scroll-view>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/components/icons/CloseIcon.vue'
import ScrollView from '@blackbp/vue-smooth-scrollbar'
export default {
  name: 'LinkedVisitorInfo',
  components: {
    CloseIcon,
    ScrollView
  },
  props: {
    linkedCCFormFields: {
      type: Object
    }
  },
  methods: {
    closeLinkedVisitorInfo() {
      this.$serverBus.$emit('close-linked-visitor-info')
    }
  }
}
</script>

<style lang="scss">
.linked-visitor-info__content-scroll-view {
  .scrollbar-thumb {
    background: #575757 !important;
  }
  .scroll-content {
    padding-right: 15px;
  }
}
</style>

<style lang="scss" scoped>
.linked-visitor-info {
  width: 100%;
  max-width: 400px;
  height: 100%;
  max-height: 600px;
  padding: 65px 30px 30px 30px;
  background: #f7f7f7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  position: relative;
  &__close {
    position: absolute;
    right: 28px;
    cursor: pointer;
    top: 25px;
  }
  &__content {
    max-width: 340px;
    width: 100%;
    background: #ffffff;
    border-radius: 6px;
    &-wrapper {
      padding: 20px 13px 20px 20px;
      max-height: 500px;
    }
  }
  &__item {
    padding: 10px 0;
    border-bottom: 1px solid #ebebeb;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &:last-child {
      border-bottom: none;
    }
    &__title {
      font-size: 15px;
      font-weight: 700;
      line-height: 18px;
      color: #575757;
      margin-bottom: 5px;
    }
    &__subtitle {
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
      color: #b2b2b2;
      &--list-item {
        display: flex;
        text-align: left;
        align-items: center;
        justify-items: flex-start;
        padding-left: 30px;
        position: relative;
        margin-bottom: 5px;
        &::before {
          content: '';
          position: absolute;
          left: 15px;
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background-color: #b2b2b2;
        }
      }
    }
  }
}
</style>
