<template>
  <div class="video-screen-minimized-navbar">
    <div class="video-screen-minimized-navbar-top-right">
      <div class="flex flex-row">
        <div class="video-screen-minimized-navbar-top-right-button white-bk mr-2" @click="$emit('on-maximize-screen')">
          <maximize-icon class="pa-icon-default" :width="20" :height="20"></maximize-icon>
        </div>
        <div class="video-screen-minimized-navbar-top-right-button red-bk" @click="$emit('on-close-communication-control')">
          <phone-icon :width="20" :height="20"></phone-icon>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PhoneIcon from '../../../components/icons/PhoneIcon.vue'
import MaximizeIcon from '../../../components/icons/MaximizeIcon.vue'
export default {
  components: {
    PhoneIcon,
    MaximizeIcon
  }
}
</script>
<style lang="scss" scoped>
.video-screen-minimized-navbar {
  &-top-right {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 9999;
    &-button {
      cursor: pointer;
      width: 30px;
      height: 30px;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      .pa-icon-default {
        color: rgba(var(--vs-secondary), 1) !important;
        path {
          stroke: rgba(var(--vs-secondary), 1) !important;
        }
      }
    }
    &-button:hover {
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    }
  }
  .red-bk {
    background: #f05541;
  }
  .white-bk {
    background: white;
  }
}
</style>
