var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "pathadvice-lead-form pathadvice-lead-form--chat pathadvice-lead-form--mobile",
      style: {
        "--contact-form-font-color": _vm.dialog.contactForm.fontColor,
        "--contact-form-background": _vm.dialog.contactForm
          .isDefaultContactFormInsideChat
          ? "#EFF1F5"
          : _vm.toolBarBackground,
        "--contact-form-darken-background":
          _vm.darkСontactFormSendButtonBackgroundColor,
        "--contact-form-dark-background":
          _vm.darkСontactFormSendButtonBackgroundColor,
      },
    },
    [
      _c("div", { staticClass: "mt-0 pathadvice-lead-form__fields" }, [
        _c("div", [
          _c(
            "div",
            {
              staticClass:
                "pathadvice__contact-form__main-container__form__field",
              class: {
                "pathadvice__contact-form__main-container__form__field_invalid":
                  _vm.errors.first("name"),
              },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.name,
                    expression: "name",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      required: true,
                      max: 80,
                    },
                    expression:
                      "{\n            required: true,\n            max: 80\n          }",
                  },
                ],
                key: "name",
                staticClass:
                  "pathadvice__contact-form__main-container__form__input",
                style:
                  "background: " +
                  (_vm.dialog.contactForm.isDefaultContactFormInsideChat
                    ? "#CFD2D7"
                    : "rgba(230, 236, 241, 0.6)"),
                attrs: {
                  name: "name",
                  placeholder: _vm._f("modifyInputPlaceholder")(
                    _vm.$t("vue.name"),
                    true
                  ),
                },
                domProps: { value: _vm.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.name = $event.target.value
                  },
                },
              }),
              _vm.errors.first("name")
                ? _c(
                    "p",
                    {
                      staticClass:
                        "pathadvice__contact-form__main-container__form__field__error-checkbox",
                      style:
                        "color: " +
                        (_vm.dialog.contactForm.isDefaultContactFormInsideChat
                          ? "#262629"
                          : "#fff"),
                    },
                    [
                      _vm._v(
                        "\n          * " +
                          _vm._s(_vm.errors.first("name")) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
              _c("UserIcon", {
                staticClass:
                  "pathadvice__contact-form__main-container__form__field__icon",
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "pathadvice__contact-form__main-container__form__field",
              class: {
                "pathadvice__contact-form__main-container__form__field_invalid":
                  _vm.errors.first("email"),
              },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      required: true,
                      email: true,
                    },
                    expression:
                      "{\n            required: true,\n            email: true\n          }",
                  },
                ],
                key: "email",
                staticClass:
                  "pathadvice__contact-form__main-container__form__input",
                style:
                  "background: " +
                  (_vm.dialog.contactForm.isDefaultContactFormInsideChat
                    ? "#CFD2D7"
                    : "rgba(230, 236, 241, 0.6)"),
                attrs: {
                  name: "email",
                  placeholder: _vm._f("modifyInputPlaceholder")(
                    _vm.$t("vue.emailAddress"),
                    true
                  ),
                },
                domProps: { value: _vm.email },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.email = $event.target.value
                  },
                },
              }),
              _vm.errors.first("email")
                ? _c(
                    "p",
                    {
                      staticClass:
                        "pathadvice__contact-form__main-container__form__field__error",
                      style:
                        "color: " +
                        (_vm.dialog.contactForm.isDefaultContactFormInsideChat
                          ? "#262629"
                          : "#fff"),
                    },
                    [
                      _vm._v(
                        "\n          * " +
                          _vm._s(_vm.errors.first("email")) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
              _c("MailIcon", {
                staticClass:
                  "pathadvice__contact-form__main-container__form__field__icon",
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "pathadvice__contact-form__main-container__form__field",
              class: {
                "pathadvice__contact-form__main-container__form__field_invalid":
                  _vm.errors.first("phone"),
              },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.phone,
                    expression: "phone",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      max: 15,
                    },
                    expression: "{\n            max: 15\n          }",
                  },
                ],
                key: "phone",
                staticClass:
                  "pathadvice__contact-form__main-container__form__input",
                style:
                  "background: " +
                  (_vm.dialog.contactForm.isDefaultContactFormInsideChat
                    ? "#CFD2D7"
                    : "rgba(230, 236, 241, 0.6)"),
                attrs: {
                  name: "phone",
                  placeholder: _vm._f("modifyInputPlaceholder")(
                    _vm.$t("vue.phone"),
                    false
                  ),
                },
                domProps: { value: _vm.phone },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.phone = $event.target.value
                  },
                },
              }),
              _vm.errors.first("phone")
                ? _c(
                    "p",
                    {
                      staticClass:
                        "pathadvice__contact-form__main-container__form__field__error",
                      style:
                        "color: " +
                        (_vm.dialog.contactForm.isDefaultContactFormInsideChat
                          ? "#262629"
                          : "#fff"),
                    },
                    [
                      _vm._v(
                        "\n          * " +
                          _vm._s(_vm.errors.first("phone")) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
              _c("VerticalPhoneIcon", {
                staticClass:
                  "pathadvice__contact-form__main-container__form__field__icon",
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "pathadvice__contact-form__main-container__form__field",
              class: {
                "pathadvice__contact-form__main-container__form__field_invalid":
                  _vm.errors.first("country"),
              },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.countryName,
                    expression: "countryName",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      max: 50,
                    },
                    expression: "{\n            max: 50\n          }",
                  },
                ],
                key: "country",
                staticClass:
                  "pathadvice__contact-form__main-container__form__input",
                style:
                  "background: " +
                  (_vm.dialog.contactForm.isDefaultContactFormInsideChat
                    ? "#CFD2D7"
                    : "rgba(230, 236, 241, 0.6)"),
                attrs: {
                  name: "country",
                  placeholder: _vm._f("modifyInputPlaceholder")(
                    _vm.$t("vue.country"),
                    false
                  ),
                },
                domProps: { value: _vm.countryName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.countryName = $event.target.value
                  },
                },
              }),
              _vm.errors.first("country")
                ? _c(
                    "p",
                    {
                      staticClass:
                        "pathadvice__contact-form__main-container__form__field__error",
                      style:
                        "color: " +
                        (_vm.dialog.contactForm.isDefaultContactFormInsideChat
                          ? "#262629"
                          : "#fff"),
                    },
                    [
                      _vm._v(
                        "\n          * " +
                          _vm._s(_vm.errors.first("country")) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
              _c("FlagIcon", {
                staticClass:
                  "pathadvice__contact-form__main-container__form__field__icon",
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "pathadvice__contact-form__main-container__form__field",
              class: {
                "pathadvice__contact-form__main-container__form__field_invalid":
                  _vm.errors.first("company"),
              },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.companyName,
                    expression: "companyName",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      max: 250,
                    },
                    expression: "{\n            max: 250\n          }",
                  },
                ],
                key: "company",
                staticClass:
                  "pathadvice__contact-form__main-container__form__input",
                style:
                  "background: " +
                  (_vm.dialog.contactForm.isDefaultContactFormInsideChat
                    ? "#CFD2D7"
                    : "rgba(230, 236, 241, 0.6)"),
                attrs: {
                  name: "company",
                  placeholder: _vm._f("modifyInputPlaceholder")(
                    _vm.$t("vue.company"),
                    false
                  ),
                },
                domProps: { value: _vm.companyName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.companyName = $event.target.value
                  },
                },
              }),
              _vm.errors.first("company")
                ? _c(
                    "p",
                    {
                      staticClass:
                        "pathadvice__contact-form__main-container__form__field__error",
                      style:
                        "color: " +
                        (_vm.dialog.contactForm.isDefaultContactFormInsideChat
                          ? "#262629"
                          : "#fff"),
                    },
                    [
                      _vm._v(
                        "\n          * " +
                          _vm._s(_vm.errors.first("company")) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
              _c("CompanyIcon", {
                staticClass:
                  "pathadvice__contact-form__main-container__form__field__icon",
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          {
            staticClass:
              "pathadvice__contact-form__main-container__form__actions pathadvice__contact-form__main-container__form__footer",
          },
          [
            _c(
              "button",
              {
                staticClass:
                  "pathadvice__contact-form__main-container__form__actions__btn",
                style:
                  "background: " +
                  (_vm.dialog.contactForm.isDefaultContactFormInsideChat
                    ? "#494A4E"
                    : _vm.darkСontactFormSendButtonBackgroundColor) +
                  "; color: #fff !important",
                attrs: { disabled: _vm.isLoadingSubmit },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n        " + _vm._s(_vm.$t("vue.send")) + "\n      ")]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }