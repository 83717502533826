var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "feather feather-volume-x",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 24 24",
        fill: "none",
        stroke: _vm.color,
        "stroke-width": "2",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
      },
    },
    [
      _c("polygon", { attrs: { points: "11 5 6 9 2 9 2 15 6 15 11 19 11 5" } }),
      _c("line", { attrs: { x1: "23", y1: "9", x2: "17", y2: "15" } }),
      _c("line", { attrs: { x1: "17", y1: "9", x2: "23", y2: "15" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }