<template>
  <div class="conversation-completed">
    <div class="conversation-completed-close flex justify-center items-center" @click="$parent.$emit('on-conversation-completed')">
      <close-icon :width="20" :height="20"></close-icon>
    </div>
    <div class="flex justify-center items-center" style="width: 100%; height: 100%">
      <div class="flex flex-col conversation-completed-message">
        <span>{{ $t('vue.wellDone') }}</span>
        <span>{{ $t('vue.conversationCompleted') }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import CloseIcon from '../../../components/icons/CloseIcon.vue'
export default {
  components: {
    CloseIcon
  }
}
</script>
<style lang="scss" scoped>
.conversation-completed {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: rgba(230, 236, 241, 0.8);
  flex-wrap: wrap;
  &-message {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 56px;
    text-align: center;
    display: flex;
    color: rgba(var(--vs-secondary), 1) !important;
    span {
      text-transform: uppercase;
    }
  }
  &-close {
    position: absolute;
    width: 35px;
    height: 35px;
    top: 25px;
    right: 25px;
    background-color: rgba(var(--vs-secondary), 1) !important;
    border-radius: 6px;
    cursor: pointer;
  }
  &-close:hover {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  }
}
</style>
