var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 66 66",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("circle", {
        attrs: { cx: _vm.cx, cy: _vm.cy, r: _vm.cx, fill: _vm.stroke },
      }),
      _c("path", {
        attrs: {
          d: "M46.3333 24L27.5417 42.7917L19 34.25",
          stroke: "white",
          "stroke-width": "5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }