var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "linked-visitor-info" }, [
    _c(
      "div",
      {
        staticClass: "linked-visitor-info__close",
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.closeLinkedVisitorInfo.apply(null, arguments)
          },
        },
      },
      [
        _c("close-icon", {
          attrs: { width: 20, height: 20, color: "#575757" },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "linked-visitor-info__content" }, [
      _c(
        "div",
        { staticClass: "linked-visitor-info__content-wrapper" },
        [
          _c(
            "scroll-view",
            {
              staticClass: "linked-visitor-info__content-scroll-view",
              staticStyle: { "max-height": "460px" },
            },
            [
              _vm.linkedCCFormFields.salutation
                ? _c("div", { staticClass: "linked-visitor-info__item" }, [
                    _c(
                      "div",
                      { staticClass: "linked-visitor-info__item__title" },
                      [_vm._v("Salutation:")]
                    ),
                    _c(
                      "div",
                      { staticClass: "linked-visitor-info__item__subtitle" },
                      [_vm._v(_vm._s(_vm.linkedCCFormFields.salutation))]
                    ),
                  ])
                : _vm._e(),
              _vm.linkedCCFormFields.companyName
                ? _c("div", { staticClass: "linked-visitor-info__item" }, [
                    _c(
                      "div",
                      { staticClass: "linked-visitor-info__item__title" },
                      [_vm._v("Company name:")]
                    ),
                    _c(
                      "div",
                      { staticClass: "linked-visitor-info__item__subtitle" },
                      [_vm._v(_vm._s(_vm.linkedCCFormFields.companyName))]
                    ),
                  ])
                : _vm._e(),
              _vm.linkedCCFormFields.companyDescription
                ? _c("div", { staticClass: "linked-visitor-info__item" }, [
                    _c(
                      "div",
                      { staticClass: "linked-visitor-info__item__title" },
                      [_vm._v("Company Description:")]
                    ),
                    _c(
                      "div",
                      { staticClass: "linked-visitor-info__item__subtitle" },
                      [
                        _vm._v(
                          _vm._s(_vm.linkedCCFormFields.companyDescription)
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm.linkedCCFormFields.generalEmailAddress
                ? _c("div", { staticClass: "linked-visitor-info__item" }, [
                    _c(
                      "div",
                      { staticClass: "linked-visitor-info__item__title" },
                      [_vm._v("General Email:")]
                    ),
                    _c(
                      "div",
                      { staticClass: "linked-visitor-info__item__subtitle" },
                      [
                        _vm._v(
                          _vm._s(_vm.linkedCCFormFields.generalEmailAddress)
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm.linkedCCFormFields.generalPhoneNumber
                ? _c("div", { staticClass: "linked-visitor-info__item" }, [
                    _c(
                      "div",
                      { staticClass: "linked-visitor-info__item__title" },
                      [_vm._v("General Phone Number:")]
                    ),
                    _c(
                      "div",
                      { staticClass: "linked-visitor-info__item__subtitle" },
                      [
                        _vm._v(
                          _vm._s(_vm.linkedCCFormFields.generalPhoneNumber)
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm.linkedCCFormFields.internalContactPerson
                ? _c("div", { staticClass: "linked-visitor-info__item" }, [
                    _c(
                      "div",
                      { staticClass: "linked-visitor-info__item__title" },
                      [_vm._v("Contact Person:")]
                    ),
                    _c(
                      "div",
                      { staticClass: "linked-visitor-info__item__subtitle" },
                      [
                        _vm._v(
                          _vm._s(_vm.linkedCCFormFields.internalContactPerson)
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm.linkedCCFormFields.reasons
                ? _c("div", { staticClass: "linked-visitor-info__item" }, [
                    _c(
                      "div",
                      { staticClass: "linked-visitor-info__item__title" },
                      [_vm._v("General Contact Reasons:")]
                    ),
                    _c(
                      "div",
                      { staticClass: "linked-visitor-info__item__subtitle" },
                      [_vm._v(_vm._s(_vm.linkedCCFormFields.reasons))]
                    ),
                  ])
                : _vm._e(),
              _vm.linkedCCFormFields.neededCustomerInformation
                ? _c("div", { staticClass: "linked-visitor-info__item" }, [
                    _c(
                      "div",
                      { staticClass: "linked-visitor-info__item__title" },
                      [_vm._v("Needed Customer Information:")]
                    ),
                    _c(
                      "div",
                      { staticClass: "linked-visitor-info__item__subtitle" },
                      _vm._l(
                        _vm.linkedCCFormFields.neededCustomerInformation,
                        function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass:
                                "linked-visitor-info__item__subtitle--list-item",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.name) +
                                  "\n            "
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ])
                : _vm._e(),
              _vm.linkedCCFormFields.contactRequestsEmail
                ? _c("div", { staticClass: "linked-visitor-info__item" }, [
                    _c(
                      "div",
                      { staticClass: "linked-visitor-info__item__title" },
                      [_vm._v("Forward lead to:")]
                    ),
                    _c(
                      "div",
                      { staticClass: "linked-visitor-info__item__subtitle" },
                      [
                        _vm._v(
                          _vm._s(_vm.linkedCCFormFields.contactRequestsEmail)
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm.linkedCCFormFields.handleRequests &&
              _vm.linkedCCFormFields.handleRequests.name
                ? _c("div", { staticClass: "linked-visitor-info__item" }, [
                    _c(
                      "div",
                      { staticClass: "linked-visitor-info__item__title" },
                      [_vm._v("Sales requests:")]
                    ),
                    _c(
                      "div",
                      { staticClass: "linked-visitor-info__item__subtitle" },
                      [
                        _vm._v(
                          _vm._s(_vm.linkedCCFormFields.handleRequests.name)
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }