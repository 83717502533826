var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "portal",
    { attrs: { selector: "#visitor-search-connected-agent-info" } },
    [
      _c(
        "div",
        {
          staticClass: "pa-agents arrow-left",
          style: _vm.styleObject,
          attrs: { id: _vm.visitorId },
        },
        [
          _c(
            "div",
            {
              staticClass: "pa-agents-close-icon",
              staticStyle: {
                position: "absolute",
                top: "20px",
                right: "20px",
                "z-index": "60002",
              },
              on: { click: _vm.closeConnectedAgentsInfo },
            },
            [_c("close-icon", { attrs: { width: 12, height: 12 } })],
            1
          ),
          _c("scroll-view", { staticClass: "pa-agents-scroll-area" }, [
            _c(
              "div",
              { staticClass: "pa-agents-box" },
              _vm._l(_vm.agents, function (agent, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "pa-agents-info" },
                  [
                    agent.type === "missed"
                      ? _c("div", { staticClass: "pa-agents-info-missed" }, [
                          _vm._v("missed"),
                        ])
                      : _c(
                          "div",
                          { staticClass: "pa-agents-info-image" },
                          [
                            _c("Avatar", {
                              staticClass: "pa-agents-info-image-avatar",
                              attrs: {
                                size: "45px",
                                photoURL: _vm.agentImage(agent.agentId),
                                name: _vm.agentName(agent.agentId),
                              },
                            }),
                          ],
                          1
                        ),
                    agent.type === "missed"
                      ? _c("div", { staticClass: "pa-agents-info-summary" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "pa-agents-info-summary-session-time",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.timeInfo(agent)) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ])
                      : _c("div", { staticClass: "pa-agents-info-summary" }, [
                          _c(
                            "div",
                            {
                              staticClass: "pa-agents-info-summary-agent-name",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.agentName(agent.agentId)) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "pa-agents-info-summary-session-time",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.timeInfo(agent)) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]),
                  ]
                )
              }),
              0
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }