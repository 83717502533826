<template>
  <div class="request-header">
    <subscriptions :isMobile="isMobile"></subscriptions>
    <!-- <filter-by-date-range></filter-by-date-range> -->
    <!-- <filter-by-sessions class="request-header__filter-by-sessions"></filter-by-sessions> -->
    <search-visitor class="request-header-align-right mb-2" :conversation-status="conversationStatus"></search-visitor>
  </div>
</template>
<script>
import Subscriptions from './Subscriptions.vue'
//import FilterBySessions from './FilterBySessions.vue'
//import FilterByDateRange from './FilterByDateRange.vue'
import SearchVisitor from './SearchVisitor.vue'

export default {
  components: {
    Subscriptions,
    // FilterBySessions,
    // FilterByDateRange,
    SearchVisitor
  },
  props: {
    conversationStatus: {
      type: String,
      required: true
    },
    isMobile: {
      type: Boolean,
      required: false
    }
  },
  watch: {
    '$i18n.locale'() {
      setTimeout(() => {
        if (document.getElementById('upgradeAddonCC')) {
          document.getElementById('upgradeAddonCC').addEventListener('click', this.upgradeAddonCC)
        }
      }, 100)
    }
  },
  methods: {
    upgradeAddonCC() {
      /* Feature to be implemented */
    }
  }
}
</script>
<style lang="scss">
.request-header {
  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  @media only screen and (min-width: 601px) {
    display: flex;
    flex-direction: row;
  }
  &-align-right {
    margin-left: auto;
  }
}

.notification-type .vs-dropdown--custom {
  width: fit-content !important;
  padding-left: 10px !important;
  padding-right: 20px !important;
}
.heading-label {
  font-size: 1.2rem;
}
.vs-con-dropdown {
  cursor: pointer;
}
.notification-type {
  cursor: pointer;
  .vs-dropdown--custom {
    width: 160px;
    .vs-component {
      margin-bottom: 10px;
    }
  }
}

.request-header {
  &-align-right {
    @media only screen and (max-width: 1024px) {
      margin: 10px 0 20px !important;
    }

    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }

  &__filter-by-sessions {
    @media only screen and (max-width: 1024px) {
      display: none !important;
    }
  }
}
</style>
