var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "visitor flex pt-4 pb-2",
      class: { "bg-secondary": _vm.isActiveChatUser },
    },
    [
      _c(
        "div",
        { staticClass: "w-full flex flex-row items-center overflow-hidden" },
        [
          _c("div", { staticClass: "visitor-info-holder flex flex-col" }, [
            !!_vm.getDialogOrCampaignName(_vm.visitor)
              ? _c(
                  "div",
                  {
                    staticClass: "visitor-source",
                    class: _vm.VISITOR_SOURCE_CLASS,
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.getDialogOrCampaignName(_vm.visitor)) + " "
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass:
                  "visitor-card flex flex-row justify-content items-center",
              },
              [
                _c("div", {}, [
                  _c("div", { staticClass: "visitor-isp" }, [
                    _vm._v(_vm._s(_vm.getVisitorInfo(_vm.visitor))),
                  ]),
                  _c("div", { staticClass: "flex flex-row" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "vx-tooltip",
                          {
                            attrs: {
                              text: _vm.countryName(_vm.visitor.country),
                              position: "top",
                            },
                          },
                          [
                            _vm.visitor.country
                              ? _c("img", {
                                  attrs: {
                                    width: "20",
                                    alt: _vm.countryName(_vm.visitor.country),
                                    src:
                                      "https://storage.googleapis.com/pathadvice-app.appspot.com/flags/" +
                                      _vm.visitor.country.toUpperCase() +
                                      ".svg",
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "ml-2 visitor-lastseen" }, [
                      _c("span", { staticClass: "ml-2" }, [
                        _vm._v(_vm._s(_vm.visitor.lastSeenTimestamp)),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]),
          _vm.showCommunicationControl
            ? _c(
                "div",
                {
                  staticClass: "visitor-communication-button",
                  class: {
                    "visitor-pulse": _vm.visitor.agentRequest,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.onStartCommunicationWithVisitor($event)
                    },
                  },
                },
                [
                  _vm.visitor.agentRequestType === "chat" ||
                  (!_vm.visitor.agentRequest && !!_vm.unreadChatMessages)
                    ? _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c(
                            "chat-icon",
                            { attrs: { width: 16, height: 16 } },
                            [
                              _vm.unreadChatMessages
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "visitor-chats-unread-count flex justify-center items-center",
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(_vm.unreadChatMessages) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm.visitor.agentRequestType === "audio"
                    ? _c("microphone-icon", {
                        attrs: { width: 16, height: 16 },
                      })
                    : _vm.visitor.agentRequestType === "video"
                    ? _c("video-call-icon", {
                        attrs: { width: 16, height: 16, color: "#fff" },
                      })
                    : _vm._e(),
                ],
                1
              )
            : [
                _vm.showConnectedAgent
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "visitor-connected-agent visitor-pulse br-25 visitor-connected-agent-wh44",
                      },
                      [
                        _c("Avatar", {
                          staticClass: "circle bd-green",
                          attrs: {
                            size: "44px",
                            photoURL: _vm.agentImage(
                              _vm.visitor.connectedAgentId,
                              _vm.visitor.id
                            ),
                            name: _vm.agentName(_vm.visitor.connectedAgentId),
                          },
                        }),
                        _vm.visitor.visitorMessages
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "visitor-chats-unread-count visitor-messages-unread-count flex justify-center items-center",
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.visitor.visitorMessages) +
                                    "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm.showCompletedChatAgent
                  ? _c(
                      "div",
                      { staticClass: "visitor-connected-agent relative" },
                      [
                        _c("div", {
                          staticStyle: {
                            position: "absolute",
                            width: "100px",
                            height: "60px",
                            right: "0px",
                            "z-index": "999999",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.showConnectedAgentsInfo(
                                $event,
                                _vm.visitor.id
                              )
                            },
                          },
                        }),
                        _vm.visitorConnectedAgents
                          ? _c(
                              "div",
                              { staticClass: "visitor-connected-agents" },
                              [
                                _vm.showCheckMarkForConnectedAgents
                                  ? _c("div", {
                                      staticClass:
                                        "visitor-connected-agent-live",
                                      style: {
                                        marginRight:
                                          _vm.visitorConnectedAgents.length *
                                            12 +
                                          48 +
                                          "px",
                                        top: "7px",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._l(
                                  _vm.visitorConnectedAgents,
                                  function (v, index) {
                                    return [
                                      v.type === "missed"
                                        ? _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass:
                                                "visitor-connected-agents-agent visitor-connected-agents-agent-missed",
                                              style: {
                                                marginRight: index * 12 + "px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n              missed\n            "
                                              ),
                                            ]
                                          )
                                        : _c("Avatar", {
                                            key: index,
                                            staticClass:
                                              "visitor-connected-agents-agent visitor-connected-agents-agent-joined",
                                            style: {
                                              marginRight: index * 12 + "px",
                                            },
                                            attrs: {
                                              size: "43px",
                                              photoURL: _vm.agentImage(
                                                v.agentId
                                              ),
                                              name: _vm.agentName(v.agentId),
                                            },
                                          }),
                                    ]
                                  }
                                ),
                                _c("connected-agent-info", {
                                  attrs: {
                                    agents: _vm.visitorConnectedAgentsAll,
                                    visitorId: _vm.visitor.id,
                                  },
                                }),
                              ],
                              2
                            )
                          : _c(
                              "div",
                              [
                                _c("div", {
                                  staticClass: "visitor-connected-agent-live",
                                  style: { marginRight: "60px", top: "7px" },
                                }),
                                _c("Avatar", {
                                  staticClass: "visitor-connected-agents-agent",
                                  attrs: {
                                    size: "45px",
                                    photoURL: _vm.agentImage(
                                      _vm.visitor.agentId
                                    ),
                                    name: _vm.agentName(_vm.visitor.agentId),
                                  },
                                }),
                              ],
                              1
                            ),
                      ]
                    )
                  : _vm.visitorConnectedAgents
                  ? _c(
                      "div",
                      { staticClass: "visitor-connected-agent relative" },
                      [
                        _c("div", {
                          staticStyle: {
                            position: "absolute",
                            width: "100px",
                            height: "60px",
                            right: "0px",
                            "z-index": "999999",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.showConnectedAgentsInfo(
                                $event,
                                _vm.visitor.id
                              )
                            },
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "visitor-connected-agents" },
                          [
                            _vm._l(
                              _vm.visitorConnectedAgents,
                              function (v, index) {
                                return [
                                  v.type === "missed"
                                    ? _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass:
                                            "visitor-connected-agents-agent visitor-connected-agents-agent-missed",
                                          style: {
                                            marginRight: index * 12 + "px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              missed\n            "
                                          ),
                                        ]
                                      )
                                    : _c("Avatar", {
                                        key: index,
                                        staticClass:
                                          "visitor-connected-agents-agent visitor-connected-agents-agent-joined",
                                        style: {
                                          marginRight: index * 12 + "px",
                                        },
                                        attrs: {
                                          size: "43px",
                                          photoURL: _vm.agentImage(v.agentId),
                                          name: _vm.agentName(v.agentId),
                                        },
                                      }),
                                ]
                              }
                            ),
                            _c("connected-agent-info", {
                              attrs: {
                                agents: _vm.visitorConnectedAgentsAll,
                                visitorId: _vm.visitor.id,
                              },
                            }),
                          ],
                          2
                        ),
                      ]
                    )
                  : !_vm.visitor.connectedAgentId &&
                    (_vm.visitor.type === "embed" ||
                      (_vm.visitor.type === "campaign" &&
                        _vm.visitor.isNewFlowCampaign))
                  ? _c(
                      "div",
                      { staticClass: "visitor-connected-agent relative" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "visitor-connected-agents-agent visitor-connected-agents-agent-missed",
                          },
                          [_vm._v("missed")]
                        ),
                      ]
                    )
                  : _c("div", { staticClass: "visitor-connected-agent" }),
              ],
          _vm.showCampaignCobrowseControl && !_vm.IS_APPSUMO_ACCOUNT
            ? _c(
                "div",
                {
                  staticClass:
                    "pl-1 pr-2 visitor-communication-button visitor-communication-button-campaign visitor-pulse",
                  on: {
                    click: function ($event) {
                      return _vm.onJoinSession()
                    },
                  },
                },
                [_c("cobrowse-icon", { attrs: { width: 42, height: 22 } })],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }