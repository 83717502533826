var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "agent-header" },
    [
      _c("Avatar", {
        staticClass: "ml-4",
        attrs: { size: "45px", photoURL: _vm.agentImage, name: _vm.agent },
      }),
      _c("div", { staticClass: "ml-2 text-white" }, [
        _c("div", { staticClass: "agent-header-company" }, [
          _vm._v(_vm._s(_vm.company)),
        ]),
        _c("div", [
          _c("h6", { staticClass: "agent-header-agent" }, [
            _vm._v(_vm._s(_vm.agent)),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }